import React from 'react';

import { Icon, Text } from 'design-library';
import { Button } from 'design-library-v2';

import {
  CyberSecurityIcon,
  DigitalMarketingIcon,
  DataAnalysisDatabaseIcon,
  ProjectManagementIcon,
  PhysicalSecurityIcon,
  SoftwareDevelopmentIcon,
  TechStackBackgroundImgIcon,
  TrainingIcon
} from 'common/images';

import './TechStack.scss';

function TechStackFun1({
  title,
  description,
  icon1,
  icon2,
  btnHire,
  btnJoin,
}: {
  title: string;
  description: string;
  icon1: string;
  icon2: string;
  btnHire: string;
  btnJoin: string;
}) {
  return (
    <div className="techStack-RightImgItem">
      <div className="techStack-RightImgItem-leftSide">
        <Text className="techStack-RightImgItem-leftSide-title" text={title} />
        <Text
          className="techStack-RightImgItem-leftSide-description"
          text={description}
        />
        <div className="techStack-RightImgItem-leftSide-btn">
          <Button
            text={btnHire}
            onClick={() => window.location.replace('/hirer/browse-experts')}
          />
          <Button
            text={btnJoin}
            buttonType="outlined"
            onClick={() => window.location.replace('/hirer')}
          />
        </div>
      </div>

      <div className="techStack-RightImgItem-rightSide">
        <Icon className="techStack-RightImgItem-rightSide-icon" icon={icon1} />
        <Icon
          className="techStack-RightImgItem-rightSide-backgroundIcon"
          icon={icon2}
        />
      </div>
    </div>
  );
}

function TechStackFun2({
  title,
  description,
  icon1,
  icon2,
  btnHire,
  btnJoin,
}: {
  title: string;
  description: string;
  icon1: string;
  icon2: string;
  btnHire: string;
  btnJoin: string;
}) {
  return (
    <div className="techStack-LeftImgItem">
      <div className="techStack-LeftImgItem-leftSide">
        <Icon className="techStack-LeftImgItem-leftSide-icon" icon={icon1} />
        <Icon
          className="techStack-LeftImgItem-leftSide-backgroundIcon"
          icon={icon2}
        />
      </div>

      <div className="techStack-LeftImgItem-rightSide">
        <Text className="techStack-LeftImgItem-rightSide-title" text={title} />
        <Text
          className="techStack-LeftImgItem-rightSide-description"
          text={description}
        />
        <div className="techStack-LeftImgItem-rightSide-btn">
          <Button
            text={btnHire}
            onClick={() => window.location.replace('/hirer/browse-experts')}
          />
          <Button
            buttonType="outlined"
            text={btnJoin}
            onClick={() => window.location.replace('/hirer')}
          />
        </div>
      </div>
    </div>
  );
}

const TechStack = () => {
  return (
    <div className='techStack'>
      <TechStackFun1
        title='Project Management'
        description='We have experienced and certified Project managers responsible for planning, 
        organizing, and directing the completion of specific projects for multinational organizations .'
        icon1={ProjectManagementIcon}
        icon2={TechStackBackgroundImgIcon}
        btnHire='Hire Expert'
        btnJoin='Join us'
      />

      <TechStackFun2
        title='Cyber security'
        description='We have world class Pen.Test Expert,Cyber Security Risk Consultant ,PCI-DSS Expert , 
        PCI-DSS Auditor, ISO 27001 Lead Auditor, ISO 27002 Lead Implementer ,Information Security Awareness 
        Expert ,ISC2 Certified Security Consultant etc.'
        icon1={CyberSecurityIcon}
        icon2={TechStackBackgroundImgIcon}
        btnHire='Hire Expert'
        btnJoin='Join us'
      />

      <TechStackFun1
        title='Digital Marketing'
        description='Digital marketers of this platform  will use various digital platforms to reach potential 
        customers for marketing your products and services offered by your company.'
        icon1={DigitalMarketingIcon}
        icon2={TechStackBackgroundImgIcon}
        btnHire='Hire Expert'
        btnJoin='Join us'
      />

      <TechStackFun2
        title='Software Development'
        description='We have experienced software developers ready to design, build and test your software of
         your imagination 
        They will also continue to monitor for bugs and issues that are uncovered by users.'
        icon1={SoftwareDevelopmentIcon}
        icon2={TechStackBackgroundImgIcon}
        btnHire='Hire Expert'
        btnJoin='Join us'
      />

      <TechStackFun1
        title='Data analysis, Database'
        description='We have Data analyst and scientist ready to to process and manipulate your data, analyse 
        the relationships and correlations between data sets, 
        and help you  to identify patterns and trends for your company.'
        icon1={DataAnalysisDatabaseIcon}
        icon2={TechStackBackgroundImgIcon}
        btnHire='Hire Expert'
        btnJoin='Join us'
      />

      <TechStackFun2
        title='Training'
        description='This platform will also provide Security Training Consultant ,Training Coordinator, 
        Security Training Module Developer ,Project Management Trainer ,Emergency Management 
        Trainer,Cyber Security Awareness Trainer.'
        icon1={TrainingIcon}
        icon2={TechStackBackgroundImgIcon}
        btnHire='Hire Expert'
        btnJoin='Join us'
      />

      <TechStackFun1
        title='Physical Security'
        description='Our Platform has Physical Security personnel, expert in the security of hardware, software, 
        networks and data from physical actions and events that could cause serious loss or damage to an enterprise, 
        agency or institution.'
        icon1={PhysicalSecurityIcon}
        icon2={TechStackBackgroundImgIcon}
        btnHire='Hire Expert'
        btnJoin='Join us'
      />
    </div>
  );
};

export default TechStack;
