import { useRef, useState } from 'react';

import { Button as MUIButton } from '@mui/material';
import {
  Box,
  ConfirmationModal,
  Divider,
  Modal,
  Skeleton,
} from 'design-library-v2';

import { IProject } from 'common/models';

import { JobPostBasicInfo, JobPostMetaInfo } from 'job-post/components';
import { useJobPost } from 'job-post/hooks';

import { ProjectOwnerCard } from './ProjectOwnerCard';
import { ProjectBidderCard } from './ProjectBidderCard';
import { submitProject } from 'services';
import { useProjectFiles } from 'projects/hooks';
import { ProjectSubmissionDetails } from './ProjectSubmissionDetails';

export const ProjectDetailsCard = ({
  focusedProjectId,
  forExpert = false,
  project,
  showMetaInfo = false,
}: {
  focusedProjectId?: string | null;
  forExpert?: boolean;
  project: IProject;
  showMetaInfo?: boolean;
}) => {
  const projectCardRef = useRef<HTMLDivElement>(null);
  const [showProjectMoreDetails, setShowProjectMoreDetails] =
    useState(showMetaInfo);
  const [showProjectSubmissionDetails, setShowProjectSubmissionDetails] =
    useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState<string>();
  const [isSubmittingProject, setIsSubmittingProject] = useState(false);

  const { onRemoveFile, onUploadProjectFiles, projectFiles } = useProjectFiles(
    project.id
  );

  const { jobPost } = useJobPost(project.jobPostId);

  let highlight = false;
  if (project.id === focusedProjectId) {
    highlight = true;
    projectCardRef.current?.scrollIntoView();
  }

  const onSubmitProject = async (message: string) => {
    setIsSubmittingProject(true);
    try {
      await submitProject(project.id, { message });
      window.location.reload();
    } catch (err) {
      console.error(err); // TO DO use sentry
    } finally {
      setIsSubmittingProject(false);
    }
  };

  return (
    <Box ref={projectCardRef} highlight={highlight} flexDirectionColumn>
      <Box>
        <Box
          styles={{
            width: '70%',
          }}
        >
          {!jobPost ? (
            <Skeleton />
          ) : (
            <JobPostBasicInfo jobPost={jobPost} projectId={project.id} />
          )}
        </Box>
        {forExpert ? (
          <Box styles={{ width: '30%' }}>
            <ProjectOwnerCard
              isSubmittingProject={isSubmittingProject}
              project={project}
              projectFiles={projectFiles}
              onDeleteFile={(fileId) => setFileIdToDelete(fileId)}
              onSubmitProject={onSubmitProject}
              onUploadProjectFiles={onUploadProjectFiles}
            />
          </Box>
        ) : (
          <Box styles={{ width: '30%' }}>
            <ProjectBidderCard
              project={project}
              onViewProject={() =>
                setShowProjectSubmissionDetails((prev) => !prev)
              }
            />
          </Box>
        )}
      </Box>
      {showProjectMoreDetails && (
        <>
          <Divider />
          {!jobPost ? <Skeleton /> : <JobPostMetaInfo jobPost={jobPost} />}
        </>
      )}
      {showProjectSubmissionDetails && (
        <Modal
          isShown
          onCancel={() => setShowProjectSubmissionDetails(false)}
          size="medium"
        >
          <ProjectSubmissionDetails
            project={project}
            projectFiles={projectFiles}
          />
        </Modal>
      )}

      {!showMetaInfo && (
        <Box styles={{ justifyContent: 'center' }}>
          <MUIButton
            variant="text"
            onClick={() =>
              setShowProjectMoreDetails(
                (showProjectMoreDetails) => !showProjectMoreDetails
              )
            }
          >
            {`View ${showProjectMoreDetails ? 'Less' : 'More'}`}
          </MUIButton>
        </Box>
      )}

      {fileIdToDelete && (
        <ConfirmationModal
          isShown
          onCancel={() => setFileIdToDelete(undefined)}
          onConfirm={() => {
            onRemoveFile(fileIdToDelete)
              .then(() => setFileIdToDelete(undefined))
              .catch((err) => {
                console.error(err); // TO DO use sentry
              });
          }}
          title="Confirm file delete"
        >
          {`Are sure to delete the file ${
            projectFiles.find((f) => f.id === fileIdToDelete)?.originalName
          }`}
          ?
        </ConfirmationModal>
      )}
    </Box>
  );
};
