import {
  IExpertInsight,
  IExpertReview,
  IExpertReviewCount,
  IPageResult,
} from 'common/models';
import { Axios } from 'common/services';

const getExpertReviews = async (
  expertId: string,
  queryString = ''
): Promise<IPageResult<IExpertReview>> =>
  (await Axios.get(`/Expert/${expertId}/reviews?${queryString}`)).data;

const getExpertReviewCount = async (
  expertId: string
): Promise<IExpertReviewCount> =>
  (await Axios.get(`/Expert/${expertId}/review-count`)).data;

const getExpertInsight = async (expertId: string): Promise<IExpertInsight> =>
  (await Axios.get(`/Expert/${expertId}/insight`)).data;

export default {
  getExpertInsight,
  getExpertReviewCount,
  getExpertReviews,
};
