import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

type BorderLinearProgressProps = {
  barcolor?: string;
  borderRadius?: number;
  height?: number;
  
};

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(
  ({ theme, barcolor = '#1a90ff', borderRadius = 5, height = 10 }) => ({
    borderRadius: borderRadius,
    height: height,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: barcolor,
      borderRadius: borderRadius,
    },
  })
);

export default BorderLinearProgress;
