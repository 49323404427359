import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Paper,
  Rating,
  Typography,
  Button,
} from 'design-library-v2';
import { Icon } from 'design-library';
import { LocationIcon } from 'common/icons';
import { BrowseExpertVerifiedIcon } from 'common/images';
import { getRatingPrecision } from 'common/utils';

const ProfileCard = ({
  expertId,
  name,
  skill,
  address,
  rating,
  projectCount,
  imageSrc,
  isOnline = false,
  isVerified = false,
  isBrowseExpertMode = false,
}: {
  expertId: string;
  name: string;
  skill: string;
  address: string;
  rating: number;
  projectCount: number;
  imageSrc?: string;
  isOnline?: boolean;
  isVerified?: boolean;
  isBrowseExpertMode?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Paper
      flexDirectionColumn
      styles={{
        height: '240px',
        justifyContent: 'center',
        padding: '32px 16px',
        position: 'relative',
        width: '240px',
      }}
    >
      <Box
        flexDirectionColumn
        styles={{
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'center',
        }}
      >
        <Avatar
          className={isOnline ? 'cardWrapper-item-avatar' : ''}
          size="medium"
          imageUrl={imageSrc}
        />
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="caption">{skill || '-'}</Typography>
        <Box
          styles={{
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'center',
          }}
        >
          <Rating value={rating} label={`${getRatingPrecision(rating)}`} />
          <Typography variant="caption">{`(${projectCount} Projects)`}</Typography>
        </Box>
      </Box>
      <Box
        styles={{ alignItems: 'center', gap: '8px', justifyContent: 'center' }}
      >
        <Icon icon={LocationIcon} />
        <Typography
          noWrap
          variant="caption"
        >{`location : ${address}`}</Typography>
      </Box>

      {isBrowseExpertMode && (
        <Box
          styles={{
            justifyContent: 'space-between',
          }}
        >
          <Button
            size="small"
            buttonType="secondary"
            styles={{
              fontSize: '12px',
            }}
            text="View Profile"
            onClick={() =>
              navigate(`/hirer/browse-experts/expert?expertId=${expertId}`)
            }
          />
          <Button
            size="small"
            buttonType="primary"
            styles={{
              fontSize: '12px',
            }}
            text="Message"
            onClick={() => navigate(`/hirer/messaging/?expertId=${expertId}`)}
          />
        </Box>
      )}
      {isVerified && (
        <Icon
          styles={{
            left: '0',
            position: 'absolute',
            top: '0',
          }}
          icon={BrowseExpertVerifiedIcon}
        />
      )}
    </Paper>
  );
};

export default ProfileCard;
