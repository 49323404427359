import {
  IExpert,
  IExpertJob,
  IExpertSkill,
  IExpertPreference,
  ISocialProfile as IExpertSocialProfile,
} from 'common/models';
import { Axios } from 'common/services';

const controllerName = 'expert';

export const getExpert = async (): Promise<IExpert> =>
  await (
    await Axios.get(`/${controllerName}/current-user`)
  ).data;

export const createExpertIdentity = async (): Promise<void> =>
  await Axios.post(`/${controllerName}/create-identity`, {});

export const updateExpertClient = async (expertId: string) => {
  const clientIdToken = localStorage.getItem('clientIdToken');
  if (clientIdToken) {
    await Axios.put(`/${controllerName}/${expertId}/client`, {
      clientId: clientIdToken,
      deviceType: 0,
      expertId: expertId,
      id: crypto.randomUUID(),
      isActive: true,
    });
  }
};

export const updateExpertPersonalInfo = async (
  expertId: string,
  payload: IExpert
): Promise<IExpert> =>
  (await Axios.put(`/${controllerName}/${expertId}/info`, payload)).data;

export const updateExpertJobPreference = async (
  expertId: string,
  payload: Array<IExpertJob>
): Promise<IExpert> =>
  await (
    await Axios.put(`/${controllerName}/${expertId}/jobs`, payload)
  ).data;

export const updateExpertSkills = async (
  expertId: string,
  payload: Array<IExpertSkill>
): Promise<IExpert> =>
  await (
    await Axios.put(`/${controllerName}/${expertId}/skill`, payload)
  ).data;

export const updateExpertPreferences = async (
  expertId: string,
  payload: IExpertPreference
): Promise<IExpert> =>
  await (
    await Axios.put(`/${controllerName}/${expertId}/preference`, payload)
  ).data;

export const updateExpertSocialProfiles = async (
  expertId: string,
  payload: IExpertSocialProfile
): Promise<IExpert> =>
  (await Axios.put(`/${controllerName}/${expertId}/social`, payload)).data;

export const getExpertProfileCompletionPercentage = async (expertId: string) =>
  (await Axios.get(`/Expert/${expertId}/percentage`)).data;
