import { useState } from 'react';

import { UsageTireEnum } from 'common/enums';

import { PaymentServices } from 'services';

export const useTokenPurchase = () => {
  const [isLoading, setIsLoading] = useState(false);

  const buyToken = async (token: number) => {
    try {
      setIsLoading(true);
      const redirectURL = await PaymentServices.buyToken({
        token,
        usageTire: UsageTireEnum.PayAsYouGo,
      });
      window.location.replace(redirectURL);
    } catch (error) {
      console.error(error); // TO DO Show alert
    } finally {
      setIsLoading(false);
    }
  };

  const updateSubscription = async () => {
    try {
      setIsLoading(true);
      const redirectURL = await PaymentServices.updateSubscription({
        usageTire: UsageTireEnum.Pro,
      });
      window.location.replace(redirectURL);
    } catch (error) {
      console.error(error); // TO DO Show alert
    } finally {
      setIsLoading(false);
    }
  };

  return {
    buyToken,
    isPurchasing: isLoading,
    updateSubscription,
  };
};
