import { Box, Skeleton } from 'design-library-v2';

export default function LoadingState() {
  return (
    <Box flexDirectionColumn>
      <Skeleton height={'80px'} />
      <Box
        flexDirectionColumn
        styles={{ gap: '8px', marginX: 'auto', maxWidth: '1440px' }}
      >
        <Skeleton
          variant="rounded"
          sx={{ height: '200px', marginTop: '16px' }}
        />
        <Skeleton variant="text" sx={{ fontSize: '3rem' }} width={'70%'} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'40%'} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'60%'} />
        <Skeleton height={'400px'} width={'75%'} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'60%'} />
      </Box>
    </Box>
  );
}
