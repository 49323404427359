import { ReactElement } from 'react';

import { AuthContext } from 'common/contexts';
import { AuthService } from 'common/services';

export default function AuthProvider({ children }: { children: ReactElement }) {
  const authService = new AuthService();
  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
}
