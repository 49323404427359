import {
  Box,
  FilePreviewList,
  FileUploader,
  RawFilePreviewList,
} from 'design-library-v2';

import { JobPostFormMode, JobPostFormProps } from './types';

export const JobPostAttachment = ({
  isEditing,
  jobPostAttachments,
  onUploadAttachments,
  onDeleteAttachments,
}: JobPostFormMode &
  Pick<JobPostFormProps, 'onDeleteAttachments' | 'onUploadAttachments'>) => {
  const handleFileUpload = (files: File[]) => {
    onUploadAttachments(
      files.map((file) => ({
        file,
        id: crypto.randomUUID(),
      }))
    );
  };

  return (
    <Box flexDirectionColumn styles={{ gap: '16px' }}>
      <FileUploader
        multiple
        title="Upload Attachments"
        onUpload={handleFileUpload}
      />
      {isEditing ? (
        <FilePreviewList
          files={jobPostAttachments}
          onDeleteFile={onDeleteAttachments}
        />
      ) : (
        <RawFilePreviewList
          files={jobPostAttachments}
          onDeleteFile={onDeleteAttachments}
        />
      )}
    </Box>
  );
};
