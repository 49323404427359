import { FormikProps, useFormik } from 'formik';

import {
  Box,
  CheckboxSelector,
  Form,
  FormControl,
  Input,
} from 'design-library-v2';
import { JobNatureEnum, JobTypeEnum, getEnumKeys } from 'common/enums';

import { JobPostSearchParams, useJobPostSearch } from 'expert/hooks';

export const JobSearchForm = ({
  setSearchParams,
  setIsSearchingJobPosts,
}: Pick<
  ReturnType<typeof useJobPostSearch>,
  'setSearchParams' | 'setIsSearchingJobPosts'
>) => {
  const formik: FormikProps<JobPostSearchParams> =
    useFormik<JobPostSearchParams>({
      initialValues: {},
      onSubmit: (values: JobPostSearchParams, { setSubmitting }) => {
        setSearchParams({ ...values });
        setIsSearchingJobPosts(true);
        setSubmitting(false);
      },
    });

  return (
    <Box>
      <Box styles={{ justifyContent: 'center', paddingX: '96px' }}>
        <Form<JobPostSearchParams>
          className="bulletinBoard-searchForm"
          formik={formik}
          submitBtnLabel="Search"
        >
          <FormControl>
            <Input
              name="name"
              onChange={formik.handleChange}
              placeholder="i.e., Job Title"
            />
          </FormControl>
          <FormControl>
            <CheckboxSelector
              alignment="horizontal"
              label="Job Type"
              defaultOptions={
                formik.values.jobType ? [formik.values.jobType] : []
              }
              options={getEnumKeys(JobTypeEnum)}
              onSelectionChanged={(options) =>
                formik.setFieldValue('jobType', options[0])
              }
              singleSelect
              type="radio"
            />
            <CheckboxSelector
              alignment="horizontal"
              label="Job Nature"
              defaultOptions={
                formik.values.jobNature ? [formik.values.jobNature] : []
              }
              options={getEnumKeys(JobNatureEnum)}
              onSelectionChanged={(options) =>
                formik.setFieldValue('jobNature', options[0])
              }
              singleSelect
              type="radio"
            />
          </FormControl>
        </Form>
      </Box>
    </Box>
  );
};
