import './Text.scss';

function Text({
  className='',
  size = 'regular',
  text,
}: {
  className?: string;
  size?: 'regular' | 'small' | 'large' | 'ex-large';
  text: string | number;
}) {
  return <p className={`iqc-dl-text-${size} ${className}`}>{text}</p>;
}

export default Text;
