import { WebStorageStateStore } from 'oidc-client';
import { appConfig } from './app';

export const oidcConfig: Oidc.UserManagerSettings = {
  accessTokenExpiringNotificationTime: 300,
  authority: 'https://auth.experthire.fr',
  automaticSilentRenew: true,
  client_id:
    process.env.NODE_ENV === 'development'
      ? 'expert-hire-test'
      : 'expert-hire-dev-test',

  post_logout_redirect_uri: appConfig.APP_URL,
  redirect_uri: `${appConfig.APP_URL}/signin-oidc`,
  response_type: 'code',
  scope: 'openid profile email experthire.api role',
  silent_redirect_uri: `${appConfig.APP_URL}/silent-renew`,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
};
