import { useState } from 'react';

import { Box, Button, Modal, Typography } from 'design-library-v2';
import { GRAY_2 } from 'common/constants/colors';

import { useExpertStripeConnection } from '../hooks';

import { ExpertStripeMetaInfoForm } from './ExpertStripeMetaInfoForm';

const ExpertStripeNotConnectedState = ({
  onClickConnect,
  isConnectingStripe,
}: {
  onClickConnect: () => void;
  isConnectingStripe: boolean;
}) => {
  return (
    <Box styles={{ alignItems: 'center', justifyContent: 'center' }}>
      <Box
        styles={{
          alignItems: 'center',
          background: GRAY_2,
          borderRadius: '50%',
          height: '100px',
          justifyContent: 'center',
          width: '100px',
        }}
      >
        <Typography variant="subtitle1">Stripe</Typography>
      </Box>
      <Typography>
        {
          'Don\'t have a Stripe account Or haven\'t you connected to your Stripe account yet?'
        }
      </Typography>
      <Button disabled={isConnectingStripe} onClick={onClickConnect}>
        Connect
      </Button>
    </Box>
  );
};

export const ExpertStripeConnection = () => {
  const [showExpertInfoModal, setShowExpertInfoModal] = useState(false);
  const { connectStripe, isConnectingStripe, isStripeConnected } =
    useExpertStripeConnection();

  return (
    <Box styles={{ justifyContent: 'center' }}>
      {!isStripeConnected && (
        <ExpertStripeNotConnectedState
          onClickConnect={() => setShowExpertInfoModal(true)}
          isConnectingStripe={isConnectingStripe}
        />
      )}
      {showExpertInfoModal && (
        <Modal
          isShown
          onCancel={() => setShowExpertInfoModal(false)}
          size="medium"
        >
          <ExpertStripeMetaInfoForm
            connectStripe={connectStripe}
            onCancel={() => setShowExpertInfoModal(false)}
          />
        </Modal>
      )}
    </Box>
  );
};
