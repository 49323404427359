import { Axios } from 'common/services';
import { IUserEducation as IExpertEducation } from 'common/models';

const controllerName = 'expertEducation';

export const getExpertEducations = async (
  expertId: string
): Promise<Array<IExpertEducation>> =>
  await (
    await Axios.get(`/${controllerName}/${expertId}/educations`)
  ).data;

export const updateExpertEducations = async (
  expertId: string,
  payload: Array<IExpertEducation>
): Promise<Array<IExpertEducation>> =>
  await (
    await Axios.put(`/${controllerName}/${expertId}/educations`, payload)
  ).data;

export const deleteExpertEducation = async (educationId: string) =>
  await Axios.delete(`/${controllerName}/${educationId}`);
