import { useState } from 'react';
import moment from 'moment';

import { Modal, Table } from 'design-library-v2';

import { ProjectStatusEnum, getEnumKeys } from 'common/enums';
import { IProject } from 'common/models';
import { getCurrencyFormat } from 'common/utils';

import { useProjects } from 'projects/hooks';
import { ProjectDetailsCard } from 'projects/components';

export const ExpertOngoingProjects = () =>  {
  const {
    isLoadingProjects,
    paginationModel,
    projectResults,
    setPaginationModel,
  } = useProjects({
    statuses: [ProjectStatusEnum.Ongoing, ProjectStatusEnum.Submitted],
  });

  const [selectedProject, setSelectedProject] = useState<IProject>();

  const getFormattedRows = (projects: Array<IProject>) =>
    projects.map((project) => ({
      budget: getCurrencyFormat(project.price),
      hirerName: project.hirerName,
      id: project.id,
      projectData: project,
      projectName: project.projectName,
      startDate: moment(project.startDate).format('DD/MM/YYYY'),
      submitted: getEnumKeys(ProjectStatusEnum)[project.status],
    }));

  return (
    <>
      <Table
        columns={[
          {
            field: 'projectName',
            headerName: 'Project Name',
            minWidth: 310,
          },
          {
            field: 'hirerName',
            headerName: 'Hirer Name',
            minWidth: 210,
          },
          {
            field: 'startDate',
            headerName: 'Start Date',
            minWidth: 210,
          },
          {
            field: 'budget',
            headerName: 'Budget',
            minWidth: 210,
          },
          {
            field: 'submitted',
            headerName: 'Submitted',
            minWidth: 210,
          },
        ]}
        loading={isLoadingProjects}
        paginationMode="server"
        onRowClick={(params) => setSelectedProject(params.row.projectData)}
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        rowCount={projectResults.total}
        rows={getFormattedRows(projectResults.data)}
      />
      {selectedProject && (
        <Modal isShown onCancel={() => setSelectedProject(undefined)}>
          <ProjectDetailsCard
            forExpert
            project={selectedProject}
            showMetaInfo
          />
        </Modal>
      )}
    </>
  );
};
