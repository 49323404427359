import {
  Box,
  Spinner,
  Typography,
} from 'design-library-v2';

import { useExpertLeaderBoard } from 'expert/hooks';

import ProfileCard from 'expert/profile/profile-card/ProfileCard';

export const LeaderBoard = () => {
  const { expertLeaderBoardData, isExpertLeaderBoardLoading } =
    useExpertLeaderBoard();

  if (isExpertLeaderBoardLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <Box flexDirectionColumn>
      <Typography variant="h4">Leader Board</Typography>
      <Box>
        {expertLeaderBoardData.map((expert) => (
          <ProfileCard
            key={expert.id}
            expertId={expert.id}
            name={expert.expertName}
            address={`${expert.city}, ${expert.country}`}
            skill={expert.skillName}
            rating={expert.rating}
            projectCount={expert.total}
            imageSrc={expert.imageUrl}
          />
        ))}
        
      </Box>
    </Box>
  );
};
