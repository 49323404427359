import Button from 'design-library-v2/button/Button';

export default function ButtonStory() {
  return (
    <>
      <div>
        <Button text="Primary large" onClick={() => alert('clicked')}></Button>

        <Button
          text="Primary large disabled"
          disabled={true}
          onClick={() => alert('clicked')}
        ></Button>

        <Button
          text="Primary medium"
          size="medium"
          onClick={() => alert('clicked')}
        ></Button>

        <Button size="small" onClick={() => alert('clicked')}>
          Primary small
        </Button>
      </div>

      <div>
        <Button
          text="Secondary large"
          buttonType="secondary"
          onClick={() => alert('clicked')}
        ></Button>

        <Button
          text="Secondary large disabled"
          disabled={true}
          buttonType="secondary"
          onClick={() => alert('clicked')}
        ></Button>

        <Button
          text="Secondary medium"
          buttonType="secondary"
          size="medium"
          onClick={() => alert('clicked')}
        ></Button>

        <Button
          size="small"
          buttonType="secondary"
          onClick={() => alert('clicked')}
        >
          Secondary small
        </Button>
      </div>
    </>
  );
}
