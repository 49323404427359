import React from 'react';
import { Modal } from '@mui/material';

import { Text } from 'design-library-v2';
import { Icon as Iconv1 } from 'design-library';
import { CrossIcon } from 'common/icons';

import './ConfirmModal.scss';

const ConfirmModal = ({
  open,
  text,
  description='',
  handleClose
}: {
  open: boolean;
  text: string;
  description?: string;
  handleClose: () => void;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
    >
      <div className='confirm-modal'>
        <button onClick={handleClose} className='confirm-modal-btnClose'>
          <Iconv1 className='conversation-header-elipsisBtn-icon' icon={CrossIcon}/>
        </button>
        <Text
          className="confirm-modal-title"
          text={text}
          type='large'
        />
        <Text
          className='confirm-modal-description'
          text={description}
          type='regular'
        />
      </div>
    </Modal>
  );
};


export default ConfirmModal;
