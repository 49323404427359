import { useRef } from 'react';

import Input from 'design-library-v2/text-input/Input';

export default function InputStory() {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputTextAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <>
      <div>
        <Input
          label="Input label"
          placeholder="Placeholder"
          inputRef={inputRef}
        />
      </div>
      <div>
        <Input hasError={true} value="Has Error" />
      </div>
      <div>
        <Input disabled placeholder="Diabled" />
      </div>

      <div>
        <Input
          inputType="text-area"
          placeholder="Placeholder"
          inputRef={inputTextAreaRef}
        />
      </div>
      <div>
        <Input
          label="With error"
          inputType="text-area"
          hasError={true}
          value="Has Error"
        />
      </div>
      <div>
        <Input inputType="text-area" disabled placeholder="Diabled" />
      </div>
    </>
  );
}
