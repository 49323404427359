import React, { useContext, useState } from 'react';
import { Popover, List, ListItemButton, ListItemText } from '@mui/material';

import {
  Avatar,
  FiberManualRecordIcon,
  MUIBox,
  Stack,
  Typography,
  // IconButton,
  // MoreVertIcon,
} from 'design-library-v2';

import { MessagingContext } from 'messaging/context';
import BlockModal from 'messaging/components/message-box/conversation/block-modal/BlockModal';
import ReportModal from 'messaging/components/message-box/conversation/report-modal/ReportModal';

export const ConversationHeader = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [blockModalIsOpen, setBlockModalIsOpen] = useState(false);
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);

  const { selectedMessagingUser } = useContext(MessagingContext);

  const handleBlockClick = (value: boolean) => {
    setBlockModalIsOpen(value);
  };

  const handleReportClick = (value: boolean) => {
    setReportModalIsOpen(value);
  };

  const handleCloseBlockClick = () => {
    setBlockModalIsOpen(false);
    setAnchorEl(null);
  };

  const handleCloseReportClick = () => {
    setReportModalIsOpen(false);
    setAnchorEl(null);
  };

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MUIBox justifyContent="space-between" px={4} py={4} boxShadow={1}>
      <MUIBox alignItems="center" gap={4}>
        <Avatar
          className="conversation-header-info-avater"
          size="small"
          imageUrl={selectedMessagingUser?.imageSrc}
        />
        <Stack>
          <MUIBox px={1}>
            <Typography>{selectedMessagingUser?.name}</Typography>
          </MUIBox>

          {selectedMessagingUser?.isOnlne && (
            <MUIBox alignItems="center" gap={1}>
              <FiberManualRecordIcon color="success" />
              <Typography variant="body2" color="green">
                Online
              </Typography>
            </MUIBox>
          )}
        </Stack>
      </MUIBox>

      {/* <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton> */}
      <Popover
        id="conversation-elipsis-id"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={{ left: -50, top: 30 }}
      >
        <List component="nav" aria-label="Block and report popover">
          <ListItemButton
            selected
            onClick={(e) => {
              e.preventDefault();
              handleBlockClick(true);
            }}
          >
            <ListItemText primary="Block" />
          </ListItemButton>
          <ListItemButton
            onClick={(e) => {
              e.preventDefault();
              handleReportClick(true);
            }}
          >
            <ListItemText primary="Report" />
          </ListItemButton>
        </List>
      </Popover>
      <BlockModal open={blockModalIsOpen} handleClose={handleCloseBlockClick} />
      <ReportModal
        open={reportModalIsOpen}
        handleClose={handleCloseReportClick}
      />
    </MUIBox>
  );
};
