import { IExperience as IExpertExperience } from 'common/models';
import { Axios } from 'common/services';

const controllerName = 'expertExperience';

export const getExpertExperiences = async (
  expertId: string
): Promise<Array<IExpertExperience>> =>
  (await Axios.get(`/${controllerName}/${expertId}/experiences`)).data;

export const updateExpertExperiences = async (
  expertId: string,
  payload: Array<IExpertExperience>
): Promise<Array<IExpertExperience>> =>
  (await Axios.put(`/${controllerName}/${expertId}/experience`, payload)).data;

export const deleteExpertExperience = async (experienceId: string) =>
  await Axios.delete(`/${controllerName}/${experienceId}`);
