import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'design-library-v2';
import { AppContext, AuthContext } from 'common/contexts';
import { ExpertHireIcon } from 'common/icons'; // TO DO replace with v2
import { Icon as IconV1 } from 'design-library'; // TO DO replace with v2

import { NavMenu } from './nav-menu/NavMenu';
import { NavProfileButton } from './nav-profile-button/NavProfileButton';
import NotificationWrapper from 'notification/components/notification-wrapper/NotificationWrapper';

import './Navbar.scss';

function Navbar() {
  const navigate = useNavigate();

  const { currentUserRole } = useContext(AppContext);
  const { isAuthenticated, signinRedirect } = useContext(AuthContext);

  return (
    <div className="nav">
      <div className="nav-wrapper">
        <div
          className="nav-left"
          onClick={() => {
            if (!currentUserRole) {
              return navigate('/');
            }
            navigate(`/${currentUserRole}`);
          }}
        >
          <IconV1 className="nav-left-logo" icon={ExpertHireIcon} />
        </div>
        {isAuthenticated() && currentUserRole && (
          <div className="nav-middle">
            {currentUserRole === 'expert' ? (
              <>
                <NavMenu icon="dashboard" menu="Dashboard" url="/expert" />
                <NavMenu
                  icon="home"
                  menu="Bulletin Board"
                  url="/expert/bulletin-board"
                />
                <NavMenu icon="billing" menu="Earning" url="/expert/earning" />
                {/* <NavMenu icon="barChart" menu="My Job" url="/expert/my-job" /> */}
              </>
            ) : (
              <>
                <NavMenu icon="dashboard" menu="Dashboard" url="/hirer" />
                <NavMenu
                  icon="postJob"
                  menu="Post A Job"
                  url="/hirer/post-job"
                />
                <NavMenu icon="billing" menu="Billing" url="/hirer/billing" />
                <NavMenu
                  icon="stat"
                  menu="Manage Your Projects"
                  url="/hirer/manage-your-projects"
                />
              </>
            )}
            {currentUserRole === 'hirer' && (
              <Button
                className="nav-searchBtn"
                icon="searchFill"
                onClick={() => navigate('/hirer/browse-experts')}
              >
                Browse Experts
              </Button>
            )}
          </div>
        )}
        <div className="nav-right">
          {isAuthenticated() ? (
            <>
              <NavMenu
                icon="envelope"
                menu=""
                url={`${currentUserRole}/messaging`}
              />
              <NotificationWrapper />
              <NavProfileButton />
            </>
          ) : (
            <Button onClick={signinRedirect}>Login</Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
