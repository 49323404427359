import { Icon } from 'design-library';
import { Box, Paper, Typography } from 'design-library-v2';

import { CurrentEmptyProjectIcon } from 'common/icons';

export const JobsRecommendedEmptyState = () => {
  return (
    <Paper styles={{ width: '30%' }}>
      <Icon icon={CurrentEmptyProjectIcon} />
      <Box styles={{ alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="subtitle1">No Job Post Found</Typography>
      </Box>
    </Paper>
  );
};
