import { AxiosError } from 'axios';
import { InvoiceStatusEnum } from 'common/enums';
import { PaginationModel } from 'common/hooks';
import { IInvoice, IPageResult } from 'common/models';
import { Axios } from 'common/services';

const getInvoices = async ({
  hirerId,
  expertId,
  status,
  page = 0,
  pageSize = 25,
}: {
  hirerId?: string;
  expertId?: string;
  status?: InvoiceStatusEnum;
} & PaginationModel): Promise<IPageResult<IInvoice>> => {
  const queryParams = new URLSearchParams({
    ...(hirerId ? { hirerId } : {}),
    ...(expertId ? { expertId } : {}),
    ...(status ? { status: status.toString() } : {}),
    page: (page + 1).toString(),
    pageSize: pageSize.toString(),
  });

  return (await Axios.get(`/Invoice/invoices?${queryParams.toString()}`)).data;
};

const downloadInvoice = async (invoiceId: string, projectName: string) => {
  try {
    const url = (await Axios.get(`/Invoice/${invoiceId}/download`)).data;
    const name = `${projectName}-invoice`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  } catch (error) {
    alert((error as AxiosError)?.response?.data);
    console.error(error); // TO DO use sentry
  }
};

export default {
  downloadInvoice,
  getInvoices,
};
