import { Text } from 'design-library';
import { faq } from '../constants';

export const FAQAnswer = ({
  questionId
}:{
  questionId: number;
}) => {
  return(
    <div className='qa-right-answers'>
      <Text className='qa-right-heading' text={faq[questionId].question} />
      <p className='qa-right-text'>{faq[questionId]?.answer ?? ''}</p>
    </div>
  );
};

export default FAQAnswer;
