import { Box } from '../layout';
import { Typography } from '../text';
import { Rating } from './Rating';

export const RatingInput = ({
  label,
  onChangeRating,
  precision = 1,
  rating = 0,
}: {
  label: string;
  onChangeRating: (newRating: number) => void;
  precision?: number;
  rating: number | null;
}) => {
  return (
    <Box flexDirectionColumn>
      <Typography variant="subtitle1">{label}</Typography>
      <Rating
        onChange={(event, newValue) => {
          onChangeRating(newValue || 0);
        }}
        value={rating}
        label={`(${rating})`}
        precision={precision}
      />
    </Box>
  );
};
