import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikProps, useFormik } from 'formik';

import { Box, Form, Label, Stepper, Typography } from 'design-library-v2';
import { JobTypeEnum } from 'common/enums';
import { IJobPost } from 'common/models';
import { getCurrencyFormat } from 'common/utils';

import { JobPostingServices } from 'hirer/services';

import { TypeOfJobSection } from './TypeOfJobSection';
import { ExpertSection } from './ExpertSection';
import { ConditionsSection } from './ConditionsSection';
import { ProjectSection } from './ProjectSection';
import { JobPostPreview } from './JobPostPreview';
import { JobDescription } from './JobDescription';

import { JobPostFormProps } from './types';

import './JobPostForm.scss';

const PART_TIME_SECTIONS = [
  'Type Of Job',
  'Expert',
  'Conditions',
  'Project',
  'Preview',
] as const;

const FULL_TIME_SECTIONS = [
  'Type Of Job',
  'Expert',
  'Job Description',
  'Preview',
] as const;

type SectionName =
  | (typeof PART_TIME_SECTIONS)[number]
  | (typeof FULL_TIME_SECTIONS)[number];

export const JobPostForm = ({
  isEditing,
  jobPost,
  jobPostAttachments,
  onDeleteAttachments,
  onUploadAttachments,
  updateJobPost,
}: JobPostFormProps) => {
  const navigate = useNavigate();

  const [steps, setSteps] = useState<Array<string>>(
    jobPost.jobType === JobTypeEnum['PartTime']
      ? [...PART_TIME_SECTIONS]
      : [...FULL_TIME_SECTIONS]
  );
  const [currentFormSection, setCurrentFormSection] =
    useState<SectionName>('Type Of Job');

  const formik: FormikProps<IJobPost> = useFormik<IJobPost>({
    initialValues: jobPost,
    onSubmit: async (jobPostValue: IJobPost, { setSubmitting }) => {
      try {
        await updateJobPost(jobPostValue, currentFormSection === 'Preview');
        if (currentFormSection === 'Preview') {
          navigate('/hirer?activeTab=posted-jobs');
          return;
        }
        navigateToFormSection(jobPostValue.jobType);
      } catch (error) {
        console.error(error); // TO DO use sentry
        alert('Failed to post the job'); // TO DO use sentry
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getSectionIndex = (
    jobType: JobTypeEnum,
    currentSection: SectionName
  ): number => {
    const sectionList =
      jobType === JobTypeEnum['PartTime']
        ? [...PART_TIME_SECTIONS]
        : [...FULL_TIME_SECTIONS];
    return sectionList.findIndex((sec) => sec === currentSection);
  };

  const navigateToFormSection = (jobType: JobTypeEnum, forward = true) =>
    setCurrentFormSection((currentFormSec) => {
      const sectionList =
        jobType === JobTypeEnum['PartTime']
          ? [...PART_TIME_SECTIONS]
          : [...FULL_TIME_SECTIONS];

      const nextSectionIndex =
        sectionList.findIndex((section) => section === currentFormSec) +
        (forward ? 1 : -1);
      return sectionList[nextSectionIndex];
    });

  const updateStepsByJobType = (jobType: JobTypeEnum) => {
    const isPartTimeJobPost = jobType === JobTypeEnum['PartTime'];
    formik.setFieldValue('showBudget', isPartTimeJobPost);
    isPartTimeJobPost
      ? setSteps([...PART_TIME_SECTIONS])
      : setSteps([...FULL_TIME_SECTIONS]);
  };

  const renderProjectSection = () =>
    isEditing ? (
      <ProjectSection
        formik={formik}
        isEditing
        jobPostAttachments={jobPostAttachments}
        onDeleteAttachments={onDeleteAttachments}
        onUploadAttachments={onUploadAttachments}
      />
    ) : (
      <ProjectSection
        formik={formik}
        isEditing={false}
        jobPostAttachments={jobPostAttachments}
        onDeleteAttachments={onDeleteAttachments}
        onUploadAttachments={onUploadAttachments}
      />
    );

  const renderJobPostPreview = () =>
    isEditing ? (
      <JobPostPreview
        formik={formik}
        isEditing
        jobPostAttachments={jobPostAttachments}
      />
    ) : (
      <JobPostPreview
        formik={formik}
        isEditing={false}
        jobPostAttachments={jobPostAttachments}
      />
    );

  const renderJobDescription = () =>
    isEditing ? (
      <JobDescription
        formik={formik}
        isEditing
        jobPostAttachments={jobPostAttachments}
        onDeleteAttachments={onDeleteAttachments}
        onUploadAttachments={onUploadAttachments}
      />
    ) : (
      <JobDescription
        formik={formik}
        isEditing={false}
        jobPostAttachments={jobPostAttachments}
        onDeleteAttachments={onDeleteAttachments}
        onUploadAttachments={onUploadAttachments}
      />
    );

  const showBudget =
    formik.values.jobType === JobTypeEnum['PartTime'] &&
    !['Type Of Job', 'Expert'].includes(currentFormSection);

  return (
    <Box>
      <Form<IJobPost>
        className="jobPostForm"
        formik={formik}
        hasSecondaryBtn={currentFormSection !== 'Type Of Job'}
        secondaryBtnLabel="Prev"
        onClickSecondaryBtn={() =>
          navigateToFormSection(formik.values.jobType, false)
        }
        submitBtnLabel={
          currentFormSection === 'Preview'
            ? isEditing
              ? 'Update'
              : 'Post'
            : 'Next'
        }
      >
        <Stepper
          activeStep={getSectionIndex(
            formik.values.jobType,
            currentFormSection
          )}
          steps={steps}
        />
        <Box flexDirectionColumn styles={{}}>
          {currentFormSection === 'Type Of Job' ? (
            <TypeOfJobSection
              formik={formik}
              updateStepsByJobType={updateStepsByJobType}
            />
          ) : currentFormSection === 'Expert' ? (
            <ExpertSection formik={formik} />
          ) : currentFormSection === 'Conditions' ? (
            <ConditionsSection formik={formik} />
          ) : currentFormSection === 'Project' ? (
            renderProjectSection()
          ) : currentFormSection === 'Job Description' ? (
            renderJobDescription()
          ) : (
            renderJobPostPreview()
          )}
        </Box>
      </Form>
      {showBudget && (
        <div className="jobPostForm-budget">
          <Label labelText="Cost Calculated" size="large" />
          <div>
            <Typography>Your approximate project cost would be</Typography>
            <Typography variant="h6">
              {`(eg: ${getCurrencyFormat(
                JobPostingServices.calculateJobPostBudget({
                  end: formik.values.endTime,
                  hourlyRate: formik.values.hourlyRate,
                  hoursPerDay: formik.values.hoursPerDay,
                  start: formik.values.startTime,
                })
              )})`}
            </Typography>
          </div>
          <Typography>
            Our platform adheres to the following formula:
          </Typography>
          <Typography align="center" variant="body1">
            Days * Hours per day * Hourly rate + Platform & Transaction fee
            (11%)
          </Typography>
          <Typography variant="caption">
            Warning or more info text goes here
          </Typography>
        </div>
      )}
    </Box>
  );
};
