export const faq: ReadonlyArray<{ 
  readonly id: number;
  readonly question: string;
  readonly answer: string;
}> = [
  {
    'answer': 'ExpertHire is a comprehensive freelancing platform that connects hirers with expert professionals for full-time, part-time, remote, and onsite opportunities. The platform focuses on security and other job categories and offers unique selling propositions and Pro features.',
    'id': 0,
    'question': 'What is ExpertHire?'
  },
  {
    'answer': 'The job categories on ExpertHire include Project Management, Cyber Security, Digital Marketing, Software Development, Database Analysis, Training, and Physical Security.',
    'id': 1,
    'question': 'What are the job categories on ExpertHire?'
  },
  {
    'answer': 'ExpertHire\'s uniqueness lies in offering physical security freelancing opportunities, providing full-time and onsite options, enabling hirers to find experts based on language preference, and using AI-powered candidate matching with Hirer Pro.',
    'id': 2,
    'question': 'What makes ExpertHire unique?'
  },
  {
    'answer': 'ExpertHire uses Stripe as its payment gateway. When a hirer accepts a bid, they make the payment through Stripe, which holds the funds until the project is completed. If the hirer is satisfied with the expert\'s work, they validate the project, and the admin releases the funds to the expert. If the hirer is unhappy with the work, they can submit a complaint, and the admin may refund the payment to the hirer after reviewing the case.',
    'id': 3,
    'question': 'How does the payment system on ExpertHire work?'
  },
  {
    'answer': 'ExpertHire offers Pro features for both hirers and experts. For hirers, Pro features include faster communication, ExpertHire shortlisting candidates, hiring both part-time and full-time experts, and organized project history boards. For experts, Pro features include unlimited tokens for applying to jobs, top placement in search results, receiving payment within 24 hours of job completion, faster communication with hirers, and an organized profile for sharing experiences.',
    'id': 4,
    'question': 'What are the Pro features offered by ExpertHire?'
  },
  {
    'answer': 'To sign up for ExpertHire, visit the website and create an account by providing your email address, choosing a password, and filling out your profile details. Once your account is set up, you can start browsing or posting job opportunities.',
    'id': 5,
    'question': 'How can I sign up for ExpertHire?'
  },
  {
    'answer': 'ExpertHire offers various filters and search options to help you find the right expert for your project. You can search based on job categories, skills, location, language preference, and other criteria. You can also communicate with experts before hiring them to ensure they are a good fit for your project.',
    'id': 6,
    'question': 'How can I find the right expert for my project on ExpertHire?'
  },
  {
    'answer': 'ExpertHire uses Stripe, a secure and reliable payment gateway, to process payments. Stripe holds the funds during the project, ensuring that experts are paid upon successful completion and hirers are satisfied with the work.',
    'id': 7,
    'question': 'How can I ensure secure payments on ExpertHire?'
  },
  {
    'answer': 'To post a project or job, sign in to your ExpertHire account, navigate to the dashboard, and click on "Post a Job." Fill out the required details, including project or job title, description, skills needed, budget, and any other relevant information.',
    'id': 8,
    'question': 'How do I post a project or job on ExpertHire?'
  },
  {
    'answer': 'ExpertHire charges a 10% commission on the total value of a project. This commission helps cover the platform\'s operational costs and enables us to provide the necessary tools and support for a seamless experience for both hirers and experts.',
    'id': 9,
    'question': 'What is the commission charged by ExpertHire?'
  },
  {
    'answer': 'ExpertHire encourages experts to create comprehensive profiles that include their skills, qualifications, experience, and client reviews. Additionally, experts can earn badges by receiving positive ratings from hirers, which helps hirers identify top-rated professionals.',
    'id': 10,
    'question': 'How does ExpertHire ensure the quality of experts on the platform?'
  },
  {
    'answer': 'Yes, ExpertHire provides a messaging system that allows hirers to communicate with experts before hiring them. This ensures that hirers can clarify project details, negotiate terms, and assess the suitability of the expert for their specific needs.',
    'id': 11,
    'question': 'Can I communicate with an expert before hiring them?'
  },
  {
    'answer': 'If you are unhappy with the work submitted by an expert, you can select the "unhappy" button and send details to the admin explaining your dissatisfaction. The admin will analyze the case, and if they agree with your concerns, they will initiate a refund through Stripe.',
    'id': 12,
    'question': 'How can I get a refund if I am unhappy with an expert\'s work?'
  },
  {
    'answer': 'To apply for jobs as an expert, sign in to your ExpertHire account, browse Bulltein borad for the available job postings, and use tokens to apply for jobs that match your skills and qualifications. Make sure your profile is complete and up-to-date to increase your chances of getting hired.',
    'id': 13,
    'question': 'How can I apply for jobs as an expert on ExpertHire?'
  },
  {
    'answer': 'Yes, if you are satisfied with an expert\'s work on a project, you can continue to work with them on future projects. You can communicate with them through the ExpertHire messaging system to discuss the new project\'s details and terms.',
    'id': 14,
    'question': 'Can I work with the same expert on multiple projects?'
  },
  {
    'answer': 'Yes, ExpertHire caters to both remote and onsite projects. When posting a project or job, specify the work location, and the platform will match you with suitable experts based on your preferences.',
    'id': 15,
    'question': 'Can I find experts for both remote and onsite projects on ExpertHire?'
  },
  {
    'answer': 'ExpertHire provides 24/7 support for headhunting experts through the Expert Pro feature. The platform\'s support team is available to assist with any questions or concerns you may have during the hiring process.',
    'id': 16,
    'question': 'What type of support does ExpertHire offer?'
  },
  {
    'answer': 'Yes, ExpertHire allows you to search for experts based on their language preferences. This feature helps you find experts who can communicate effectively in your preferred language.',
    'id': 17,
    'question': 'Can I search for experts based on their language skills?'
  },
  {
    'answer': 'To upgrade to ExpertHire Pro, sign in to your account, navigate to the Pro features section, and follow the instructions for purchasing a Pro subscription. Review the pricing.',
    'id': 18,
    'question': 'How do I upgrade to ExpertHire Pro?'
  },
  {
    'answer': 'Experts with a Pro subscription receive payment within 24 hours of job completion, subject to project approval by the hirer. For non-Pro subscribers, the payment timeline may vary.',
    'id': 19,
    'question': 'How long does it take for experts to receive payment after completing a project?'
  },
  {
    'answer': 'ExpertHire\'s AI-powered candidate matching system uses advanced algorithms to analyze hirer\'s job requirements and match them with the most suitable experts on the platform. This feature saves time and helps hirers find the best candidate for their projects.',
    'id': 20,
    'question': 'How does the AI-powered candidate matching work for Hirer Pro users?'
  },
  {
    'answer': 'To get a verified tag on your expert profile, you must undergo ExpertHire\'s verification process, which may include verifying your identity, qualifications, and work experience. Once you complete the verification process, the verified tag will be added to your profile.',
    'id': 21,
    'question': 'How do I get a verified tag on my expert profile?'
  },
  {
    'answer': 'Tokens are similar to "connects" on other platforms and are used to apply for jobs on ExpertHire. Experts receive a certain number of tokens with their account, and additional tokens can be purchased. To apply for a job, use the required number of tokens for that specific job posting.',
    'id': 22,
    'question': 'What are tokens, and how do I use them to apply for jobs on ExpertHire?'
  },
  {
    'answer': 'To add your portfolio to your expert profile, log in to your ExpertHire account, navigate to your profile settings, and upload samples of your work or provide links to your previous projects. This will help hirers evaluate your skills and expertise.',
    'id': 23,
    'question': 'How do I add my portfolio to my expert profile?'
  },
  {
    'answer': 'After a project is completed, you can provide feedback or leave a review for the expert on their profile. This helps other hirers evaluate the expert\'s skills and performance and contributes to the expert\'s overall rating on the platform.',
    'id': 24,
    'question': 'How can I give feedback or leave a review for an expert I have worked with?'
  },
  {
    'answer': 'To report an issue or dispute with an expert or a project, contact ExpertHire support with the details of your concern. The support team will review the issue and work with both parties to resolve the dispute fairly.',
    'id': 25,
    'question': 'How do I report an issue or dispute with an expert or a project?'
  }
];
