import React from 'react';
import { Card } from '@mui/material';
import { Text } from 'design-library-v2';
import { Icon, ButtonV2 } from 'design-library';

import { ConfirmIcon } from 'common/icons';
import { Link } from 'react-router-dom';

import './Package.scss';

const Package = () => {
  return (
    <div className="package-container">
      <Text className='text-lg' text='Want to Be a Hirer Pro ?'/>
      <Text type='large' className='text' text='Choose the perfect package for you'/>

      <div className="package-container-package-type">
        <Card variant='outlined' className="package-container-package-type-item">
          <div className="package-container-package-type-item-header">
            <Text className='text-md' text='Free'/>
          </div>
          <div className="package-container-package-type-item-points">
            {
              Array.from({ length: 5 }, (_, idx) => idx).map( idx => {
                return (
                  <div key={idx} className="package-container-package-type-item-points-point">
                    <Icon icon={ConfirmIcon}/>
                    <Text className='text-sm' text='Dedicated bulletin board'/>
                  </div>
                );
              })
            }
          </div>
        </Card>
  
        <Card variant='outlined' className="package-container-package-type-item">
          <div className="package-container-package-type-item-header">
            <Text className='text-md' text='Pro'/>
            <Text className='text-md' text='$5/month'/>
          </div>
          <div className="package-container-package-type-item-points">
            {
              Array.from({ length: 7 }, (_, idx) => idx).map( idx => {
                return (
                  <div key={idx} className="package-container-package-type-item-points-point">
                    <Icon icon={ConfirmIcon}/>
                    <Text className='text-sm' text='Dedicated bulletin board'/>
                  </div>
                );
              })
            }
          </div>
          <Link to='/hirer/plus-package' className='package-container-link'>
            <ButtonV2 className='package-container-package-type-item-select-btn' text='Select' />
          </Link>
        </Card>
      </div>
    </div>
  );
};

export default Package;
