import React, { ReactNode } from 'react';
import { IJob, ILanguage, ILocation, ISkill } from 'common/models';
import {
  useCurrentUserRole,
  useJobNames,
  useLanguages,
  useLocations,
  useSkillsCache,
} from 'common/hooks';
import { UserRole } from 'common/types';

export type AppContextValue = {
  currentUserRole: UserRole | undefined;
  getCitiesByCountry: ReturnType<typeof useLocations>['getCitiesByCountry'];
  jobs: Array<IJob>;
  languages: Array<ILanguage>;
  loadCities: ReturnType<typeof useLocations>['loadCities'];
  locations: Array<ILocation>;
  skills: Array<ISkill>;
};

export const AppContext = React.createContext<AppContextValue>({
  currentUserRole: undefined,
  getCitiesByCountry: () => [],
  jobs: [],
  languages: [],
  loadCities: () => new Promise(() => ({})),
  locations: [],
  skills: [],
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { currentUserRole } = useCurrentUserRole();
  const { jobs } = useJobNames();
  const { languages } = useLanguages();
  const { getCitiesByCountry, locations, loadCities } = useLocations();
  const { skills } = useSkillsCache();

  return (
    <AppContext.Provider
      value={{
        currentUserRole,
        getCitiesByCountry,
        jobs,
        languages,
        loadCities,
        locations,
        skills,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
