import { ReactElement, useState } from 'react';
import Select from 'react-select';

import AvatarStory from '../avatar/Avatar.story';
import ButtonStory from '../button/Button.story';
import InputStory from '../text-input/Input.story';
import FormStory from '../form/Form.story';
import IconStory from '../icon/Icon.story';
import TextStory from '../text/Text.story';
import FileUploadStory from '../file-upload/FileUpload.story';
import DropdownStory from '../dropdown/Dropdown.story';
import CheckboxSelectorStory from '../checkbox-selector/CheckboxSelector.story';
import DatePickerStory from '../date-picker/DatePicker.story';
import ModalStory from '../modal/Modal.story';
import { TableStory } from '../table';
import { RadioGroupStory } from '../radio-group';

import './StoryBook.scss';

function DLComponentCard({
  children,
}: {
  children: ReactElement | ReactElement[];
}) {
  return (
    <div className="iqc-dl-stroyBook-rightPane-displayCard">{children}</div>
  );
}

function StoryBook() {
  const [selectedCompoent, setSelectedCompoent] = useState<string>('Text');

  const wrapWithDLContainer = (children: ReactElement) => (
    <DLComponentCard key={crypto.randomUUID()}>{children}</DLComponentCard>
  );

  const dlComponentMap = new Map<string, ReactElement>([
    ['Avatar', wrapWithDLContainer(<AvatarStory />)],
    ['Text', wrapWithDLContainer(<TextStory />)],
    ['Button', wrapWithDLContainer(<ButtonStory />)],
    ['Input', wrapWithDLContainer(<InputStory />)],
    ['Form', wrapWithDLContainer(<FormStory />)],
    ['FileUpload', wrapWithDLContainer(<FileUploadStory />)],
    ['Icons', wrapWithDLContainer(<IconStory />)],
    ['Dropdown', wrapWithDLContainer(<DropdownStory />)],
    ['Checkbox Selector', wrapWithDLContainer(<CheckboxSelectorStory />)],
    ['DatePicker', wrapWithDLContainer(<DatePickerStory />)],
    ['Modal', wrapWithDLContainer(<ModalStory />)],
    ['Table', wrapWithDLContainer(<TableStory />)],
    ['RadioGroup', wrapWithDLContainer(<RadioGroupStory />)],
  ]);

  return (
    <div className="iqc-dl-stroyBook">
      <div className="iqc-dl-stroyBook-leftPane">
        <div className="iqc-dl-stroyBook-leftPane-dlItemSelector">
          <label>Design Library Compoents</label>
          <Select
            defaultValue={{
              id: crypto.randomUUID(),
              label: 'Text',
              value: 'Text',
            }}
            isSearchable={true}
            isClearable={true}
            isRtl={false}
            name="Design Library Compoents"
            options={Array.from(dlComponentMap.keys())
              .sort()
              .map((component) => ({
                id: crypto.randomUUID(),
                label: component,
                value: component,
              }))}
            onChange={(selectedOption) =>
              selectedOption?.value &&
              setSelectedCompoent(selectedOption?.value)
            }
          />
        </div>
      </div>
      <div className="iqc-dl-stroyBook-rightPane">
        {dlComponentMap.get(selectedCompoent)}
      </div>
    </div>
  );
}

export default StoryBook;
