import { useState } from 'react';
import moment from 'moment';
import {
  Form,
  FormControl,
  ConfirmationModal,
  DatePicker,
  Input,
} from 'design-library-v2';
import { IUserTraining } from 'common/models';
import { FormikProps, useFormik } from 'formik';

const TrainingForm = ({
  isPlaceholder = false,
  training,
  onUpdateTrainings,
  onDeleteTraining,
}: {
	isPlaceholder?: boolean;
	training: IUserTraining;
	onUpdateTrainings: (updatedTraining: IUserTraining) => Promise<void>;
	onDeleteTraining?: (trainingId: string) => Promise<void>;
}) => {
  const [trainingIdToDelete, setTrainingIdToDelete] = useState<string>();
  const formik: FormikProps<IUserTraining> = useFormik<IUserTraining>({
    initialValues: training,
    onSubmit: async (values: IUserTraining, { setSubmitting }) => {
      try {
        await onUpdateTrainings(values);
      } catch (error) {
        alert('Failed to update!!'); // TO DO
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onDelete = async () => {
    if (!onDeleteTraining || !trainingIdToDelete) {
      return;
    }
    try {
      formik.setSubmitting(true);
      await onDeleteTraining(trainingIdToDelete);
    } catch (error) {
      alert('Failed to delete training'); // TO DO alert handle
    } finally {
      formik.setSubmitting(false);
    }
  };


  return (
    <>
      <Form<IUserTraining>
        className="trainings-form"
        label="Training"
        formik={formik}
        hasDelete={!isPlaceholder}
        onDelete={() => setTrainingIdToDelete(formik.values.id)}
      >
        <FormControl>
          <Input
            label="Certification"
            name="certification"
            onChange={formik.handleChange}
            value={formik.values.certification}
            placeholder="i.e. Java"
            required
          />
          <Input
            label="Institute"
            name="institute"
            onChange={formik.handleChange}
            value={formik.values.institute}
            placeholder="i.e. GETCO-NIIT"
            required
          />
        </FormControl>
        <FormControl>
          <DatePicker
            label="Validity"
            name="validity"
            onChange={formik.handleChange}
            value={moment(formik.values.validity).format('YYYY-MM-DD')}
            required
          />
        </FormControl>
      </Form>
      {trainingIdToDelete && (
        <ConfirmationModal
          isShown
          isConfirming={formik.isSubmitting}
          title="Confirm Delete Training"
          onCancel={() => setTrainingIdToDelete(undefined)}
          onConfirm={onDelete}
        >
          Are you sure?
        </ConfirmationModal>
      )}
    </>
  );
};

export default TrainingForm;
