import { ComponentProps, ReactNode } from 'react';
import { Card as MUICard } from '@mui/material';

export const Card = ({
  children,
  ...props
}: { children: ReactNode } & ComponentProps<typeof MUICard>) => {
  return (
    <MUICard {...props} sx={{ padding: '16px 16px', ...props.sx }}>
      {children}
    </MUICard>
  );
};
