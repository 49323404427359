import { useContext } from 'react';

import { Spinner } from 'design-library-v2';
import { IHirerPreference } from 'common/models';
import { HirerContext } from 'hirer/context';
import { HirerProfileSetupServices } from 'hirer/services';
import { DesiredExpertDetailsForm } from './DesiredExpertDetailsForm';

export function DesiredExpertDetails() {
  const { hirer, hirerPreference, updateHirerPreference } =
    useContext(HirerContext);

  const onUpdateHirerPreference = async (values: IHirerPreference) => {
    if (!hirer) {
      return;
    }
    updateHirerPreference(
      await HirerProfileSetupServices.updateHirerPreference(hirer.id, values)
    );
  };

  if (!hirerPreference) {
    return <Spinner type='contained' />;
  }

  return (
    <DesiredExpertDetailsForm
      hirerPreference={hirerPreference}
      onUpdateHirerPreference={onUpdateHirerPreference}
    />
  );
}
