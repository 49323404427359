import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage, Messaging, MessagePayload } from 'firebase/messaging';

export type FirebaseConfigType = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export const firebaseConfig: FirebaseConfigType = {
  apiKey: 'AIzaSyAt-ChRsIwUMizTZ6dt5uKiWI4lUw1thTM',
  appId: '1:390038866052:web:4a66ae8931403a26569ad1',
  authDomain: 'expert-hire-messaging.firebaseapp.com',
  measurementId: 'G-F5RG3PKYHK',
  messagingSenderId: '390038866052',
  projectId: 'expert-hire-messaging',
  storageBucket: 'expert-hire-messaging.appspot.com',
};

export const PUBLIC_VAPID_KEY = 'BMqEa1Wb6vnodbJibASmTqg-c7x5IqaB6n92DHXXOHs4CkhPBG6g1oi89IgotjdVpiuIzoydgB6PxRcqsLWNe3A';

export class FirebaseMessagingService {
  private firebaseConfig: FirebaseConfigType;
  private app: FirebaseApp;
  private auth: Auth;
  private storage: FirebaseStorage;
  private db: Firestore;
  private messaging: Messaging;
  private currentToken: string;

  constructor(FirebaseConfigType: FirebaseConfigType) {
    this.firebaseConfig = FirebaseConfigType;
    this.currentToken = '';
    this.app = initializeApp(this.firebaseConfig);
    this.auth = getAuth();
    this.storage = getStorage();
    this.db = getFirestore();
    this.messaging = getMessaging(this.app);
  }

  get firebaseApp (): FirebaseApp {
    return this.app;
  }

  get firebaseDB (): Firestore {
    return this.db;
  }

  get firebaseAuth (): Auth {
    return this.auth;
  }

  get firebaseStorage(): FirebaseStorage {
    return this.storage;
  }

  get firebaseMessaging(): Messaging {
    return this.messaging;
  }

  public async setFirebaseToken (vapidPublicKey: string): Promise<void> {
    const currentToken = await getToken(this.firebaseMessaging, { vapidKey: vapidPublicKey });
    this.currentToken = currentToken;
  }

  get firebaseToken (): string {
    return this.currentToken;
  }

  public async seTokenToLocalStorage() {
    try{
      await this.setFirebaseToken(PUBLIC_VAPID_KEY);
      const token = this.firebaseToken;
      if(!token) {
        throw Error(`Firebase token ${token} is not valid`);
      }

      localStorage.setItem('clientIdToken', token);
    }
    catch(err) {
      console.error(err);
    }
  }

  public onMessageListener(onNotificationRecieve: (payload: MessagePayload) => void): void {
    onMessage(this.firebaseMessaging, onNotificationRecieve);
  }
}

export const firebaseMessagingService = new FirebaseMessagingService(firebaseConfig);
