import React from 'react';
import { Box, Stack as MUIStack } from '@mui/material';

export const MUIBox = ({
  children,
  ...props
}: React.ComponentProps<typeof Box> & { children: React.ReactNode }) => {
  return (
    <Box display="flex" {...props}>
      {children}
    </Box>
  );
};

type StackProps = React.ComponentProps<typeof MUIStack> & {
  children: React.ReactNode;
};

// eslint-disable-next-line react/display-name
export const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  ({ children, ...props }, ref) => (
    <MUIStack direction="column" {...props} ref={ref}>
      {children}
    </MUIStack>
  )
);
