
const Input = ({
  placeholder='',
  className,
  type='text',
}: {
  placeholder?: string;
  className?: string;
  type?: 'text' | 'number' | 'checkbox' | 'date'
}) => {
  return <input type={type} className={`${className}`} placeholder={placeholder} />;

};

export default Input;
