import moment from 'moment';

import { IJobPost } from 'common/models';
import { Axios } from 'common/services';

export const platformAndTransactionFee = 0.11;

export const createOrUpdate = async (
  hirerId: string,
  payload: IJobPost
): Promise<IJobPost> =>
  await (
    await Axios.put(`/HirerJobPost/${hirerId}/post-job`, payload)
  ).data;

export const getHirerJobPost = async (jobPostId: string): Promise<IJobPost> =>
  (await Axios.get(`/HirerJobPost/${jobPostId}/job`)).data;

export const calculateJobPostBudget = ({
  end,
  hourlyRate,
  hoursPerDay,
  start,
}: {
  end: Date | string | number;
  hourlyRate: number;
  hoursPerDay: number;
  start: Date | string | number;
}): number => {
  const days = moment(end).diff(moment(start), 'days');
  const cost = days * hoursPerDay * hourlyRate;
  const platformCharge = cost * platformAndTransactionFee;
  return cost + platformCharge;
};
