import { ComponentProps, ReactNode } from 'react';

import { Link } from '@mui/material';

export const AnchorLink = ({
  children,
  underline = 'none',
  ...props
}: {
  children: ReactNode;
} & ComponentProps<typeof Link>) => {
  return (
    <Link underline={underline} {...props}>
      {children}
    </Link>
  );
};
