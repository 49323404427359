import React from 'react';

import { Text } from 'design-library';

import './JobCategories.scss';

const categories = [
  {
    id: 1,
    title: 'Project Management',
  },
  {
    id: 2,
    title: 'Cyber security',
  },
  {
    id: 3,
    title: 'Digital Marketing',
  },
  {
    id: 4,
    title: 'Software Development',
  },
  {
    id: 5,
    title: 'Data Analysis, Database',
  },
  {
    id: 6,
    title: 'Training',
  },
  {
    id: 7,
    title: 'Physical Security',
  },
];

const JobCategories = () => {
  return (
    <div className='jobCategories'>
      <Text className='jobCategories-heading' text='Job categories' />
      <div className='jobCategories-cardWrapper'>
        {categories.map((item) => {
          return (
            <div
              key={item.id}
              className='jobCategories-cardWrapper-item'
            >
              <Text
                className='jobCategories-cardWrapper-item-text'
                text={item.title}
              />
              
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JobCategories;
