import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Table, TableRowParams } from 'design-library-v2';
import { ProjectStatusEnum } from 'common/enums';
import { IProject } from 'common/models';
import { getCurrencyFormat } from 'common/utils';

import { useProjects } from 'projects/hooks';

import './CurrentProjects.scss';

export const CurrentProjects = () => {
  const navigate = useNavigate();

  const {
    isLoadingProjects,
    projectResults,
    paginationModel,
    setPaginationModel,
  } = useProjects({
    statuses: [ProjectStatusEnum.Ongoing, ProjectStatusEnum.Submitted],
  });

  const getFormattedRows = (projects: Array<IProject>) =>
    projects.map((project) => ({
      agreedPrice: getCurrencyFormat(project.price),
      expertName: project.expertName,
      id: project.id,
      projectName: project.projectName,
      startDate: moment(project.startDate).format('DD/MM/YYYY'),
    }));

  const routeToProjectDetails = (params: TableRowParams) =>
    navigate(
      `/hirer/manage-your-projects?activeTab=ongoing-projects&page=${paginationModel.page}&pageSize=${paginationModel.pageSize}&focusedJob=${params.id}`
    );

  return (
    <Table
      columns={[
        {
          field: 'projectName',
          headerName: 'Project Name',
          width: 210,
        },
        {
          field: 'expertName',
          headerName: 'Expert Name',
          width: 210,
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          width: 210,
        },
        {
          field: 'agreedPrice',
          headerName: 'Agreed Price',
          width: 210,
        },
      ]}
      loading={isLoadingProjects}
      paginationMode="server"
      onRowClick={routeToProjectDetails}
      onPaginationModelChange={setPaginationModel}
      paginationModel={paginationModel}
      pageSizeOptions={[paginationModel.pageSize]}
      rowCount={projectResults.total}
      rows={getFormattedRows(projectResults.data)}
    />
  );
};
