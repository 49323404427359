import { useEffect, useMemo, useState } from 'react';
import { getSkills } from 'common/services';
import { ISkill } from 'common/models';

export function useSkillsCache() {
  const [isSkillsLoading, setIsSkillsLoading] = useState(false);
  const [skillsData, setSkillsData] = useState<Array<ISkill>>([]);

  const fetchData = async () => {
    setIsSkillsLoading(true);
    try {
      setSkillsData(await getSkills());
    } catch (error) {
      console.error(error);
    } finally {
      setIsSkillsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const skills = useMemo(() => skillsData, [skillsData]);

  return { isSkillsLoading, skills };
}
