import { PageWrapper } from 'design-library-v2';

import TopBanner from './top-banner/TopBanner';
import JobCategories from './job-categories/JobCategories';
import TechStack from './tech-stack/TechStack';
import WorksCarousel from './works-carousel/WorksCarousel';
import PlansSection from './plans-section/PlansSection';
import FaqSection from './faq-section/FaqSection';
import ContactUs from './contact-us/ContactUs';
import Footer from './footer/Footer';

import './LandingPage.scss';

const LandingPage = () => {
  return (
    <PageWrapper styles={{ paddingY: '0px' }}>
      <TopBanner />
      <JobCategories />
      <TechStack />
      <WorksCarousel />
      <PlansSection />
      <FaqSection />
      <ContactUs />
      <Footer />
    </PageWrapper>
  );
};

export default LandingPage;
