import { Box, Card, Typography } from 'design-library-v2';

import { ProjectStatusEnum } from 'common/enums';

import { useHirerProjectStatistics } from 'hirer/hooks';
import { BASE_BLUE } from 'common/constants/colors';

const statusTitles = [
  'Total Projects',
  'Ongoing Projects',
  'Finished Project',
  'Canceled Projects',
];

const HirerProjectsStatisticsCard = ({
  title,
  count,
}: {
  title: string;
  count: number;
}) => {
  return (
    <Card
      sx={{
        background: 'rgba(54, 159, 255, 0.1)',
        color: BASE_BLUE,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        minWidth: '150px',
      }}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="h2">{count}</Typography>
    </Card>
  );
};

export const HirerProjectsStatistics = () => {
  const { projectStatistics } = useHirerProjectStatistics();

  const getCount = (status: number): number =>
    projectStatistics.find((ps) => ps.status === status)?.count || 0;

  return (
    <Box flexDirectionColumn styles={{ paddingY: '16px', width: '100%' }}>
      <Typography variant="subtitle1">Statistics</Typography>
      <Box
        styles={{ minHeight: '150px', paddingX: '5px', position: 'relative' }}
      >
        <HirerProjectsStatisticsCard
          key={crypto.randomUUID()}
          title={statusTitles[0]}
          count={getCount(6)}
        />
        <HirerProjectsStatisticsCard
          key={crypto.randomUUID()}
          title={statusTitles[1]}
          count={
            getCount(ProjectStatusEnum.Ongoing) +
            getCount(ProjectStatusEnum.Submitted)
          }
        />
        <HirerProjectsStatisticsCard
          key={crypto.randomUUID()}
          title={statusTitles[2]}
          count={getCount(ProjectStatusEnum.Accepted)}
        />
        <HirerProjectsStatisticsCard
          key={crypto.randomUUID()}
          title={statusTitles[3]}
          count={getCount(ProjectStatusEnum.Rejected)}
        />
      </Box>
    </Box>
  );
};
