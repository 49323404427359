import { useContext, useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Form,
  FormControl,
  getDDOption,
  IDropdownOptionBase,
  Input,
  Modal,
  Spinner,
  Typography,
} from 'design-library-v2';

import { IExpertPreference } from 'common/models';
import {
  getEnumKeys,
  JobActiveEnum,
  JobNatureEnum,
  JobTypeEnum,
  SizeEnum,
  UsageTireEnum,
} from 'common/enums';
import { AppContext } from 'common/contexts';
import { getCurrencyFormat } from 'common/utils';

import { ExpertContext } from 'expert/context';
import { ProPlanDetails } from 'expert/components';
import { updateExpertPreferences } from 'expert/services';
import { useExpertTokens } from 'expert/hooks';

import JobPreference from './JobPreference';

export function Preferences() {
  const { expertTokens } = useExpertTokens();
  const { currentExpert, updateCurrentExpert } = useContext(ExpertContext);
  const { locations } = useContext(AppContext);

  const [showProPlanModal, setShowProPlanModal] = useState(false);

  const formik: FormikProps<IExpertPreference> = useFormik<IExpertPreference>({
    initialValues: {
      active: 0,
      companySize: 0,
      hoursPerDay: 0,
      id: crypto.randomUUID(),
      jobLocation: '',
      jobNature: 0,
      jobType: 0,
      perHourRate: 0,
      projectSize: 0,
      salaryHigh: 0,
      salaryLow: 0,
      userId: currentExpert?.id as string,
      ...currentExpert?.userPreference,
    },
    onSubmit: async (values: IExpertPreference, { setSubmitting }) => {
      try {
        const updatedExpert = await updateExpertPreferences(
          currentExpert?.id as string,
          values
        );
        updateCurrentExpert(updatedExpert);
      } catch (error) {
        alert('Failed to update');
        console.error(error); // TO DO use sentry
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getDDOptionFromNumber = (
    num: number,
    label?: string
  ): IDropdownOptionBase => ({
    id: num.toString(),
    label: (label || num.toString())
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase()),
    value: num.toString(),
  });

  const updateNumberDDNumberField = (
    options: Array<IDropdownOptionBase>,
    fieldName: keyof typeof formik.values
  ) => {
    options.forEach((option) => {
      formik.setFieldValue(fieldName, option.value);
    });
  };

  const onChangeJobNature = () =>
    formik.setFieldValue(
      'jobNature',
      !formik.values.jobNature ? JobNatureEnum.Onsite : JobNatureEnum.Remote
    );

  const onChangeJobType = () =>
    formik.setFieldValue(
      'jobType',
      !formik.values.jobType ? JobTypeEnum.FullTime : JobTypeEnum.PartTime
    );

  if (formik.isSubmitting) {
    return <Spinner type="contained" />;
  }

  const isProUser =
    expertTokens?.type &&
    [UsageTireEnum.Pro, UsageTireEnum.ProPlus].includes(expertTokens.type);

  return (
    <Form formik={formik}>
      <JobPreference />
      <Box flexDirectionColumn style={{ gap: '8px' }}>
        <Typography variant="subtitle2">Job Nature</Typography>
        <Box styles={{ justifyContent: 'space-between' }}>
          <Box styles={{ alignItems: 'center' }}>
            <Checkbox
              checked={formik.values.jobNature === JobNatureEnum.Remote}
              onChange={onChangeJobNature}
            />
            <Typography variant="body1">Remote</Typography>
          </Box>
          <Box styles={{ alignItems: 'center' }}>
            <Checkbox
              checked={formik.values.jobNature === JobNatureEnum.Onsite}
              onChange={onChangeJobNature}
            />
            <Typography variant="body1">Onsite</Typography>
          </Box>
        </Box>
      </Box>
      <Box flexDirectionColumn style={{ gap: '8px' }}>
        <Typography variant="subtitle2">Job Type</Typography>
        <Box styles={{ justifyContent: 'space-between' }}>
          <Box styles={{ alignItems: 'center' }}>
            <Checkbox
              checked={formik.values.jobType === JobTypeEnum.PartTime}
              onChange={onChangeJobType}
            />
            <Typography variant="body1">Part Time</Typography>
          </Box>
          <Box styles={{ alignItems: 'center' }}>
            <Checkbox
              disabled={!isProUser}
              checked={formik.values.jobType === JobTypeEnum.FullTime}
              onChange={onChangeJobType}
            />
            <Typography variant="body1">Full Time</Typography>
            {!isProUser && (
              <Button
                buttonType="secondary"
                onClick={() => setShowProPlanModal(true)}
                size="small"
              >
                Pro
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <FormControl label="Your preference for part time job">
        <Dropdown
          defaultOptions={[formik.values.hoursPerDay].map((hour) =>
            getDDOptionFromNumber(hour)
          )}
          label="Hours Per Day"
          onSelect={(options) =>
            updateNumberDDNumberField(options, 'hoursPerDay')
          }
          options={Array.from(Array(10).keys()).map((hour) =>
            getDDOptionFromNumber(hour + 1)
          )}
        />
        <Dropdown
          defaultOptions={[formik.values.active].map((option) =>
            getDDOptionFromNumber(option, getEnumKeys(JobActiveEnum)[option])
          )}
          label="Active"
          onSelect={(options) => updateNumberDDNumberField(options, 'active')}
          options={getEnumKeys(JobActiveEnum).map((option, index) =>
            getDDOptionFromNumber(index, option)
          )}
        />
      </FormControl>
      <FormControl>
        <Dropdown
          defaultOptions={[formik.values.projectSize].map((option) =>
            getDDOptionFromNumber(option, getEnumKeys(SizeEnum)[option])
          )}
          label="Size of the project"
          onSelect={(options) =>
            updateNumberDDNumberField(options, 'projectSize')
          }
          options={getEnumKeys(SizeEnum).map((option, index) =>
            getDDOptionFromNumber(index, option)
          )}
        />

        <Dropdown
          defaultOptions={[formik.values.perHourRate].map((option) =>
            getDDOptionFromNumber(option, `${option}$`)
          )}
          label={'Hourly Rate'}
          onSelect={(options) =>
            updateNumberDDNumberField(options, 'perHourRate')
          }
          options={Array.from(Array(150).keys()).map((option) =>
            getDDOptionFromNumber(option, `${option}$`)
          )}
        />
      </FormControl>
      {formik.values.jobType === JobTypeEnum.FullTime && (
        <>
          <Typography variant="subtitle2">
            Your preference for full time job
          </Typography>
          <Box styles={{ flexWrap: 'wrap' }}>
            <Typography variant="subtitle2">Salary Range</Typography>
            <FormControl>
              <Input
                label="From"
                type="number"
                name="salaryLow"
                onChange={formik.handleChange}
                placeholder={getCurrencyFormat(1000)}
                value={formik.values.salaryLow}
              />
              <Input
                label="To"
                type="number"
                name="salaryHigh"
                onChange={formik.handleChange}
                placeholder={getCurrencyFormat(2000)}
                value={formik.values.salaryHigh}
              />
            </FormControl>
            <FormControl>
              <Dropdown
                defaultOptions={[formik.values.projectSize].map((option) =>
                  getDDOptionFromNumber(option, getEnumKeys(SizeEnum)[option])
                )}
                label="Size of the Company"
                onSelect={(options) =>
                  updateNumberDDNumberField(options, 'companySize')
                }
                options={getEnumKeys(SizeEnum).map((option, index) =>
                  getDDOptionFromNumber(index, option)
                )}
              />
              <Dropdown
                label="Location"
                defaultOptions={
                  formik.values.jobLocation
                    ? [getDDOption({ label: formik.values.jobLocation })]
                    : []
                }
                options={locations.map((l) =>
                  getDDOption({
                    label: l.name,
                    value: l.name,
                  })
                )}
                onSelect={(options) => {
                  options.length &&
                    formik.setFieldValue('jobLocation', options[0].value);
                }}
              />
            </FormControl>
          </Box>
        </>
      )}
      {showProPlanModal && (
        <Modal isShown onCancel={() => setShowProPlanModal(false)} size="large">
          <ProPlanDetails />
        </Modal>
      )}
    </Form>
  );
}
