import moment from 'moment';
import { useState } from 'react';

import { Modal, Table } from 'design-library-v2';
import { IProject } from 'common/models';
import { getCurrencyFormat } from 'common/utils';
import {
  ProjectPaymentStatusEnum,
  ProjectStatusEnum,
  getEnumKeys,
} from 'common/enums';

import { useProjects } from 'projects/hooks';
import { ProjectDetailsCard } from 'projects/components';

export const ExpertPastProjects = () => {
  const {
    isLoadingProjects,
    paginationModel,
    projectResults,
    setPaginationModel,
  } = useProjects({
    statuses: [ProjectStatusEnum.Accepted, ProjectStatusEnum.Rejected],
  });

  const [selectedProject, setSelectedProject] = useState<IProject>();

  const getFormattedRows = (projects: Array<IProject>) =>
    projects.map((project) => ({
      budget: project.price,
      completionDate: moment(project.endDate).format('DD/MM/YYYY'),
      cost: getCurrencyFormat(project.price),
      hirerName: project.hirerName,
      id: project.id,
      paymentStatus: getEnumKeys(ProjectPaymentStatusEnum)[
        project.paymentStatus || 0
      ],
      projectData: project,
      projectName: project.projectName,
    }));

  return (
    <>
      <Table
        columns={[
          {
            field: 'projectName',
            headerName: 'Project Name',
            minWidth: 310,
          },
          {
            field: 'hirerName',
            headerName: 'Hirer Name',
            minWidth: 210,
          },
          {
            field: 'completionDate',
            headerName: 'Completion Date',
            minWidth: 210,
          },
          {
            field: 'cost',
            headerName: 'Cost',
            minWidth: 230,
          },
          {
            field: 'paymentStatus',
            headerName: 'Payment Status',
            minWidth: 210,
          },
        ]}
        loading={isLoadingProjects}
        paginationMode="server"
        onRowClick={(params) => setSelectedProject(params.row.projectData)}
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        rowCount={projectResults.total}
        rows={getFormattedRows(projectResults.data)}
      />
      {selectedProject && (
        <Modal isShown onCancel={() => setSelectedProject(undefined)}>
          <ProjectDetailsCard
            forExpert
            project={selectedProject}
            showMetaInfo
          />
        </Modal>
      )}
    </>
  );
};
