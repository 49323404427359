import React, { ChangeEvent, useState } from 'react';

import { Skeleton } from '@mui/material';

import { AssetServices } from 'services';

import { Box } from '../layout';
import { Avatar } from './Avatar';

import './AvatarUploader.scss';

export const AvatarUploader = ({
  avatarURL,
  onUpload,
  refId,
  title,
}: {
  avatarURL?: string;
  onUpload: (imageURL: string) => void;
  refId: string;
  title?: string;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [imageURL, setImageURL] = useState(avatarURL);

  const handleOnUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length > 0) {
      setIsUploading(true);
      try {
        const imageURL = await AssetServices.uploadProfilePicture(
          refId,
          files[0]
        );
        setImageURL(imageURL + '#');
        onUpload(imageURL);
        window.location.reload();
      } catch (error) {
        console.error(error); // TO DO USE Sentry
        alert('Failed to upload');
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <Box styles={{ alignItems: 'center', gap: '32px' }}>
      {isUploading ? (
        <Skeleton variant="circular" sx={{ height: '112px', width: '112px' }} />
      ) : (
        <Avatar imageUrl={imageURL} />
      )}
      <label
        className="iqcDL-avatarUploader-uploadBtn"
        htmlFor="profile-image-input"
      >
        <Box>{title || 'Upload Your Picture'}</Box>
      </label>
      <input
        accept="image/png, image/jpeg"
        className="iqcDL-avatarUploader-input"
        id="profile-image-input"
        onChange={handleOnUpload}
        type="file"
      />
    </Box>
  );
};
