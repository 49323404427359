import { ReactNode } from 'react';
import { FormikProps } from 'formik';

import Button from '../button/Button';

import { Typography } from '../text';
import { Box } from '../layout';

import './Form.scss';

export default function Form<ValueType>({
  className = '',
  children,
  formik,
  hasDelete = false,
  hasSecondaryBtn = false,
  hideFormFooter = false,
  label,
  secondaryBtnLabel = '',
  submitBtnLabel = 'Save',
  deleteBtnText = 'Delete',
  onDelete,
  onClickSecondaryBtn,
}: {
  className?: string;
  children: ReactNode;
  formik: FormikProps<ValueType>;
  hasDelete?: boolean;
  hasSecondaryBtn?: boolean;
  hideFormFooter?: boolean;
  label?: string;
  secondaryBtnLabel?: string;
  submitBtnLabel?: string;
  deleteBtnText?: string;
  onDelete?: () => void;
  onClickSecondaryBtn?: () => void;
}) {
  return (
    <form className={`iqcDL-form ${className}`} onSubmit={formik?.handleSubmit}>
      <Box flexDirectionColumn>
        {label && <Typography variant="subtitle2">{label}</Typography>}
        {children}
      </Box>
      {!hideFormFooter && (
        <div className="iqcDL-form-footer">
          {hasDelete && (
            <Button
              disabled={formik?.isSubmitting}
              buttonType="secondary"
              onClick={onDelete}
            >
              {deleteBtnText}
            </Button>
          )}
          {hasSecondaryBtn && (
            <Button
              disabled={formik?.isSubmitting}
              buttonType="secondary"
              onClick={onClickSecondaryBtn}
            >
              {secondaryBtnLabel}
            </Button>
          )}
          <Button disabled={formik?.isSubmitting} props={{ type: 'submit' }}>
            {submitBtnLabel}
          </Button>
        </div>
      )}
    </form>
  );
}
