import { CircularProgress } from '@mui/material';

import './Spinner.scss';

export function Spinner({
  className = '',
  type,
}: {
  className?: string;
  type: 'page' | 'contained';
}) {
  return (
    <div className={`iqlDL-spinner ${type} ${className}`}>
      <CircularProgress />
    </div>
  );
}
