import { PageWrapper, Spinner } from 'design-library-v2';
import { HirerBillingTable } from 'hirer/components';
import { HirerContext } from 'hirer/context';
import { useContext } from 'react';

export const BillingPage = () => {
  const { hirer } = useContext(HirerContext);

  if (!hirer) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper>
      <HirerBillingTable hirerId={hirer.id} />
    </PageWrapper>
  );
};
