import React from 'react';

import './Icon.scss';

const Icon = ({
  className='',
  icon,
  altText = 'img',
  size = 'regular',
  styles={}
}: {
  className?: string;
  icon?: string;
  altText?: string;
  size?: string;
  styles?: React.CSSProperties
}) => {
  return (
    <img
      className={`iqc-dl-icon-${size} ${className}`}
      src={icon}
      alt={altText}
      style={{ ...styles }}
    />
  );
};

export default Icon;
