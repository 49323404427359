import React, { ComponentProps } from 'react';
import { Button } from '@mui/material';

export function MUIButton({
  children,
  variant = 'contained',
  color = 'primary',
  ...props
}: {
  children?: React.ReactNode;
} & ComponentProps<typeof Button>) {
  const paddingStyles =
    props.size === 'small'
      ? { padding: '8px 16px' }
      : props.size === 'medium'
        ? { padding: '16px 24px' }
        : { padding: '16px 32px' };

  return (
    <Button
      variant={variant}
      color={color}
      sx={{
        ...(variant !== 'text' ? { borderRadius: '50px' } : {}),
        ...(color === 'primary'
          ? {
            background: 'linear-gradient(180deg, #063065 0%, #3370BC 100%)',
            color: 'white',
          }
          : {}),
        ...paddingStyles,
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '22px',
        textTransform: 'capitalize',
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
