import React, { useState } from 'react';

import Label from '../label/Label';

import './CheckboxSelector.scss';

export function CheckboxSelector({
  alignment = 'vertical',
  className = '',
  defaultOptions = [],
  disabled = false,
  label,
  name,
  options,
  onSelectionChanged,
  required = false,
  singleSelect = false,
  type = 'checkbox',
}: {
  alignment?: 'horizontal' | 'vertical';
  className?: string;
  defaultOptions?: number[];
  disabled?: boolean;
  label?: string;
  name?: string;
  options: string[];
  onSelectionChanged: (selectedOptions: number[]) => void;
  required?: boolean;
  singleSelect?: boolean;
  type?: 'checkbox' | 'radio';
}) {
  const [selectedOptions, setSelectedOptions] =
    useState<number[]>(defaultOptions);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = parseInt(event.target.value);
    let options = [];
    if (event.target.checked) {
      options = singleSelect
        ? [selectedOption]
        : [...selectedOptions, selectedOption];
    } else {
      options = singleSelect
        ? []
        : selectedOptions.filter((option) => option !== selectedOption);
    }

    setSelectedOptions(options);
    onSelectionChanged(options);
  };

  return (
    <div className={`iqcDL-checkboxSelector ${className}`}>
      {label && (
        <Label labelText={label} disabled={disabled} required={required} />
      )}
      <div className={`iqcDL-checkboxSelector-labelContainer __${alignment}`}>
        {options.map((option, index) => (
          <label
            className="iqcDL-checkboxSelector-label"
            key={crypto.randomUUID()}
          >
            <input
              disabled={disabled}
              name={name}
              type={type}
              value={index}
              checked={selectedOptions.includes(index)}
              onChange={handleOptionChange}
              required={required}
            />
            {option}
          </label>
        ))}
      </div>
    </div>
  );
}
