import { ButtonV2, Input, Text } from 'design-library';

import './UnderConstruction.scss';

export const UnderConstruction = () => {
  return (
    <div
      className="u-construction"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + '/images/under-construction.png'
        })`,
      }}
    >
      <Text className="u-construction-heading" text="Under Construction" />
      <Text
        className="u-construction-text"
        text="We Are Currently Working On This Website"
      />
      <Text className="u-construction-text" text="To Deliver" />
      <Text className="u-construction-text" text="A Better User Experience" />
      <Input
        className="u-construction-input"
        placeholder="Type your email account to get notified"
      />
      <div className="u-construction-btn">
        <ButtonV2 text="Send" />
      </div>
    </div>
  );
};
