import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button as MUIButton } from '@mui/material';
import { Box, Divider, Paper, Skeleton, Typography } from 'design-library-v2';

import { JobTypeEnum } from 'common/enums';
import { IBid, IJobPost } from 'common/models';

import { withdrawBid } from 'services';

import { JobPostBasicInfo } from './JobPostBasicInfo';
import { JobPostBidTable } from 'job-post-bidding/components';
import { JobPostMetaInfo } from './JobPostMetaInfo';
import { JobPosterAndBidCard } from './JobPosterAndBidCard';

const JobPostApplications = () => (
  <Paper
    flexDirectionColumn
    styles={{
      alignItems: 'center',
      height: '340px',
      justifyContent: 'center',
      width: '100%',
    }}
  >
    <Typography align="center" variant="subtitle1">
      Your job Has been successfully posted. It is now visible to our pool of
      experts. Our ExpertHire team member will soon contact with you.
    </Typography>
    <Typography variant="h4">Thank you for trusting ExpertHire.</Typography>
  </Paper>
);

export const PostedJobDetailsCard = ({
  focusedJobPostId,
  jobBidInfo,
  jobPost,
  onCloseBidDetails,
  onClickApplyOrBid,
  role = 'hirer',
  showMetaInfo = false,
}: {
  focusedJobPostId?: string | null;
  jobBidInfo?: IBid;
  jobPost: IJobPost;
  onCloseBidDetails?: () => void;
  onClickApplyOrBid?: () => void;
  role?: 'expert' | 'hirer';
  showMetaInfo?: boolean;
}) => {
  const navigate = useNavigate();

  const projectCardRef = useRef<HTMLDivElement>(null);

  const [showProjectMoreInfo, setShowProjectMoreInfo] = useState(false);

  let highlight = false;
  if (jobPost.id === focusedJobPostId) {
    highlight = true;
    projectCardRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  const onWithdrawBid = async (bidId: string) => {
    await withdrawBid(bidId);
    navigate('/expert?activeTab=bid-on-projects');
    onCloseBidDetails?.();
  };

  const rightInfoCardWidth = role === 'expert' ? 70 : 55;

  return (
    <Box ref={projectCardRef} highlight={highlight} flexDirectionColumn>
      <Box>
        <Box
          styles={{
            width: `${rightInfoCardWidth}%`,
          }}
        >
          {!jobPost ? (
            <Skeleton />
          ) : (
            <JobPostBasicInfo jobPost={jobPost} projectId={jobPost.id} />
          )}
        </Box>
        <Box
          styles={{
            width: `${100 - rightInfoCardWidth}%`,
          }}
        >
          {role === 'hirer' ? (
            jobPost.jobType === JobTypeEnum.PartTime ? (
              <JobPostBidTable jobPostId={jobPost.id} />
            ) : (
              <JobPostApplications />
            )
          ) : (
            <JobPosterAndBidCard
              bid={jobBidInfo}
              jobPost={jobPost}
              onWithdrawBid={onWithdrawBid}
              onClickApplyOrBid={onClickApplyOrBid}
            />
          )}
        </Box>
      </Box>
      {(showMetaInfo || showProjectMoreInfo) && (
        <>
          <Divider />
          <JobPostMetaInfo jobPost={jobPost} />
        </>
      )}
      <Box styles={{ justifyContent: 'center' }}>
        {!showMetaInfo && (
          <MUIButton
            variant="text"
            onClick={() =>
              setShowProjectMoreInfo(
                (showProjectMoreDetails) => !showProjectMoreDetails
              )
            }
          >
            {`View ${showProjectMoreInfo ? 'Less' : 'More'}`}
          </MUIButton>
        )}
        {role === 'hirer' && (
          <MUIButton
            variant="text"
            onClick={() => navigate(`/hirer/post-job?jobPostId=${jobPost.id}`)}
          >
            Edit
          </MUIButton>
        )}
      </Box>
    </Box>
  );
};
