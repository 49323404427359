import { ReactElement, useContext } from 'react';

import { UserRole } from 'common/types';
import { AppContext, AuthContext } from 'common/contexts';
import { ErrorsPage } from 'common/components';

import LoadingState from '../LoadingState';

export function AuthorizedRoute({
  children,
  role,
}: {
  children: ReactElement;
  role?: UserRole;
}) {
  const { currentUserRole } = useContext(AppContext);
  const { isAuthenticated, signinRedirect } = useContext(AuthContext);

  if (Boolean(children) && isAuthenticated()) {
    if (role && currentUserRole !== role) {
      return <ErrorsPage />;
    }
    return children;
  }

  signinRedirect();

  return <LoadingState />;
}
