import { useCallback, useEffect, useState } from 'react';

import { IExpertProject as IExpertProject } from 'common/models';

import {
  deleteExpertProject,
  getExpertProjects,
  updateExpertProjects,
} from 'expert/services';

export const useExpertProject = (expertId: string) => {
  const [isExpertProjectsLoading, setIsExpertProjectsLoading] = useState(true);
  const [expertProjects, setExpertProjects] = useState<Array<IExpertProject>>(
    []
  );

  useEffect(() => {
    if (!expertId) {
      return;
    }
    setIsExpertProjectsLoading(true);
    getExpertProjects(expertId)
      .then((res) => setExpertProjects(res))
      .catch((err) => {
        alert('Failed to fetch expert project');
        console.error(err); // TO DO use sentry;
      })
      .finally(() => setIsExpertProjectsLoading(false));
  }, [expertId]);

  const onUpdateExpertProjects = useCallback(
    async (projects: Array<IExpertProject>) => {
      try {
        setIsExpertProjectsLoading(true);
        const updatedProjects = await updateExpertProjects(expertId, projects);
        setExpertProjects(updatedProjects);
      } catch (error) {
        alert('Failed to update user projects');
        console.error(error); // TO DO use sentry;
      } finally {
        setIsExpertProjectsLoading(false);
      }
    },
    [expertId]
  );

  const onDeleteExpertProjects = async (projectId: string) => {
    try {
      setIsExpertProjectsLoading(true);
      await deleteExpertProject(projectId);
      setExpertProjects(
        expertProjects.filter((project) => project.id !== projectId)
      );
    } catch (error) {
      alert('Failed to delete user projects');
      console.error(error); // TO DO use sentry;
    } finally {
      setIsExpertProjectsLoading(false);
    }
  };

  return {
    expertProjects,
    isExpertProjectsLoading,
    onDeleteExpertProjects,
    onUpdateExpertProjects,
  };
};
