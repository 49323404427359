import { useEffect, useState } from 'react';

import { IBid } from 'common/models';

import { getBidInfo } from 'services';

export const useJobBid = (jobBidId: string) => {
  const [bidInfo, setBidInfo] = useState<IBid>();

  useEffect(() => {
    getBidInfo(jobBidId)
      .then(setBidInfo)
      .catch((err) => console.error(err));
  }, [jobBidId]);

  return {
    bidInfo,
  };
};
