import { useState } from 'react';

import { Button as MUIButton } from '@mui/material';
import { Box, Button, Typography } from 'design-library-v2';

import { getCurrencyFormat } from 'common/utils';

import { useTokenPurchase } from 'token/hooks';

export const BuyTokenForm = () => {
  const [token, setToken] = useState(0);
  const { buyToken, isPurchasing, updateSubscription } = useTokenPurchase();

  return (
    <Box flexDirectionColumn>
      <Typography variant="h1">Do not you have enough token?</Typography>
      <Typography variant="subtitle2">
        Choose the perfect package for you
      </Typography>
      <Box>
        <Box flexDirectionColumn>
          <Box flexDirectionColumn styles={{ height: '160px' }}>
            <Typography variant="subtitle1">{`${getCurrencyFormat(0.5)}/Per Token`}</Typography>
            <Box styles={{ alignItems: 'center' }}>
              <Typography variant="subtitle2">{`Number of Token:  ${token}`}</Typography>
              <MUIButton
                variant="outlined"
                size="small"
                onClick={() => setToken((prevToke) => prevToke + 1)}
              >
                +
              </MUIButton>
              <MUIButton
                variant="outlined"
                size="small"
                onClick={() =>
                  setToken((prevToke) => (prevToke - 1 < 0 ? 0 : prevToke - 1))
                }
              >
                -
              </MUIButton>
            </Box>
            <Typography variant="subtitle2">{`Calculate price: ${getCurrencyFormat(
              token * 0.5
            )}`}</Typography>
          </Box>
          <Box>
            <Button
              disabled={token < 1 || isPurchasing}
              onClick={() => buyToken(token)}
            >
              Buy
            </Button>
          </Box>
        </Box>
        <Box flexDirectionColumn>
          <Box flexDirectionColumn styles={{ height: '160px' }}>
            <Typography variant="subtitle1">{`${getCurrencyFormat(5)}/Per Month`}</Typography>
            <Typography variant="subtitle2">* Unlimited token</Typography>
          </Box>
          <Box>
            <Button
              disabled={isPurchasing}
              onClick={() => updateSubscription()}
            >
              Select
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
