import { Text } from 'design-library';


const HowWorks = ({
  title,
  backgroundImage
}:{
  title: string;
  backgroundImage: string;
}) => {
  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className='howWorks'>
      <div className='howWorks-container'>
        <div className='howWorks-item'>
          <Text className='howWorks-item-heading' text={title} />
          <Text className='howWorks-item-description' text='This is a platform where individuals or businesses can find and hire independent professionals, known as freelancers, to complete specific projects or tasks.' />
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
