import { Box, Paper } from 'design-library-v2';

import { ProjectStatusEnum } from 'common/enums';
import { ResultPagePaginationFooter } from 'common/components';
import { useProjects } from 'projects/hooks';

import { ProjectDetailsCard } from './ProjectDetailsCard';

export const HirerPastProjects = ({
  focusedJobPostId,
  page,
  pageSize,
}: {
  focusedJobPostId?: string | null;
  page?: number;
  pageSize?: number;
}) => {
  const { projectResults, paginationModel, setPaginationModel } = useProjects({
    page,
    pageSize,
    statuses: [ProjectStatusEnum.Accepted, ProjectStatusEnum.Rejected],
  });

  return (
    <Box flexDirectionColumn>
      {projectResults.data.map((project) => (
        <Paper key={project.id}>
          <ProjectDetailsCard
            project={project}
            focusedProjectId={focusedJobPostId}
          />
        </Paper>
      ))}

      <ResultPagePaginationFooter
        count={projectResults.data.length}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        total={projectResults.total}
      />
    </Box>
  );
};
