import { useContext, useEffect } from 'react';

import { AuthContext } from 'common/contexts';

import LoadingState from './LoadingState';

const SilentRenew = () => {
  const { signinSilentCallback } = useContext(AuthContext);

  useEffect(() => signinSilentCallback(), [signinSilentCallback]);

  return <LoadingState />;
};

export default SilentRenew;
