import { AxiosError } from 'axios';
import { useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Button } from '@mui/material';

import { CheckboxOption, Form, Input, Typography } from 'design-library-v2';
import { BidStatusEnum } from 'common/enums';
import { IBid, IJobPost } from 'common/models';
import { getCurrencyFormat } from 'common/utils';
import { TermsAndCondition } from 'common/components';

import { bidOnProject } from 'services';

import { platformAndTransactionFee } from 'hirer/services/JobPostingServices';

export const JobPostBiddingForm = ({
  expertId,
  jobPost,
  onConfirmBidding,
}: {
  expertId: string;
  jobPost: IJobPost;
  onConfirmBidding: () => void;
}) => {
  const [showTermsAndConditions, setShowTermsAndConditions] =
    useState<boolean>(false);
  const formik: FormikProps<IBid> = useFormik<IBid>({
    initialValues: {
      agreeTermsAndCondition: false,
      bidAmount: 0,
      expertId,
      id: crypto.randomUUID(),
      jobId: jobPost.id,
      projectName: jobPost.title,
      projectPostDate: jobPost.createdAt,
      status: BidStatusEnum.Draft,
    },
    onSubmit: async (values: IBid, { setSubmitting }) => {
      try {
        await bidOnProject(values);
        onConfirmBidding();
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 400) {
          alert('Already bidden');
          return;
        }
        alert('Failed to bid');
        console.error(error); // TO DO use sentry
      } finally {
        setSubmitting(false);
      }
    },
  });

  if (showTermsAndConditions) {
    return (
      <TermsAndCondition
        isChecked={formik.values.agreeTermsAndCondition}
        checkboxName="agreeTermsAndCondition"
        onCheckTerms={formik.handleChange}
        onAccept={() => setShowTermsAndConditions(false)}
      />
    );
  }

  return (
    <Form<IBid>
      className="JobPostBiddingForm"
      formik={formik}
      submitBtnLabel="Confirm"
    >
      <Input
        label="Amount you want to bid"
        type="number"
        name="bidAmount"
        onChange={formik.handleChange}
        value={formik.values.bidAmount}
        placeholder={getCurrencyFormat(10)}
      />
      <Typography variant="body1">Amount your Hirer will see</Typography>
      <Typography variant="h5">
        {getCurrencyFormat(
          formik.values.bidAmount +
            Math.ceil(formik.values.bidAmount * platformAndTransactionFee)
        )}
      </Typography>
      <Typography variant="body1">
        Our platform adheres to the following formula:
      </Typography>
      <Typography variant="h5">
        Bidding Amount + Platform & Transaction fee (11%)
      </Typography>
      <CheckboxOption
        checked={formik.values.agreeTermsAndCondition}
        name="agreeTermsAndCondition"
        onChange={formik.handleChange}
        required
        value={formik.values.agreeTermsAndCondition}
        size="small"
        labelComponent={
          <Typography variant="caption">
            I certify that i have read and accept the{' '}
            <Button
              variant="text"
              onClick={() => setShowTermsAndConditions(true)}
            >
              terms & conditions
            </Button>{' '}
            and I have read and understand the rate description and rate rules
            for my Project.
          </Typography>
        }
      />
    </Form>
  );
};
