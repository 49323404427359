import { useContext } from 'react';
import { FormikProps, useFormik } from 'formik';

import { ISocialProfile } from 'common/models';
import { Form, FormControl, Input, Spinner } from 'design-library-v2';

import { ExpertContext } from 'expert/context';
import { updateExpertSocialProfiles } from 'expert/services';

export function SocialProfile() {
  const { currentExpert, isExpertContextLoading, updateCurrentExpert } =
    useContext(ExpertContext);

  const formik: FormikProps<ISocialProfile> = useFormik<ISocialProfile>({
    initialValues: {
      github: '',
      id: crypto.randomUUID(),
      linkedin: '',
      twitter: '',
      userId: currentExpert?.id || '',
      website: '',
      ...currentExpert?.socialProfile,
    },
    onSubmit: async (values: ISocialProfile, { setSubmitting }) => {
      if (!currentExpert) {
        return;
      }
      try {
        const updatedExpert = await updateExpertSocialProfiles(
          currentExpert?.id as string,
          values
        );
        updateCurrentExpert(updatedExpert);
      } catch (error) {
        alert('Failed to update!!'); // FIX ME
      } finally {
        setSubmitting(false);
      }
    },
  });

  if (formik.isSubmitting || isExpertContextLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <Form<ISocialProfile> formik={formik}>
      <FormControl>
        <Input
          label="Website"
          name="website"
          onChange={formik.handleChange}
          placeholder="i.e. https://www.expert-hire.io"
          value={formik.values.website}
        />
      </FormControl>
      <FormControl>
        <Input
          label="Linkedin"
          name="linkedin"
          onChange={formik.handleChange}
          placeholder="i.e. https://www.linkedin.com/in/expert-hire"
          value={formik.values.linkedin}
        />
      </FormControl>
      <FormControl>
        <Input
          label="Github"
          name="github"
          onChange={formik.handleChange}
          placeholder="i.e. https://github.com/expert-hire"
          value={formik.values.github}
        />
      </FormControl>
      <FormControl>
        <Input
          label="Twitter"
          name="twitter"
          onChange={formik.handleChange}
          placeholder="i.e. https://twitter.com/expert-hire"
          value={formik.values.twitter}
        />
      </FormControl>
    </Form>
  );
}
