import { IJob } from 'common/models';
import { getJobNames } from 'common/services';
import { useEffect, useMemo, useState } from 'react';

export function useJobNames() {
  const [jobsData, setJobsData] = useState<Array<IJob>>([]);

  const fetchData = async () => {
    try {
      setJobsData(await getJobNames());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const jobs = useMemo(() => jobsData, [jobsData]);

  return { jobs };
}
