import { Axios } from 'common/services';
import {
  IExpertReview,
  IPageResult,
  IProject,
  IProjectStatistics,
} from 'common/models';

export const getProjects = async (
  queryString: string
): Promise<IPageResult<IProject>> =>
  (await Axios.get(`/Project/projects?${queryString}`)).data;

export const getHirerProjectStatistics = async (): Promise<
  Array<IProjectStatistics>
> => (await Axios.get('/Hirer/statistics')).data;

export const submitProject = async (
  projectId: string,
  payload: {
    message: string;
  }
): Promise<IProject> =>
  (await Axios.put(`/Project/${projectId}/submit`, payload)).data;

export const validateProject = async (
  projectId: string,
  payload: Pick<IExpertReview, 'comment' | 'ratings'>
) => await Axios.put(`/Project/${projectId}/validate`, payload);

export const rejectProject = async (projectId: string) =>
  await Axios.put(`/Project/${projectId}/rejected`);
