import { useState } from 'react';

export type PaginationModel = {
  page?: number;
  pageSize?: number;
};

export const usePagination = (
  { page = 0, pageSize = 5 }: PaginationModel = {
    page: 0,
    pageSize: 5,
  }
) => {
  const [paginationModel, setPaginationModel] = useState({
    page,
    pageSize,
  });

  return {
    paginationModel,
    setPaginationModel,
  };
};
