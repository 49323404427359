import clsx from 'clsx';
import { ComponentProps, ForwardedRef, ReactNode } from 'react';
import { Box as MUIBox } from '@mui/material';

type MUIBoxProps = ComponentProps<typeof MUIBox>;

type BoxProps = {
  children: ReactNode;
  className?: string;
  flexDirectionColumn?: boolean;
  highlight?: boolean;
  ref?: ForwardedRef<HTMLDivElement> | null;
  styles?: MUIBoxProps['sx'];
} & MUIBoxProps;

export const Box = ({
  children,
  className = '',
  flexDirectionColumn = false,
  highlight,
  ref = null,
  styles = {},
  ...props
}: BoxProps) => (
  <MUIBox
    className={clsx([className, highlight])}
    ref={ref}
    sx={{
      borderRadius: '8px',
      display: 'flex',
      flexDirection: flexDirectionColumn ? 'column' : 'row',
      gap: '16px',
      width: '100%', // TO DO Remove it
      ...styles,
    }}
    {...props}
  >
    {children}
  </MUIBox>
);
