import { ComponentProps } from 'react';
import { Skeleton as MUISkeleton } from '@mui/material';

export const Skeleton = (props: ComponentProps<typeof MUISkeleton>) => (
  <MUISkeleton
    variant="rectangular"
    sx={{ borderRadius: 'inherit', height: '100%', width: '100%' }}
    {...props}
  />
);
