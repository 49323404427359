import { ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

const palette = {
  primary: {
    dark: '#063065',
    main: '#063065',
    secondary: '#31353B',
  },
};

const theme = createTheme({
  palette,
  spacing: 4,
  typography: {
    fontFamily: ['Manrope'].join(','),
    h1: {
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '56px',
    },
    h4: {
      color: palette.primary.main,
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '40px',
    },
    h6: {
      color: palette.primary.secondary,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '18px',
      margin: '0',
    },
    subtitle1: {
      color: palette.primary.main,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '22px',
    },
    subtitle2: {
      color: palette.primary.secondary,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '22px',
    },
  },
});

export const MUIThemeProvider = ({ children }: { children: ReactNode }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
