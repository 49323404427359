import { useContext } from 'react';

import { IHirer } from 'common/models';
import { Spinner } from 'design-library-v2';

import { HirerContext } from 'hirer/context';
import { HirerProfileSetupServices } from 'hirer/services';
import { HirerPersonalInfoForm } from './HirerPersonalInfoForm';

export function HirerPersonalInfo() {
  const { hirer, updateCurrentHirer } = useContext(HirerContext);

  const onUpdateHirerPersonalInfo = async (updatedHirer: IHirer) => {
    if (!hirer) {
      return;
    }
    updateCurrentHirer(
      await HirerProfileSetupServices.updateHirerInfo(hirer.id, updatedHirer)
    );
  };

  if (!hirer) {
    return <Spinner type="contained" />;
  }

  return (
    <HirerPersonalInfoForm
      hirer={hirer}
      onUpdateHirerPersonalInfo={onUpdateHirerPersonalInfo}
    />
  );
}
