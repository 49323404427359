import { IBase } from './base';

export const languageProficiencyEnum = [
  'Basic',
  'Medium',
  'Professional',
  'Native',
];
export interface ILanguage extends IBase {
  name: string;
  code: string;
}
