import React from 'react';
import { CheckboxSelector } from './CheckboxSelector';

export default function CheckboxSelectorStory() {
  return (
    <>
      <div>
        <CheckboxSelector
          label="Checkbox Selector Vertical"
          options={['A', 'B', 'C']}
          onSelectionChanged={() => ({})}
        />
      </div>
      <div>
        <CheckboxSelector
          label="Checkbox Selector Vertical default options"
          defaultOptions={[1]}
          options={['A', 'B', 'C']}
          onSelectionChanged={() => ({})}
        />
      </div>
      <div>
        <CheckboxSelector
          alignment="horizontal"
          label="Checkbox Selector Horizontal Single Select"
          options={['A', 'B', 'C', 'D', 'E']}
          onSelectionChanged={() => ({})}
          singleSelect
        />
      </div>
      <div>
        <CheckboxSelector
          label="Radio Selector"
          options={['A', 'B', 'C', 'D']}
          onSelectionChanged={() => ({})}
          type="radio"
        />
      </div>
      <div>
        <CheckboxSelector
          alignment="horizontal"
          label="Radio Selector Horizontal"
          options={['A', 'B', 'C', 'D', 'E']}
          onSelectionChanged={() => ({})}
          type="radio"
        />
      </div>
    </>
  );
}
