import { FileTypeEnum } from 'common/enums';
import { Asset, IFile } from 'common/models';
import { Axios } from 'common/services';

import { appConfig } from 'config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const uploadFileToS3 = async (files: File[]): Promise<Asset[]> => {
  throw new Error('Not implemented');
};

const uploadProfilePicture = async (
  refId: string,
  file: File
): Promise<string> => {
  const imageURL = (
    await Axios.post(
      `/Files/${refId}/profile-image`,
      { file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  ).data;
  updateCurrentUserImageURL(imageURL);
  return imageURL;
};

const updateCurrentUserImageURL = (imageURL: string) => {
  sessionStorage.removeItem(appConfig.USER_PROFILE_IMAGE_KEY);
  sessionStorage.setItem(appConfig.USER_PROFILE_IMAGE_KEY, imageURL);
};

const getProfilePictureURL = (): string =>
  sessionStorage.getItem(appConfig.USER_PROFILE_IMAGE_KEY) || '';

const getFilesByReference = async (
  refId: string,
  fileType: FileTypeEnum
): Promise<Array<IFile>> =>
  (await Axios.get(`/Files/${refId}/files/${fileType}`)).data;

const _uploadFiles = async (
  refId: string,
  files: File[],
  url: string
): Promise<void> => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file);
  });

  await Axios.post(`/Files/${refId}/${url}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const uploadProjectFiles = async (
  projectId: string,
  files: File[]
): Promise<void> => await _uploadFiles(projectId, files, 'project-files');

const uploadJobFiles = async (jobId: string, files: File[]): Promise<void> =>
  await _uploadFiles(jobId, files, 'job-files');

const uploadExpertResume = async (
  expertId: string,
  resume: File
): Promise<void> =>
  await Axios.post(
    `/Files/${expertId}/cv`,
    { file: resume },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

const removeFile = async (fileId: string): Promise<void> =>
  await Axios.delete(`/Files/${fileId}`);

const completeDownload = ({ name, url }: { name: string; url: string }) =>
  fetch(url, { method: 'GET' })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });

const downloadFile = async (fileId: string): Promise<void> =>
  completeDownload((await Axios.get(`/Files/${fileId}/url`)).data);

export default {
  completeDownload,
  downloadFile,
  getFilesByReference,
  getProfilePictureURL,
  removeFile,
  updateCurrentUserImageURL,
  uploadExpertResume,
  uploadFileToS3,
  uploadJobFiles,
  uploadProfilePicture,
  uploadProjectFiles,
};
