import { useNavigate } from 'react-router-dom';

import { Button as MUIButton } from '@mui/material';
import {
  AnchorLink,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Spinner,
  Typography,
} from 'design-library-v2';

import { useExpertProject } from 'expert/hooks';

const ProjectAndPortfolio = ({
  expertId,
  readOnly,
}: {
  expertId: string;
  readOnly: boolean;
}) => {
  const navigate = useNavigate();
  const { expertProjects, isExpertProjectsLoading } =
    useExpertProject(expertId);

  const goToProjectEditPage = () =>
    navigate('/expert/profile-setup/project-and-portfolio');

  if (isExpertProjectsLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <Box flexDirectionColumn>
      {expertProjects.map((project) => (
        <Paper key={project.id} flexDirectionColumn>
          <Box styles={{ justifyContent: 'space-between' }}>
            <Typography variant="h6">{project.title}</Typography>
            <Typography variant="h6">{project.orgName}</Typography>
          </Box>
          <Box>
            <Typography>{project.description}</Typography>
          </Box>
          {project.link && (
            <Box styles={{ gap: '8px' }}>
              <Typography>
                <strong>Project URL:</strong>
              </Typography>
              <AnchorLink href={project.link} target="_blank">
                <Typography>{project.link}</Typography>
              </AnchorLink>
            </Box>
          )}
          <Box styles={{ flexWrap: 'wrap', gap: '8px' }}>
            {(project.skills || []).map((skill) => (
              <Chip key={skill.id} label={skill.name} />
            ))}
          </Box>
          <Divider />
          {!readOnly && (
            <Box styles={{ justifyContent: 'flex-end' }}>
              <MUIButton onClick={goToProjectEditPage}>Edit</MUIButton>
            </Box>
          )}
        </Paper>
      ))}

      {!readOnly && (
        <Box styles={{ justifyContent: 'flex-end' }}>
          <Button onClick={goToProjectEditPage}>+ Add More</Button>
        </Box>
      )}
    </Box>
  );
};

export default ProjectAndPortfolio;
