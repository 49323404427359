import { InvoiceStatusEnum } from 'common/enums';
import { IInvoice, IPageResult } from 'common/models';
import { useEffect, useState } from 'react';
import { InvoiceServices } from 'services';
import { usePagination } from './usePagination';

export const useInvoices = ({
  hirerId,
  expertId,
}: Pick<
  Parameters<typeof InvoiceServices.getInvoices>[0],
  'hirerId' | 'expertId'
>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatusEnum>();
  const [invoices, setInvoices] = useState<IPageResult<IInvoice>>({
    data: [],
    total: 0,
  });
  const { paginationModel, setPaginationModel } = usePagination({
    page: 0,
    pageSize: 25,
  });

  useEffect(() => {
    setIsLoading(true);
    InvoiceServices.getInvoices({
      expertId,
      hirerId,
      status: invoiceStatus,
      ...paginationModel,
    })
      .then(setInvoices)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [expertId, hirerId, invoiceStatus, paginationModel]);

  return {
    invoices,
    isLoadingInvoices: isLoading,
    paginationModel,
    setInvoiceStatus,
    setPaginationModel,
  };
};
