import moment from 'moment';
import React from 'react';

import Label from '../label/Label';

import './DatePicker.scss';

export function DatePicker({
  className = '',
  dateFormat = 'YYYY-MM-DD',
  disabled,
  label,
  max,
  min,
  name,
  onChange,
  required,
  value,
}: {
  className?: string;
  disabled?: boolean;
  dateFormat?: 'YYYY-MM-DD';
  label?: string;
  max?: Date | string | number;
  min?: Date | string | number;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  value?: Date | string | number;
}) {
  return (
    <div className={`iqcDl-datePicker ${className}`}>
      {label && <Label labelText={label} disabled={disabled} />}
      <input
        className="iqcDl-datePicker-input"
        disabled={disabled}
        max={max ? moment(max).format(dateFormat) : undefined}
        min={min ? moment(min).format(dateFormat) : undefined}
        name={name}
        onChange={onChange}
        type="date"
        value={value ? moment(value).format(dateFormat) : undefined}
        required={required}
      />
    </div>
  );
}
