import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import {
  IExpert,
  IUserTraining as IExpertTraining,
} from 'common/models';
import { useCurrentExpert } from 'expert/hooks';
import {
  deleteExpertTraining,
  getExpertTrainings,
  updateExpertTrainings,
} from 'expert/services';

import { Spinner } from 'design-library-v2';

export type ExpertContextValue = {
  currentExpert: IExpert | undefined;
  expertTrainings: Array<IExpertTraining>;
  isExpertContextLoading: boolean;
  onDeleteExpertTraining: (trainingId: string) => Promise<void>;
  onUpdateExpertTrainings: (trainings: Array<IExpertTraining>) => Promise<void>;
  updateCurrentExpert: ReturnType<
    typeof useCurrentExpert
  >['updateCurrentExpert'];
};

export const ExpertContext = React.createContext<ExpertContextValue>({
  currentExpert: undefined,
  expertTrainings: [],
  isExpertContextLoading: true,
  onDeleteExpertTraining: () => new Promise(() => ({})),
  onUpdateExpertTrainings: () => new Promise(() => ({})),
  updateCurrentExpert: () => ({}),
});

export const ExpertProvider = ({ children }: { children: ReactNode }) => {
  const { currentExpert, isExpertLoading, updateCurrentExpert } =
    useCurrentExpert();

  const [isExpertContextLoading, setIsExpertContextLoading] =
    useState<boolean>(isExpertLoading);

  const [expertTrainings, setExpertTrainings] = useState<
    Array<IExpertTraining>
  >([]);

  const fetchExpertData = useCallback(async () => {
    if (!currentExpert) {
      return;
    }
    try {
      setIsExpertContextLoading(true);
      setExpertTrainings(await getExpertTrainings(currentExpert.id));
    } catch (error) {
      alert('Failed to fetch expert data');
      console.error(error); // TO DO use sentry;
    } finally {
      setIsExpertContextLoading(false);
    }
  }, [currentExpert]);

  useEffect(() => {
    fetchExpertData();
  }, [fetchExpertData]);

  const onDeleteExpertTraining = async (trainingId: string) => {
    try {
      setIsExpertContextLoading(true);
      await deleteExpertTraining(trainingId);
      setExpertTrainings(
        expertTrainings.filter((tranining) => tranining.id !== trainingId)
      );
    } catch (error) {
      alert('Failed to delete expert training');
      console.error(error); // TO DO use sentry;
    } finally {
      setIsExpertContextLoading(false);
    }
  };

  const onUpdateExpertTrainings = useCallback(
    async (trainings: Array<IExpertTraining>) => {
      try {
        setIsExpertContextLoading(true);
        const updatedTrainings = await updateExpertTrainings(
          currentExpert?.id as string,
          trainings
        );
        setExpertTrainings(updatedTrainings);
      } catch (error) {
        alert('Failed to update user Educations');
        console.error(error); // TO DO use sentry;
      } finally {
        setIsExpertContextLoading(false);
      }
    },
    [currentExpert]
  );

  if (!currentExpert) {
    return <Spinner type="page" />;
  }

  return (
    <ExpertContext.Provider
      value={{
        currentExpert,
        expertTrainings,
        isExpertContextLoading,
        onDeleteExpertTraining,
        onUpdateExpertTrainings,
        updateCurrentExpert,
      }}
    >
      {children}
    </ExpertContext.Provider>
  );
};
