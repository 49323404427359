
const TextArea = ({
  placeholder='Describe here',
  className,
}: {
  placeholder?: string;
  className?: string;
}) => {
  return <textarea className={`${className}`} placeholder={placeholder}/>;
};

export default TextArea;
