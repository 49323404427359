import { Divider, MUIBox, Stack } from 'design-library-v2';
import MessageBox from 'messaging/components/message-box/MessageBox';
// import SearchBox from './SearchBox';
import ContactList from './ContactList';

const MessagingWrapper = () => {
  return (
    <MUIBox py={1} gap={4} width={1}>
      <Stack
        boxShadow={2}
        divider={<Divider />}
        px={2}
        py={2}
        spacing={2}
        width="28%"
        height="80vh"
      >
        {/* <SearchBox /> */}
        <ContactList />
      </Stack>
      <MessageBox />
    </MUIBox>
  );
};

export default MessagingWrapper;
