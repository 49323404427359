import { useEffect, useMemo, useState } from 'react';
import { IExpert } from 'common/models';
import {
  createExpertIdentity,
  getExpert,
  updateExpertClient,
} from 'expert/services';
import { AssetServices } from 'services';

export const useCurrentExpert = () => {
  const [expert, setExpert] = useState<IExpert>();
  const [isExpertLoading, setIsExpertLoading] = useState<boolean>(false);

  const fetchExpert = async () => {
    try {
      setIsExpertLoading(true);
      let expert: IExpert = await getExpert();
      if (!expert) {
        await createExpertIdentity();
        expert = await getExpert();
      }
      expert.imageSrc &&
        AssetServices.updateCurrentUserImageURL(expert.imageSrc);
      await updateExpertClient(expert.id);
      setExpert(expert);
    } catch (error) {
      console.error(error); // TO DO use sentry
    } finally {
      setIsExpertLoading(false);
    }
  };

  useEffect(() => {
    fetchExpert();
  }, []);

  const updateCurrentExpert = (updatedExpert?: IExpert) => {
    if (updatedExpert) {
      setExpert(updatedExpert);
      return;
    }
    fetchExpert();
  };

  const currentExpert = useMemo(() => expert, [expert]);

  return {
    currentExpert,
    isExpertLoading,
    updateCurrentExpert,
  };
};
