import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUserRole } from 'common/hooks';
import { Box, Typography } from 'design-library-v2';

export const PaymentCancel = () => {
  const navigate = useNavigate();

  const { currentUserRole } = useCurrentUserRole();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/${currentUserRole}`);
    }, 3000);
  }, [currentUserRole, navigate]);

  return (
    <Box
      flexDirectionColumn
      styles={{
        alignContent: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h4">Payment Canceled!!</Typography>
      <Typography variant="subtitle1">Redirecting to dashboard...</Typography>
    </Box>
  );
};
