import { useEffect, useState } from 'react';

import { IExpertReview, IPageResult } from 'common/models';
import { usePagination } from 'common/hooks';

import { ExpertReviewServices } from 'expert/services';

export const useExpertReviews = (expertId: string) => {
  const { paginationModel, setPaginationModel } = usePagination({
    page: 0,
    pageSize: 3,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [expertReviews, setExpertReviews] = useState<
    IPageResult<IExpertReview>
  >({
    data: [],
    total: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    const params = new URLSearchParams({
      page: (paginationModel.page + 1).toString(),
      pageSize: paginationModel.pageSize.toString(),
    });
    ExpertReviewServices.getExpertReviews(expertId, params.toString())
      .then(setExpertReviews)
      .catch((err) => {
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoading(false));
  }, [expertId, paginationModel.page, paginationModel.pageSize]);

  return {
    expertReviews,
    isLoadingExpertReviews: isLoading,
    paginationModel,
    setPaginationModel,
  };
};
