import { useEffect, useState } from 'react';

import { PaymentServices } from 'services';
import { ExpertStripeMetaInfo } from '../types';

export const useExpertStripeConnection = () => {
  const [isConnectingStripe, setIsConnectingStripe] = useState(false);
  const [isStripeConnected, setIsStripeConnected] = useState(false);

  useEffect(() => {
    PaymentServices.isStripeConnected()
      .then((res) => setIsStripeConnected(res))
      .catch((err) => {
        console.error(err); //TO DO Use sentry
      });
  }, []);

  const connectStripe = async (payload: ExpertStripeMetaInfo) => {
    setIsConnectingStripe(true);
    try {
      const redirectURL = await PaymentServices.connectExpertToStripe(payload);
      window.location.replace(redirectURL);
    } catch (err) {
      console.error(err); //TO DO Use sentry
      alert('Failed to connect to stripe!');
    } finally {
      setIsConnectingStripe(false);
    }
  };

  return {
    connectStripe,
    isConnectingStripe,
    isStripeConnected,
  };
};
