import { useEffect, useState } from 'react';

import { IJobPost } from 'common/models';

import { getJobPost } from 'services';

export const useJobPost = (jobPostId: string) => {
  const [jobPost, setJobPost] = useState<IJobPost>();

  useEffect(() => {
    getJobPost(jobPostId)
      .then((res) => setJobPost(res))
      .catch((err) => console.error(err));
  }, [jobPostId]);

  return {
    jobPost,
  };
};
