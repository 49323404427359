import moment from 'moment';
import { Log, SigninRequest, User, UserManager } from 'oidc-client';

import { appConfig, oidcConfig } from 'config';
import { UserRole } from 'common/types';

export class AuthService {
  userManager: UserManager;

  constructor() {
    this.userManager = new UserManager(oidcConfig);

    Log.logger = console;
    Log.level = Log.DEBUG;

    this.userManager.events.addUserLoaded(() => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });

    this.userManager.events.addSilentRenewError((e) => {
      console.error('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpiring(() => {
      console.error('token expiring...');
      this.signinSilent();
    });

    this.userManager.events.addAccessTokenExpired(() => {
      console.error('token expired');
      this._clearUserSession();
      this.signinSilent();
    });
  }

  _getSessionItemKey() {
    return appConfig.USER_SESSION_TOKEN_KEY;
  }

  _getUserSession() {
    return sessionStorage.getItem(this._getSessionItemKey());
  }

  _updateUserSession(user: User) {
    sessionStorage.removeItem(this._getSessionItemKey());
    sessionStorage.setItem(this._getSessionItemKey(), user.access_token);
  }

  _clearUserSession() {
    sessionStorage.removeItem(this._getSessionItemKey());
    sessionStorage.removeItem(appConfig.USER_PROFILE_IMAGE_KEY);
  }

  _storeCurrentLocationURL() {
    const currentPath = window.location.pathname;
    if (currentPath !== '/') {
      sessionStorage.setItem(
        appConfig.authConfig.currentLocationKey,
        currentPath
      );
    }
  }

  createSigninRequest = (): Promise<SigninRequest> => {
    return this.userManager.createSigninRequest();
  };

  getUser = async (): Promise<User> => {
    const user = await this.userManager.getUser();
    if (!user) {
      return await this.userManager.signinRedirectCallback();
    }
    return user;
  };

  getUserRole = async (): Promise<UserRole> => {
    const user = await this.getUser();
    return user.profile.role;
  };

  getUserLastLogin = async (): Promise<Date> => {
    return moment((await this.getUser())?.profile?.last_login).toDate();
  };

  isAuthenticated = (): boolean => {
    return Boolean(this._getUserSession());
  };

  logout = (): void => {
    this.userManager.signoutRedirect({
      id_token_hint: sessionStorage.getItem(
        appConfig.authConfig.idTokenHintKey
      ),
    });
    this.userManager.clearStaleState();
    this._clearUserSession();
  };

  navigateToScreen = (): void => {
    window.location.replace('/');
  };

  signinRedirect = (): void => {
    this._storeCurrentLocationURL();
    this.userManager.signinRedirect();
  };

  signinRedirectCallback = (): void => {
    this.userManager.signinRedirectCallback().then((user: User) => {
      this._updateUserSession(user);
      window.location.replace(
        sessionStorage.getItem(appConfig.authConfig.currentLocationKey) ||
          `/${user.profile.role}`
      );
    });
  };

  signinSilent = (): void => {
    this.userManager
      .signinSilent()
      .then((user: User) => {
        this._updateUserSession(user);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  signinSilentCallback = (): void => {
    this.userManager.signinSilentCallback();
  };

  signoutRedirectCallback = (): void => {
    this.userManager.signoutRedirect().then(() => {
      this._clearUserSession();
      sessionStorage.removeItem(appConfig.authConfig.currentLocationKey);
    });
    this.userManager.clearStaleState();
  };
}
