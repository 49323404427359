import React from 'react';

import { Text } from 'design-library';
import { Button, CheckboxOption, MUICheckboxProps } from 'design-library-v2';

import './TermsAndCondition.scss';

export const TermsAndCondition = ({
  checkboxName,
  disableAcceptBtn,
  isChecked = false,
  isCheckingRequired = false,
  onCheckTerms,
  onAccept,
}: {
  checkboxName?: string;
  disableAcceptBtn?: boolean;
  isChecked?: boolean;
  isCheckingRequired?: boolean;
  onCheckTerms: MUICheckboxProps['onChange'];
  onAccept: () => void;
}) => {
  return (
    <div className="termsAndCondition">
      <div className="termsAndCondition-container">
        <div className="termsAndCondition-terms">
          <Text className="termsAndCondition-terms-title" text="Terms of Use" />
          <Text
            className="termsAndCondition-terms-date"
            text="Last updated on 1/12/2021"
          />
        </div>
        <div className="termsAndCondition-conditions">
          <Text
            className="termsAndCondition-conditions-title"
            text="By using our Expert Hire marketplace platform, you agree to the following terms and conditions: "
          />
          <ul className="termsAndCondition-conditions-ul">
            <li>
              By submitting a bid, you confirm that you have fully understood
              the project requirements and have the necessary expertise to
              complete the project to the hirers satisfaction.
            </li>

            <li>
              You agree to complete any project you bid on to the best of your
              abilities and within the time-frame and cost specified by the
              hirer. If you cannot complete a project, you agree to notify the
              hirer as soon as possible.
            </li>

            <li>
              You agree to maintain clear and open communication with the hirer
              throughout the project, providing regular updates and addressing
              any question or concern they may have.
            </li>

            <li>
              You understand that hirers have the right to request changes or
              revisions to the work you submit, and you agree to make reasonable
              efforts to accommodate these requests.
            </li>

            <li>
              You agree to provide a detailed work roadmap to the hirer after
              the hirer accepts the bid. The work roadmap shall include a
              timeline, milestones, deliverables, and any other relevant
              information related to the project.
            </li>

            <li>
              You agree to inform the hirer if you will be using any paid tools
              or logistics to complete the project. You shall discuss with the
              hirer the costs associated with such tools or logistics and obtain
              the hirers prior written consent before proceeding with any
              purchases.
            </li>

            <li>
              You understand that hirers have the right to request a refund if
              they are not satisfied with the work you have completed. In the
              event of a refund, you will not receive any payment for the
              project and agree that our platform is not responsible for any
              refund.
            </li>

            <li>
              You agree to maintain the confidentiality of all information and
              materials provided by the hirer and not to disclose any such
              information or materials to any third party without the hirers
              prior written consent. You also agree that all intellectual
              property rights in any work created for the hirer shall belong to
              the hirer.
            </li>

            <li>
              You agree to maintain a high standard of professionalism and
              integrity when interacting with hirers on our platform. This
              includes refraining from engaging in any inappropriate or
              unethical behavior.
            </li>

            <li>
              You understand that our platform charges a transaction fee and
              commission for each project completed through our platform, and
              you agree to pay these fees as well as conversion fees if there
              are any.
            </li>

            <li>
              You acknowledge and agree that you shall not share any personal
              contact information, such as email addresses or phone numbers,
              with the hirer, and shall communicate with the hirer only through
              the platforms messaging system. You also understand that sharing
              personal contact information or communicating outside of the
              platform may compromise the security and integrity of the platform
              and may also violate the terms of service of the platform. You
              agree that if you are found to be sharing personal contact
              information or communicating outside of the platform, the platform
              may suspend or terminate your account, and that you may be banned
              from using the platform in the future.
            </li>

            <li>
              You agree to comply with all applicable laws and regulations in
              your use of our platform.
            </li>

            <li>
              You understand that any violation of these terms and conditions
              may result in your suspension or termination from our platform.
            </li>
          </ul>
          <Text
            className="termsAndCondition-conditions-text"
            text='If you have any questions or concerns about these terms and conditions, please do not hesitate to contact us. By clicking "Agree" below, you acknowledge that you have read and agreed to these terms and conditions. '
          />
          <div className="termsAndCondition-conditions-checkbox">
            <CheckboxOption
              checked={isChecked}
              name={checkboxName}
              labelComponent="I agree to terms & conditions."
              onChange={onCheckTerms}
              required={isCheckingRequired}
              size="small"
            />
          </div>

          <div className="termsAndCondition-conditions-btn">
            <Button
              disabled={(isCheckingRequired && !isChecked) || disableAcceptBtn}
              onClick={onAccept}
            >
              Accept & Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
