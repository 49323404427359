import { useEffect, useState } from 'react';

import { IProjectStatistics } from 'common/models';
import { getHirerProjectStatistics } from 'services';

export const useHirerProjectStatistics = () => {
  const [isLoadingProjectStatistics, setIsLoadingProjectStatistics] =
    useState(false);
  const [projectStatistics, setProjectStatistics] = useState<
    Array<IProjectStatistics>
  >([]);

  useEffect(() => {
    setIsLoadingProjectStatistics(true);
    getHirerProjectStatistics()
      .then(setProjectStatistics)
      .catch((err) => {
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoadingProjectStatistics(false));
  }, []);

  return {
    isLoadingProjectStatistics,
    projectStatistics,
  };
};
