import { useContext, useEffect, useState } from 'react';
import { User } from 'oidc-client';
import { AuthContext } from 'common/contexts';
import { useCurrentUserRole } from './useCurrentUserRole';

export function useCurrentUser() {
  const { currentUserRole } = useCurrentUserRole();
  const { getUser } = useContext(AuthContext);
  const [currentUser, setCurrentUser] = useState<User>();

  useEffect(() => {
    getUser().then( user => setCurrentUser(user));
  }, [currentUserRole, getUser]);

  return { currentUser };
}
