import { Box, Table } from 'design-library-v2';

import { useInvoices } from 'common/hooks';
import { IInvoice } from 'common/models';
import { getCurrencyFormat, getDateFormat } from 'common/utils';
import { InvoiceStatusEnum, getEnumKeys } from 'common/enums';
import { InvoiceDownloadButton } from 'common/components';

export const HirerBillingTable = ({ hirerId }: { hirerId: string }) => {
  const { invoices, paginationModel, setPaginationModel } = useInvoices({
    hirerId,
  });

  const getFormattedRows = (invoices: Array<IInvoice>) =>
    invoices.map((invoice) => ({
      amount: `${getCurrencyFormat(invoice.amount)}`,
      date: getDateFormat(invoice.createdAt),
      id: invoice.id,
      invoice,
      projectName: invoice.projectName,
      status:
        getEnumKeys(InvoiceStatusEnum)[(invoice.hirerPaymentStatus || 1) - 1],
      transactionId: invoice.transactionId,
    }));

  return (
    <Box>
      <Table
        columns={[
          {
            field: 'date',
            headerName: 'Date',
            width: 100,
          },
          {
            field: 'transactionId',
            headerName: 'Transaction Id',
            width: 350,
          },
          {
            field: 'projectName',
            headerName: 'Project Name',
            width: 300,
          },
          {
            field: 'amount',
            headerName: 'Amount',
            width: 210,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 210,
          },
          {
            field: 'invoice',
            headerName: 'Invoice',
            renderCell: (params) => (
              <InvoiceDownloadButton invoice={params.value} />
            ),
            width: 210,
          },
        ]}
        loading={false}
        paginationMode="server"
        onRowClick={() => ({})}
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        rowCount={invoices.total}
        rows={getFormattedRows(invoices.data)}
      />
    </Box>
  );
};
