import { useContext } from 'react';

import { PageWrapper, Spinner } from 'design-library-v2';
import { CarouselContent } from 'common/components';

import { ExpertContext } from 'expert/context';
import { useJobPostSearch } from 'expert/hooks';
import { JobSearchForm } from 'expert/bulletin-board/components';
import { JobsRecommended } from 'expert/jobs-recommended/components';

export const ExpertBulletinBoardPage = () => {
  const { currentExpert } = useContext(ExpertContext);
  const {
    isSearchingJobPosts,
    jobResults,
    paginationModel,
    setIsSearchingJobPosts,
    setPaginationModel,
    setSearchParams,
  } = useJobPostSearch();

  return (
    <PageWrapper>
      <JobSearchForm
        setIsSearchingJobPosts={setIsSearchingJobPosts}
        setSearchParams={setSearchParams}
      />
      <CarouselContent />
      {isSearchingJobPosts || !currentExpert ? (
        <Spinner type="contained" />
      ) : (
        <JobsRecommended
          expertId={currentExpert.id}
          jobResults={jobResults}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      )}
    </PageWrapper>
  );
};
