import { ReactNode } from 'react';
import { Button } from '@mui/material';

import { Box } from 'design-library-v2/layout';
import { Appearance, ComponentSize } from '../type';

import './Modal.scss';

export function Modal({
  appearance = 'primary',
  children,
  hideCloseBtn = false,
  isShown,
  onCancel,
  size = 'large',
}: {
  appearance?: Appearance;
  children: ReactNode;
  hideCloseBtn?: boolean;
  isShown: boolean;
  onCancel: () => void;
  size?: ComponentSize;
}) {
  if (!isShown) {
    return null;
  }

  return (
    <div className="iqlDL-modal">
      <div className={`iqlDL-modal-container ${size} ${appearance}`}>
        {!hideCloseBtn && (
          <Button
            className="iqlDL-modal-cancelBtn"
            variant="text"
            size="small"
            onClick={onCancel}
          >
            Close
          </Button>
        )}
        <Box flexDirectionColumn>{children}</Box>
      </div>
    </div>
  );
}
