import { ComponentProps } from 'react';

import { TabList } from 'design-library-v2';

import { useExpertReviews } from 'expert/hooks';

import ProjectAndPortfolio from './profileMenuOptions/ProjectAndPortfolio';
import EducationAndTraining from './profileMenuOptions/education-and-training/EducationAndTraining';
import Experience from './profileMenuOptions/Experience';
import RecruiterReviews from './profileMenuOptions/RecruiterReviews';

export const ExpertProfileTabs = ({
  expertId,
  readOnly = false,
}: {
  expertId: string;
  readOnly?: boolean;
}) => {
  const { expertReviews, paginationModel, setPaginationModel } =
    useExpertReviews(expertId);

  const tabNames = [
    'Project & Portfolio',
    'Education & Training',
    'Experience',
  ];
  const tabs: ComponentProps<typeof TabList>['tabs'] = [
    {
      component: (
        <ProjectAndPortfolio expertId={expertId} readOnly={readOnly} />
      ),
      label: tabNames[0],
    },
    {
      component: (
        <EducationAndTraining expertId={expertId} readOnly={readOnly} />
      ),
      label: tabNames[1],
    },
    {
      component: <Experience expertId={expertId} readOnly={readOnly} />,
      label: tabNames[2],
    },
  ];

  if (expertReviews.total) {
    tabNames.unshift('Recruiter Reviews');
    tabs.unshift({
      component: (
        <RecruiterReviews
          reviews={expertReviews}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      ),
      label: tabNames[0],
    });
  }

  return <TabList defaultTab={tabNames[0]} tabs={tabs} />;
};
