import moment from 'moment';

export const getCurrencyFormat = (value: number) =>
  new Intl.NumberFormat('en-IN', {
    currency: 'EUR',
    style: 'currency',
  }).format(value);

export const getDateFormat = (date?: string | Date, format = 'DD/MM/YY') =>
  date ? moment(date).format(format) : '-';

export const getRatingPrecision = (value: number) => Number(value).toFixed(1);
