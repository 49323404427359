import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Skeleton,
} from 'design-library-v2';

import {
  ProjectPaymentStatusEnum,
  ProjectStatusEnum,
  getEnumKeys,
} from 'common/enums';
import { IFile, IProject } from 'common/models';
import { getCurrencyFormat, getDateFormat } from 'common/utils';

import { useProjectFiles } from 'projects/hooks';
import { useJobBid } from 'job-post-bidding/hooks';
import { SubTitleAndCaption } from 'job-post/components';
import { ProjectSubmissionForm } from './ProjectSubmissionForm';

export const ProjectOwnerCard = ({
  isSubmittingProject,
  project,
  projectFiles,
  onDeleteFile,
  onSubmitProject,
  onUploadProjectFiles,
}: {
  isSubmittingProject: boolean;
  project: IProject;
  projectFiles: IFile[];
  onDeleteFile?: (fileId: string) => void;
  onSubmitProject: (message: string) => Promise<void>;
  onUploadProjectFiles: ReturnType<
    typeof useProjectFiles
  >['onUploadProjectFiles'];
}) => {
  const navigate = useNavigate();
  const { bidInfo } = useJobBid(project.jobBidId);

  const [showProjectSubmissionModal, setShowProjectSubmissionModal] =
    useState(false);

  if (!bidInfo) {
    return <Skeleton />;
  }

  return (
    <Paper styles={{ height: 'fit-content', width: '100%' }}>
      <Box flexDirectionColumn>
        <Avatar size="medium" imageUrl={project.hirerImage} />
        <SubTitleAndCaption subTitle={bidInfo.bidInfo?.hirerName} />
        <Box>
          <SubTitleAndCaption
            subTitle="Start Date"
            caption={getDateFormat(bidInfo.createdAt)}
          />
          <SubTitleAndCaption
            subTitle="Agreed Price"
            caption={`${getCurrencyFormat(bidInfo.bidAmount)}`}
          />
        </Box>
        <Box>
          {project.status === ProjectStatusEnum.Accepted && (
            <SubTitleAndCaption
              subTitle="Payment Status"
              caption={
                getEnumKeys(ProjectPaymentStatusEnum)[
                  project.paymentStatus || 0
                ]
              }
            />
          )}
          {project.status === ProjectStatusEnum.Submitted && (
            <SubTitleAndCaption
              subTitle="Completion Date"
              caption={getDateFormat(project.endDate)}
            />
          )}
        </Box>

        <Divider />
        <Box styles={{ justifyContent: 'space-between' }}>
          {[ProjectStatusEnum.Ongoing, ProjectStatusEnum.Submitted].includes(
            project.status
          ) && (
            <Button
              disabled={project.status === ProjectStatusEnum.Submitted}
              onClick={() => setShowProjectSubmissionModal(true)}
              size="small"
            >
              Submit
            </Button>
          )}
          <Button size="small" onClick={() => navigate('/expert/messaging')}>
            Message
          </Button>
        </Box>
      </Box>
      {showProjectSubmissionModal && (
        <Modal
          isShown
          size="medium"
          onCancel={() => setShowProjectSubmissionModal(false)}
        >
          <ProjectSubmissionForm
            isSubmittingProject={isSubmittingProject}
            onDeleteFile={onDeleteFile}
            onUploadProjectFiles={onUploadProjectFiles}
            onSubmitProject={onSubmitProject}
            projectFiles={projectFiles}
          />
        </Modal>
      )}
    </Paper>
  );
};
