import { Chip as MUIChip } from '@mui/material';

import { Variant } from '../type';

export const Chip = ({
  label,
  variant = 'outlined',
}: {
  label: string;
  variant?: Variant;
}) => {
  return <MUIChip label={label} sx={{ cursor: 'pointer' }} variant={variant} />;
};
