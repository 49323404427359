import { Outlet } from 'react-router-dom';

import { HirerProfileSetupNavbar } from 'hirer/components';

import { Box, PageWrapper } from 'design-library-v2';

export const HirerProfileSetupPage = () => {
  return (
    <PageWrapper>
      <Box styles={{ paddingY: '32px' }}>
        <Box styles={{ position: 'relative', width: '32%' }}>
          <HirerProfileSetupNavbar />
        </Box>
        <Box styles={{ paddingX: '72px', position: 'relative' }}>
          <Outlet />
        </Box>
      </Box>
    </PageWrapper>
  );
};
