import React, { useEffect, useState, useRef } from 'react';
import { MESSAGE_EVENTS } from 'services/constants';
import { signalrService } from 'services/signalrServices';
import {
  MessagingUserType,
  ConversationType,
  UserConversationsType,
} from 'messaging/types';
import { useCurrentUser } from 'common/hooks';

export type MessagingContextValue = {
  conversations: UserConversationsType;
  selectedMessagingUser: MessagingUserType | undefined;
  selectedUserConversations: Array<ConversationType>;
  messagingUsers: Array<MessagingUserType>;
  contacts: Array<MessagingUserType>;
  updateSelectedUser: (
    currentChoiceUser: MessagingUserType | undefined
  ) => void;
  onSearchContact: (searchValue: string) => void;
  sendMessageToSelectedUser: (message: string) => void;
};

export const MessagingContext = React.createContext<MessagingContextValue>({
  contacts: [],
  conversations: {},
  messagingUsers: [],
  onSearchContact: () => ({}),
  selectedMessagingUser: undefined,
  selectedUserConversations: [],
  sendMessageToSelectedUser: () => ({}),
  updateSelectedUser: () => ({}),
});

export const MessagingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentUser } = useCurrentUser();

  const [selectedMessagingUser, setSelectedMessagingUser] = useState<
    MessagingUserType | undefined
  >(undefined);
  const [selectedUserConversations, setSelectedUserConversations] = useState<
    Array<ConversationType>
  >([]);
  const [messagingUsers, setMessagingUsers] = useState<
    Array<MessagingUserType>
  >([]);
  const [contacts, setContacts] = useState<Array<MessagingUserType>>([]);
  const [conversations, setConversations] = useState<UserConversationsType>({});

  const mappedOnlineUsersRef = useRef<Set<string>>(new Set());

  const updateSelectedUser = (
    currentChoiceUser: MessagingUserType | undefined
  ): void => {
    if (currentChoiceUser) {
      setSelectedUserConversations(
        conversations[currentChoiceUser.userId] ?? []
      );
      setSelectedMessagingUser({
        ...currentChoiceUser,
        isOnlne: mappedOnlineUsersRef.current.has(currentChoiceUser?.userId),
      });
    } else {
      setSelectedMessagingUser(currentChoiceUser);
    }
  };

  const updateConversations = (
    conversationId: string,
    data: ConversationType
  ): void => {
    setConversations((prev) => {
      return {
        ...prev,
        [conversationId]: [...(prev[conversationId] ?? []), data],
      };
    });

    setSelectedUserConversations((prev) => [...prev, data]);
  };

  const onRecieveOnlineUsers = (
    onlineUsers: Array<MessagingUserType>
  ): void => {
    const mappedUsers = new Set(onlineUsers.map((item) => item.userId));

    mappedOnlineUsersRef.current = mappedUsers;
  };

  useEffect(() => {
    signalrService.addGetOnlineUsersHandler(onRecieveOnlineUsers);

    return () => signalrService.closeEventHandler('getOnlineUsers');
  }, [onRecieveOnlineUsers]);

  const onRecieveMessage = (
    recieverId: string,
    data: Omit<ConversationType, 'isSelf'>
  ) => {
    const recievedUser = messagingUsers.find(
      (item) => item.userId === recieverId
    );

    if (recievedUser) {
      setContacts([
        recievedUser,
        ...contacts.filter((item) => item.userId !== recieverId),
      ]);
      updateSelectedUser(recievedUser);
    }

    updateConversations(recieverId, {
      ...data,
      isSelf: false,
    });
  };

  useEffect(() => {
    signalrService.addMessageReceivedHandler(onRecieveMessage);
    return () =>
      signalrService.closeEventHandler(MESSAGE_EVENTS.RECEIVE_MESSAGE);
  }, [onRecieveMessage]);

  const onRecieveChatList = (data: Array<MessagingUserType>): void => {
    setMessagingUsers(data);
    setContacts(data);
    updateSelectedUser(data[0]);
  };

  useEffect(() => {
    signalrService.getChatList(onRecieveChatList);
    return () =>
      signalrService.closeEventHandler(MESSAGE_EVENTS.GET_MESSAGE_HISTORY);
  }, [onRecieveChatList]);

  const getPeerMessageHistory = (
    peerConversations: Array<Omit<ConversationType, 'isSelf'>>
  ) => {
    const retrievedPeersConversations: UserConversationsType = {};

    const currentUserId = currentUser?.profile?.sub;

    peerConversations.forEach((peerConversation) => {
      const isSelf = peerConversation.senderId === currentUserId;
      const mappedConversationId =
        peerConversation.senderId === currentUserId
          ? peerConversation.receiverId
          : peerConversation.senderId;

      if (retrievedPeersConversations[mappedConversationId]) {
        retrievedPeersConversations[mappedConversationId].push({
          ...peerConversation,
          isSelf,
        });
      } else {
        retrievedPeersConversations[mappedConversationId] = [
          { ...peerConversation, isSelf },
        ];
      }
    });

    setConversations((prevConversations) => ({
      ...prevConversations,
      ...retrievedPeersConversations,
    }));

    const selectedUserId = selectedMessagingUser?.userId;

    if (selectedUserId) {
      setSelectedUserConversations(
        retrievedPeersConversations[selectedUserId] ||
          conversations[selectedUserId] ||
          []
      );
    }
  };

  useEffect(() => {
    if (selectedMessagingUser && !conversations[selectedMessagingUser.userId]) {
      signalrService.getPeerMessageHistory(getPeerMessageHistory);
      signalrService.invokePeerMessage(selectedMessagingUser.userId);
    }

    return () => {
      signalrService.closeEventHandler(MESSAGE_EVENTS.GET_PEER_MESSAGE);
      signalrService.closeEventHandler(MESSAGE_EVENTS.PEER_MESSAGE);
    };
  }, [selectedMessagingUser]);

  useEffect(() => {
    signalrService.startConnection();

    return () => signalrService.stopConnection();
  }, []);

  const onSearchContact = (searchValue: string): void => {
    if (!searchValue) {
      setContacts(messagingUsers);
    } else {
      setContacts(
        messagingUsers.filter((item) =>
          item.name.toLowerCase().includes(searchValue)
        )
      );
    }
  };

  const sendMessageToSelectedUser = (message: string) => {
    if (selectedMessagingUser) {
      signalrService.sendMessage(selectedMessagingUser.userId, message);

      updateConversations(selectedMessagingUser.userId, {
        body: message,
        createdAt: new Date().toString(),
        id: crypto.randomUUID(),
        isSeen: false,
        isSelf: true,
        receiverId: selectedMessagingUser.userId,
        senderId: currentUser?.profile?.sub || crypto.randomUUID(),
      });
    }
  };

  return (
    <MessagingContext.Provider
      value={{
        contacts,
        conversations,
        messagingUsers,
        onSearchContact,
        selectedMessagingUser,
        selectedUserConversations,
        sendMessageToSelectedUser,
        updateSelectedUser,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};
