import { Box, Typography } from 'design-library-v2';

import { IJobPost, IPageResult } from 'common/models';
import { ResultPagePaginationFooter } from 'common/components';

import { useJobPostSearch } from 'expert/hooks';
import { JobPostCard } from 'expert/bulletin-board/components';

import { JobsRecommendedEmptyState } from './JobsRecommendedEmptyState';


type UseJobPostSearchTypes = ReturnType<typeof useJobPostSearch>;

export const JobsRecommended = ({
  expertId,
  jobResults = {
    data: [],
    total: 0,
  },
  paginationModel = { page: 0, pageSize: 9 },
  setPaginationModel = () => ({}),
}: {
  expertId: string;
  jobResults?: IPageResult<IJobPost>;
  paginationModel?: UseJobPostSearchTypes['paginationModel'];
  setPaginationModel?: UseJobPostSearchTypes['setPaginationModel'];
}) => {
  return (
    <Box flexDirectionColumn style={{ gap: '32px' }}>
      <Typography variant="h4">Jobs recommended for You</Typography>
      <Box
        styles={{
          flexWrap: 'wrap',
          gap: '32px',
          justifyContent: 'space-evenly',
        }}
      >
        {!jobResults.data.length ? (
          <JobsRecommendedEmptyState />
        ) : (
          jobResults.data.map((jobPost) => (
            <JobPostCard
              key={jobPost.id}
              expertId={expertId}
              jobPost={jobPost}
            />
          ))
        )}
      </Box>
      <ResultPagePaginationFooter
        count={jobResults.data.length}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        total={jobResults.total}
      />
    </Box>
  );
};
