import { useContext, useRef, useEffect } from 'react';

import { MUIBox, Stack, Typography } from 'design-library-v2';
import { MessagingContext } from 'messaging/context';

import { formatDateTime } from 'common/services';
import { ConversationType } from 'messaging/types';

const ConversationItem = ({
  self = true,
  conversation,
}: {
  self?: boolean;
  conversation: ConversationType;
}) => {
  return (
    <MUIBox justifyContent={self ? 'flex-start' : 'flex-end'}>
      <Stack py={2} alignItems={self ? 'self-start' : 'self-end'}>
        <MUIBox
          borderRadius={self ? '14px 14px 14px 0px' : '14px 14px 0px 14px'}
          width="fit-content"
          px={2}
          py={2}
          sx={{ background: self ? '#063065' : '#2E69B21A' }}
        >
          <Typography color={self ? 'white' : 'black'}>
            {conversation.body}
          </Typography>
        </MUIBox>
        <Typography variant="caption">
          {formatDateTime(conversation.createdAt)}
        </Typography>
      </Stack>
    </MUIBox>
  );
};

export const ConversationBody = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { selectedUserConversations } = useContext(MessagingContext);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [selectedUserConversations]);

  return (
    <Stack
      ref={containerRef}
      px={4}
      py={4}
      spacing={2}
      height='65vh'
      boxShadow={2}
      sx={{
        overflowY: 'scroll',
      }}
    >
      {selectedUserConversations.map((conversation, idx) => (
        <ConversationItem
          key={idx}
          self={conversation.isSelf}
          conversation={conversation}
        />
      ))}
    </Stack>
  );
};
