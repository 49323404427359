import { IHirer, IHirerPreference } from 'common/models';
import { Axios } from 'common/services';

export const createIdentity = async () =>
  await Axios.post('/hirer/create-identity');

export const updateHirerClient = async (hirerId: string) => {
  const clientIdToken = localStorage.getItem('clientIdToken');
  if (clientIdToken) {
    await Axios.put(`/Hirer/${hirerId}/client`, {
      clientId: clientIdToken,
      deviceType: 0,
      hirerId: hirerId,
      id: crypto.randomUUID(),
      isActive: true,
    });
  }
};

export const getCurrentHirer = async (): Promise<IHirer> =>
  await (
    await Axios.get('/Hirer/current-user')
  ).data;

export const updateHirerInfo = async (
  hirerId: string,
  payload: IHirer
): Promise<IHirer> =>
  await (
    await Axios.put(`/Hirer/${hirerId}/info`, payload)
  ).data;

export const updateHirerPreference = async (
  hirerId: string,
  payload: IHirerPreference
): Promise<IHirerPreference> =>
  await (
    await Axios.put(`/Hirer/${hirerId}/preference`, payload)
  ).data;

export const getHirerPreference = async (
  hirerId: string
): Promise<IHirerPreference> =>
  await (
    await Axios.get(`/Hirer/${hirerId}/preference`)
  ).data;
