import { FormikProps, useFormik } from 'formik';
import {
  AvatarUploader,
  Dropdown,
  Form,
  FormControl,
  Input,
  getDDOption,
} from 'design-library-v2';
import { IHirer } from 'common/models';
import { SizeEnum } from 'common/enums';

export function HirerPersonalInfoForm({
  hirer,
  onUpdateHirerPersonalInfo,
}: {
  hirer: IHirer;
  onUpdateHirerPersonalInfo: (updateHirer: IHirer) => Promise<void>;
}) {
  const formik: FormikProps<IHirer> = useFormik<IHirer>({
    initialValues: hirer,
    onSubmit: async (values: IHirer, { setSubmitting }) => {
      try {
        await onUpdateHirerPersonalInfo(values);
      } catch (error) {
        alert('Failed to update!!'); // TO DO use sentry
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Form<IHirer> formik={formik}>
      <FormControl className="personal-info-avatarControl">
        <AvatarUploader
          avatarURL={formik.values.imageSrc}
          onUpload={(imageSrc) => formik.setFieldValue('imageSrc', imageSrc)}
          refId={hirer.id}
        />
      </FormControl>
      <FormControl>
        <Input
          disabled={Boolean(hirer.name)}
          label="Name"
          name="name"
          onChange={formik.handleChange}
          placeholder="i.e. Faizul Islam"
          required
          value={formik.values.name}
        />
      </FormControl>
      <FormControl>
        <Input
          label="Company Name"
          name="companyName"
          onChange={formik.handleChange}
          placeholder="i.e. Exper Hire"
          required
          value={formik.values.companyName}
        />
      </FormControl>
      <FormControl>
        <Input
          disabled={true}
          label="Email"
          name="email"
          onChange={formik.handleChange}
          placeholder="i.e. Exper Hire"
          required
          value={formik.values.email}
        />
      </FormControl>
      <FormControl>
        <Dropdown
          label="Company Size"
          defaultOptions={[
            getDDOption({
              label: SizeEnum[formik.values.companySize || 0],
            }),
          ]}
          options={[0, 1, 2].map((i) => getDDOption({ label: SizeEnum[i] }))}
          onSelect={(options) => {
            options.length &&
              formik.setFieldValue('companySize', options[0].value);
          }}
          required
        />
      </FormControl>
    </Form>
  );
}
