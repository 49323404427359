import { useContext, useState } from 'react';

import { Dropdown, FormControl, IDropdownOptionBase } from 'design-library-v2';
import { AppContext } from 'common/contexts';
import { IExpertJob } from 'common/models';

import { ExpertContext } from 'expert/context';
import { updateExpertJobPreference } from 'expert/services';

interface JopPreferenceDDOption extends IDropdownOptionBase, IExpertJob {}

function JobPreference() {
  const [isLoading, setIsLoading] = useState(false);
  const { jobs } = useContext(AppContext);
  const { currentExpert, updateCurrentExpert } = useContext(ExpertContext);

  const onUpdateJobPreference = async (jobs: Array<JopPreferenceDDOption>) => {
    try {
      setIsLoading(true);
      const updatedExpert = await updateExpertJobPreference(
        currentExpert?.id as string,
        jobs
      );
      updateCurrentExpert(updatedExpert);
    } catch (error) {
      alert('Failed to update');
      console.error(error); // TO DO use sentry
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormControl>
      <Dropdown<JopPreferenceDDOption>
        allowMultiSelect
        disabled={isLoading}
        defaultOptions={currentExpert?.jobs.map((job) => ({
          ...job,
          label: job.name,
          value: job.jobId,
        }))}
        label="What type of job are you interested in?"
        onSelect={onUpdateJobPreference}
        options={jobs.map((job) => ({
          id: crypto.randomUUID(),
          jobId: job.id,
          label: job.name,
          name: job.name,
          userId: currentExpert?.id as string,
          value: job.id,
        }))}
        placeholder="Search here"
      />
    </FormControl>
  );
}

export default JobPreference;
