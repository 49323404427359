import { ComponentProps } from 'react';
import { Box, Rating as MUIRating } from '@mui/material';

import { BASE_BLUE_50 } from 'common/constants/colors';

import { Typography } from '../text';

export function Rating({
  value,
  label = '',
  ...props
}: ComponentProps<typeof MUIRating> & { label?: string }) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
      }}
    >
      <MUIRating
        value={value}
        sx={{ color: BASE_BLUE_50 }}
        precision={0.5}
        size="small"
        {...props}
      />
      <Typography variant="caption">{label}</Typography>
    </Box>
  );
}
