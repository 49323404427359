import { ComponentProps, ReactNode } from 'react';

import { Box } from './Box';

export const PageWrapper = ({
  children,
  flexDirectionColumn = true,
  styles = {},
}: {
  children: ReactNode;
  flexDirectionColumn?: boolean;
  styles?: ComponentProps<typeof Box>['styles'];
}) => {
  return (
    <Box
      flexDirectionColumn={flexDirectionColumn}
      styles={{
        gap: '32px',
        paddingBottom: '16px',
        paddingTop: '16px',
        position: 'relative',
        ...styles,
      }}
    >
      {children}
    </Box>
  );
};
