import React from 'react';

import { appConfig } from 'config';
import { Icon, Text } from 'design-library';
import { AnchorLink } from 'design-library-v2';
import { WHITE } from 'common/constants/colors';

import { 
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  ExpertHireIcon, 
  LocationIcon,
  CellIcon,
  EmailIcon,
} from 'common/icons';

import './Footer.scss';

// const appConfig.APP_URL = 'https://dev.experthire.fr/';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-itemContainer'>
        <div className='footer-item footer-item1'>
          <Icon className='' icon={ExpertHireIcon} />
          <Text className='footer-item-text' text='Powerful Freelance Marketplace System with ability to 
          change the Users (Freelancers & Recruiter)' />
          <div className='footer-item-icons'>
            <AnchorLink
              target='_blank'
              href={appConfig.APP_URL}
            >
              <Icon className='' icon={FacebookIcon} />
            </AnchorLink>
            
            <AnchorLink
              target='_blank'
              href={appConfig.APP_URL}
            >
              <Icon className='footer-item-icons-icon' icon={TwitterIcon} />
            </AnchorLink>

            <AnchorLink
              target='_blank'
              href={appConfig.APP_URL}
            >
              <Icon className='footer-item-icons-icon' icon={InstagramIcon} />
            </AnchorLink>
            
            <AnchorLink
              target='_blank'
              href={appConfig.APP_URL}
            >
              <Icon className='footer-item-icons-icon' icon={LinkedinIcon} />
            </AnchorLink>
          </div>
        </div>

        <div className='footer-item footer-item2'>

          <AnchorLink
            style={{ color: WHITE }}
            href={`${appConfig.APP_URL}/hirer`}
            target='_blank'
          >
            <Text className='footer-item-boldText' text='For Recruiter' />
            <Text className='footer-item-text' text='Find Freelancers' />
            <Text className='footer-item-text' text='Post Job' />
            <Text className='footer-item-text' text='Refund Policy' />
            <Text className='footer-item-text' text='Privacy Policy' />
          </AnchorLink>
        </div>

        <div className='footer-item footer-item3'>
          <AnchorLink
            style={{ color: WHITE }}
            href={`${appConfig.APP_URL}/expert`}
            target='_blank'
          >
            <Text className='footer-item-boldText' text='For Freelancers' />
            <Text className='footer-item-boldText' text='Find Work' />
            <Text className='footer-item-boldText' text='Create Account' />
          </AnchorLink>
        </div>

        <div className='footer-item footer-item4'>
          <Text className='footer-item-boldText' text='Call Us' />
          <div>
            <div className='footer-childItem'>
              <Icon className='test footer-childItem-icon' icon={LocationIcon} />
              <Text className='footer-item-text' text='France' /> 
            </div>
            <div className='footer-childItem'>
              <Icon className='footer-childItem-icon' icon={CellIcon} />
              <Text className='footer-item-text' text='+25470000000' /> 
            </div>
            <div className='footer-childItem'>
              <Icon className='footer-childItem-icon' icon={EmailIcon} />
              <Text className='footer-item-text' text='experthire@gmail.com' /> 
            </div>
            
          </div>
        </div>
      </div>   

      <Text className='footer-lastText' text='2022 ExpertHire. All right reserved' />  
    </div>
  );
};

export default Footer;
