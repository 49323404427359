import { TabList } from 'design-library-v2';

import { ExpertOngoingProjects } from './ExpertOngoingProjects';
import { ExpertBidJobs } from './ExpertBidJobs';
import { ExpertPastProjects } from './ExpertPastProjects';

const tabNames = ['Ongoing Projects', 'Bid On Projects', 'Past Projects'];

export const ExpertJobsAndProjects = ({ expertId }: { expertId: string }) => {
  return (
    <TabList
      defaultTab={tabNames[0]}
      tabs={[
        {
          component: <ExpertOngoingProjects />,
          label: tabNames[0],
        },
        {
          component: <ExpertBidJobs expertId={expertId} />,
          label: tabNames[1],
        },
        {
          component: <ExpertPastProjects />,
          label: tabNames[2],
        },
      ]}
    />
  );
};
