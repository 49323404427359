import React, { RefObject } from 'react';

import Label from '../label/Label';

import './Input.scss';

export default function Input({
  className = '',
  disabled = false,
  hasError = false,
  inputRef,
  inputType = 'input',
  type = 'text',
  label,
  name,
  placeholder,
  required = false,
  value,
  onChange,
  onKeyDown,
}: {
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  inputType?: 'input' | 'text-area';
  type?: 'text' | 'number';
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  value?: string | number;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement| HTMLTextAreaElement>) => void;
}) {
  if (inputType === 'text-area') {
    return (
      <div className="iqcDL-input-container">
        {label && (
          <Label labelText={label} disabled={disabled} required={required} />
        )}
        <textarea
          ref={inputRef as RefObject<HTMLTextAreaElement>}
          className={`iqcDL-input text-area ${className} ${
            hasError ? 'hasError' : ''
          }`}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        ></textarea>
      </div>
    );
  }

  return (
    <div className="iqcDL-input-container">
      {label && (
        <Label labelText={label} disabled={disabled} required={required} />
      )}
      <input
        ref={inputRef as RefObject<HTMLInputElement>}
        className={`iqcDL-input ${className} ${hasError ? 'hasError' : ''}`}
        disabled={disabled}
        type={type}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
