import React from 'react';
import { SigninRequest, User } from 'oidc-client';
import { AuthService } from 'common/services';
import { UserRole } from 'common/types';

export type AuthContextValues = Pick<
  AuthService,
  | 'createSigninRequest'
  | 'getUser'
  | 'getUserLastLogin'
  | 'getUserRole'
  | 'isAuthenticated'
  | 'logout'
  | 'signinRedirect'
  | 'signinRedirectCallback'
  | 'signinSilentCallback'
  | 'signoutRedirectCallback'
>;

export const AuthContext = React.createContext<AuthContextValues>({
  createSigninRequest: () =>
    new Promise<SigninRequest>(() => ({ state: null, url: '' })),
  getUser: () => new Promise<User>(() => ({})),
  getUserLastLogin: () => new Promise<Date>(() => ({})),
  getUserRole: () => new Promise<UserRole>(() => ({})),
  isAuthenticated: () => false,
  logout: () => ({}),
  signinRedirect: () => ({}),
  signinRedirectCallback: () => ({}),
  signinSilentCallback: () => ({}),
  signoutRedirectCallback: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;
