import Select, { MultiValue, SingleValue } from 'react-select';

import Label from '../label/Label';

import './Dropdown.scss';

export interface IDropdownOptionBase {
  id: string;
  label: string;
  value: string;
}

export const getDDOption = ({
  label,
  value,
}: {
  label: string;
  value?: string;
}): IDropdownOptionBase => ({
  id: crypto.randomUUID(),
  label,
  value: value || label,
});

export function Dropdown<T extends IDropdownOptionBase>({
  allowMultiSelect = false,
  className = '',
  defaultOptions = [],
  disabled = false,
  isLoading = false,
  isClearable = true,
  isRtl = false,
  isSearchable = true,
  label,
  onSelect,
  options = [],
  placeholder,
  required = false,
}: {
  allowMultiSelect?: boolean;
  className?: string;
  disabled?: boolean;
  defaultOptions?: Array<T>;
  isLoading?: boolean;
  isClearable?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  label?: string;
  onSelect: (value: Array<T>) => void;
  options: Array<T>;
  placeholder?: string;
  required?: boolean;
}) {
  const handleOnChange = (newValue: SingleValue<T> | MultiValue<T>) =>
    onSelect(Array.isArray(newValue) ? newValue : [newValue]);

  return (
    <div className={`iqcDL-dropdown ${className}`}>
      {label && (
        <Label labelText={label} disabled={disabled} required={required} />
      )}
      <Select
        className="iqcDL-dropdown-select"
        classNamePrefix="iqcDL-dropdown-select"
        defaultValue={defaultOptions}
        getOptionLabel={(option: T) => option.label}
        getOptionValue={(option: T) => option.value}
        isDisabled={disabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isMulti={allowMultiSelect}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={label}
        onChange={handleOnChange}
        options={options}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
}
