export const BASE_BLUE = '#063065';
export const BASE_BLUE_25 = '#cddcfc';
export const BASE_BLUE_50 = '#24355a';
export const BASE_BLUE_75 = '#369FFF';
export const GRAY_1 = '#f8f8f9';
export const GRAY_2 = '#ebebef';
export const GRAY_3 = '#31353B';
export const GRAY_4 = '#2D3748';
export const GRAY_5 = '#404040';
export const GRAY_6 = '#6F6F6F';
export const WHITE = '#ffffff';
