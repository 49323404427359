import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthContext } from 'common/contexts';
import { IHirer, IHirerPreference } from 'common/models';
import { useCurrentHirer } from 'hirer/hooks';
import { HirerProfileSetupServices } from 'hirer/services';

export type HirerContextValues = {
  hirer: IHirer | undefined;
  hirerPreference: IHirerPreference | undefined;
  lastLogin: Date | undefined;
  updateCurrentHirer: ReturnType<typeof useCurrentHirer>['updateCurrentHirer'];
  updateHirerPreference: (hirerPreference?: IHirerPreference) => void;
};

export const HirerContext = createContext<HirerContextValues>({
  hirer: undefined,
  hirerPreference: undefined,
  lastLogin: undefined,
  updateCurrentHirer: () => ({}),
  updateHirerPreference: () => ({}),
});

export const HirerProvider = ({ children }: { children: ReactElement }) => {
  const { getUserLastLogin } = useContext(AuthContext);
  const { currentHirer, updateCurrentHirer } = useCurrentHirer();

  const [lastLogin, setLastLogin] = useState<Date>();
  const [hirerPreference, setHirerPreference] = useState<IHirerPreference>();

  const fetchHirerPreferences = useCallback(
    async (hirerId: string) => {
      try {
        const preference: IHirerPreference =
          await HirerProfileSetupServices.getHirerPreference(hirerId);
        setHirerPreference({
          ...{
            baseOn: 0,
            categories: [],
            city: '',
            country: '',
            hirerId: currentHirer?.id,
            id: crypto.randomUUID(),
            languages: [],
          },
          ...preference,
        });
      } catch (error) {
        console.error(error); // TO DO use sentry
      }
    },
    [currentHirer?.id]
  );

  useEffect(() => {
    if (currentHirer) {
      fetchHirerPreferences(currentHirer.id);
    }
  }, [currentHirer, fetchHirerPreferences]);

  useEffect(() => {
    getUserLastLogin().then((res) => setLastLogin(res));
  }, [getUserLastLogin]);

  const updateHirerPreference = useCallback(
    (hirerPreference?: IHirerPreference) => {
      if (hirerPreference) {
        setHirerPreference(hirerPreference);
        return;
      }
      currentHirer && fetchHirerPreferences(currentHirer?.id);
    },
    [currentHirer, fetchHirerPreferences]
  );

  return (
    <HirerContext.Provider
      value={{
        hirer: currentHirer,
        hirerPreference,
        lastLogin,
        updateCurrentHirer,
        updateHirerPreference,
      }}
    >
      {children}
    </HirerContext.Provider>
  );
};
