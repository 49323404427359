import { useEffect, useMemo, useState } from 'react';
import { getLanguages } from 'common/services';
import { ILanguage } from 'common/models';

export function useLanguages() {
  const [languageData, setLanguageData] = useState<Array<ILanguage>>([]);

  const fetchData = async () => {
    try {
      setLanguageData(await getLanguages());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const languages = useMemo(() => languageData, [languageData]);

  return { languages };
}
