import { useState } from 'react';
import { Text } from 'design-library';


import FaqNav from './faq-nav/FaqNav';
import FAQAnswer from './faq-answers/FaqAnswers';

import './FaqSection.scss';

const FaqSection = () => {

  const [currentQuestionId, setcurrentQuestionId] =
    useState<number>(0);
  
  const onChangecurrentQuestionId = (questionId: number): void => {
    setcurrentQuestionId(questionId);
  };

  return (
    <div className='faqSection'>
      <div className='faqSection-container'>
        <Text className='faqSection-heading' text='Frequently Asked Questions'/>

        <div className='qa'>
          <div className='qa-right'>
            <FAQAnswer questionId={currentQuestionId}/>
          </div>

          <div className='qa-left'>
            <div className='qa-left-item'>
              <FaqNav 
                currentQuestionId={currentQuestionId}
                onChangecurrentQuestionId={onChangecurrentQuestionId}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FaqSection;
