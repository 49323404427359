import { useEffect, useMemo, useState } from 'react';
import { ILocation } from 'common/models';
import { getCities, getLocations } from 'common/services';

export function useLocations() {
  const [locationData, setLocationData] = useState<ILocation[]>([]);

  const fetchData = async () => {
    try {
      setLocationData(await getLocations());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const locations = useMemo(() => locationData, [locationData]);

  const getCitiesByCountry = (country: string): string[] =>
    locations.find((location) => location.name === country)?.cities || [];

  const loadCities = async (country: string) => {
    const locationIndex = locationData.findIndex(
      (location) => location.name === country
    );
    if (locationIndex === -1 || locationData[locationIndex].cities?.length) {
      return;
    }
    locationData[locationIndex].cities = await getCities(country);
    setLocationData([...locationData]);
  };

  return { getCitiesByCountry, loadCities, locations };
}
