import { useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import {
  Box,
  Button,
  FilePreviewList,
  FileUploader,
  Form,
  Input,
  RatingInput,
  RawFilePreviewList,
  Typography,
} from 'design-library-v2';

import { ProjectStatusEnum } from 'common/enums';
import { IExpertReview, IFile, IProject, RawFile } from 'common/models';
import { rejectProject, validateProject } from 'services';

type RatingAndCommentFormData = Pick<IExpertReview, 'comment' | 'ratings'>;

const RatingAndCommentForm = ({
  projectId,
  onCancel,
}: {
  projectId: string;
  onCancel: () => void;
}) => {
  const RatingLabels = ['Communication', 'Work Quality', 'Punctuality'];

  const mockId = crypto.randomUUID();

  const formik: FormikProps<RatingAndCommentFormData> =
    useFormik<RatingAndCommentFormData>({
      initialValues: {
        comment: '',
        ratings: RatingLabels.map((_, i) => {
          return {
            id: mockId,
            rate: 2,
            reviewId: mockId,
            type: i,
          };
        }),
      },
      onSubmit: (values: RatingAndCommentFormData, { setSubmitting }) => {
        validateProject(projectId, values)
          .then(() => {
            window.location.replace(
              '/hirer/manage-your-projects?activeTab=past-projects'
            );
          })
          .catch((err) => {
            console.error(err); // TO DO use sentry
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  return (
    <Box flexDirectionColumn>
      <Form<RatingAndCommentFormData>
        formik={formik}
        submitBtnLabel="Submit"
        hasSecondaryBtn
        secondaryBtnLabel="Cancel"
        onClickSecondaryBtn={onCancel}
      >
        <Typography variant="h1">Rating and Comment</Typography>
        <Input
          label="Comment"
          inputType="text-area"
          name="comment"
          onChange={formik.handleChange}
        />
        <Box styles={{ justifyContent: 'space-between' }}>
          {/** TO DO use RatingTypeEnum for labels  */}
          {formik.values.ratings.map((rating) => (
            <RatingInput
              key={crypto.randomUUID()}
              label={RatingLabels[rating.type]}
              rating={rating.rate}
              onChangeRating={(newRating) => {
                const updatedRatings = [...formik.values.ratings];
                updatedRatings[rating.type].rate = newRating;
                formik.setFieldValue('ratings', updatedRatings);
              }}
            />
          ))}
        </Box>
      </Form>
    </Box>
  );
};

type ProjectRejectFormData = {
  attachments: File[];
  message: string;
};

const ProjectRejectForm = ({
  projectId,
  onCancel,
}: {
  projectId: string;
  onCancel: () => void;
}) => {
  const [attachments, setAttachments] = useState<RawFile[]>([]);

  const formik: FormikProps<ProjectRejectFormData> =
    useFormik<ProjectRejectFormData>({
      initialValues: {
        attachments: [],
        message: '',
      },
      onSubmit: (values: ProjectRejectFormData, { setSubmitting }) => {
        rejectProject(projectId)
          .then(() => {
            window.location.replace(
              '/hirer/manage-your-projects?activeTab=past-projects'
            );
          })
          .catch((err) => {
            console.error(err); // TO DO use sentry
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  return (
    <Box flexDirectionColumn>
      <Form<ProjectRejectFormData>
        formik={formik}
        submitBtnLabel="Submit"
        hasSecondaryBtn
        secondaryBtnLabel="Cancel"
        onClickSecondaryBtn={onCancel}
      >
        <Typography variant="h1">Message To Admin</Typography>
        <Input inputType="text-area" />
        <FileUploader
          multiple
          onUpload={(files) =>
            setAttachments((prevFiles) => [
              ...prevFiles,
              ...files.map((file) => ({
                file,
                id: crypto.randomUUID(),
              })),
            ])
          }
        />
        <RawFilePreviewList
          files={attachments}
          onDeleteFile={(id) =>
            setAttachments(attachments.filter((a) => a.id !== id))
          }
        />
      </Form>
    </Box>
  );
};

export const ProjectSubmissionDetails = ({
  project,
  projectFiles,
}: {
  project: IProject;
  projectFiles: IFile[];
}) => {
  const [formState, setFormState] = useState<
    'submissionDetails' | 'accept' | 'reject'
  >('submissionDetails');

  return (
    <Box
      flexDirectionColumn
      styles={{ justifyContent: 'space-between', padding: '16px 16px' }}
    >
      {formState === 'accept' ? (
        <RatingAndCommentForm
          projectId={project.id}
          onCancel={() => setFormState('submissionDetails')}
        />
      ) : formState === 'reject' ? (
        <ProjectRejectForm
          projectId={project.id}
          onCancel={() => setFormState('submissionDetails')}
        />
      ) : (
        <>
          <Box flexDirectionColumn>
            <Typography variant="h1">Project Submission Details</Typography>
            <Typography variant="subtitle1">Message From Expert</Typography>
            <Typography>{project.message}</Typography>
            <Typography variant="subtitle1">Files</Typography>
            <FilePreviewList files={projectFiles} isReadOnly />
          </Box>
          {project.status === ProjectStatusEnum.Submitted && (
            <Box flexDirectionColumn>
              <Box
                styles={{
                  justifyContent: 'flex-end',
                  padding: '8px 8px ',
                }}
              >
                <Button onClick={() => setFormState('accept')}>Validate</Button>
                <Button
                  onClick={() => setFormState('reject')}
                  buttonType="secondary"
                >
                  Unhappy
                </Button>
              </Box>
              <Box
                styles={{
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                <span>
                  {/* TO DO Move to icons*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 16C12.2267 16 12.4168 15.9232 12.5704 15.7696C12.7235 15.6165 12.8 15.4267 12.8 15.2V11.98C12.8 11.7533 12.7235 11.5667 12.5704 11.42C12.4168 11.2733 12.2267 11.2 12 11.2C11.7733 11.2 11.5835 11.2765 11.4304 11.4296C11.2768 11.5832 11.2 11.7733 11.2 12V15.22C11.2 15.4467 11.2768 15.6333 11.4304 15.78C11.5835 15.9267 11.7733 16 12 16ZM12 9.6C12.2267 9.6 12.4168 9.5232 12.5704 9.3696C12.7235 9.21653 12.8 9.02667 12.8 8.8C12.8 8.57333 12.7235 8.3832 12.5704 8.2296C12.4168 8.07653 12.2267 8 12 8C11.7733 8 11.5835 8.07653 11.4304 8.2296C11.2768 8.3832 11.2 8.57333 11.2 8.8C11.2 9.02667 11.2768 9.21653 11.4304 9.3696C11.5835 9.5232 11.7733 9.6 12 9.6ZM12 20C10.8933 20 9.85333 19.7899 8.88 19.3696C7.90667 18.9499 7.06 18.38 6.34 17.66C5.62 16.94 5.05013 16.0933 4.6304 15.12C4.21013 14.1467 4 13.1067 4 12C4 10.8933 4.21013 9.85333 4.6304 8.88C5.05013 7.90667 5.62 7.06 6.34 6.34C7.06 5.62 7.90667 5.04987 8.88 4.6296C9.85333 4.20987 10.8933 4 12 4C13.1067 4 14.1467 4.20987 15.12 4.6296C16.0933 5.04987 16.94 5.62 17.66 6.34C18.38 7.06 18.9499 7.90667 19.3696 8.88C19.7899 9.85333 20 10.8933 20 12C20 13.1067 19.7899 14.1467 19.3696 15.12C18.9499 16.0933 18.38 16.94 17.66 17.66C16.94 18.38 16.0933 18.9499 15.12 19.3696C14.1467 19.7899 13.1067 20 12 20ZM12 18.4C13.7733 18.4 15.2835 17.7768 16.5304 16.5304C17.7768 15.2835 18.4 13.7733 18.4 12C18.4 10.2267 17.7768 8.71653 16.5304 7.4696C15.2835 6.2232 13.7733 5.6 12 5.6C10.2267 5.6 8.7168 6.2232 7.4704 7.4696C6.22347 8.71653 5.6 10.2267 5.6 12C5.6 13.7733 6.22347 15.2835 7.4704 16.5304C8.7168 17.7768 10.2267 18.4 12 18.4Z"
                      fill="#9B9B9B"
                    />
                  </svg>
                </span>
                <Typography variant="caption">
                  It will automatically validate in 48H
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
