import moment from 'moment';
import { DatePicker } from './DatePicker';

export default function DatePickerStory() {
  const today = new Date();

  return (
    <>
      <div>
        <DatePicker label="Date picker" onChange={() => ({})} />
        <DatePicker
          label="Date picker with default value"
          onChange={() => ({})}
          value="2023-04-02"
        />
      </div>
      <div>
        <DatePicker
          label="Date picker disabled"
          disabled
          onChange={() => ({})}
        />
        <DatePicker
          disabled
          label="Date picker with default value disabled"
          onChange={() => ({})}
          value="2023-04-02"
        />
      </div>
      <div>
        <DatePicker
          label="Date picker with dare range"
          onChange={() => ({})}
          max={moment(moment(today).add('days', 8)).format('YYYY-MM-DD')}
          min={moment(moment(today).add('days', 5)).format('YYYY-MM-DD')}
        />
      </div>
    </>
  );
}
