import moment from 'moment';
import { FormikProps } from 'formik';

import {
  CheckboxSelector,
  DatePicker,
  Dropdown,
  FormControl,
  Input,
  Label,
  getDDOption,
} from 'design-library-v2';
import { IJobPost } from 'common/models';
import { getCurrencyFormat } from 'common/utils';

export const ConditionsSection = ({
  formik,
}: {
  formik: FormikProps<IJobPost>;
}) => {
  return (
    <>
      <Label
        labelText="What type of condition do you want to give?"
        size="large"
      />
      <div className="jobPostForm-dateRange-container">
        <FormControl
          className="jobPostForm-dateRange-picker"
          label="What type of condition do you want to give?"
        >
          <DatePicker
            name="startTime"
            max={formik.values.endTime}
            onChange={formik.handleChange}
            required
            value={formik.values.startTime}
          />
          <DatePicker
            min={formik.values.startTime}
            name="endTime"
            onChange={formik.handleChange}
            required
            value={formik.values.endTime}
          />
        </FormControl>
        <FormControl label="Total days">
          <Input
            className="jobPostForm-dateRange-dayCount"
            disabled
            value={moment(formik.values.endTime)
              .diff(moment(formik.values.startTime), 'days')
              .toString()}
          />
        </FormControl>
      </div>
      <FormControl>
        <Dropdown
          defaultOptions={[formik.values.hoursPerDay].map((hour) =>
            getDDOption({ label: hour.toString() })
          )}
          label="Hours per day"
          onSelect={(options) =>
            options.length &&
            formik.setFieldValue('hoursPerDay', options[0].value)
          }
          options={Array.from(Array(10).keys()).map((hour) =>
            getDDOption({ label: (hour + 1).toString() })
          )}
        />
        <Dropdown
          defaultOptions={[formik.values.hourlyRate].map((option) =>
            getDDOption({
              label: `${getCurrencyFormat(option)}`,
              value: option.toString(),
            })
          )}
          label={'Hourly Rate'}
          onSelect={(options) =>
            options.length &&
            formik.setFieldValue('hourlyRate', options[0].value)
          }
          options={Array.from(Array(150).keys()).map((option) =>
            getDDOption({
              label: `${getCurrencyFormat(option + 5)}`,
              value: (option + 5).toString(),
            })
          )}
        />
      </FormControl>
      <FormControl>
        <CheckboxSelector
          label="Do you want to show budget of the project to the bidder?"
          defaultOptions={[formik.values.showBudget ? 0 : 1]}
          options={['Yes', 'No']}
          onSelectionChanged={(options) =>
            formik.setFieldValue('showBudget', Boolean(options[0]))
          }
          singleSelect
          type="radio"
        />
      </FormControl>
    </>
  );
};
