import { Box, Button, Checkbox, Paper, Typography } from 'design-library-v2';

import { getCurrencyFormat } from 'common/utils';
import { useTokenPurchase } from 'token/hooks';

export const ProPlanDetails = () => {
  const { isPurchasing, updateSubscription } = useTokenPurchase();

  const renderPlansCard = (plans: string[], forPro = false) => {
    return (
      <Paper flexDirectionColumn>
        <Box styles={{ justifyContent: 'space-between' }}>
          <Typography variant="subtitle2">{`${
            forPro ? 'Pro' : 'Free'
          }`}</Typography>
          {forPro && (
            <Typography variant="subtitle2">{`${getCurrencyFormat(
              5
            )}/month`}</Typography>
          )}
        </Box>
        <Box flexDirectionColumn styles={{ gap: '4px' }}>
          {plans.map((plan) => (
            <Box key={crypto.randomUUID()} styles={{ alignItems: 'center' }}>
              <Checkbox checked />
              <Typography>{plan}</Typography>
            </Box>
          ))}
        </Box>
        {forPro && (
          <Box>
            <Button
              disabled={isPurchasing}
              onClick={() => updateSubscription()}
            >
              Select
            </Button>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <Box
      flexDirectionColumn
      styles={{ height: '100%', justifyContent: 'space-around' }}
    >
      <Box flexDirectionColumn styles={{ alignItems: 'center' }}>
        <Typography variant="h4">Want to Be Pro freelancer</Typography>
        <Typography variant="body1">
          Choose the perfect package for you
        </Typography>
      </Box>
      <Box styles={{ justifyContent: 'space-around' }}>
        {renderPlansCard([
          'Earn bidding tokens with every login.',
          'Receive payment within 48 hours of job completion.',
          'Faster communication with the hirer.',
          'Effortlessly place bids on jobs that fit your preferences.',
          'Payment within a week of job completion.',
          'Filter any type of job on the bulletin board.',
          'Earn badges by receiving ratings from hirers.',
          'An organized projects history board.',
          'Organized profile for sharing experiences.',
        ])}
        {renderPlansCard(
          [
            'Unlimited tokens for applying to jobs',
            'Be at the top of the expert search results.',
            'Receive payment within 24 hours of job completion.',
            'Get notified of preferred job postings.',
            'Earn badges by receiving ratings from hirers.',
            'Faster communication with hirers.',
            'Filter any type of job on the bulletin board.',
            'An organized projects history board.',
            'Organized profile for sharing experiences.',
            'Verified tag & Pro expert tag on profile.',
          ],
          true
        )}
      </Box>
    </Box>
  );
};
