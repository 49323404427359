export enum JobTypeEnum {
  PartTime,
  FullTime,
}

export enum JobNatureEnum {
  Remote,
  Onsite,
}

export enum JobActiveEnum {
  EveryDay,
  MondayToFriday,
  SundayToThursday,
}

export enum SizeEnum {
  Small,
  Medium,
  Large,
}

export enum SubscriptionTypeEnum {
  Free,
  Pro,
}

export enum UserTypeEnum {
  Expert,
  Hirer,
}

export enum LanguageProficiencyEnum {
  Basic,
  Medium,
  Professional,
  Native,
}

export enum DeviceTypeEnum {
  Web,
  Android,
  IOS,
}

export enum ProjectStatusEnum {
  Ongoing = 0,
  Submitted = 1,
  Accepted = 2,
  Rejected = 3,
}

export enum JobPostStatusEnum {
  Posted,
  BidOnGoing,
  ProjectOnGoing,
  Complete,
  Cancel,
}

export enum BidStatusEnum {
  Draft = 0,
  Pending = 1,
  Withdraw = 2,
  Accepted = 3,
  Rejected = 4,
}

export const getEnumKeys = (e: object) => {
  const keys = Object.keys(e);
  return keys.slice(keys.length / 2);
};

export enum UserRoleEnum {
  EXPERT = 'expert',
  HIRER = 'hirer',
}

export enum UsageTireEnum {
  Free = 1,
  Pro = 2,
  ProPlus = 3,
  PayAsYouGo = 4,
}

export enum FileTypeEnum {
  Avatar = 0,
  Cv = 1,
  SupportingDocument = 2,
  ExpertProjectFile = 3,
  ExpertTrainingFile = 4,
  ExpertEducationFile = 5,
  JobProjectFile = 6,
  PostJobFile = 7,
}

export enum RatingTypeEnum {
  Communication,
  WorkQuality,
  Punctuality,
}

export enum ProjectPaymentStatusEnum {
  Pending,
  Completed,
  Declined,
}

export enum InvoiceStatusEnum {
  Unpaid = 1,
  Paid = 2,
  Declined = 3,
}
