import {
  Avatar,
  Box,
  Chip,
  Paper,
  Rating,
  Typography,
} from 'design-library-v2';

import { IExpertReview, IPageResult } from 'common/models';
import { getDateFormat, getRatingPrecision } from 'common/utils';
import { ResultPagePaginationFooter } from 'common/components';
import { usePagination } from 'common/hooks';

type UsePaginationTypes = ReturnType<typeof usePagination>;

const RecruiterReviews = ({
  reviews,
  paginationModel,
  setPaginationModel = () => ({}),
}: {
  reviews: IPageResult<IExpertReview>;
  paginationModel: UsePaginationTypes['paginationModel'];
  setPaginationModel: UsePaginationTypes['setPaginationModel'];
}) => {
  return (
    <Box flexDirectionColumn>
      {reviews.data.map((review) => (
        <Paper key={crypto.randomUUID()} flexDirectionColumn>
          <Box styles={{ justifyContent: 'space-between' }}>
            <Box>
              <Avatar imageUrl={review.hirerImage} size="medium" />
              <Box flexDirectionColumn styles={{ gap: '8px' }}>
                <Typography variant="subtitle2">{review.hirerName}</Typography>
                <Typography variant="caption">{review.hirerOrgName}</Typography>
                <Rating
                  value={review.ratingAvg}
                  label={getRatingPrecision(review.ratingAvg)}
                />
              </Box>
            </Box>
            <Box styles={{ justifyContent: 'flex-end' }}>
              <Typography variant="subtitle2">
                {' '}
                {getDateFormat(review.createdAt)}
              </Typography>
            </Box>
          </Box>

          <Box styles={{ gap: '24px' }}>
            {['Communication', 'Work Quality', 'Punctuality'].map((l, i) => (
              <Box
                key={crypto.randomUUID()}
                flexDirectionColumn
                styles={{
                  gap: '4px',
                }}
              >
                <Chip label={l} />
                <Box>
                  <Rating
                    value={review.ratings[i].rate}
                    label={`${review.ratings[i].rate}(5)`}
                  />
                </Box>
              </Box>
            ))}
          </Box>

          {review.comment && (
            <Box flexDirectionColumn styles={{ gap: '8px' }}>
              <Typography variant="subtitle1">Comment</Typography>
              <Typography>{review.comment}</Typography>
            </Box>
          )}
        </Paper>
      ))}

      <ResultPagePaginationFooter
        count={reviews.data.length}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        total={reviews.total}
      />
    </Box>
  );
};

export default RecruiterReviews;
