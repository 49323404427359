import { useContext } from 'react';

import { Box, PageWrapper, Spinner, TabList } from 'design-library-v2';

import { HirerContext } from 'hirer/context';
import { CarouselContent } from 'common/components/carousel-content';
import { CurrentProjects } from 'hirer/components/dashboard/current-projects/CurrentProjects';
import { FinishedProjects } from 'hirer/components/dashboard/finished-projects/FinishedProjects';
import { PostedJobs } from 'hirer/components/dashboard/posted-jobs/PostedJobs';
import { HirerProfile, HirerProjectsStatistics } from 'hirer/components';

const tabNames = ['current-projects', 'posted-jobs', 'finished-projects'];

export const HirerDashboard = () => {
  const { hirer } = useContext(HirerContext);

  if (!hirer) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper flexDirectionColumn={false}>
      <Box styles={{ position: 'relative', width: '32%' }}>
        <HirerProfile />
      </Box>
      <Box flexDirectionColumn styles={{ position: 'relative' }}>
        <CarouselContent />
        <HirerProjectsStatistics />
        <TabList
          defaultTab={tabNames[0]}
          tabs={[
            {
              component: <CurrentProjects />,
              label: tabNames[0],
            },
            {
              component: <PostedJobs hirerId={hirer.id} />,
              label: tabNames[1],
            },
            {
              component: <FinishedProjects />,
              label: tabNames[2],
            },
          ]}
        />
      </Box>
    </PageWrapper>
  );
};
