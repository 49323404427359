import { useContext } from 'react';

import { Box, PageWrapper, Spinner } from 'design-library-v2';

import { ExpertContext } from 'expert/context';
import { useExpertTokens, useJobPostSearch } from 'expert/hooks';
import { CarouselContent } from 'common/components/carousel-content';

import { JobsRecommended } from 'expert/jobs-recommended/components';
import {
  ExpertJobsAndProjects,
  LeaderBoard,
  TokenBalance,
} from 'expert/dashboard/components';

export const ExpertDashboardPage = () => {
  const { currentExpert } = useContext(ExpertContext);
  const { expertTokens, hasExpertSubscription } = useExpertTokens();
  const { jobResults } = useJobPostSearch({
    jobNature: currentExpert?.userPreference?.jobNature,
    jobType: currentExpert?.userPreference?.jobType,
    skills: currentExpert?.skills.map((skill) => skill.skillId).join(','),
  });

  if (!currentExpert) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper>
      <CarouselContent />
      <Box>
        <LeaderBoard />
        <TokenBalance
          expertTokens={expertTokens}
          hasSubscription={hasExpertSubscription()}
        />
      </Box>
      <ExpertJobsAndProjects expertId={currentExpert.id} />
      <JobsRecommended expertId={currentExpert.id} jobResults={jobResults} />
    </PageWrapper>
  );
};
