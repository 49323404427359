import { useState } from 'react';

import {
  Box,
  Button,
  Modal,
  Paper,
  Spinner,
  Typography,
} from 'design-library-v2';
import { Icon } from 'design-library';

import { TokenIcon } from 'common/icons';
import { IExpertTokens } from 'common/models';

import { BuyTokenForm } from 'token/components';

export const TokenBalance = ({
  expertTokens,
  hasSubscription,
}: {
  expertTokens?: IExpertTokens;
  hasSubscription?: boolean;
}) => {
  const [showBuyTokenModal, setShowBuyTokenModal] = useState(false);

  if (!expertTokens) {
    return <Spinner type="contained" />;
  }

  return (
    <Box flexDirectionColumn styles={{ position: 'relative', width: '450px' }}>
      {!expertTokens ? (
        <Spinner type="contained" />
      ) : (
        <>
          <Box flexDirectionColumn>
            <Typography variant="h4">Token balance</Typography>
            <Paper styles={{ alignItems: 'center', height: '240px' }}>
              <Icon className="tokenBalance-item-icon" icon={TokenIcon} />
              <Box
                flexDirectionColumn
                styles={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <Typography variant="h4">
                  {hasSubscription
                    ? 'Enjoy Unlimited Tokens'
                    : expertTokens.totalToken.toString()}
                </Typography>
                {!hasSubscription && (
                  <Button onClick={() => setShowBuyTokenModal(true)}>
                    Buy Token
                  </Button>
                )}
              </Box>
            </Paper>
          </Box>
          {showBuyTokenModal && (
            <Modal
              isShown
              onCancel={() => setShowBuyTokenModal(false)}
              size="medium"
            >
              <BuyTokenForm />
            </Modal>
          )}
        </>
      )}
    </Box>
  );
};
