import { useState } from 'react';
import { Button as MUIButton } from '@mui/material';

import { InvoiceStatusEnum } from 'common/enums';
import { IInvoice } from 'common/models';

import { InvoiceServices } from 'services';

export const InvoiceDownloadButton = ({ invoice }: { invoice: IInvoice }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const status = invoice.hirerPaymentStatus || invoice.expertPaymentStatus || 0;
  return (
    <MUIButton
      disabled={status !== InvoiceStatusEnum.Paid || isDownloading}
      onClick={() => {
        setIsDownloading(true);
        InvoiceServices.downloadInvoice(
          invoice.id,
          invoice.projectName
        ).finally(() => setTimeout(() => setIsDownloading(false), 5000));
      }}
      variant="text"
      size="small"
    >
      Download
    </MUIButton>
  );
};
