import React from 'react';
import { Carousel } from 'design-library';
import HirerWorks from './HowWorks';
import { HirerWorksIcon, ExpertWorksIcon } from 'common/icons';

import './WorksCarousel.scss';

export default function WorksCarousel() {
  return (
    <div className='worksCarouselContainer'>
      <Carousel
        carouselChildren={[
          <HirerWorks 
            backgroundImage={HirerWorksIcon}
            title='How Hirer Works' 
            key='1' 
          />,
          <HirerWorks 
            backgroundImage={ExpertWorksIcon}
            title='How Expert Works' 
            key='2' 
          />,

        ]}
      />
    </div>
  );
}
