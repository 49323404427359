import { Box, Stepper as MUIStepper, Step, StepLabel } from '@mui/material';

export const Stepper = ({
  activeStep,
  steps,
}: {
  activeStep: number;
  steps: string[];
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <MUIStepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </MUIStepper>
    </Box>
  );
};
