export const appConfig = {
  APP_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://dev.experthire.fr',
  BASE_API_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080'
      : 'https://dev.api.experthire.fr',
  BASE_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/api/v1'
      : 'https://dev.api.experthire.fr/api/v1',
  USER_PROFILE_IMAGE_KEY: 'user_profile_image_url',
  USER_SESSION_TOKEN_KEY: 'iqc_user_token',
  authConfig: {
    currentLocationKey: 'redirectUri',
    idTokenHintKey: 'id_token',
    webAuthResponseType: 'code',
  },
};
