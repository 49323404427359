import { Toaster } from 'react-hot-toast';
import { BASE_BLUE, WHITE } from 'common/constants/colors';

export const CustomToaster = () => {
  return (
    <Toaster
      toastOptions={{
        success: {
          style: {
            background: WHITE,
            color: BASE_BLUE,
          },
        },
      }}
    />
  );
};

