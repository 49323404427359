import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Chip,
  Paper,
  ProgressBar,
  Rating,
  TextBadge,
  Typography,
} from 'design-library-v2';
import { Icon } from 'design-library'; // TO DO Remove
import { Button as MUIButton } from '@mui/material';

import {
  BadgeDollar,
  EditorIcon,
  GithubIcon,
  LinkedinIcon,
  TwitterIcon,
  VerifyIcon,
  WebsiteIcon,
} from 'common/icons';
import { IExpert, IExpertInsight } from 'common/models';
import { LanguageProficiencyEnum, getEnumKeys } from 'common/enums';
import { getCurrencyFormat, getRatingPrecision } from 'common/utils';
import { CarouselContent } from 'common/components';

import { useExpertInsight, useExpertReviewCount } from 'expert/hooks';
import { getExpertProfileCompletionPercentage } from 'expert/services';

import { ExpertProfileTabs } from './ExpertProfileTabs';

const ExpertProfileTopSection = ({ expert }: { expert: IExpert }) => {
  const { expertReviewCount } = useExpertReviewCount(expert.id);

  const getAddress = () => {
    const { addresses } = expert;
    if (addresses.length) {
      return `${addresses[0].city}, ${addresses[0].country}`;
    }
    return '';
  };

  return (
    <Box flexDirectionColumn>
      <Box styles={{ gap: '8px' }}>
        <Box styles={{ justifyContent: 'center' }}>
          <Avatar imageUrl={expert.imageSrc} />
        </Box>
        <Box flexDirectionColumn styles={{ gap: '8px' }}>
          <Typography variant="subtitle1">{expert.name}</Typography>
          <Typography>{expert.displayName}</Typography>
          <Typography variant="caption">{getAddress()}</Typography>
          <Box>
            <Rating
              value={expertReviewCount.rate}
              label={`${parseFloat(
                getRatingPrecision(expertReviewCount.rate)
              )} (${expertReviewCount.total})`}
              precision={0.1}
            />
          </Box>
          {expert.verified && (
            <Box styles={{ alignItems: 'center', gap: '8px' }}>
              <Icon icon={VerifyIcon} className="verify-icon" />
              <Typography variant="h6">Verified</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ProfileCompletionStatus = ({ expert }: { expert: IExpert }) => {
  const [profileCompletionParentage, setProfileCompletionParentage] =
    useState(0);

  useEffect(() => {
    getExpertProfileCompletionPercentage(expert.id).then((res) =>
      setProfileCompletionParentage(res)
    );
  }, [expert.id]);

  const getStrengthLabel = useCallback(() => {
    return profileCompletionParentage < 30
      ? 'Incomplete'
      : profileCompletionParentage < 80
        ? 'Intermediate'
        : 'Strong';
  }, [profileCompletionParentage]);

  return (
    <Box flexDirectionColumn styles={{ gap: '8px' }}>
      <Box styles={{ justifyContent: 'center' }}>
        <Typography>
          <strong>Profile Strength:</strong>
          {` ${getStrengthLabel()}`}
        </Typography>
      </Box>
      <ProgressBar progress={profileCompletionParentage} />
    </Box>
  );
};

const ProfileBadges = ({ expert }: { expert: IExpert }) => {
  return (
    <Box styles={{ gap: '8px', justifyContent: 'center' }}>
      <Paper
        flexDirectionColumn
        styles={{
          alignItems: 'center',
          height: '80px',
          justifyContent: 'center',
          padding: '8px 16px',
          width: '110px',
        }}
      >
        <Icon icon={BadgeDollar} />
        <TextBadge
          primaryText="Rate"
          secondaryText={`${
            getCurrencyFormat(expert.userPreference?.perHourRate || 0) ?? '-'
          }/hr`}
        />
      </Paper>
    </Box>
  );
};

const PersonDescription = ({ expert }: { expert: IExpert }) => {
  const bioCharLimit = 190;
  const isLongBio = expert.bio?.length > bioCharLimit;
  const [shouldShowReadMore, setShouldShowReadMore] = useState(false);

  const onToggleBtn = () => {
    setShouldShowReadMore((prevState) => !prevState);
  };

  return (
    <Box flexDirectionColumn styles={{ gap: '4px' }}>
      <Typography>
        {isLongBio && !shouldShowReadMore
          ? `${expert.bio.substring(0, bioCharLimit)}...`
          : expert.bio}
      </Typography>
      {isLongBio && (
        <Box>
          <MUIButton
            variant="outlined"
            size="small"
            onClick={onToggleBtn}
            sx={{ borderRadius: '20px', fontSize: '8px' }}
          >
            {!shouldShowReadMore ? 'Read More' : 'Read Less'}
          </MUIButton>
        </Box>
      )}
    </Box>
  );
};

const ExpertLanguages = ({
  languages = [],
}: {
  languages: IExpert['userLanguages'];
}) => (
  <Box style={{ flexWrap: 'wrap', gap: '8px' }}>
    {languages.map((language) => (
      <Paper
        key={language.id}
        styles={{ padding: '8px 16px', width: 'fit-content' }}
      >
        <TextBadge
          primaryText={language.name}
          secondaryText={
            getEnumKeys(LanguageProficiencyEnum)[language.proficiency]
          }
        />
      </Paper>
    ))}
  </Box>
);

const ExpertInsights = ({
  expertInsight,
}: {
  expertInsight: IExpertInsight;
}) => {
  return (
    <Box flexDirectionColumn>
      <Typography variant="subtitle1">Insights</Typography>
      <Box flexDirectionColumn styles={{ gap: '4px' }}>
        <Box styles={{ justifyContent: 'space-between' }}>
          <Typography variant="body2">Project Worked On</Typography>
          <Typography variant="body2">{expertInsight.projectCount}</Typography>
        </Box>
        <Box styles={{ justifyContent: 'space-between' }}>
          <Typography variant="body2">Recruiter Worked With</Typography>
          <Typography variant="body2">{expertInsight.hirerCount}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ExpertSkills = ({ skills }: { skills: IExpert['skills'] }) => {
  return (
    <Box flexDirectionColumn style={{ gap: '8px' }}>
      <Typography variant="subtitle1">Skills</Typography>
      <Box styles={{ flexWrap: 'wrap', gap: '4px' }}>
        {(skills || []).map((item) => {
          return <Chip key={item.id} label={item.name} />;
        })}
      </Box>
    </Box>
  );
};

const ExpertSocialProfileLinks = ({
  socialProfile,
}: {
  socialProfile: IExpert['socialProfile'];
}) => {
  if (!socialProfile) {
    return null;
  }

  return (
    <Box style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="subtitle1">Social Media Connected</Typography>
      </Box>
      <Box
        style={{ gap: '8px', justifyContent: 'flex-end', width: 'fit-content' }}
      >
        {socialProfile?.website && (
          <a target="_blank" href={socialProfile?.website} rel="noreferrer">
            <Icon className="social-media-icons-item" icon={WebsiteIcon} />
          </a>
        )}
        {socialProfile?.github && (
          <a target="_blank" href={socialProfile?.github} rel="noreferrer">
            <Icon className="social-media-icons-item" icon={GithubIcon} />
          </a>
        )}
        {socialProfile?.linkedin && (
          <a target="_blank" href={socialProfile?.linkedin} rel="noreferrer">
            <Icon className="social-media-icons-item" icon={LinkedinIcon} />
          </a>
        )}
        {socialProfile?.twitter && (
          <a target="_blank" href={socialProfile?.twitter} rel="noreferrer">
            <Icon className="social-media-icons-item" icon={TwitterIcon} />
          </a>
        )}
      </Box>
    </Box>
  );
};

export const ExpertProfileLeftPanel = ({
  expert,
  readOnly = false,
}: {
  expert: IExpert;
  readOnly?: boolean;
}) => {
  const navigate = useNavigate();
  const { expertInsight } = useExpertInsight(expert.id);

  return (
    <Box
      flexDirectionColumn
      styles={{
        position: 'relative',
      }}
    >
      <ExpertProfileTopSection expert={expert} />
      <ProfileCompletionStatus expert={expert} />
      <ProfileBadges expert={expert} />
      <PersonDescription expert={expert} />
      <ExpertLanguages languages={expert.userLanguages} />
      <ExpertInsights expertInsight={expertInsight} />
      <ExpertSkills skills={expert.skills} />
      <ExpertSocialProfileLinks socialProfile={expert.socialProfile} />
      {!readOnly && (
        <Button onClick={() => navigate('/expert/profile-setup')}>
          Edit Profile
          <Icon icon={EditorIcon} />
        </Button>
      )}
    </Box>
  );
};

export const ExpertProfile = ({
  expert,
  readOnly = false,
}: {
  expert: IExpert;
  readOnly?: boolean;
}) => (
  <Box>
    <Box
      styles={{
        boxSizing: 'border-box',
        height: '100%',
        position: 'relative',
        width: '36%',
      }}
    >
      <ExpertProfileLeftPanel expert={expert} readOnly={readOnly} />
    </Box>

    <Box flexDirectionColumn styles={{ position: 'relative' }}>
      <CarouselContent />
      <ExpertProfileTabs expertId={expert.id} readOnly={readOnly} />
    </Box>
  </Box>
);
