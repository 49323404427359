import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Chip,
  ConfirmationModal,
  Divider,
  Modal,
  Paper,
  Typography,
} from 'design-library-v2';

import { JobTypeEnum } from 'common/enums';
import { IJobPost } from 'common/models';
import { getCurrencyFormat } from 'common/utils';

import { calculateJobPostBudget } from 'hirer/services/JobPostingServices';
import { PostedJobDetailsCard } from 'job-post/components';

import { JobPostBiddingForm } from '.';
import { useFullTimeJobApplication } from '../hooks';

export const JobPostCard = ({
  expertId,
  jobPost,
}: {
  expertId: string;
  jobPost: IJobPost;
}) => {
  const navigate = useNavigate();

  const [showJobModal, setShowJobModal] = useState(false);
  const [showBiddingModal, setShowBiddingModal] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);

  const { isApplying, onApplyFullTimeJob } = useFullTimeJobApplication();

  const isPartTimeJobPost = jobPost.jobType === JobTypeEnum.PartTime;

  const onClickApplyOrBid = () => {
    if (isPartTimeJobPost) {
      setShowBiddingModal(true);
    } else {
      setShowApplyModal(true);
    }
  };

  const showBudget = jobPost.showBudget && isPartTimeJobPost;

  return (
    <Box style={{ gap: '8px', width: '456px' }}>
      <Paper flexDirectionColumn styles={{ width: '100%' }}>
        <Box flexDirectionColumn onClick={() => setShowJobModal(true)}>
          <Box
            flexDirectionColumn
            styles={{ cursor: 'pointer', gap: '8px', maxWidth: '390px' }}
          >
            <Typography variant="h4" noWrap>
              {jobPost.projectName}
            </Typography>
            <Typography>{`${jobPost.title}${
              !isPartTimeJobPost ? ' (Full Time)' : ''
            }`}</Typography>
          </Box>
          <Divider />
          <Box styles={{ justifyContent: 'space-between' }}>
            <Box styles={{ alignItems: 'center' }}>
              <Avatar size="small" imageUrl={jobPost.imageSrc} />
              <Box flexDirectionColumn styles={{ gap: '4px' }}>
                <Typography variant="subtitle2">{jobPost.postedBy}</Typography>
                <Typography>{jobPost.hirerOrgName}</Typography>
              </Box>
            </Box>
            {showBudget && (
              <Box flexDirectionColumn styles={{ gap: '4px', width: '25%' }}>
                <Typography variant="subtitle2">Budget</Typography>
                <Typography variant="subtitle1">
                  {getCurrencyFormat(
                    calculateJobPostBudget({
                      end: jobPost.endTime,
                      hourlyRate: jobPost.hourlyRate,
                      hoursPerDay: jobPost.hoursPerDay,
                      start: jobPost.startTime,
                    })
                  )}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Divider />
        <Box style={{ height: '180px' }}>
          <Typography>{`${jobPost.description.substring(0, 400)}${
            jobPost.description.length > 400 ? '...' : ''
          }`}</Typography>
        </Box>
        <Divider />
        <Box flexDirectionColumn>
          <Typography variant="subtitle2">Expertise</Typography>
          <Box style={{ flexWrap: 'wrap' }}>
            {jobPost.expertises.map((expertise) => (
              <Chip key={expertise.id} label={expertise.name} />
            ))}
          </Box>
        </Box>
        <Divider />
        <Typography>{`${
          jobPost.jobType === JobTypeEnum.PartTime ? 2 : 20
        } token required`}</Typography>
        <Modal isShown={showJobModal} onCancel={() => setShowJobModal(false)}>
          <PostedJobDetailsCard
            jobPost={jobPost}
            role="expert"
            showMetaInfo
            onClickApplyOrBid={onClickApplyOrBid}
          />
        </Modal>
        <Modal
          isShown={showBiddingModal}
          onCancel={() => setShowBiddingModal(false)}
          size="medium"
        >
          <JobPostBiddingForm
            expertId={expertId}
            jobPost={jobPost}
            onConfirmBidding={() => {
              setShowBiddingModal(false);
              navigate('/expert?activeTab=bid-on-projects');
            }}
          />
        </Modal>
        <ConfirmationModal
          isShown={showApplyModal}
          isConfirming={isApplying}
          title="Confirm Full Time Job Application"
          onCancel={() => setShowApplyModal(false)}
          onConfirm={async () => {
            await onApplyFullTimeJob(jobPost.id, expertId);
            navigate('/expert?activeTab=bid-on-projects');
            setShowApplyModal(false);
            setShowJobModal(false);
          }}
        >
          <Typography>Applying with your current resume</Typography>
          <Typography variant="subtitle1">Are you sure?</Typography>
        </ConfirmationModal>
      </Paper>
    </Box>
  );
};
