import { useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  ConfirmationModal,
  FilePreviewList,
  FileUploader,
  Spinner,
  Typography,
} from 'design-library-v2';

import { FileTypeEnum } from 'common/enums';
import { IFile } from 'common/models';

import { ExpertContext } from 'expert/context';

import { useProfileSetUpOutletContext } from '../ProfileSetup';
import { AssetServices } from 'services';

const useExpertResume = (expertId: string) => {
  const [resume, setResume] = useState<IFile>();
  const [isLoading, setIsLoafing] = useState(false);

  const getOrUpdateResume = useCallback((expertId: string) => {
    setIsLoafing(true);
    AssetServices.getFilesByReference(expertId, FileTypeEnum.Cv)
      .then((res) => setResume(res[0]))
      .catch(console.error)
      .finally(() => setIsLoafing(false));
  }, []);

  useEffect(() => {
    getOrUpdateResume(expertId);
  }, [expertId, getOrUpdateResume]);

  const uploadExpertResume = async (expertId: string, resume: File) => {
    setIsLoafing(true);
    await AssetServices.uploadExpertResume(expertId, resume);
    getOrUpdateResume(expertId);
  };

  const removeExpertResume = async (expertId: string, resumeId: string) => {
    await AssetServices.removeFile(resumeId);
    getOrUpdateResume(expertId);
  };

  return {
    isLoadingResume: isLoading,
    removeExpertResume,
    resume,
    uploadExpertResume,
  };
};

export const Resume = () => {
  const { currentExpert } = useContext(ExpertContext);
  const { isProUser } = useProfileSetUpOutletContext();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const { isLoadingResume, resume, removeExpertResume, uploadExpertResume } =
    useExpertResume(currentExpert?.id as string);

  if (!currentExpert) {
    return <Spinner type="contained" />;
  }

  return (
    <Box flexDirectionColumn>
      <Typography variant="body1">
        CV attachment is the best way to stand out from the other candidates.
        Show your skills on your own way
      </Typography>
      <Typography variant="subtitle2">
        Standard file uploading guideline
      </Typography>
      <ul>
        <li>
          <Typography>File must be less than 5 MB</Typography>
        </li>
        <li>
          <Typography>File format should be .pdf, .doc, .docx</Typography>
        </li>
      </ul>
      <FileUploader
        accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        disabled={!isProUser || isLoadingResume}
        onUpload={(files) => uploadExpertResume(currentExpert.id, files[0])}
      />
      <FilePreviewList
        files={resume ? [resume] : []}
        onDeleteFile={() => setShowDeleteConfirmationModal(true)}
      />

      {showDeleteConfirmationModal && (
        <ConfirmationModal
          isShown
          title="Delete Resume"
          onCancel={() => setShowDeleteConfirmationModal(false)}
          onConfirm={async () => {
            if (resume?.id) {
              await removeExpertResume(currentExpert.id, resume.id);
            }
            setShowDeleteConfirmationModal(false);
          }}
        >
          Are you sure
        </ConfirmationModal>
      )}
    </Box>
  );
};
