import './Text.scss';

export function Text({
  className='',
  type = 'regular',
  text,
}: {
  className?: string;
  type?: 'regular' | 'medium' | 'large';
  text: string;
}) {
  return <p className={`iqcDL-text ${type} ${className}`}>{text}</p>;
}
