import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';

import { CarouselBackground } from 'common/images';

import './Carousel.scss';

const Carousel = ({
  carouselChildren,
}: {
  carouselChildren: React.ReactNode[];
}) => {
  const [slideNumber, setSlideNumber] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(
      () =>
        setSlideNumber(
          (prevState) => (prevState + 1) % carouselChildren.length
        ),
      4 * 1000
    );

    return () => {
      clearInterval(slideInterval);
    };
  }, [carouselChildren.length]);

  return (
    <Card
      className="carousel"
      variant="outlined"
      sx={{
        backgroundImage: `url(${CarouselBackground})`,
        borderRadius: '10px',
        width: '100%',
      }}
    >
      {carouselChildren[slideNumber]}
      <div className="carousel-dot">
        {Array.from(
          { length: carouselChildren?.length || 0 },
          (_, idx) => idx
        ).map((idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                setSlideNumber(idx);
              }}
              className="carousel-dot-circle"
            />
          );
        })}
      </div>
    </Card>
  );
};

export default Carousel;
