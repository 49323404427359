import { IPageResult, IExpert } from 'common/models';
import { Axios } from 'common/services';

const controllerName = 'expert';

export const getExperts = async (
  queryString: string
): Promise<IPageResult<IExpert>> =>
  await (
    await Axios.get(`/${controllerName}/experts?${queryString}`)
  ).data;

export const getExpertById = async (expertId: string) =>
  (await Axios.get(`/${controllerName}/${expertId}/info`)).data;
