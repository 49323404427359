import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagingUserType, ConversationType } from 'messaging/types';
import { appConfig } from 'config';
import { MESSAGE_EVENTS } from './constants';

class SignalRService {
  private connection: HubConnection | null = null;

  public startConnection(): void {
    const accessToken = sessionStorage.getItem(
      appConfig.USER_SESSION_TOKEN_KEY
    );
    this.connection = new HubConnectionBuilder()
      .withUrl(`${appConfig.BASE_API_URL}/chat?access_token=${accessToken}`)
      .withAutomaticReconnect()
      .build();

    this.connection?.start().catch((error) => {
      console.error('SignalR connection error:', error);
    });
  }

  public addMessageReceivedHandler(
    handler: (
      receiverId: string,
      data: Omit<ConversationType, 'isSelf'>
    ) => void
  ): void {
    this.connection?.on(MESSAGE_EVENTS.RECEIVE_MESSAGE, handler);
  }

  public addGetOnlineUsersHandler(
    handler: (onlineUsers: Array<MessagingUserType>) => void
  ): void {
    this.connection?.on(MESSAGE_EVENTS.GET_ONLINE_USERS, handler);
  }

  public sendMessage(receiverId: string, message: string): void {
    this.connection
      ?.invoke(MESSAGE_EVENTS.SEND_MESSAGE, receiverId, message)
      .catch((error) => {
        console.error('Failed to send message:', error);
      });
  }

  public stopConnection(): void {
    this.connection?.stop();
  }

  public getPeerMessageHistory(
    handler: (
      peerConversations: Array<Omit<ConversationType, 'isSelf'>>
    ) => void
  ) {
    this.connection?.on(MESSAGE_EVENTS.PEER_MESSAGE, handler);
  }

  public getChatList(handler: (chatList: Array<MessagingUserType>) => void) {
    this.connection?.on(MESSAGE_EVENTS.GET_MESSAGE_HISTORY, (res) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chatHistory = res.map((item: any) => ({
        createdAt: item.createdAt,
        imageSrc: item.imageSrc,
        isOnline: item.isOnline,
        message: item.lastMessage,
        name: item.name,
        userId: item.userId,
      }));

      handler(chatHistory);
    });
  }

  public invokePeerMessage(peerId: string) {
    this.connection
      ?.invoke(MESSAGE_EVENTS.GET_PEER_MESSAGE, peerId)
      .catch((error) => {
        console.error('Failed to Invoke peer message:', error);
      });
  }

  public closeEventHandler(eventName: string): void {
    this.connection?.off(eventName);
  }
}

export const signalrService = new SignalRService();
