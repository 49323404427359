import { FormikProps, useFormik } from 'formik';

import Form from 'design-library-v2/form/Form';
import Input from 'design-library-v2/text-input/Input';
import FormControl from 'design-library-v2/form/FormControl';

type FormValueType = { textField: string };

export default function FormStory() {
  const formik: FormikProps<FormValueType> = useFormik<FormValueType>({
    initialValues: {
      textField: '',
    },
    onSubmit: (values: FormValueType, { setSubmitting }) => {
      setTimeout(() => {
        alert('Success!!');
        setSubmitting(false);
      }, 3000);
    },
  });

  return (
    <Form<FormValueType>
      formik={formik}
      hasDelete={true}
      onDelete={() => alert('Delete button is clicked')}
    >
      <Input label="Name" placeholder="Full name" />
      <FormControl label="With form control label">
        <Input
          label="Input label"
          placeholder="Single element in form control"
        />
      </FormControl>

      <FormControl>
        <Input label="Input 1" placeholder="Full name" />
        <Input label="Input 2" placeholder="Disabled" disabled={true} />
      </FormControl>
    </Form>
  );
}
