import { useCallback, useEffect, useState } from 'react';

import { Spinner, PageWrapper, Box } from 'design-library-v2';

import { IPageResult, IExpert } from 'common/models';
import { ResultPagePaginationFooter } from 'common/components';
import { QueryParams } from 'common/types';

import ProfileCard from 'expert/profile/profile-card/ProfileCard';
import { FilterSection } from '../../browse-experts/components/filter-section/FilterSection';
import { BrowseExpertServices } from 'hirer/services';
import { BrowseExpertFilterType } from 'hirer/browse-experts/types';
import { EXPERT_FILTER_QUERY_PARAMS, JOB_TYPE } from 'hirer/browse-experts/constants';

export function BrowseExpertsPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: '1',
    pageSize: '9',
  });
  const [expertsPageResult, setExpertsPageResult] = useState<
    IPageResult<IExpert>
  >({
    data: [],
    total: 0,
  });

  const [filterData, setFilterData] = useState<BrowseExpertFilterType>({});

  const updateQueryParamsFromFilterData = (data: BrowseExpertFilterType) => {
    const newData: QueryParams = {};

    if (data.skills) {
      newData.skills = data.skills.map((item) => item.id).join(',');
    }

    const _addQueryParam = (
      key: string,
      value: string | undefined | null
    ): void => {
      if (value && typeof value === 'string') {
        newData[key] = value;
      }
    };

    if(JOB_TYPE.PART_TIME.id !== data?.jobType?.id) {
      _addQueryParam(EXPERT_FILTER_QUERY_PARAMS.minRate, data?.minRate);
      _addQueryParam(EXPERT_FILTER_QUERY_PARAMS.maxRate, data?.maxRate);
    }

    _addQueryParam(EXPERT_FILTER_QUERY_PARAMS.name, data?.name);
    _addQueryParam(EXPERT_FILTER_QUERY_PARAMS.jobType, data?.jobType?.value);
    _addQueryParam(
      EXPERT_FILTER_QUERY_PARAMS.jobActive,
      data?.jobActive?.value
    );
    _addQueryParam(EXPERT_FILTER_QUERY_PARAMS.language, data?.language?.value);
    
    const cityAndCountry = (data?.cityAndCountry || '').split(',');

    _addQueryParam(
      EXPERT_FILTER_QUERY_PARAMS.city,
      cityAndCountry.length ? cityAndCountry[0].trim() : null
    );
    _addQueryParam(
      EXPERT_FILTER_QUERY_PARAMS.country,
      cityAndCountry.length > 1 ? cityAndCountry[1].trim() : null
    );

    const { page, pageSize } = queryParams;
    setQueryParams({ page, pageSize, ...newData });
  };

  const onSetFilterData = (data: BrowseExpertFilterType): void => {
    setFilterData(data);

    updateQueryParamsFromFilterData(data);
  };

  const getExperts = useCallback(
    (includePrevResult = false) => {
      setIsLoading(true);
      const params = new URLSearchParams(queryParams);
      BrowseExpertServices.getExperts(params.toString())
        .then((res) =>
          setExpertsPageResult((prev) => ({
            ...prev,
            data: includePrevResult ? [...prev.data, ...res.data] : res.data,
            total: res.total,
          }))
        )
        .catch((err) => {
          console.error(err); // TO DO use sentry
        })
        .finally(() => setIsLoading(false));
    },
    [queryParams]
  );

  useEffect(() => {
    getExperts();
  }, [getExperts]);

  const getExpertAddress = (addresses: IExpert['addresses']) =>
    addresses.length ? `${addresses[0].city}, ${addresses[0].country}` : '';

  if (isLoading) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper>
      <FilterSection
        filterData={filterData}
        onSetFilterData={onSetFilterData}
      />

      <Box styles={{ flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
        {expertsPageResult.data.map((expert) => (
          <ProfileCard
            key={expert.id}
            expertId={expert.id}
            name={expert.name}
            address={getExpertAddress(expert.addresses)}
            skill={expert.skills.length ? expert.skills[0].name : ''}
            rating={expert.reviewCount.rate}
            projectCount={expert.reviewCount.total}
            imageSrc={expert.imageSrc}
            isOnline={expert.isOnline}
            isVerified={expert.verified}
            isBrowseExpertMode
          />
        ))}
      </Box>

      <ResultPagePaginationFooter
        count={expertsPageResult.data.length}
        paginationModel={{
          page: parseInt(queryParams.page) - 1,
          pageSize: parseInt(queryParams.pageSize),
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setPaginationModel={(page: number, pageSize: number) =>
          setQueryParams({
            page: (page + 1).toString(),
            pageSize: pageSize.toString(),
          })
        }
        total={expertsPageResult.total}
      />
    </PageWrapper>
  );
}
