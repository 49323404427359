import { useEffect, useState } from 'react';
import { IHirer } from 'common/models';
import { HirerProfileSetupServices } from 'hirer/services';
import { AssetServices } from 'services';

export function useCurrentHirer() {
  const [currentHirer, setCurrentHirer] = useState<IHirer>();

  const fetchHirer = async () => {
    try {
      let hirer: IHirer = await HirerProfileSetupServices.getCurrentHirer();
      if (!hirer) {
        await HirerProfileSetupServices.createIdentity();
        hirer = await HirerProfileSetupServices.getCurrentHirer();
      }
      hirer.imageSrc && AssetServices.updateCurrentUserImageURL(hirer.imageSrc);
      await HirerProfileSetupServices.updateHirerClient(hirer.id);
      setCurrentHirer(hirer);
    } catch (error) {
      console.error(error); // TO DO use sentry
    }
  };

  useEffect(() => {
    fetchHirer();
  }, []);

  const updateCurrentHirer = (updatedHirer?: IHirer) => {
    if (updatedHirer) {
      setCurrentHirer(updatedHirer);
      return;
    }
    fetchHirer();
  };

  return {
    currentHirer,
    updateCurrentHirer,
  };
}
