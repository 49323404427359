import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { appConfig } from 'config';
import { MessagePayload } from 'firebase/messaging';
import {
  Caret,
  Icon,
  Avatar,
  MUIBox,
  Typography,
  Stack,
  Divider,
  Popover,
} from 'design-library-v2';
import { firebaseMessagingService } from 'services';
import { NotificationType, NotificationDataType } from 'notification/types';
import { successNotify } from 'notification/components/toaster';
import { LOGGED_IN_NOTIFICATION_TITLE } from 'notification/constants';

import './NotificationWrapper.scss';

const NotificationItem = ({ item }: { item?: NotificationType }) => {
  const navigate = useNavigate();

  const routeToJobPost = () => {
    if (!item?.body) {
      return;
    }
    navigate(`/expert/bulletin-board?jobPostId=${item?.body}`);
  };

  return (
    <MUIBox
      py={1}
      px={1}
      alignItems="center"
      gap={4}
      sx={{ cursor: 'pointer' }}
      onClick={routeToJobPost}
    >
      <Avatar size="small" imageUrl={`${appConfig.APP_URL}/logo.png`} />
      <Typography variant="h6">
        {item?.title || 'You don not have any new notification'}
      </Typography>
    </MUIBox>
  );
};

const NotificationWrapper = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notification, setNotification] = useState<NotificationDataType>({
    count: 0,
    data: [],
  });

  const onNotificationReceive = useCallback(
    (payload: MessagePayload): void => {
      const receivedNotification: NotificationType = {
        body: payload?.notification?.body ?? '',
        id: payload.messageId,
        title: payload?.notification?.title ?? 'No Title',
      };

      if (LOGGED_IN_NOTIFICATION_TITLE === receivedNotification.title) {
        setTimeout(() => {
          successNotify(receivedNotification.body);
        }, 1000 * 2);
      }

      setNotification({
        count: notification.count + 1,
        data: [receivedNotification, ...notification.data],
      });
    },
    [notification.count, notification.data]
  );

  useEffect(() => {
    firebaseMessagingService.onMessageListener(onNotificationReceive);
  }, [notification, onNotificationReceive]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotification((prev) => ({ ...prev, count: 0 }));

    setAnchorEl(event.currentTarget);
  };

  const handleNotificationModalClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <button onClick={handleClick} className="navMenu notoficationBellBtn">
        {notification.count ? (
          <Caret
            appearance="danger"
            className="navMenu-caret"
            value={notification.count}
          />
        ) : null}
        <Icon name="notificationFill" />
      </button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleNotificationModalClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Stack
          px={2}
          py={2}
          spacing={2}
          divider={<Divider />}
          width="400px"
          maxHeight="80vh"
        >
          {notification.data.length ? (
            notification.data.map((item) => (
              <NotificationItem key={item.id} item={item} />
            ))
          ) : (
            <NotificationItem />
          )}
        </Stack>
      </Popover>
    </React.Fragment>
  );
};

export default NotificationWrapper;
