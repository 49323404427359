import './Button.scss';

const Button = ({ //This will be depricated
  text,
  className,
  size = 'regular',
  disabled = false,
  onClick,
  children,
}: {
  text?: string;
  className?: string;
  size?: string;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`iqc-dl-button-${size} ${className}`}
      type="button"
    >
      {text || children}
    </button>
  );
};

export default Button;
