import { IExpertInsight } from 'common/models';
import { ExpertReviewServices } from 'expert/services';
import { useEffect, useState } from 'react';

export const useExpertInsight = (expertId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [expertInsight, setExpertInsight] = useState<IExpertInsight>({
    hirerCount: 0,
    projectCount: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    ExpertReviewServices.getExpertInsight(expertId)
      .then(setExpertInsight)
      .catch((err) => {
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoading(false));
  }, [expertId]);

  return {
    expertInsight,
    isLoadingExpertReviewCount: isLoading,
  };
};
