import { Appearance } from '../type';

import './Caret.scss';

export function Caret({
  appearance = 'primary',
  className = '',
  value,
}: {
  appearance?: Appearance;
  className?: string;
  value: number | string;
}) {
  return (
    <div className={`iqcDL-caret ${appearance} ${className}`}>{value}</div>
  );
}
