import { useContext } from 'react';
import { FormikProps } from 'formik';

import { IJobPost, IJobPostExpertise, IJobPostLanguage } from 'common/models';
import {
  Dropdown,
  IDropdownOptionBase,
  Input,
  Label,
  getDDOption,
} from 'design-library-v2';
import { AppContext } from 'common/contexts';

interface IJobPostExpertiseDDOption
  extends IDropdownOptionBase,
    IJobPostExpertise {}

interface IJobPostLanguageDDOption
  extends IDropdownOptionBase,
    IJobPostLanguage {}

export const ExpertSection = ({
  formik,
}: {
  formik: FormikProps<IJobPost>;
}) => {
  const { languages, skills } = useContext(AppContext);

  return (
    <>
      <Label labelText="Types of expert being looked for." size="large" />
      <Input
        label="Title of the Position"
        name="title"
        onChange={formik.handleChange}
        placeholder="i.e. Add here"
        required
        value={formik.values.title}
      />
      <Dropdown<IJobPostExpertiseDDOption>
        allowMultiSelect
        label="What kind of Expertise do you want?"
        defaultOptions={formik.values.expertises.map((expertise) => ({
          ...getDDOption({
            label: expertise.name,
            value: expertise.expertiseId,
          }),
          ...expertise,
        }))}
        options={skills.map((skill) => ({
          ...getDDOption({ label: skill.name, value: skill.id }),
          expertiseId: skill.id,
          jobPostId: formik.values.id,
          name: skill.name,
        }))}
        onSelect={(options) => formik.setFieldValue('expertises', options)}
      />
      <Dropdown<IJobPostLanguageDDOption>
        allowMultiSelect
        label="Language of Communication"
        defaultOptions={formik.values.languages.map((language) => ({
          ...getDDOption({
            label: language.name,
            value: language.languageId,
          }),
          ...language,
        }))}
        options={languages.map((language) => ({
          ...getDDOption({ label: language.name, value: language.id }),
          jobPostId: formik.values.id,
          languageId: language.id,
          name: language.name,
        }))}
        onSelect={(options) => formik.setFieldValue('languages', options)}
      />
    </>
  );
};
