import { ReactElement } from 'react';

import Text from './text/Text';

import './DesignLibrary.scss';

function DesignLibraryCard({ component }: {component: ReactElement}) {
  return (
    <div className="iqc-dl-dl-card">
      {component}
    </div>
  );
}

function DesignLibrary() {
  return (
    <div className="iqc-dl">
      <DesignLibraryCard component={
        <Text text="Regular text" />
      } />

      <DesignLibraryCard component={
        <Text text="Large text" size={'large'} />
      } />

    </div>
  );
}

export default DesignLibrary;
