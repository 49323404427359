import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
} from 'design-library-v2';
import { JobNatureEnum, ProjectStatusEnum } from 'common/enums';
import { IProject } from 'common/models';
import { getCurrencyFormat } from 'common/utils';

import { useJobBid } from 'job-post-bidding/hooks';
import { SubTitleAndCaption } from 'job-post/components';

export const ProjectBidderCard = ({
  project,
  onViewProject,
}: {
  project: IProject;
  onViewProject: () => void;
}) => {
  const navigate = useNavigate();
  const { bidInfo } = useJobBid(project.jobBidId);

  if (!bidInfo) {
    return <Skeleton />;
  }

  return (
    <Paper styles={{ height: 'fit-content', width: '100%' }}>
      <Box flexDirectionColumn>
        <Avatar size="medium" imageUrl={project.expertImage} />
        <SubTitleAndCaption subTitle={bidInfo.bidInfo?.expertName} />
        <Box>
          <SubTitleAndCaption
            subTitle={`${bidInfo.bidInfo?.expertAddress?.country || '-,'} ${
              bidInfo.bidInfo?.expertAddress?.city || '-'
            }`}
            caption={
              bidInfo.bidInfo?.expertPreference?.jobNature ===
              JobNatureEnum.Onsite
                ? 'Onsite'
                : 'Remote'
            }
          />
          <SubTitleAndCaption
            subTitle="Agreed Price"
            caption={`${getCurrencyFormat(bidInfo.bidAmount)}`}
          />
        </Box>
        <Box>
          <SubTitleAndCaption
            subTitle="Project Delivered"
            caption={
              [
                ProjectStatusEnum.Submitted,
                ProjectStatusEnum.Accepted,
              ].includes(project.status)
                ? 'Yes'
                : 'No'
            }
          />
          <SubTitleAndCaption
            subTitle="Start Date"
            caption={moment(bidInfo.createdAt).format('DD/MM/YYYY')}
          />
        </Box>
        <Divider />
        <Box styles={{ justifyContent: 'space-between' }}>
          {[ProjectStatusEnum.Submitted, ProjectStatusEnum.Accepted].includes(
            project.status
          ) && (
            <Button size="small" onClick={onViewProject}>
              View Project
            </Button>
          )}
          <Button size="small" onClick={() => navigate('/hirer/messaging')}>
            Message
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
