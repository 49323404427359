import React from 'react';

import { ButtonV2, Input, Text, TextArea } from 'design-library';

import './ContactUs.scss';

const ContactUs = () => {
  return (
    <div className='contactUs'>
      <div className='contactUs-main'>
        <Text className='contactUs-main-title' text='Get In Touch With Us' />     
      </div>

      <div className="contactUs-formSection">
        <form className="contactUs-form" >
          <Input 
            className='contactUs-form-inputField'
            placeholder={'Your Name'}
          />
          <Input 
            className='contactUs-form-inputField'
            placeholder={'Your Email'}
          />
          <Input 
            className='contactUs-form-inputField'
            placeholder={'Your Phone'}
          />
          <TextArea 
            className='contactUs-form-textAreaField'
            placeholder='Your Message'
          />
          <ButtonV2
            className='contactUs-form-btn'
            text='Send Message' 
          />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
