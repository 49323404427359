import { useCallback, useEffect, useState } from 'react';

import { IExpertTokens } from 'common/models';
import { UsageTireEnum } from 'common/enums';

import { getExpertTokens } from 'services';

export const useExpertTokens = () => {
  const [expertTokens, setExpertTokens] = useState<IExpertTokens>();

  useEffect(() => {
    getExpertTokens()
      .then((res) => setExpertTokens(res))
      .catch((err) => console.error(err));
  }, []);

  const hasExpertSubscription = useCallback(
    (): boolean =>
      Boolean(
        expertTokens &&
          [UsageTireEnum.Pro, UsageTireEnum.ProPlus].includes(
            expertTokens?.type
          )
      ),
    [expertTokens]
  );

  return { expertTokens, hasExpertSubscription };
};
