import { Axios } from 'common/services';
import { IJobPost, IPageResult } from 'common/models';

export const getHirerPostedJobs = async (
  hirerId: string,
  queryString: string
): Promise<IPageResult<IJobPost>> =>
  (await Axios.get(`/HirerJobPost/${hirerId}/jobs?${queryString}`)).data;

export const getJobPost = async (jobPostId: string): Promise<IJobPost> =>
  (await Axios.get(`/HirerJobPost/${jobPostId}/job`)).data;
