import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IExpert } from 'common/models';
import { PageWrapper, Spinner } from 'design-library-v2';

import { getExpertById } from 'hirer/services/BrowsingExpertServices';
import { ExpertProfile } from 'expert/profile';

export const BrowseExpertProfile = () => {
  const [searchParams] = useSearchParams();

  const [expert, setExpert] = useState<IExpert>();

  useEffect(() => {
    const expertId = searchParams.get('expertId');
    if (!expertId) {
      return;
    }
    getExpertById(expertId)
      .then((res) => setExpert(res))
      .catch((err) => {
        console.error(err); // TO DO use sentry
        alert('Failed to fetch expert');
      });
  }, [searchParams]);

  if (!expert) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper>
      <ExpertProfile expert={expert} readOnly={true} />
    </PageWrapper>
  );
};
