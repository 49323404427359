import { TokenRecord } from 'common/models';
import { Axios } from 'common/services';

const buyToken = async (payload: TokenRecord): Promise<string> =>
  (await Axios.post('/Payment/buy-token', payload)).data;

const updateSubscription = async (
  payload: TokenRecord,
  role = 'expert'
): Promise<string> =>
  (await Axios.post(`/Payment/${role}/subscription`, payload)).data;

const isStripeConnected = async (): Promise<boolean> =>
  (await Axios.get('/Expert/connected')).data;

const connectExpertToStripe = async (payload: {
  firstName: string;
  lastName: string;
  country: string;
}): Promise<string> => (await Axios.put('/Expert/connect-url', payload)).data;

export default {
  buyToken,
  connectExpertToStripe,
  isStripeConnected,
  updateSubscription,
};
