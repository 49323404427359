import React, { useEffect } from 'react';
import { AppRoutes } from './routes';
import { AppProvider } from 'common/contexts';
import { AuthProvider, MUIThemeProvider } from 'common/providers';
import { MessagingProvider } from 'messaging/context';
import { firebaseMessagingService } from 'services';
import { CustomToaster } from 'notification/components/toaster';

import './App.scss';

function App() {

  useEffect(() => {
    firebaseMessagingService.seTokenToLocalStorage();
  }, []);
  
  return (
    <React.Fragment>
      <CustomToaster/>
      <MUIThemeProvider>
        <AuthProvider>
          <AppProvider>
            <MessagingProvider>
              <AppRoutes />
            </MessagingProvider>
          </AppProvider>
        </AuthProvider>
      </MUIThemeProvider>
    </React.Fragment>
  );
}

export default App;
