import { BASE_BLUE_25, BASE_BLUE_50 } from 'common/constants/colors';

import { Box } from '../layout';
import { Typography } from '../text';

export const TextBadge = ({
  primaryText,
  secondaryText,
}: {
  primaryText: string;
  secondaryText: string;
}) => {
  return (
    <Box
      styles={{
        alignItems: 'center',
        gap: '4px',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h6">{primaryText}</Typography>
      <Typography
        variant="h6"
        styles={{
          background: BASE_BLUE_25,
          borderRadius: '4px',
          color: BASE_BLUE_50,
          padding: '2px 4px',
        }}
      >
        {secondaryText}
      </Typography>
    </Box>
  );
};
