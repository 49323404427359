import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { Box, Modal, Table, Typography } from 'design-library-v2';

import { ProjectStatusEnum } from 'common/enums';
import { usePagination } from 'common/hooks';
import { IBid, IPageResult } from 'common/models';
import { getCurrencyFormat } from 'common/utils';
import { TermsAndCondition } from 'common/components';

import { acceptBid, getHirerJobPostBids } from 'services';

const BidTableEmptyState = () => (
  <Box
    styles={{ alignItems: 'center', height: '100%', justifyContent: 'center' }}
  >
    <Typography variant="subtitle1">No expert has bid yet</Typography>
  </Box>
);

export const JobPostBidTable = ({ jobPostId }: { jobPostId: string }) => {
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const { paginationModel, setPaginationModel } = usePagination();
  const [isLoadingJobPostBids, setIsLoadingJobPostBids] = useState(true);
  const [bidToAccept, setBidToAccept] = useState<IBid>();
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [jobPostBidResult, setJobPostBidResult] = useState<IPageResult<IBid>>({
    data: [],
    total: 0,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams({
      page: (paginationModel.page + 1).toString(),
      pageSize: paginationModel.pageSize.toString(),
    });

    setIsLoadingJobPostBids(true);
    getHirerJobPostBids(jobPostId, queryParams.toString())
      .then((res) => setJobPostBidResult(res))
      .catch((error) => {
        console.error(error); // TO DO use sentry
      })
      .finally(() => setIsLoadingJobPostBids(false));
  }, [jobPostId, paginationModel.page, paginationModel.pageSize]);

  const getFormattedRows = (bids: Array<IBid>) =>
    bids.map((bid) => ({
      action: bid,
      date: moment(bid.createdAt).format('DD/MM/YYYY'),
      expertName: bid.bidInfo?.expertName,
      id: bid.id,
      price: `${getCurrencyFormat(bid.bidAmount)}`,
    }));

  const confirmPayment = async (bidId: string) => {
    try {
      setIsConfirmingPayment(true);
      const stripeRedirectURL = await acceptBid(bidId);
      window.location.replace(stripeRedirectURL);
    } catch (error) {
      console.error(error);
    } finally {
      setIsConfirmingPayment(false);
    }
  };

  return (
    <>
      <Table
        columns={[
          {
            field: 'expertName',
            headerName: 'Expert Name',
            width: 180,
          },
          {
            align: 'center',
            field: 'date',
            headerAlign: 'center',
            headerName: 'Date',
          },
          {
            align: 'right',
            field: 'price',
            headerAlign: 'right',
            headerName: 'Price',
          },
          {
            align: 'center',
            field: 'action',
            headerAlign: 'center',
            headerName: 'Action',
            renderCell: (params) => (
              <Button
                onClick={async () => {
                  if (params.value.status !== ProjectStatusEnum.Accepted) {
                    setBidToAccept(params.value);
                  }
                }}
                variant="contained"
                size="small"
              >
                {params.value.status !== ProjectStatusEnum.Accepted
                  ? 'Accept Bid'
                  : 'Withdraw'}
              </Button>
            ),
            width: 170,
          },
        ]}
        loading={isLoadingJobPostBids}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        rowCount={jobPostBidResult.total}
        rows={getFormattedRows(jobPostBidResult.data)}
        slots={{
          noRowsOverlay: () => <BidTableEmptyState />,
        }}
        styles={{ height: '340px' }}
      />
      {bidToAccept && (
        <Modal isShown onCancel={() => setBidToAccept(undefined)}>
          <TermsAndCondition
            disableAcceptBtn={isConfirmingPayment}
            isChecked={acceptTermsAndConditions}
            isCheckingRequired={true}
            onCheckTerms={() => setAcceptTermsAndConditions((prev) => !prev)}
            onAccept={() => confirmPayment(bidToAccept.id)}
          />
        </Modal>
      )}
    </>
  );
};
