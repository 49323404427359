import moment from 'moment';
import { FormikProps } from 'formik';

import { IJobPost } from 'common/models';
import {
  Box,
  Chip,
  Divider,
  FilePreviewList,
  FormControl,
  Label,
  RawFilePreviewList,
  Text,
  Typography,
} from 'design-library-v2';
import { JobNatureEnum, JobTypeEnum } from 'common/enums';
import { getCurrencyFormat } from 'common/utils';

import { JobPostFormMode } from './types';

const TextInfo = ({ label, text }: { label: string; text: string }) => (
  <div className="jobPostForm-previewInfo">
    <Label labelText={label} size="regular" />
    <Text text={text} />
  </div>
);

export const JobPostPreview = ({
  formik,
  isEditing,
  jobPostAttachments,
}: {
  formik: FormikProps<IJobPost>;
} & JobPostFormMode) => {
  const isPartTimeJob = formik.values.jobType === JobTypeEnum['PartTime'];

  const getDuration = (): string => {
    if (formik.values.startTime && formik.values.endTime) {
      return `${moment(formik.values.startTime).format(
        'DD/MM/YYYY'
      )} to ${moment(formik.values.endTime).format('DD/MM/YYYY')}`;
    }
    return '-';
  };

  const getDays = () =>
    moment(formik.values.endTime)
      .diff(moment(formik.values.startTime), 'days')
      .toString();

  return (
    <Box flexDirectionColumn>
      <Label labelText="Type of job offer you want to post" size="large" />
      <FormControl>
        <FormControl label="Job Type">
          <Text text={JobTypeEnum[formik.values.jobType]} />
        </FormControl>
        <FormControl label="Job Nature">
          <Text text={JobNatureEnum[formik.values.jobNature]} />
        </FormControl>
      </FormControl>
      {formik.values.jobNature === JobNatureEnum.Onsite && (
        <FormControl label="Where are you based ?">
          <FormControl label="Country">
            <Typography>{formik.values.country}</Typography>
          </FormControl>
          <FormControl label="City">
            <Typography>{formik.values.city}</Typography>
          </FormControl>
        </FormControl>
      )}
      <Divider />
      <Label labelText="What type of expert is being looked for" size="large" />
      <FormControl label="Title of the position">
        <Text text={formik.values.title} />
      </FormControl>
      <FormControl label="What kind of Expertise do you want?">
        <div className="jobPostForm-chipWrap">
          {' '}
          {formik.values.expertises.map((expertise) => (
            <Chip key={expertise.id} label={expertise.name} />
          ))}
        </div>
      </FormControl>
      <FormControl label="Languages of Communication">
        <div className="jobPostForm-chipWrap">
          {formik.values.languages.map((language) => (
            <Chip key={language.id} label={language.name} />
          ))}
        </div>
      </FormControl>
      {isPartTimeJob && (
        <>
          <FormControl label="What type of condition do you want to give?">
            <TextInfo
              label="Estimated duration of the project"
              text={getDuration()}
            />
            <TextInfo label="Total days" text={getDays()} />
          </FormControl>
          <FormControl>
            <TextInfo
              label="Hours per day"
              text={formik.values.hoursPerDay.toString()}
            />
            <TextInfo
              label="Minimum hourly rate"
              text={`${getCurrencyFormat(formik.values.hourlyRate)}`}
            />
          </FormControl>
          <TextInfo
            label="Do you want to show your budget to the bidder?"
            text={formik.values.showBudget ? 'Yes' : 'No'}
          />
        </>
      )}
      <Divider />
      <Label
        labelText={
          isPartTimeJob ? 'Project Details' : 'Write down details of the job'
        }
        size="large"
      />
      <TextInfo label="Name of the Project" text={formik.values.projectName} />
      {isPartTimeJob && (
        <TextInfo
          label="Key objective of the project"
          text={formik.values.keyObjective}
        />
      )}
      <TextInfo
        label={isPartTimeJob ? 'Project Description' : 'Job Description'}
        text={formik.values.description}
      />
      {isEditing ? (
        <FilePreviewList files={jobPostAttachments} isReadOnly />
      ) : (
        <RawFilePreviewList files={jobPostAttachments} isReadOnly />
      )}
    </Box>
  );
};
