import { Link, useLocation } from 'react-router-dom';

import { IconName } from 'common/icons';
import { Icon } from 'design-library-v2';

import './HirerProfileSetup.scss';

export function HirerProfileSetupNavbar() {
  const location = useLocation();

  const isActiveRoute = (routeName: string, routeIndex: number) => {
    const urlSegments = location.pathname.split('/');
    if (urlSegments.length === 3 && routeIndex === 0) {
      return true;
    }
    return urlSegments.includes(routeName);
  };

  const formatRouteName = (route: string) =>
    route
      .split('-')
      .map((r) => r[0].toUpperCase() + r.slice(1))
      .join(' ');

  const getProfileSetupRoutes = (): Array<{
    icon: IconName;
    name: string;
  }> => [
    { icon: 'person', name: 'personal-info' },
    {
      icon: 'skills',
      name: 'desired-expert-details',
    },
  ];

  return (
    <div className="hirer-profileSetup-navbar">
      <ul>
        {getProfileSetupRoutes().map((route, index) => (
          <li
            key={index}
            className={isActiveRoute(route.name, index) ? 'isActive' : ''}
          >
            <Link to={index === 0 ? '' : route.name}>
              <Icon name={route.icon as IconName} />
              {formatRouteName(route.name)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
