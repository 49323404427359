import { useContext, useEffect } from 'react';
import { AuthContext } from 'common/contexts';

import LoadingState from './LoadingState';

const Logout = () => {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    logout();
  }, [logout]);

  return <LoadingState />;
};

export default Logout;
