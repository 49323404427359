import moment from 'moment';
import { Box, Chip, Typography } from 'design-library-v2';

import { JobNatureEnum, JobTypeEnum } from 'common/enums';
import { IJobPost } from 'common/models';
import { getCurrencyFormat } from 'common/utils';

export const SubTitleAndCaption = ({
  subTitle,
  caption,
}: {
  subTitle?: string;
  caption?: string | number;
}) => (
  <Box flexDirectionColumn styles={{ gap: '8px' }}>
    <Typography variant="subtitle1">{subTitle || '-'}</Typography>
    {caption && <Typography>{caption}</Typography>}
  </Box>
);

export const JobPostMetaInfo = ({ jobPost }: { jobPost: IJobPost }) => {
  const isFullTimeJobPost = jobPost.jobType === JobTypeEnum.FullTime;

  return (
    <Box>
      <Box flexDirectionColumn>
        <Box flexDirectionColumn>
          <Typography variant="subtitle1">Expertise</Typography>
          <Box styles={{ flexWrap: 'wrap', gap: '8px' }}>
            {jobPost.expertises.map((expertise) => (
              <Chip key={expertise.id} label={expertise.name} />
            ))}
          </Box>
        </Box>
        <Box flexDirectionColumn>
          <Typography variant="subtitle1">
            Languages Of Communication
          </Typography>
          <Box>
            {jobPost.languages.map((language) => (
              <Chip key={language.id} label={language.name} />
            ))}
          </Box>
        </Box>
        {jobPost.country && jobPost.city && (
          <SubTitleAndCaption
            subTitle="Location"
            caption={`${jobPost.city}, ${jobPost.country}`}
          />
        )}
      </Box>
      <Box flexDirectionColumn>
        <SubTitleAndCaption
          subTitle="Job Nature"
          caption={
            jobPost.jobNature === JobNatureEnum.Remote ? 'Remote' : 'Onsite'
          }
        />
        <SubTitleAndCaption
          subTitle="Job Type"
          caption={
            jobPost.jobType === JobTypeEnum.PartTime ? 'Part time' : 'Full Time'
          }
        />
      </Box>
      {!isFullTimeJobPost && (
        <Box flexDirectionColumn>
          <SubTitleAndCaption
            subTitle="Estimated Duration"
            caption={`${moment(jobPost.startTime).format(
              'DD/MM/YYYY'
            )} -${moment(jobPost.endTime).format('DD/MM/YYYY')}`}
          />
          <SubTitleAndCaption
            subTitle="Total Days"
            caption={`${moment(jobPost.endTime).diff(
              moment(jobPost.startTime),
              'days'
            )} Days`}
          />
        </Box>
      )}
      {!isFullTimeJobPost && (
        <Box flexDirectionColumn>
          <SubTitleAndCaption
            subTitle="Hours Per Day"
            caption={jobPost.hoursPerDay}
          />
          <SubTitleAndCaption
            subTitle="Minimum Hourly Rate"
            caption={`${getCurrencyFormat(jobPost.hourlyRate)}`}
          />
        </Box>
      )}
    </Box>
  );
};
