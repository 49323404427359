import { toast } from 'react-hot-toast';

export * from './CustomToaster';

export const successNotify = (successMessage: string): void => {
  toast.success(successMessage, {
    duration: 1000 * 4,
    position: 'top-right',
		
  });
};
