import clsx from 'clsx';
import { ChangeEvent, DragEvent } from 'react';

import { Box } from '@mui/material';
import { Typography, Icon } from 'design-library-v2';

import { IFile, RawFile } from 'common/models';

import { AssetServices } from 'services';

import './FileUploader.scss';

const FileIcon = () => (
  <Box sx={{ border: '1px solid gray', borderRadius: '5px' }}>
    <Icon name="attachment" size="ex-large" />
  </Box>
);

export const RawFilePreviewList = ({
  isReadOnly = false,
  files,
  onDeleteFile = () => ({}),
}: {
  isReadOnly?: boolean;
  files: RawFile[];
  onDeleteFile?: (fileId: string) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        paddingY: '12px',
      }}
    >
      {files.map((file) => (
        <Box
          key={crypto.randomUUID()}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            position: 'relative',
          }}
        >
          {!isReadOnly && (
            <button
              className="iqcDL-filePreviewList-deleteBtn"
              onClick={(e) => {
                e.preventDefault();
                onDeleteFile(file.id);
              }}
            >
              <Icon name="cross" size="small" />
            </button>
          )}
          <FileIcon />
          {/* <Skeleton variant="rounded" sx={{ height: '80px', width: '80px' }} /> */}
          <Box sx={{ display: 'flex', width: '80px' }}>
            <Typography variant="caption" noWrap={true}>
              {file.file.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const FilePreviewList = ({
  isReadOnly = false,
  files,
  onDeleteFile = () => ({}),
}: {
  isReadOnly?: boolean;
  files: IFile[];
  onDeleteFile?: (fileId: string) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        paddingY: '12px',
      }}
    >
      {files.map((file) => (
        <Box
          key={crypto.randomUUID()}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            position: 'relative',
          }}
        >
          {!isReadOnly && (
            <button
              className="iqcDL-filePreviewList-deleteBtn"
              onClick={() => onDeleteFile(file.id)}
            >
              <Icon name="cross" size="small" />
            </button>
          )}
          <button
            className="iqcDL-filePreviewList-downloadBtn"
            onClick={() => AssetServices.downloadFile(file.id)}
          >
            <Icon name="download" />
          </button>

          <FileIcon />
          <Box sx={{ display: 'flex', width: '80px' }}>
            <Typography variant="caption" noWrap={true}>
              {file.originalName}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const FileUploader = ({
  accept,
  disabled = false,
  dropAreaClassName = '',
  dropAreaLabel = '',
  multiple = false,
  onUpload,
  title,
}: {
  accept?: string;
  disabled?: boolean;
  dropAreaClassName?: string;
  dropAreaLabel?: string;
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  title?: string;
}) => {
  const onCompleteFileUpload = async (files: File[]) => {
    onUpload([...files]);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files || []);
    onCompleteFileUpload(files);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    onCompleteFileUpload(files);
  };

  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {title && <Typography variant="subtitle1">{title}</Typography>}
      <div
        className={clsx(
          'iqcDL-fileUploader-inputDropArea',
          dropAreaClassName,
          disabled && 'is-disabled'
        )}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <label htmlFor="file-input">
          <Typography>
            {dropAreaLabel || 'Drag & Drop files here Or Browse files'}
          </Typography>
        </label>
        <input
          accept={accept}
          disabled={disabled}
          className="iqcDL-fileUploader-input"
          id="file-input"
          multiple={multiple}
          onChange={handleFileUpload}
          type="file"
        />
      </div>
    </Box>
  );
};
