import { useContext } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { Box, Spinner } from 'design-library-v2';

import { ExpertContext } from 'expert/context';
import { useExpertTokens } from 'expert/hooks';

import { ProfileSetupNavbar } from './ProfileSetupNavbar';

import './ProfileSetup.scss';

type ProfileSetupOutletContext = {
  isProUser?: boolean;
};

export function ProfileSetup() {
  const { isExpertContextLoading } = useContext(ExpertContext);
  const { hasExpertSubscription } = useExpertTokens();

  if (isExpertContextLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <Box styles={{ paddingY: '32px' }}>
      <Box styles={{ position: 'relative', width: '32%' }}>
        <ProfileSetupNavbar isProUser={hasExpertSubscription()} />
      </Box>
      <Box styles={{ paddingX: '72px', position: 'relative' }}>
        <Outlet
          context={
            {
              isProUser: hasExpertSubscription(),
            } as ProfileSetupOutletContext
          }
        />
      </Box>
    </Box>
  );
}

export function useProfileSetUpOutletContext() {
  return useOutletContext<ProfileSetupOutletContext>();
}
