export const EXPERT_FILTER_QUERY_PARAMS = {
  city: 'city',
  cityAndCountry: 'cityAndCountry',
  country: 'country',
  jobActive: 'jobActive',
  jobType: 'jobType',
  language: 'language',
  maxRate: 'maxRate',
  minRate: 'minRate',
  name: 'name',
  pageNumber: 'pageNumber',
  pageSize: 'pageSize',
  skills: 'skills',
};

export const JOB_TYPE = {
  FULL_TIME: {
    id: 'full-time',
    label: 'Full Time'
  },
  PART_TIME: {
    id: 'part-time',
    lable: 'Part Time'
  }
};
