import React, { useState, useContext } from 'react';

import { SkillDropdownOption } from 'common/types';
import { AppContext } from 'common/contexts';
import { FormikProps, useFormik } from 'formik';
import { Icon, ButtonV2, Text, DistanceSlider } from 'design-library';
import {
  Input,
  Dropdown,
  IDropdownOptionBase,
  Form,
  getDDOption,
} from 'design-library-v2';
import { LocationIcon, SearchIcon } from 'common/icons';
import { JobTypeEnum, JobActiveEnum } from 'common/enums';
import { BrowseExpertFilterType } from 'hirer/browse-experts/types';
import { EXPERT_FILTER_QUERY_PARAMS, JOB_TYPE } from 'hirer/browse-experts/constants';

import './FilterSection.scss';

export const FilterSection = ({
  filterData,
  onSetFilterData,
}: {
  filterData: BrowseExpertFilterType;
  onSetFilterData: (data: BrowseExpertFilterType) => void;
}) => {

  const { languages, skills } = useContext(AppContext);
  const [showFilterForm, setShowFilterForm] = useState(false);

  const formik: FormikProps<BrowseExpertFilterType> =
    useFormik<BrowseExpertFilterType>({
      initialValues: filterData,
      onSubmit: async (values: BrowseExpertFilterType, { setSubmitting }) => {
        try {
          setSubmitting(true);
          onSetFilterData(values);
          setShowFilterForm(false);
        } catch (error) {
          alert('Failed to update!!'); // FIX ME
        } finally {
          setSubmitting(false);
        }
      },
    });

  const handleFilterDelete = (): void => {
    const name = formik.values?.name;
    const cityAndCountry = formik.values?.cityAndCountry;

    onSetFilterData({ cityAndCountry, name });

    formik.resetForm({});
    formik.setValues({ cityAndCountry, name });

    setShowFilterForm(false);
  };

  const onSliderUpdate = (data: [number, number]): void => {
    formik.setValues({
      [EXPERT_FILTER_QUERY_PARAMS.minRate]: data[0].toString(),
      [EXPERT_FILTER_QUERY_PARAMS.maxRate]: data[1].toString(),
    });
  };

  const handleRateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const onSearchExperts = () => {
    onSetFilterData({
      ...filterData,
      [EXPERT_FILTER_QUERY_PARAMS.name]: formik.values?.name ?? '',
      [EXPERT_FILTER_QUERY_PARAMS.cityAndCountry]:
        formik.values?.cityAndCountry ?? '',
    });
  };

  
  const isPartTimeJob = () => formik.values?.jobType?.id === JOB_TYPE.PART_TIME.id ? true: false;

  return (
    <Form<BrowseExpertFilterType>
      className="browserExpert-filter-form"
      submitBtnLabel="Apply"
      secondaryBtnLabel="Reset"
      formik={formik}
      hasSecondaryBtn
      onClickSecondaryBtn={handleFilterDelete}
    >
      <div className="browserExpert-filterSectionContainer">
        <div className="browserExpert-filterSection">
          <div className="browserExpert-filterSection-item">
            <Icon
              className="browserExpert-filterSection-icon"
              icon={SearchIcon}
            />
            <Input
              name={EXPERT_FILTER_QUERY_PARAMS.name}
              value={formik.values?.name || ''}
              className="browserExpert-filterSection-input"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                e.preventDefault();
                formik.setFieldValue(
                  EXPERT_FILTER_QUERY_PARAMS.name,
                  e.target.value
                );
              }}
              placeholder="Job title or keyword"
            />
          </div>
          <div className="browserExpert-filterSection-vertical-line" />
          <div className="browserExpert-filterSection-item">
            <Icon
              className="browserExpert-filterSection-icon"
              icon={LocationIcon}
            />
            <Input
              name={EXPERT_FILTER_QUERY_PARAMS.cityAndCountry}
              value={formik.values?.cityAndCountry || ''}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                e.preventDefault();
                formik.setFieldValue(
                  EXPERT_FILTER_QUERY_PARAMS.cityAndCountry,
                  e.target.value
                );
              }}
              className="browserExpert-filterSection-input"
              placeholder="Bandung, Indonesia"
            />
          </div>
          <div className="browserExpert-filterSection-item">
            <ButtonV2
              onClick={onSearchExperts}
              className="browserExpert-filterSection-btn"
              text="Search"
            />
            {/* <div className="browserExpert-filterSection-vertical-line" />
            <ButtonV2
              onClick={() => setShowFilterForm((prev) => !prev)}
              className="browserExpert-filterSection-btn"
            >
              <Icon icon={FilterIcon} />
              Filter
            </ButtonV2> */}
          </div>
        </div>
      </div>

      <Dropdown<SkillDropdownOption>
        allowMultiSelect
        defaultOptions={(formik.values.skills || []).map((skill) => ({
          id: skill.id,
          isPublic: skill.isPublic,
          label: skill.label,
          value: skill.id,
        }))}
        label="What kind of expertise do you want?"
        onSelect={(options: Array<SkillDropdownOption>) => {
          formik.setFieldValue(EXPERT_FILTER_QUERY_PARAMS.skills, options);
        }}
        options={skills.map((skill) => ({
          id: skill.id,
          isPublic: skill.isPublic,
          label: skill.name,
          value: skill.id,
        }))}
        placeholder="Search here"
      />
      <div className="browserExpert-filter-form-budget">
        {
          !isPartTimeJob() && (
            <React.Fragment>
              <div className="browserExpert-filter-form-budget-item">
                <Text className="form-text" text="Budget Range" />
                <DistanceSlider
                  onSliderUpdate={onSliderUpdate}
                  value={[
                    Number(formik.values?.minRate ?? '0'),
                    Number(formik.values?.maxRate ?? '0'),
                  ]}
                  max={10000}
                  className="distance-slider"
                />
              </div>

              <div className="browserExpert-filter-form-budget-item">
                <Text className="form-text" text="Min" />
                <Input
                  name={EXPERT_FILTER_QUERY_PARAMS.minRate}
                  value={formik.values?.minRate || ''}
                  className="browserExpert-filter-form-budget-item-input"
                  placeholder="$20"
                  type="number"
                  onChange={handleRateChange}
                />
              </div>
              <div className="browserExpert-filter-form-budget-item">
                <Text className="form-text" text="Max" />
                <Input
                  name={EXPERT_FILTER_QUERY_PARAMS.maxRate}
                  className="browserExpert-filter-form-budget-item-input"
                  placeholder="$30"
                  type="number"
                  value={formik.values?.maxRate || ''}
                  onChange={handleRateChange}
                />
              </div>
            </React.Fragment>
          )
        }
        <div className="browserExpert-filter-form-budget-item">
          <Dropdown<IDropdownOptionBase>
            options={[
              {
                id: 'part-time',
                label: 'Part Time',
                value: JobTypeEnum.PartTime.toString(),
              },
              {
                id: 'full-time',
                label: 'Full Time',
                value: JobTypeEnum.FullTime.toString(),
              },
            ]}
            defaultOptions={
              formik.values.jobType ? [formik.values.jobType] : []
            }
            label="Job Type"
            onSelect={(options) => {
              formik.setFieldValue(
                EXPERT_FILTER_QUERY_PARAMS.jobType,
                options[0]
              );
            }}
          />
        </div>
        <div className="browserExpert-filter-form-budget-item">
          <Dropdown<IDropdownOptionBase>
            className="browserExpert-filter-form-budget-item-dropdown"
            label="Availability"
            options={[
              {
                id: 'everyday',
                label: 'Everyday',
                value: JobActiveEnum.EveryDay.toString(),
              },
              {
                id: 'mondayToFriday',
                label: 'Monday to Friday',
                value: JobActiveEnum.MondayToFriday.toString(),
              },
              {
                id: 'sundayToThursday',
                label: 'Sunday To Thursday',
                value: JobActiveEnum.SundayToThursday.toString(),
              },
            ]}
            defaultOptions={
              formik.values.jobActive ? [formik.values.jobActive] : []
            }
            onSelect={(options) => {
              formik.setFieldValue(
                EXPERT_FILTER_QUERY_PARAMS.jobActive,
                options[0]
              );
            }}
          />
        </div>
        <div className="browserExpert-filter-form-budget-item">
          <Dropdown<IDropdownOptionBase>
            className="browserExpert-filter-form-budget-item-dropdown"
            label="Language"
            options={languages.map((language) =>
              getDDOption({ label: language.name })
            )}
            defaultOptions={
              formik.values.language ? [formik.values.language] : []
            }
            onSelect={(options) => {
              formik.setFieldValue(
                EXPERT_FILTER_QUERY_PARAMS.language,
                options[0]
              );
            }}
          />
        </div>
      </div>
    </Form>
  );
};
