import { ReactElement } from 'react';

import { ReactComponent as Check } from './svgs/check.svg';
import { ReactComponent as InsertChart } from './svgs/insertChart.svg';
import { ReactComponent as Person } from './svgs/person.svg';
import { ReactComponent as Search } from './svgs/search.svg';
import { ReactComponent as Skills } from './svgs/skills.svg';
import { ReactComponent as Profiles } from './svgs/profiles.svg';
import { ReactComponent as Preferences } from './svgs/preferences.svg';
import { ReactComponent as Portfolio } from './svgs/portfolio.svg';
import { ReactComponent as Plus } from './svgs/plus.svg';
import { ReactComponent as Rocket } from './svgs/rocket.svg';
import { ReactComponent as TickMark } from './svgs/TickMarkIcon.svg';
import { ReactComponent as DeleteFill } from './svgs/deleteFill.svg';
import { ReactComponent as Experience } from './svgs/experience.svg';
import { ReactComponent as Education } from './svgs/education.svg';
import { ReactComponent as PostJob } from './svgs/postJob.svg';
import { ReactComponent as Billing } from './svgs/billing.svg';
import { ReactComponent as Stat } from './svgs/stat.svg';
import { ReactComponent as Dashboard } from './svgs/dashboard.svg';
import { ReactComponent as Home } from './svgs/home.svg';
import { ReactComponent as BarChart } from './svgs/barChart.svg';
import { ReactComponent as Envelope } from './svgs/envelope.svg';
import { ReactComponent as NotificationFill } from './svgs/notificationFill.svg';
import { ReactComponent as SearchFill } from './svgs/searchFill.svg';
import { ReactComponent as ResumeIcon } from './ResumeIcon.svg';
import { ReactComponent as Attachment } from './svgs/attachment.svg';
import { ReactComponent as Cross } from './svgs/cross.svg';
import { ReactComponent as Download } from './svgs/download.svg';

export type IconName =
  | 'attachment'
  | 'barChart'
  | 'billing'
  | 'check'
  | 'cross'
  | 'dashboard'
  | 'deleteFill'
  | 'download'
  | 'education'
  | 'envelope'
  | 'experience'
  | 'home'
  | 'insertChart'
  | 'notificationFill'
  | 'person'
  | 'portfolio'
  | 'postJob'
  | 'preferences'
  | 'profiles'
  | 'search'
  | 'searchFill'
  | 'skills'
  | 'stat'
  | 'plus'
  | 'rocket'
  | 'tickMark'
  | 'resume';

export const svgIcons: {
  [key in IconName]: ReactElement;
} = {
  attachment: <Attachment />,
  barChart: <BarChart />,
  billing: <Billing />,
  check: <Check />,
  cross: <Cross />,
  dashboard: <Dashboard />,
  deleteFill: <DeleteFill />,
  download: <Download />,
  education: <Education />,
  envelope: <Envelope />,
  experience: <Experience />,
  home: <Home />,
  insertChart: <InsertChart />,
  notificationFill: <NotificationFill />,
  person: <Person />,
  plus: <Plus />,
  portfolio: <Portfolio />,
  postJob: <PostJob />,
  preferences: <Preferences />,
  profiles: <Profiles />,
  resume: <ResumeIcon />,
  rocket: <Rocket />,
  search: <Search />,
  searchFill: <SearchFill />,
  skills: <Skills />,
  stat: <Stat />,
  tickMark: <TickMark />,
};
