import { useContext } from 'react';
import { Avatar, MUIBox, Stack, Typography } from 'design-library-v2';
import { formatDateTime } from 'common/services';
import { MessagingContext } from 'messaging/context';

export type ContactType = {
  id: string;
  name: string;
};

const ContactList = () => {
  const { conversations, contacts, updateSelectedUser } =
    useContext(MessagingContext);

  const getLastConversionMessage = (userId: string): string => {
    const currentUserConversion = conversations[userId] ?? [];
    const lastConversationIndex = currentUserConversion?.length ?? 0;

    return lastConversationIndex
      ? currentUserConversion[lastConversationIndex - 1].body
      : '';
  };

  return (
    <MUIBox>
      {!contacts.length && (
        <MUIBox justifyContent="center" alignItems="center" py={8} width={1}>
          <Typography variant="subtitle2">No conversion found!</Typography>
        </MUIBox>
      )}
      <Stack spacing={2} height="70vh" overflow='auto'>
        {contacts.map((contact) => (
          <MUIBox
            key={contact.userId}
            onClick={() => updateSelectedUser(contact)}
            justifyContent="space-between"
            boxShadow={1}
            py={2}
            px={2}
          >
            <MUIBox alignItems="center" gap={2}>
              <Avatar size="small" imageUrl={contact.imageSrc} />
              <Stack>
                <Typography>{contact.name}</Typography>
                <MUIBox width={250} overflow="hidden" textOverflow="ellipsis">
                  <Typography variant="body2" noWrap={true}>
                    {getLastConversionMessage(contact.userId) || ''}
                  </Typography>
                </MUIBox>
              </Stack>
            </MUIBox>
            <Typography variant="caption">
              {formatDateTime(contact.createdAt)}
            </Typography>
          </MUIBox>
        ))}
      </Stack>
    </MUIBox>
  );
};

export default ContactList;
