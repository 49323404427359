import { FormikProps, useFormik } from 'formik';
import {
  Dropdown,
  Form,
  FormControl,
  IDropdownOptionBase,
  getDDOption,
} from 'design-library-v2';
import {
  IHirerPreference,
  IHirerPreferenceCategory,
  IHirerPreferenceLanguage,
} from 'common/models';
import { JobNatureEnum } from 'common/enums';
import { useJobNames, useLanguages, useLocations } from 'common/hooks';

interface IHirerPreferenceCategoryDDOption
  extends IDropdownOptionBase,
    IHirerPreferenceCategory {}

interface IHirerPreferenceLanguageDDOption
  extends IDropdownOptionBase,
    IHirerPreferenceLanguage {}

export function DesiredExpertDetailsForm({
  hirerPreference,
  onUpdateHirerPreference,
}: {
  hirerPreference: IHirerPreference;
  onUpdateHirerPreference: (values: IHirerPreference) => Promise<void>;
}) {
  const { jobs } = useJobNames();
  const { languages } = useLanguages();
  const { loadCities, locations } = useLocations();

  const formik: FormikProps<IHirerPreference> = useFormik<IHirerPreference>({
    initialValues: hirerPreference,
    onSubmit: async (values: IHirerPreference, { setSubmitting }) => {
      try {
        await onUpdateHirerPreference(values);
      } catch (error) {
        alert('Failed to update!!'); // FIX ME
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Form<IHirerPreference> formik={formik}>
      <FormControl>
        <Dropdown<IHirerPreferenceCategoryDDOption>
          allowMultiSelect
          label="Category of the job"
          defaultOptions={formik.values.categories.map((category) => ({
            ...category,
            label: category.categoryName,
            value: category.categoryId,
          }))}
          options={jobs.map((job) => ({
            categoryId: job.id,
            categoryName: job.name,
            hirerPreferenceId: formik.values.id,
            id: crypto.randomUUID(),
            label: job.name,
            value: job.id,
          }))}
          onSelect={(options) => formik.setFieldValue('categories', options)}
          placeholder="i.e. Search here"
        />
      </FormControl>

      <FormControl label="Where are you based?">
        <Dropdown
          defaultOptions={[
            getDDOption({
              label: JobNatureEnum[formik.values.baseOn],
              value: formik.values.baseOn.toString(),
            }),
          ]}
          options={[0, 1].map((i) =>
            getDDOption({ label: JobNatureEnum[i], value: i.toString() })
          )}
          onSelect={(options) =>
            options.length &&
            formik.setFieldValue('baseOn', parseInt(options[0].value))
          }
        />
      </FormControl>

      {formik.values.baseOn === JobNatureEnum['Onsite'] && (
        <FormControl>
          <Dropdown
            defaultOptions={
              formik.values.country
                ? [getDDOption({ label: formik.values.country })]
                : []
            }
            label="Country"
            options={locations.map((location) =>
              getDDOption({ label: location.name })
            )}
            onSelect={async (options) => {
              const country = options.length && options[0].value;
              formik.setFieldValue('country', country);
              if (!country) {
                return;
              }
              await loadCities(country);
            }}
          />
          <Dropdown
            label="City"
            defaultOptions={
              formik.values.city
                ? [getDDOption({ label: formik.values.city })]
                : []
            }
            disabled={!formik.values.country}
            options={(
              locations.find(
                (location) => location.name === formik.values.country
              )?.cities || []
            ).map((city) => getDDOption({ label: city }))}
            onSelect={(options) =>
              formik.setFieldValue('city', options[0]?.value)
            }
          />
        </FormControl>
      )}

      <FormControl>
        <Dropdown<IHirerPreferenceLanguageDDOption>
          allowMultiSelect
          label="Languages"
          defaultOptions={formik.values.languages.map((language) => ({
            ...language,
            label: language.name,
            value: language.languageId,
          }))}
          options={languages.map((language) => ({
            hirerPreferenceId: formik.values.id,
            id: crypto.randomUUID(),
            label: language.name,
            languageId: language.id,
            name: language.name,
            value: language.id,
          }))}
          onSelect={(options) =>
            options.length && formik.setFieldValue('languages', options)
          }
        />
      </FormControl>
    </Form>
  );
}
