import { Text } from './Text';

export default function TextStory() {
  return (
    <>
      <div>
        <Text text="regular text, A quick brown fox jumps over the lazy dog." />
      </div>
      <div>
        <Text
          text="medium text, A quick brown fox jumps over the lazy dog."
          type="medium"
        />
      </div>
      <div>
        <Text
          text="large text, A quick brown fox jumps over the lazy dog."
          type="large"
        />
      </div>
    </>
  );
}
