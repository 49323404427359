import { ChangeEvent, ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';

export function RadioGroup<T>({
  children,
  defaultValue,
  label,
  onChange,
  required = false,
}: {
  children: ReactNode;
  defaultValue?: T;
  label: string;
  onChange: (value: T) => void;
  required?: boolean;
}) {
  return (
    <FormControl required={required}>
      <FormLabel>{label}</FormLabel>
      <MUIRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        onChange={(e: ChangeEvent<HTMLInputElement>, v: unknown) => {
          e.preventDefault();
          onChange(v as T);
        }}
      >
        {children}
      </MUIRadioGroup>
    </FormControl>
  );
}
