import axios from 'axios';

import { appConfig } from 'config';
import { AuthService } from './auth';

const Axios = axios.create({
  baseURL: appConfig.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem(appConfig.USER_SESSION_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const auth = new AuthService();
      auth.signinRedirect();
    }
    return Promise.reject(error);
  }
);

export { Axios };
