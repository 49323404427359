import React from 'react';
import { IconName } from 'common/icons';
import { Appearance } from '../type';
import Icon from '../icon/Icon';

import './Button.scss';

function Button({
  children = '',
  className = '',
  disabled = false,
  icon,
  onClick,
  props,
  text,
  styles={},
  buttonType = 'primary',
  size = 'large',
}: {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: IconName;
  onClick?: () => void;
  props?: Record<string, string>;
  size?: 'large' | 'medium' | 'small';
  text?: string;
  styles?: React.CSSProperties;
  buttonType?: Appearance;
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`iqcDL-button ${buttonType} ${size} ${className}`}
      type="button"
      style={{ ...styles }}
      {...props}
    >
      {icon && <Icon name={icon} size="small" />}
      {text || children}
    </button>
  );
}

export default Button;
