import { Card } from '@mui/material';
import { Link } from 'react-router-dom';
import { CrossIcon } from 'common/icons';
import { ButtonV2, Icon, Input, Text } from 'design-library';

import './PlusPackage.scss';

const PlusPackage = () => {
  return (
    <div className='plusPackage'>
      <Link to='/hirer/package' className='plusPackage-cross-btn' >
        <Icon className='plusPackage-cross-icon' icon={CrossIcon} />
      </Link>
      <div className='plusPackage-container'>
        <Text className='plusPackage-heading' text='Want to Buy Plus Package ?' />
        <Text className='plusPackage-sub-heading' text='Choose the perfect package for you' />

        <div className='plusPackage-cards'>
          <Card 
            variant='outlined' 
            className='plusPackage-card'>
            <div className='plusPackage-card-item'>
              <div className='plusPackage-card-item-top'>
                <Text className='plusPackage-card-item-title' text='Pro' />
                <Text className='plusPackage-card-item-date' text='Monthly' />
              </div>
              <p className='plusPackage-card-item-ptext'>
                <span className='plusPackage-card-item-text'>Get Pro membership for only </span><br />
                <span className='plusPackage-card-item-btext'>$5/</span> 
                <span className='plusPackage-card-item-text'>month!</span>
              </p>
              <ButtonV2 className='plusPackage-card-item-btn' text='Buy' />
            </div>
          </Card>
          <Card 
            variant='outlined' 
            className='plusPackage-card'>
            <div className='plusPackage-card-item'>
              <div className='plusPackage-card-item-top'>
                <Text className='plusPackage-card-item-title' text='Pro' />
                <Text className='plusPackage-card-item-date' text='Yearly' />
              </div>
              <p className='plusPackage-card-item-ptext'>
                <span className='plusPackage-card-item-text'>Get a </span>
                <span className='plusPackage-card-item-btext'>10%</span>
                <br />
                <span className='plusPackage-card-item-text'>discount for a whole year!</span>
              </p>
              <p className='plusPackage-card-item-ptext2'>
                <span className='plusPackage-card-item-text'>Only $ 60 </span>
                <span className='plusPackage-card-item-btext'> $54</span>
              </p>
              <Text className='plusPackage-card-item-promo-text' text='Add promo code' />
              <div className='plusPackage-card-item-searchDiv'>
                <Input
                  className='plusPackage-card-item-searchDiv-search'
                />
                <div className='plusPackage-card-item-searchDiv-btn' >
                  <ButtonV2 text='Valid' />
                </div>
              </div>
              <ButtonV2 className='plusPackage-card-item-btn' text='Buy' />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PlusPackage;
