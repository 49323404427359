import clsx from 'clsx';
import React, { ComponentProps, ReactNode } from 'react';
import { Paper as MUIPaper } from '@mui/material';
import { ComponentSize } from '../type';

type MUIPaperProps = ComponentProps<typeof MUIPaper>;

type PaperProps = {
  children: ReactNode;
  className?: string;
  flexDirectionColumn?: boolean;
  highlight?: boolean;
  size?: ComponentSize;
  styles?: MUIPaperProps['sx'];
};

const Paper = React.forwardRef<HTMLDivElement, PaperProps>(
  (
    {
      children,
      className = '',
      flexDirectionColumn = false,
      highlight,
      size = 'large',
      styles = {},
    },
    ref
  ) => (
    <MUIPaper
      className={clsx({
        className,
        highlight: highlight,
      })}
      elevation={3}
      ref={ref}
      sx={{
        borderRadius: '16px',
        display: 'flex',
        flexDirection: flexDirectionColumn ? 'column' : 'row',
        gap: '24px',
        padding:
          size === 'small'
            ? '8px 8px'
            : size === 'medium'
              ? '16px 16px'
              : '24px 24px',
        ...styles,
      }}
    >
      {children}
    </MUIPaper>
  )
);

Paper.displayName = 'Paper';

export { Paper };
