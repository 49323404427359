import { useContext, useState } from 'react';

import { Button, Spinner } from 'design-library-v2';
import { IExpertProject } from 'common/models';

import { ExpertContext } from 'expert/context';
import { useExpertProject } from 'expert/hooks';
import { ProjectForm } from './ProjectForm';

import './ProjectAndPortfolio.scss';

export function ProjectAndPortfolio() {
  const { currentExpert, isExpertContextLoading } = useContext(ExpertContext);

  const {
    expertProjects,
    isExpertProjectsLoading,
    onDeleteExpertProjects,
    onUpdateExpertProjects,
  } = useExpertProject(currentExpert?.id as string);

  const [showAddProjectForm, setShowAddProjectForm] = useState<boolean>(false);

  const onUpdateProjects = async (updatedProject: IExpertProject) => {
    const currentProjects = [...expertProjects];
    const projectIndex = expertProjects.findIndex(
      (project) => project.id === updatedProject.id
    );
    if (projectIndex === -1) {
      currentProjects.push(updatedProject);
    } else {
      currentProjects[projectIndex] = updatedProject;
    }
    await onUpdateExpertProjects(currentProjects);
    setShowAddProjectForm(false);
  };

  if (isExpertContextLoading || isExpertProjectsLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <div className="projects">
      {expertProjects.map((project) => (
        <ProjectForm
          key={project.id}
          project={project}
          onDeleteProject={onDeleteExpertProjects}
          onUpdateProjects={onUpdateProjects}
        />
      ))}
      {showAddProjectForm && currentExpert && (
        <ProjectForm
          isPlaceholder={true}
          project={{
            description: '',
            id: crypto.randomUUID(),
            link: '',
            orgName: '',
            skills: [],
            title: '',
            userId: currentExpert.id,
          }}
          onUpdateProjects={onUpdateProjects}
        />
      )}
      <div className="projects-addProject">
        <Button
          disabled={expertProjects.length >= 3}
          buttonType="secondary"
          onClick={() => setShowAddProjectForm(true)}
        >
          Add Project
        </Button>
      </div>
    </div>
  );
}
