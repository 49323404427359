import { useContext, useEffect } from 'react';

import { AuthContext } from 'common/contexts';

import LoadingState from './LoadingState';

const LogoutCallback = () => {
  const { signoutRedirectCallback } = useContext(AuthContext);

  useEffect(() => {
    signoutRedirectCallback();
  }, [signoutRedirectCallback]);

  return <LoadingState />;
};

export default LogoutCallback;
