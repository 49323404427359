import { useContext } from 'react';

import { PageWrapper, Spinner } from 'design-library-v2';

import { ExpertContext } from 'expert/context';
import { ExpertProfile } from 'expert/profile';

export const ExpertProfilePage = () => {
  const { currentExpert } = useContext(ExpertContext);

  if (!currentExpert) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper>
      <ExpertProfile expert={currentExpert} />;
    </PageWrapper>
  );
};
