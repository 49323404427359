import { useState } from 'react';
import Button from '../button/Button';
import { Modal } from './Modal';
import { ConfirmationModal } from './ConfirmationModal';

export default function ModalStory() {
  const [showBasicModal, setShowBasicModal] = useState<boolean>(false);
  const [showBasicSmallModal, setShowBasicSmallModal] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  return (
    <>
      <div>
        <Button onClick={() => setShowBasicModal(true)}>
          Open Basic Modal
        </Button>
        <Modal
          isShown={showBasicModal}
          onCancel={() => setShowBasicModal(false)}
        >
          <h2>Basic Modal</h2>
        </Modal>
      </div>
      <div>
        <Button onClick={() => setShowBasicSmallModal(true)}>
          Open Basic Small Modal
        </Button>
        <Modal
          isShown={showBasicSmallModal}
          onCancel={() => setShowBasicSmallModal(false)}
          size="small"
        >
          <h2>Basic small Modal</h2>
        </Modal>
      </div>
      <div>
        <Button onClick={() => setShowConfirmationModal(true)}>
          Open Confirmation Modal
        </Button>
        <ConfirmationModal
          isShown={showConfirmationModal}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={() => ({})}
          title="Confirmation Modal title"
        >
          Are you sure?
        </ConfirmationModal>
      </div>
    </>
  );
}
