import { Axios } from 'common/services';
import { IBid, IPageResult } from 'common/models';

export const bidOnProject = async (payload: IBid): Promise<IBid> =>
  (await Axios.post('/Bidding/bid', payload)).data;

export const applyFullTimeJob = async (payload: IBid): Promise<IBid> =>
  (await Axios.post('/Bidding/bid', payload)).data;

export const acceptBid = async (bidId: string): Promise<string> =>
  (await Axios.put(`/Bidding/${bidId}/accept`)).data;

export const withdrawBid = async (bidId: string): Promise<IBid> =>
  (await Axios.put(`/Bidding/${bidId}/withdraw`)).data;

export const getExpertBidProjects = async (
  expertId: string,
  queryString: string
): Promise<IPageResult<IBid>> =>
  (await Axios.get(`/Bidding/${expertId}/expert-bids?${queryString}`)).data;

export const getHirerJobPostBids = async (
  jobPostId: string,
  queryString: string
): Promise<IPageResult<IBid>> =>
  (await Axios.get(`/Bidding/${jobPostId}/project-bids?${queryString}`)).data;

export const getBidInfo = async (bidId: string): Promise<IBid> =>
  (await Axios.get(`/Bidding/${bidId}/bidding`)).data;
