import { ComponentProps, ReactNode } from 'react';
import { Typography as MUITypography } from '@mui/material';

type MUITypographyProps = ComponentProps<typeof MUITypography>;

export const Typography = ({
  children,
  noWrap = false,
  styles = {},
  ...props
}: {
  children: ReactNode;
  noWrap?: MUITypographyProps['noWrap'];
  styles?: MUITypographyProps['sx'];
} & MUITypographyProps) => {
  return (
    <MUITypography noWrap={noWrap} sx={styles} {...props}>
      {children}
    </MUITypography>
  );
};
