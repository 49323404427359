import { RadioGroup } from './RadioGroup';
import { RadioOption } from './RadioOption';

export const RadioGroupStory = () => {
  return (
    <>
      <div>
        <RadioGroup label="Radio group" onChange={() => ({})}>
          <RadioOption label="Option 1" value={1} />
          <RadioOption label="Option 2" value={2} />
        </RadioGroup>
      </div>
      <div>
        <RadioGroup
          label="Radio group required"
          onChange={() => ({})}
          required={true}
        >
          <RadioOption label="Option 1" value={1} />
          <RadioOption label="Option 2" value={2} />
        </RadioGroup>
      </div>
    </>
  );
};
