import { ComponentProps, ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tab } from '@mui/material';
import { TabContext, TabList as MUITabList, TabPanel } from '@mui/lab';

import { BASE_BLUE } from 'common/constants/colors';

type TabPanelProps = ComponentProps<typeof TabPanel>;

const getTabValue = (tabName: string) =>
  tabName
    .split(' ')
    .map((t) => t.toLowerCase())
    .join('-');

export function TabList({
  defaultTab,
  queryParam = 'activeTab',
  tabPanelStyles,
  tabs,
}: {
  defaultTab: string;
  queryParam?: string;
  tabPanelStyles?: TabPanelProps['sx'];
  tabs: Array<{
    label: string;
    component: ReactNode;
  }>;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(
    searchParams.get(queryParam) || getTabValue(defaultTab)
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    if (!tabs.map(({ label }) => getTabValue(label)).includes(newValue)) {
      return;
    }
    setValue(newValue);
    setSearchParams({
      [queryParam]: newValue,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <TabContext value={value}>
        <MUITabList
          TabIndicatorProps={{
            style: {
              backgroundColor: BASE_BLUE,
            },
          }}
          textColor="inherit"
          onChange={handleChange}
          sx={{ height: '48px' }}
        >
          {tabs.map(({ label }) => (
            <Tab key={label} label={label} value={getTabValue(label)} />
          ))}
        </MUITabList>
        {tabs.map(({ component, label }) => (
          <TabPanel key={label} value={getTabValue(label)} sx={tabPanelStyles}>
            {component}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
}
