import moment from 'moment';
import { useContext, useState } from 'react';

import { Button, Spinner } from 'design-library-v2';
import { IUserEducation } from 'common/models';

import { ExpertContext } from 'expert/context';
import { EducationForm } from './EducationForm';
import { useExpertEducation } from 'expert/hooks';

import './Education.scss';

export function Education() {
  const { currentExpert, isExpertContextLoading } = useContext(ExpertContext);

  const {
    expertEducations,
    isExpertEducationLoading,
    onDeleteExpertEducation,
    onUpdateExpertEducations,
  } = useExpertEducation(currentExpert?.id as string);

  const [showAddEducationForm, setShowAddEducationForm] =
    useState<boolean>(false);

  const onUpdateEducations = async (updatedEducation: IUserEducation) => {
    const currentEducations = [...expertEducations];
    const educationIndex = expertEducations.findIndex(
      (education) => education.id === updatedEducation.id
    );
    if (educationIndex === -1) {
      currentEducations.push(updatedEducation);
    } else {
      currentEducations[educationIndex] = updatedEducation;
    }
    await onUpdateExpertEducations(currentEducations);
    setShowAddEducationForm(false);
  };

  if (isExpertContextLoading || isExpertEducationLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <div className="educations">
      {expertEducations.map((education) => (
        <EducationForm
          key={education.id}
          education={education}
          onDeleteEducation={onDeleteExpertEducation}
          onUpdateEducations={onUpdateEducations}
        />
      ))}
      {showAddEducationForm && currentExpert && (
        <EducationForm
          isPlaceholder
          education={{
            degreeTitle: '',
            educationLevel: '',
            group: '',
            id: crypto.randomUUID(),
            instituteName: '',
            passingYear: moment().year(),
            userId: currentExpert.id,
          }}
          onDeleteEducation={onDeleteExpertEducation}
          onUpdateEducations={onUpdateEducations}
        />
      )}
      <div className="educations-addEducation">
        <Button
          buttonType="secondary"
          disabled={expertEducations.length >= 3}
          onClick={() => setShowAddEducationForm(true)}
        >
          Add Education
        </Button>
      </div>
    </div>
  );
}
