import { useContext, useEffect, useState } from 'react';
import { UserRole } from 'common/types';
import { AuthContext } from 'common/contexts';

export function useCurrentUserRole() {
  const { getUserRole } = useContext(AuthContext);
  const [currentUserRole, setCurrentUserRole] = useState<UserRole>();

  useEffect(() => {
    getUserRole().then((role) => setCurrentUserRole(role));
  }, [getUserRole]);

  return { currentUserRole };
}
