/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { ReactElement } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { UserRole } from 'common/types';

import {
  AuthorizedRoute,
  Logout,
  LogoutCallback,
  NotImplemented,
  RootPage,
  SigninRedirectCallback,
  SilentRenew,
} from './components';

import { LandingPage } from 'landing-page';
import { ErrorsPage, UnderConstruction } from 'common/components';
import {
  Education,
  Experience,
  ExpertRoot,
  PersonalInfo,
  Preferences,
  ProfileSetup,
  ProjectAndPortfolio,
  Resume,
  Skills,
  SocialProfile,
  Training,
} from 'expert/components';
import {
  DesiredExpertDetails,
  HirerPersonalInfo,
  HirerRoot,
} from 'hirer/components';
import {
  ExpertBulletinBoardPage,
  ExpertDashboardPage,
  ExpertEarningPage,
  ExpertProfilePage,
} from 'expert/pages';
import {
  BillingPage,
  BrowseExpertsPage,
  HirerDashboard,
  HirerProfileSetupPage,
  ManageProjects,
  PostJobPage,
} from 'hirer/pages';
import { BrowseExpertProfile } from 'hirer/browse-experts';
import { StoryBook } from 'design-library-v2';
import DesignLibrary from 'design-library/DesignLibrary';
import Package from 'hirer/package/Package';
import MessagingWrapper from 'messaging/components/MessagingWrapper';
import PlusPackage from 'hirer/package/plus-package/PlusPackage';
import ProfileBage from 'expert/profile/profile-badge/ProfileBage';
import {
  PaymentCancel,
  PaymentSuccess,
  StripeConnectReturn,
} from 'stripe/pages';

const secureElement = (element: ReactElement, role?: UserRole) => (
  <AuthorizedRoute role={role}>{element}</AuthorizedRoute>
);

const routes = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    errorElement: <ErrorsPage />,
    children: [
      { index: true, element: <LandingPage /> },
      {
        path: 'profile-badge/',
        element: secureElement(<ProfileBage />),
      },
      {
        path: 'common/',
        children: [
          {
            path: 'under-construction/',
            element: <UnderConstruction />,
          },
          {
            path: 'error-page/',
            element: <ErrorsPage />,
          },
        ],
      },
      {
        path: 'hirer/',
        element: secureElement(<HirerRoot />, 'hirer'),
        errorElement: <ErrorsPage />,
        children: [
          {
            index: true,
            element: secureElement(<HirerDashboard />, 'hirer'),
          },
          {
            path: 'post-job/',
            element: secureElement(<PostJobPage />, 'hirer'),
          },
          {
            path: 'browse-experts',
            element: secureElement(<BrowseExpertsPage />, 'hirer'),
          },
          {
            path: 'browse-experts/expert',
            element: secureElement(<BrowseExpertProfile />, 'hirer'),
          },
          {
            path: 'manage-your-projects',
            element: secureElement(<ManageProjects />, 'hirer'),
          },
          {
            path: 'billing',
            element: secureElement(<BillingPage />),
          },
          {
            path: 'package',
            element: secureElement(<Package />, 'hirer'),
          },
          {
            path: 'billing',
            element: secureElement(<NotImplemented />, 'hirer'),
          },
          {
            path: 'plus-package',
            element: secureElement(<PlusPackage />),
          },
          {
            path: 'profile-setup/',
            element: secureElement(<HirerProfileSetupPage />),
            children: [
              {
                index: true,
                element: secureElement(<HirerPersonalInfo />),
              },
              {
                path: 'desired-expert-details',
                element: secureElement(<DesiredExpertDetails />, 'hirer'),
              },
            ],
          },
          {
            path: 'expert-profile/',
            element: secureElement(<div>Expert Profile</div>),
          },
          {
            path: 'messaging/',
            element: secureElement(<MessagingWrapper />, 'hirer'),
          },
        ],
      },
      {
        path: 'expert/',
        element: secureElement(<ExpertRoot />, 'expert'),
        errorElement: <ErrorsPage />,
        children: [
          {
            index: true,
            element: secureElement(<ExpertDashboardPage />, 'expert'),
          },
          {
            path: 'profile/',
            element: secureElement(<ExpertProfilePage />, 'expert'),
          },
          {
            path: 'bulletin-board',
            element: secureElement(<ExpertBulletinBoardPage />, 'expert'),
          },
          {
            path: 'earning',
            element: secureElement(<ExpertEarningPage />, 'expert'),
          },
          {
            path: 'profile-setup/',
            element: secureElement(<ProfileSetup />),
            errorElement: <ErrorsPage />,
            children: [
              {
                index: true,
                element: secureElement(<PersonalInfo />),
              },
              {
                path: 'skills',
                element: secureElement(<Skills />),
              },
              {
                path: 'social-profile',
                element: secureElement(<SocialProfile />),
              },
              {
                path: 'preferences',
                element: secureElement(<Preferences />),
              },
              {
                path: 'project-and-portfolio',
                element: secureElement(<ProjectAndPortfolio />),
              },
              {
                path: 'experience',
                element: secureElement(<Experience />),
              },
              {
                path: 'education',
                element: secureElement(<Education />),
              },
              {
                path: 'training',
                element: secureElement(<Training />),
              },
              {
                path: 'resume',
                element: secureElement(<Resume />),
              },
              {
                path: 'desired-expert-details',
                element: secureElement(<DesiredExpertDetails />, 'hirer'),
              },
            ],
          },
          {
            path: 'messaging/',
            element: secureElement(<MessagingWrapper />, 'expert'),
          },
        ],
      },
      {
        path: 'private/',
        children: [
          { index: true, element: secureElement(<StoryBook />) },
          { path: 'design-library', element: secureElement(<DesignLibrary />) },
          { path: 'story-book', element: secureElement(<StoryBook />) },
        ],
      },
    ],
  },
  { path: '/signin-oidc', element: <SigninRedirectCallback /> },
  { path: '/logout', element: <Logout /> },
  { path: '/logout/callback', element: <LogoutCallback /> },
  { path: '/silent-renew', element: <SilentRenew /> },
  { path: '/success', element: secureElement(<PaymentSuccess />) },
  { path: '/cancel', element: secureElement(<PaymentCancel />) },
  { path: '/return', element: secureElement(<StripeConnectReturn />) },
]);

function AppRoutes() {
  return <RouterProvider router={routes} />;
}

export default AppRoutes;
