import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Chip,
  Spinner,
  Typography,
} from 'design-library-v2';
import { Icon } from 'design-library';
import { EditorIcon } from 'common/icons';

import { HirerContext } from 'hirer/context';
import { useHirerInsight } from 'hirer/hooks';

export const HirerProfile = () => {
  const navigate = useNavigate();
  const { hirer, lastLogin, hirerPreference } = useContext(HirerContext);

  const { hirerInsight } = useHirerInsight(hirer?.id as string);

  if (!hirer) {
    return <Spinner type="contained" />;
  }

  return (
    <Box flexDirectionColumn styles={{ gap: '32px' }}>
      <Box styles={{ gap: '8px' }}>
        <Box styles={{ alignItems: 'center', justifyContent: 'center' }}>
          <Avatar imageUrl={hirer.imageSrc} />
          <Box flexDirectionColumn styles={{ gap: '8px' }}>
            <Typography variant="subtitle1">{hirer.name}</Typography>
            <Typography>{hirer.companyName}</Typography>
          </Box>
        </Box>
      </Box>
      <Box flexDirectionColumn>
        <Box styles={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Expert Worked With</Typography>
          <Typography variant="h6">{hirerInsight}</Typography>
        </Box>
        <Box styles={{ gap: '8px', justifyContent: 'space-between' }}>
          <Typography variant="h6">Last active</Typography>
          <Typography variant="h6">{lastLogin?.toDateString()}</Typography>
        </Box>
      </Box>

      <Box flexDirectionColumn>
        <Typography variant="subtitle2">Preferred Jobs</Typography>
        <Box styles={{ flexWrap: 'wrap', gap: '8px' }}>
          {(hirerPreference?.categories || []).map((category) => (
            <Chip key={category.id} label={category.categoryName} />
          ))}
        </Box>
      </Box>

      <Button onClick={() => navigate('/hirer/profile-setup')}>
        Edit Profile
        <Icon icon={EditorIcon} />
      </Button>
    </Box>
  );
};
