import clsx from 'clsx';
import { useState } from 'react';
import { FormikProps } from 'formik';

import {
  ILanguage,
  IExpertLanguage,
  languageProficiencyEnum,
} from 'common/models';
import {
  Button,
  Dropdown,
  FormControl,
  Icon,
  IDropdownOptionBase,
  ConfirmationModal
} from 'design-library-v2';
import { PersonalInfoFormData } from './PersonalInfo';

interface UserLanguageDropdownOption
  extends IDropdownOptionBase,
    IExpertLanguage {}

export function UserLanguages({
  formik,
  languageList,
  userId,
  userLanguages,
}: {
  formik: FormikProps<PersonalInfoFormData>;
  languageList: ReadonlyArray<ILanguage>;
  userId: string;
  userLanguages: Array<IExpertLanguage>;
}) {
  const [addLanguage, setAddLanguage] = useState<boolean>(false);
  const [languageIdToDelete, setLanguageIdToDelete] = useState<string>();

  const getProficiencyOption = (level: number): IDropdownOptionBase => ({
    id: crypto.randomUUID(),
    label: languageProficiencyEnum[level],
    value: level.toString(),
  });

  const getProficiencyOptions = (): Array<IDropdownOptionBase> =>
    [0, 1, 2, 3].map((l) => getProficiencyOption(l));

  const getFormattedLanguageOptions = (
    languages: ReadonlyArray<ILanguage>,
    userId: string
  ): Array<UserLanguageDropdownOption> =>
    languages
      .filter(
        (language) => !userLanguages.find((l) => l.languageId === language.id)
      )
      .map((language) => ({
        id: language.id,
        label: language.name,
        languageId: language.id,
        name: language.name,
        proficiency: 0,
        userId,
        value: language.id,
      }));

  const shouldShowLabelForPlaceholder =
    formik.values.userLanguages.length === 0;

  const onDeleteLanguage = () => {
    formik.setFieldValue(
      'userLanguages',
      formik.values.userLanguages.filter((language) => language.id !== languageIdToDelete)
    );
    setLanguageIdToDelete(undefined);
  };

  return (
    <div className="personal-info-languageControl">
      {userLanguages
        .filter((language) => Boolean(language))
        .map((language, index) => (
          <div
            key={crypto.randomUUID()}
            className="personal-info-languageControl-container"
          >
            <FormControl>
              <Dropdown<UserLanguageDropdownOption>
                isClearable={false}
                label={!index ? 'Language' : undefined}
                defaultOptions={[
                  {
                    id: language.id,
                    label: language.name,
                    languageId: language.languageId,
                    name: language.name,
                    proficiency: language.proficiency,
                    userId,
                    value: language.languageId,
                  },
                ]}
                options={getFormattedLanguageOptions(languageList, userId)}
                onSelect={(options: Array<UserLanguageDropdownOption>) => {
                  formik.setFieldValue('userLanguages', [
                    ...formik.values.userLanguages.filter(
                      (l) => l.id !== language.id
                    ),
                    options[0],
                  ]);
                }}
              />
              <Dropdown<IDropdownOptionBase>
                defaultOptions={[
                  {
                    id: crypto.randomUUID(),
                    label:
                      typeof language.proficiency === 'number'
                        ? languageProficiencyEnum[language.proficiency]
                        : language.proficiency,
                    value: language.proficiency.toString(),
                  },
                ]}
                isClearable={false}
                label={!index ? 'Proficiency Label' : undefined}
                options={getProficiencyOptions()}
                onSelect={(options: Array<IDropdownOptionBase>) => {
                  const languages = [...formik.values.userLanguages];
                  const languageToUpdate = languages.find(
                    (l) => l.id === language.id
                  );
                  if (languageToUpdate) {
                    languageToUpdate.proficiency = parseInt(options[0].value);
                    formik.setFieldValue('userLanguages', [...languages]);
                  }
                }}
              />
            </FormControl>
            <Button
              className={clsx({
                '--first': !index,
                'personal-info-languageDeleteBtn': true,
              })}
              onClick={() => setLanguageIdToDelete(language.id)}
            >
              <Icon name="deleteFill" />
            </Button>

          </div>
        ))}
      {languageIdToDelete && (
        <ConfirmationModal
          isShown
          isConfirming={formik.isSubmitting}
          title="Confirm Delete Language"
          onCancel={() => setLanguageIdToDelete(undefined)}
          onConfirm={onDeleteLanguage}
        >
            Are you sure?
        </ConfirmationModal>
      )}
      {addLanguage && (
        <div
          key={crypto.randomUUID()}
          className="personal-info-languageControl-container"
        >
          <FormControl>
            <Dropdown<UserLanguageDropdownOption>
              isClearable={false}
              label={shouldShowLabelForPlaceholder ? 'Language' : undefined}
              defaultOptions={[]}
              options={getFormattedLanguageOptions(languageList, userId)}
              onSelect={(options: Array<UserLanguageDropdownOption>) => {
                formik.setFieldValue('userLanguages', [
                  ...formik.values.userLanguages,
                  options[0],
                ]);
                setAddLanguage(false);
              }}
            />
            <Dropdown
              defaultOptions={[]}
              disabled
              label={
                shouldShowLabelForPlaceholder ? 'Proficiency Label' : undefined
              }
              options={[]}
              onSelect={() => ({})}
            />
          </FormControl>
          <Button
            className={clsx({
              '--first': shouldShowLabelForPlaceholder,
              'personal-info-languageDeleteBtn': true,
            })}
            onClick={() => setAddLanguage(false)}
          >
            <Icon name="deleteFill" />
          </Button>
        </div>
      )}
      <div className="personal-info-languageControl-addBtn-wrapper">
        <Button
          disabled={addLanguage}
          size="small"
          onClick={() => setAddLanguage(true)}
        >
          + Add Language
        </Button>
      </div>
    </div>
  );
}
