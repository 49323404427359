import { Text, ButtonV2, Carousel } from 'design-library';

import { Box } from 'design-library-v2';

import './CarouselContent.scss';

const ContentItem = ({ text }: { text: string }) => {
  return (
    <div className="content">
      <div className="content-item">
        <Text className="content-item-text" size="large" text={text} />
      </div>
      <div className="content-item">
        <ButtonV2 className="content-item-button" text="CTA" />
      </div>
    </div>
  );
};

export const CarouselContent = () => {
  return (
    <Box>
      <Carousel
        carouselChildren={[
          <ContentItem key={0} text="An upcoming project announcement" />,
          <ContentItem key={1} text="This is slide 2" />,
          <ContentItem key={2} text="This is slide 3" />,
        ]}
      />
    </Box>
  );
};
