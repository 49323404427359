import { FormControlLabel, Radio } from '@mui/material';

export const RadioOption = ({
  label,
  value,
}: {
  label: string;
  value: string | number | boolean;
}) => {
  return <FormControlLabel value={value} control={<Radio />} label={label} />;
};
