import { useEffect, useState } from 'react';

import { ILeaderBoardExpert } from 'common/models';
import { LeaderBoardServices } from 'services';

export const useExpertLeaderBoard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [expertLeaderBoardData, setExpertLeaderBoardData] =
    useState< Array<ILeaderBoardExpert> >([]);

  useEffect(() => {
    setIsLoading(true);
    LeaderBoardServices.getLeaderBoardData()
      .then(setExpertLeaderBoardData)
      .catch((err) => {
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoading(false));
  }, []);

  return {
    expertLeaderBoardData,
    isExpertLeaderBoardLoading: isLoading,
  };
};
