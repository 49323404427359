import { useCallback, useEffect, useState } from 'react';

import { IFile } from 'common/models';
import { AssetServices } from 'services';
import { FileTypeEnum } from 'common/enums';

export const useProjectFiles = (projectId: string) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [isUploadingProjectFiles, setIsUploadingProjectFiles] = useState(false);

  const updateFileList = useCallback(
    async () =>
      setFiles(
        await AssetServices.getFilesByReference(
          projectId,
          FileTypeEnum.JobProjectFile
        )
      ),
    [projectId]
  );

  useEffect(() => {
    updateFileList();
  }, [updateFileList]);

  const onUploadProjectFiles = useCallback(
    async (files: File[]) => {
      setIsUploadingProjectFiles(true);
      try {
        await AssetServices.uploadProjectFiles(projectId, files);
        await updateFileList();
      } catch (err) {
        console.error(err); // TO DO Use sentry
      } finally {
        setIsUploadingProjectFiles(false);
      }
    },
    [projectId, updateFileList]
  );

  const onRemoveFile = useCallback(
    async (fileId: string) => {
      try {
        await AssetServices.removeFile(fileId);
        await updateFileList();
      } catch (err) {
        console.error(err); // TO DO Use sentry
      }
    },
    [updateFileList]
  );

  return {
    isUploadingProjectFiles,
    onRemoveFile,
    onUploadProjectFiles,
    projectFiles: files,
  };
};
