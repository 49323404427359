import { useCallback, useEffect, useState } from 'react';

import { IExperience as IExpertExperience } from 'common/models';
import {
  deleteExpertExperience,
  getExpertExperiences,
  updateExpertExperiences,
} from 'expert/services';

export const useExpertExperience = (expertId: string) => {
  const [isExpertExperiencesLoading, setIsExpertExperiencesLoading] =
    useState(true);
  const [expertExperiences, setExpertExperiences] = useState<
    Array<IExpertExperience>
  >([]);

  useEffect(() => {
    if (!expertId) {
      return;
    }
    setIsExpertExperiencesLoading(true);
    getExpertExperiences(expertId)
      .then((res) => setExpertExperiences(res))
      .catch((err) => {
        alert('Failed to fetch expert project');
        console.error(err); // TO DO use sentry;
      })
      .finally(() => setIsExpertExperiencesLoading(false));
  }, [expertId]);

  const onDeleteExpertExperience = async (experienceId: string) => {
    try {
      setIsExpertExperiencesLoading(true);
      await deleteExpertExperience(experienceId);
      setExpertExperiences(
        expertExperiences.filter((experience) => experience.id !== experienceId)
      );
    } catch (error) {
      alert('Failed to delete user educations');
      console.error(error); // TO DO use sentry;
    } finally {
      setIsExpertExperiencesLoading(false);
    }
  };

  const onUpdateExpertExperiences = useCallback(
    async (experiences: Array<IExpertExperience>) => {
      try {
        setIsExpertExperiencesLoading(true);
        const updatedExperiences = await updateExpertExperiences(
          expertId,
          experiences
        );
        setExpertExperiences(updatedExperiences);
      } catch (error) {
        alert('Failed to update user Experience');
        console.error(error); // TO DO use sentry;
      } finally {
        setIsExpertExperiencesLoading(false);
      }
    },
    [expertId]
  );

  return {
    expertExperiences,
    isExpertExperiencesLoading,
    onDeleteExpertExperience,
    onUpdateExpertExperiences,
  };
};
