import { Box, FilePreviewList, Typography } from 'design-library-v2';

import { IJobPost } from 'common/models';
import { useProjectFiles } from 'projects/hooks';
import { useJobPostFiles } from 'job-post/hooks';

export const JobPostBasicInfo = ({
  jobPost,
  projectId,
}: {
  jobPost: IJobPost;
  projectId: string;
}) => {
  const { projectFiles } = useProjectFiles(projectId);
  const { jobFiles } = useJobPostFiles(jobPost.id);

  const attachments = projectFiles.length ? projectFiles : jobFiles;

  return (
    <Box flexDirectionColumn>
      <Box styles={{ alignItems: 'center' }}>
        <Typography variant="h1" noWrap styles={{ width: '60%' }}>
          {jobPost.projectName}
        </Typography>
        <Typography variant="subtitle2">{`Project ID: ${projectId.substring(
          // TO DO FIX ME
          0,
          8
        )}`}</Typography>
      </Box>
      <Box flexDirectionColumn styles={{ gap: '8px' }}>
        <Typography variant="subtitle1">Position</Typography>
        <Typography>{jobPost.title}</Typography>
      </Box>
      <Box flexDirectionColumn styles={{ gap: '8px' }}>
        <Typography variant="subtitle1">Project Key Objectives</Typography>
        <Typography>{jobPost.keyObjective}</Typography>
      </Box>
      <Box flexDirectionColumn styles={{ gap: '8px' }}>
        <Typography variant="subtitle1">Project Description</Typography>
        <Typography>{jobPost.description}</Typography>
      </Box>
      {attachments.length > 0 && (
        <Box flexDirectionColumn styles={{ gap: '8px' }}>
          <Typography variant="subtitle1">Project Attachments</Typography>
          <FilePreviewList files={attachments} isReadOnly />
        </Box>
      )}
    </Box>
  );
};
