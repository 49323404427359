import { ButtonV2, Icon, Text } from 'design-library';

import { CorrectIcon, CrossIcon } from 'common/icons';

import './PlansSection.scss';

function PlansSectionFun({
  serviceType,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
  feature9,
  feature10
}: {
  serviceType: string;
  feature1: string;
  feature2: string;
  feature3: string;
  feature4: string;
  feature5: string;
  feature6: string;
  feature7: string;
  feature8: string;
  feature9: string;
  feature10: string;
}) {
  return (
    <div className='plansSection-item'>
      <div className='plansSection-item-left'>
        <Text className='plansSection-item-left-heading' text={serviceType} />
        <div className='plansSection-item-left-features'>
          <Text className='plansSection-item-left-features-text' text={feature1} />
          <Text className='plansSection-item-left-features-text' text={feature2} />
          <Text className='plansSection-item-left-features-text' text={feature3} />
          <Text className='plansSection-item-left-features-text' text={feature4} />
          <Text className='plansSection-item-left-features-text' text={feature5} />
          <Text className='plansSection-item-left-features-text' text={feature6} />
          <Text className='plansSection-item-left-features-text' text={feature7} />
          <Text className='plansSection-item-left-features-text' text={feature8} />
          <Text className='plansSection-item-left-features-text' text={feature9} />
          <Text className='plansSection-item-left-features-text' text={feature10} />
        </div>
      </div>

      <div className='plansSection-item-right'>
        <div className='plansSection-item-right-starter'>
          <Text className='plansSection-item-right-starter-title' text='Starter' />
          <p className='plansSection-item-right-starter-subTitle'>
            <span className='dollar'>€0</span>
            <span className='per-month'>/month</span>
          </p>
          <div className='plansSection-item-right-starter-icon'>
            <Icon className='plansSection-item-right-starter-blueCorrectIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-starter-blueCorrectIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-starter-blueCorrectIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-starter-blueCorrectIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-starter-crossIcon' icon={CrossIcon} />
            <Icon className='plansSection-item-right-starter-crossIcon' icon={CrossIcon} />
            <Icon className='plansSection-item-right-starter-crossIcon' icon={CrossIcon} />
            <Icon className='plansSection-item-right-starter-crossIcon' icon={CrossIcon} />
            <Icon className='plansSection-item-right-starter-crossIcon' icon={CrossIcon} />
            <Icon className='plansSection-item-right-starter-crossIcon' icon={CrossIcon} />
          </div>
          <div className='plansSection-item-right-starter-btn'>
            <ButtonV2 text='Start Free' onClick={() => window.location.replace('/expert')} />
          </div>
        </div>

        <div className='plansSection-item-right-pro'>
          <Text className='plansSection-item-right-pro-title' text='Pro' />
          <p className='plansSection-item-right-pro-subTitle'>
            <span className='dollar'>€5</span>
            <span className='per-month'>/month</span>
          </p>
          <div className='plansSection-item-right-pro-icon'>
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
            <Icon className='plansSection-item-right-pro-correctIcon' icon={CorrectIcon} />
          </div>
          <div className='plansSection-item-right-pro-btn'>
            <ButtonV2 className='plansSection-item-right-pro-btn-color' text='Purchase' onClick={() => window.location.replace('/expert')} />
          </div>
        </div>
        
      </div>
    </div>
  );
}

const PlansSection = () => {
  return (
    <div className='plansSection'>
      <Text className='plansSection-heading' text='Simple, transparent pricing' />
      <Text className='plansSection-subHeading' text='No contracts. No surprise fees.' />
    
      <div className='plansSection-items'>
        <div className='plansSection-expert'>
          <PlansSectionFun
            serviceType='For Expert'
            feature1='Tokens with every login.'
            feature2='Earn badges from hirers.'
            feature3='Get hired for part-time jobs'
            feature4='Get fulltime jobs'
            feature5='Add resume'
            feature6='Payment within a week'
            feature7='Get featured in search results.'
            feature8='Unlimited tokens'
            feature9='Get Job leads'
            feature10='Verified & Pro tag'
          />
        </div>

        <div className='plansSection-hirer'>
          <PlansSectionFun
            serviceType='For Hirer'
            feature1='Bulletin board for posting jobs.'
            feature2='Faster communication.'
            feature3='Get onsite experts'
            feature4='Hire part time experts.'
            feature5='Projects history board.'
            feature6='24/7 support'
            feature7='Fulltime Candidates shortlisting'
            feature8='Experthire project support .'
            feature9='Hire full time experts.'
            feature10='Verified & Pro tag'
          />
        </div>

      </div>
    </div>
  );
};

export default PlansSection;
