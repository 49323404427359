import { useState } from 'react';
import {
  Box,
  Button,
  FilePreviewList,
  FileUploader,
  Input,
  Typography,
} from 'design-library-v2';

import { IFile } from 'common/models';
import { useProjectFiles } from 'projects/hooks';

export const ProjectSubmissionForm = ({
  isSubmittingProject,
  onDeleteFile,
  onSubmitProject,
  onUploadProjectFiles,
  projectFiles,
}: {
  isSubmittingProject: boolean;
  onDeleteFile?: (fileId: string) => void;
  onSubmitProject: (message: string) => Promise<void>;
  onUploadProjectFiles: ReturnType<
    typeof useProjectFiles
  >['onUploadProjectFiles'];
  projectFiles: IFile[];
}) => {
  const [message, setMessage] = useState('');

  return (
    <Box flexDirectionColumn styles={{ gap: '16px', paddingX: '8px' }}>
      <Typography variant="h1">Have you completed your project?</Typography>
      <FileUploader
        multiple
        title="Add your Project File"
        onUpload={onUploadProjectFiles}
      />
      <FilePreviewList files={projectFiles} onDeleteFile={onDeleteFile} />
      <Box>
        <Input
          label="Message to Hirer"
          inputType="text-area"
          onChange={(e) => setMessage(e.target.value)}
        />
      </Box>
      <Box styles={{ justifyContent: 'flex-end' }}>
        <Button
          disabled={isSubmittingProject}
          buttonType="outlined"
          onClick={() => onSubmitProject(message)}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};
