import { IconName, svgIcons } from 'common/icons';

import './Icon.scss';

export default function Icon({
  className = '',
  name,
  size = 'regular',
}: {
  className?: string;
  name: IconName;
  size?: 'small' | 'regular' | 'large' | 'ex-large' | 'medium';
}) {
  return (
    <span className={`iqcDL-icon ${size} ${className}`}>{svgIcons[name]}</span>
  );
}
