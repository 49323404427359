import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ConfirmationModal, PageWrapper, Spinner } from 'design-library-v2';
import { IJobPost, RawFile } from 'common/models';

import { HirerContext } from 'hirer/context';
import { JobPostingServices } from 'hirer/services';
import { JobPostForm } from 'hirer/components';
import { useJobPostFiles } from 'job-post/hooks';

export const PostJobPage = () => {
  const [searchParams] = useSearchParams();
  const jobPostId = searchParams.get('jobPostId');

  const { hirer } = useContext(HirerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [jobPost, setJobPost] = useState<IJobPost>();
  const [jobPostAttachments, setJobPostAttachments] = useState<RawFile[]>([]);
  const [attachmentToDelete, setAttachmentToDelete] = useState<string>();

  const isEditing = Boolean(jobPostId);

  const { jobFiles, onUploadJobFiles, onRemoveJobFile } =
    useJobPostFiles(jobPostId);

  useEffect(() => {
    if (!jobPostId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    JobPostingServices.getHirerJobPost(jobPostId)
      .then((jobPost) => setJobPost(jobPost))
      .catch((err) => {
        alert('failed to fetch job post'); // TO DO use sentry
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  }, [jobPostId]);

  const updateJobPost = async (value: IJobPost, isFinal = false) => {
    setJobPost(value);
    if (!isFinal || !hirer) {
      return;
    }
    setIsLoading(false);
    try {
      const updateJobPost = await JobPostingServices.createOrUpdate(
        hirer.id,
        value
      );
      !isEditing &&
        (await onUploadJobFiles(
          updateJobPost.id,
          jobPostAttachments.map((a) => a.file)
        ));
    } catch (err) {
      alert('failed to fetch job post'); // TO DO use sentry
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!hirer || isLoading) {
    return <Spinner type="page" />;
  }

  const today = new Date();

  return (
    <PageWrapper>
      {!isEditing ? (
        <JobPostForm
          isEditing={false}
          jobPostAttachments={jobPostAttachments}
          jobPost={{
            city: '',
            country: '',
            description: '',
            endTime: moment(today).add('days', 10).toISOString(),
            expertises: [],
            hirerId: hirer.id,
            hourlyRate: 30,
            hoursPerDay: 0,
            id: crypto.randomUUID(),
            jobNature: 0,
            jobType: 0,
            keyObjective: '',
            languages: [],
            projectName: '',
            showBudget: true,
            startTime: today,
            title: '',
            ...jobPost,
          }}
          onDeleteAttachments={(fileId: string) =>
            setAttachmentToDelete(fileId)
          }
          onUploadAttachments={(rawFiles: RawFile[]) =>
            setJobPostAttachments((preFiles) => [...preFiles, ...rawFiles])
          }
          updateJobPost={updateJobPost}
        />
      ) : jobPost ? (
        <JobPostForm
          isEditing
          jobPost={jobPost}
          jobPostAttachments={jobFiles}
          onDeleteAttachments={(fileId: string) =>
            setAttachmentToDelete(fileId)
          }
          onUploadAttachments={(rawFiles: RawFile[]) =>
            onUploadJobFiles(
              jobPost.id,
              rawFiles.map((f) => f.file)
            )
          }
          updateJobPost={updateJobPost}
        />
      ) : (
        <Spinner type="page" />
      )}
      {attachmentToDelete && (
        <ConfirmationModal
          isShown
          onCancel={() => setAttachmentToDelete(undefined)}
          onConfirm={() => {
            setAttachmentToDelete(undefined);
            isEditing && jobPostId
              ? onRemoveJobFile(jobPostId, attachmentToDelete)
              : setJobPostAttachments(
                jobPostAttachments.filter((a) => a.id !== attachmentToDelete)
              );
          }}
          title="Confirm attachment delete"
        >
          {`Are sure to delete the attachment ${
            jobPostAttachments.find((a) => a.id === attachmentToDelete)?.file
              .name
          }`}
          ?
        </ConfirmationModal>
      )}
    </PageWrapper>
  );
};
