import { IExpertProject } from 'common/models';
import { Axios } from 'common/services';

const controllerName = 'expertProject';

export const getExpertProjects = async (
  expertId: string
): Promise<Array<IExpertProject>> =>
  await (
    await Axios.get(`/${controllerName}/${expertId}/projects`)
  ).data;

export const updateExpertProjects = async (
  expertId: string,
  payload: Array<IExpertProject>
): Promise<Array<IExpertProject>> =>
  await (
    await Axios.put(`/${controllerName}/${expertId}/projects`, payload)
  ).data;

export const deleteExpertProject = async (projectId: string) =>
  await Axios.delete(`/${controllerName}/${projectId}`);
