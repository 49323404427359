import { useEffect, useState } from 'react';

import { ProjectStatusEnum } from 'common/enums';
import { PaginationModel, usePagination } from 'common/hooks';
import { IPageResult, IProject } from 'common/models';

import { getProjects } from 'services';

export const useProjects = ({
  page = 0,
  pageSize = 5,
  statuses = [],
}: PaginationModel & {
  statuses: ProjectStatusEnum[];
}) => {
  const { paginationModel, setPaginationModel } = usePagination({
    page,
    pageSize,
  });

  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [projectResults, setProjectResults] = useState<IPageResult<IProject>>({
    data: [],
    total: 0,
  });

  useEffect(() => {
    setIsLoadingProjects(true);
    const queryParams = new URLSearchParams({
      page: (paginationModel.page + 1).toString(),
      pageSize: paginationModel.pageSize.toString(),
    });
    getProjects(
      `${queryParams.toString()}${statuses.reduce(
        (prev, cur) => `${prev}&status=${cur}`,
        ''
      )}`
    )
      .then((res) => setProjectResults(res))
      .catch((err) => {
        alert('Failed to fetch projects');
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoadingProjects(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  return {
    isLoadingProjects,
    paginationModel,
    projectResults,
    setPaginationModel,
  };
};
