import React from 'react';

import {
  ActiveIcon,
  CalendarIcon,
  HirersIcon,
  PdfUploadIcon,
  PlusIcon,
  ProfileIcon,
  ProjectsIcon,
  ReviewsIcon,
  StarIcon,
  SubmissionIcon,
  UploadIcon,
} from 'common/icons/badge-icons';

import { BackIcon } from 'common/icons';
import { Button, Icon, Text } from 'design-library';

import './ProfileBage.scss';

const badges = [
  {
    bgColor: '',
    description: 'Completed three projects',
    icon: ProjectsIcon,
    iconColor: 'blueIcon',
    id: 1,
    number: 3,
    numberColor: 'blueNumber',
  },
  {
    bgColor: '',
    description: 'Received five reviews from a hirer',
    icon: ReviewsIcon,
    iconColor: 'blueIcon',
    id: 2,
    number: 5,
    numberColor: 'blueNumber',
  },
  {
    bgColor: 'blue',
    description: 'Got a five star from a hirer',
    icon: StarIcon,
    iconColor: 'whiteIcon',
    id: 3,
    number: 5,
    numberColor: 'whiteNumber',
  },
  {
    bgColor: 'blue',
    description: 'Completed 10 submissions on time',
    icon: SubmissionIcon,
    iconColor: 'whiteIcon',
    id: 4,
    number: 10,
    numberColor: 'whiteNumber',
  },
  {
    bgColor: '',
    description: 'Logged in for hundred days',
    icon: CalendarIcon,
    iconColor: 'blueIcon',
    id: 5,
    number: 100,
    numberColor: 'blueNumber',
  },
  {
    bgColor: 'blue',
    description: '100% Updated the profile',
    icon: ProfileIcon,
    iconColor: 'whiteIcon',
    id: 6,
    number: 100,
    numberColor: 'whiteNumber',
  },
  {
    bgColor: 'blue',
    description: 'Skilled in more than two languages',
    icon: PlusIcon,
    iconColor: 'whiteIcon',
    id: 7,
    number: 2,
    numberColor: 'whiteNumber',
  },
  {
    bgColor: '',
    description: 'Recruited by same hirer twice',
    icon: HirersIcon,
    iconColor: 'blueIcon',
    id: 8,
    number: '',
    numberColor: 'blueNumber',
  },
  {
    bgColor: '',
    description: 'Portfolio Uploaded',
    icon: UploadIcon,
    iconColor: 'blueIcon',
    id: 9,
    number: '',
    numberColor: 'blueNumber',
  },
  {
    bgColor: 'blue',
    description: 'Being active for hundred hours completed',
    icon: ActiveIcon,
    iconColor: 'whiteIcon',
    id: 10,
    number: 100,
    numberColor: 'whiteNumber',
  },
  {
    bgColor: '',
    description: 'CV Uploaded ',
    icon: PdfUploadIcon,
    iconColor: 'blueIcon',
    id: 11,
    number: '',
    numberColor: 'blueNumber',
  },
  {
    bgColor: 'blue',
    description: 'Efficiently completed 25 projects',
    icon: PlusIcon,
    iconColor: 'whiteIcon',
    id: 12,
    number: 25,
    numberColor: 'whiteNumber',
  },
  {
    bgColor: '',
    description: 'Received more than twenty five stars from hirers',
    icon: StarIcon,
    iconColor: 'blueIcon',
    id: 13,
    number: 12,
    numberColor: 'blueNumber',
  },
  {
    bgColor: '',
    description: 'have 10+ experience in industries',
    icon: PlusIcon,
    iconColor: 'blueIcon',
    id: 14,
    number: 2,
    numberColor: 'blueNumber',
  },
];

const ProfileBage = () => {
  return (
    <div className='badge'>
      <div className='badge-container'>
        <Button className='badge-backBtn'>
          <Icon className='' icon={BackIcon} />
          <Text className='' text='Back' />
        </Button>
        <Text className='badge-heading' text='Badge' />

        <div className='badge-items'>
          { badges.map( item => {
            return(
              <div key={item.id} className={`badge-items-item ${item.bgColor}`}>
                <div className='badge-items-item-top'>
                  <p className={`badge-items-item-top-${item.numberColor}`}>{item.number}</p>
                  <Icon className={`badge-items-item-top-${item.iconColor}`} icon={item.icon} />
                </div>
                <Text className='badge-items-item-description' text={item.description} />
              </div>
            );
          }) }
        </div>
      </div>
    </div>
  );
};

export default ProfileBage;
