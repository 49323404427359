import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Button as MUIButton } from '@mui/material';
import {
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Spinner,
  Typography,
} from 'design-library-v2';

import { useExpertExperience } from 'expert/hooks';

const Experience = ({
  expertId,
  readOnly,
}: {
  expertId: string;
  readOnly: boolean;
}) => {
  const navigate = useNavigate();
  const { expertExperiences, isExpertExperiencesLoading } =
    useExpertExperience(expertId);

  const goToExperienceEditPage = () =>
    navigate('/expert/profile-setup/experience');

  if (isExpertExperiencesLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <Box flexDirectionColumn>
      {expertExperiences.map((experience) => (
        <Paper key={experience.id} flexDirectionColumn>
          <Box styles={{ justifyContent: 'space-between' }}>
            <Box flexDirectionColumn styles={{ gap: '8px' }}>
              <Typography variant="h6">{experience.companyName}</Typography>
              <Typography>{experience.companyBusiness}</Typography>
            </Box>
            <Box flexDirectionColumn styles={{ gap: '8px' }}>
              <Typography variant="h6">{experience.designation}</Typography>
              <Typography>{experience.department}</Typography>
            </Box>
          </Box>
          <Box styles={{ justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h6">Employment Period</Typography>
            </Box>
            <Box>
              <Chip
                label={moment(experience.startTime).toDate().toDateString()}
              />
              {!experience.isCurrentCompany ? (
                <Chip
                  label={moment(experience.endTime).toDate().toDateString()}
                />
              ) : (
                <Chip label="Present" />
              )}
            </Box>
          </Box>
          <Box styles={{ flexWrap: 'wrap', gap: '8px' }}>
            {(experience.areaOfExperiences || []).map((expertise) => (
              <Chip key={expertise.id} label={expertise.name} />
            ))}
          </Box>
          <Box>
            <Typography>{experience.responsibilites}</Typography>
          </Box>
          <Divider />
          {!readOnly && (
            <Box styles={{ justifyContent: 'flex-end' }}>
              <MUIButton onClick={goToExperienceEditPage}>Edit</MUIButton>
            </Box>
          )}
        </Paper>
      ))}

      {!readOnly && (
        <Box styles={{ justifyContent: 'flex-end' }}>
          <Button onClick={goToExperienceEditPage}>+ Add More</Button>
        </Box>
      )}
    </Box>
  );
};

export default Experience;
