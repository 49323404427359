import './ButtonV2.scss';

function ButtonV2({
  children = '',
  className = '',
  disabled = false,
  onClick,
  outlined,
  props,
  text,
  type = 'regular',
  size = 'regular',
}: {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  outlined?: boolean;
  onClick?: () => void;
  props?: Record<string, string>;
  size?: 'regular' | 'large' | 'small';
  text?: string;
  type?: 'regular' | 'submit';
}) {
  if (type === 'submit') {
    return (
      <input
        className={`iqc-dl-button-v2 ${size} ${
          outlined ? '__outlined' : ''
        } ${className}`}
        type="submit"
        value={text}
      />
    );
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`iqc-dl-button-v2 ${size} ${
        outlined ? '__outlined' : ''
      } ${className}`}
      type="button"
      {...props}
    >
      {text || children}
    </button>
  );
}

export default ButtonV2;
