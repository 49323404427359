import { FormikProps, useFormik } from 'formik';

import { Box, Dropdown, Form, Input, getDDOption } from 'design-library-v2';

import { CountryCodes } from 'config';

import { useExpertStripeConnection } from '../hooks';
import { ExpertStripeMetaInfo } from '../types';

export const ExpertStripeMetaInfoForm = ({
  connectStripe,
  onCancel,
}: {
  connectStripe: ReturnType<typeof useExpertStripeConnection>['connectStripe'];
  onCancel: () => void;
}) => {
  const formik: FormikProps<ExpertStripeMetaInfo> =
    useFormik<ExpertStripeMetaInfo>({
      initialValues: {
        country: '',
        firstName: '',
        lastName: '',
      },
      onSubmit: (values: ExpertStripeMetaInfo, { setSubmitting }) => {
        connectStripe(values)
          .catch((err) => console.error(err))
          .finally(() => setSubmitting(false));
      },
    });

  return (
    <Box
      flexDirectionColumn
      style={{ height: '100%', justifyContent: 'space-between' }}
    >
      <Form<ExpertStripeMetaInfo>
        formik={formik}
        submitBtnLabel="Connect"
        hasSecondaryBtn
        secondaryBtnLabel="Cancel"
        onClickSecondaryBtn={onCancel}
      >
        <Box flexDirectionColumn style={{ gap: '32px' }}>
          <Input
            label="First Name"
            name="firstName"
            required
            onChange={formik.handleChange}
          />
          <Input
            label="Last Name"
            name="lastName"
            required
            onChange={formik.handleChange}
          />
          <Dropdown
            label="Country"
            required
            defaultOptions={[]}
            options={Object.entries(CountryCodes).map(([key, value]) =>
              getDDOption({
                label: value,
                value: key,
              })
            )}
            onSelect={(options) =>
              options.length > 0 &&
              formik.setFieldValue('country', options[0].value)
            }
          />
        </Box>
      </Form>
    </Box>
  );
};
