import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Table } from './Table';

export const TableStory = () => {
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', sortable: false, width: 100 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 110,
    },
    {
      description: 'This column has a value getter and is not sortable.',
      field: 'fullName',
      headerName: 'Full name',
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      width: 160,
    },
  ];

  const rows = [
    { age: 35, firstName: 'Jon', id: 1, lastName: 'Snow' },
    { age: 42, firstName: 'Cersei', id: 2, lastName: 'Lannister' },
    { age: 45, firstName: 'Jaime', id: 3, lastName: 'Lannister' },
    { age: 16, firstName: 'Arya', id: 4, lastName: 'Stark' },
    { age: null, firstName: 'Daenerys', id: 5, lastName: 'Targaryen' },
    { age: 150, firstName: null, id: 6, lastName: 'Melisandre' },
    { age: 44, firstName: 'Ferrara', id: 7, lastName: 'Clifford' },
    { age: 36, firstName: 'Rossini', id: 8, lastName: 'Frances' },
    { age: 65, firstName: 'Harvey', id: 9, lastName: 'Roxie' },
  ];

  return (
    <div>
      <Table
        columns={columns}
        rows={rows}
        paginationModel={{
          page: 1,
          pageSize: 5,
        }}
        pageSizeOptions={[3, 4, 5]}
      />
    </div>
  );
};
