import { useContext, useState } from 'react';

import { Button, Spinner } from 'design-library-v2';
import { IExperience } from 'common/models';

import { ExpertContext } from 'expert/context';
import { useExpertExperience } from 'expert/hooks';

import { ExperienceForm } from './ExperienceForm';

import './Experience.scss';

export function Experience() {
  const { currentExpert, isExpertContextLoading } = useContext(ExpertContext);
  const {
    expertExperiences,
    isExpertExperiencesLoading,
    onDeleteExpertExperience,
    onUpdateExpertExperiences,
  } = useExpertExperience(currentExpert?.id as string);

  const [showAddExperienceForm, setShowAddExperienceForm] =
    useState<boolean>(false);

  const onUpdateExperiences = async (updatedExperience: IExperience) => {
    const currentExperiences = [...expertExperiences];
    const experienceIndex = expertExperiences.findIndex(
      (experience) => experience.id === updatedExperience.id
    );
    if (experienceIndex === -1) {
      currentExperiences.push(updatedExperience);
    } else {
      currentExperiences[experienceIndex] = updatedExperience;
    }
    await onUpdateExpertExperiences(currentExperiences);
    setShowAddExperienceForm(false);
  };

  if (isExpertContextLoading || isExpertExperiencesLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <div className="experiences">
      {expertExperiences.map((experience) => (
        <ExperienceForm
          key={experience.id}
          experience={experience}
          onDeleteExperience={onDeleteExpertExperience}
          onUpdateExperiences={onUpdateExperiences}
        />
      ))}
      {showAddExperienceForm && currentExpert && (
        <ExperienceForm
          isPlaceholder
          experience={{
            areaOfExperiences: [],
            companyBusiness: '',
            companyName: '',
            department: '',
            designation: '',
            endTime: '',
            id: crypto.randomUUID(),
            isCurrentCompany: 0,
            responsibilites: '',
            startTime: '',
            userId: currentExpert.id,
          }}
          onUpdateExperiences={onUpdateExperiences}
        />
      )}
      <div className="experiences-addExperience">
        <Button
          buttonType="secondary"
          disabled={expertExperiences.length >= 3}
          onClick={() => setShowAddExperienceForm(true)}
        >
          Add Experience
        </Button>
      </div>
    </div>
  );
}
