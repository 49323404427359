import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageWrapper, Spinner, TabList } from 'design-library-v2';

import { CarouselContent } from 'common/components';

import { HirerContext } from 'hirer/context';
import {
  HirerOngoingProjects,
  HirerPastProjects,
  HirerPostedProjects,
} from 'projects/components';

export const ManageProjects = () => {
  const [searchParams] = useSearchParams();
  const { hirer } = useContext(HirerContext);

  const page = parseInt(searchParams.get('page') || '0');
  const pageSize = parseInt(searchParams.get('pageSize') || '5');
  const focusedJobPostId = searchParams.get('focusedJob');

  if (!hirer) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper>
      <CarouselContent />
      <TabList
        defaultTab="Ongoing Projects"
        tabs={[
          {
            component: (
              <HirerOngoingProjects
                focusedJobPostId={focusedJobPostId}
                page={page}
                pageSize={pageSize}
              />
            ),
            label: 'Ongoing Projects',
          },
          {
            component: (
              <HirerPostedProjects
                focusedJobPostId={focusedJobPostId}
                hirerId={hirer.id}
                page={page}
                pageSize={pageSize}
              />
            ),
            label: 'Yet To Start Projects',
          },
          {
            component: (
              <HirerPastProjects
                focusedJobPostId={focusedJobPostId}
                page={page}
                pageSize={pageSize}
              />
            ),
            label: 'Past Projects',
          },
        ]}
      />
    </PageWrapper>
  );
};
