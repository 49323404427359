import { Box, Paper } from 'design-library-v2';
import { ResultPagePaginationFooter } from 'common/components';

import { useHirerPostedJobs } from 'job-post/hooks';
import { PostedJobDetailsCard } from 'job-post/components';

export const HirerPostedProjects = ({
  focusedJobPostId,
  hirerId,
  page,
  pageSize,
}: {
  focusedJobPostId?: string | null;
  hirerId: string;
  page?: number;
  pageSize?: number;
}) => {
  const { postedJobsResult, paginationModel, setPaginationModel } =
    useHirerPostedJobs({
      hirerId,
      page,
      pageSize,
    });

  return (
    <Box flexDirectionColumn>
      {postedJobsResult.data.map((jobPost) => (
        <Paper key={jobPost.id}>
          <PostedJobDetailsCard
            jobPost={jobPost}
            focusedJobPostId={focusedJobPostId}
          />
        </Paper>
      ))}

      <ResultPagePaginationFooter
        count={postedJobsResult.data.length}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        total={postedJobsResult.total}
      />
    </Box>
  );
};
