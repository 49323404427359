import { FormikProps } from 'formik';

import { FormControl, Input, Label } from 'design-library-v2';
import { IJobPost } from 'common/models';

import { JobPostAttachment } from './JobPostAttachment';
import { JobPostFormMode, JobPostFormProps } from './types';

export const JobDescription = ({
  formik,
  isEditing,
  jobPostAttachments,
  onDeleteAttachments,
  onUploadAttachments,
}: {
  formik: FormikProps<IJobPost>;
} & JobPostFormMode &
  Pick<JobPostFormProps, 'onDeleteAttachments' | 'onUploadAttachments'>) => {
  return (
    <>
      <Label labelText="Write down details of the job" size="large" />
      <FormControl>
        <Input
          label="Name of the Project"
          name="projectName"
          onChange={formik.handleChange}
          placeholder="i.e. Project Name"
          required
          value={formik.values.projectName}
        />
      </FormControl>
      <FormControl>
        <Input
          inputType="text-area"
          name="description"
          onChange={formik.handleChange}
          placeholder="i.e. Project Description"
          required
          value={formik.values.description}
        />
      </FormControl>
      {isEditing ? (
        <JobPostAttachment
          isEditing
          jobPostAttachments={jobPostAttachments}
          onDeleteAttachments={onDeleteAttachments}
          onUploadAttachments={onUploadAttachments}
        />
      ) : (
        <JobPostAttachment
          isEditing={false}
          jobPostAttachments={jobPostAttachments}
          onDeleteAttachments={onDeleteAttachments}
          onUploadAttachments={onUploadAttachments}
        />
      )}
    </>
  );
};
