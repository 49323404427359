import {
  ConfirmationModal,
  Dropdown,
  Form,
  FormControl,
  Input,
  getDDOption,
} from 'design-library-v2';
import { IUserEducation } from 'common/models';
import { FormikProps, useFormik } from 'formik';
import { useState } from 'react';

export function EducationForm({
  isPlaceholder = false,
  education,
  onDeleteEducation,
  onUpdateEducations,
}: {
  isPlaceholder?: boolean;
  education: IUserEducation;
  onDeleteEducation?: (educationId: string) => Promise<void>;
  onUpdateEducations: (updatedEducation: IUserEducation) => Promise<void>;
}) {
  const [educationIdDelete, setEducationIdToDelete] = useState<string>();

  const formik: FormikProps<IUserEducation> = useFormik<IUserEducation>({
    initialValues: education,
    onSubmit: async (values: IUserEducation, { setSubmitting }) => {
      try {
        await onUpdateEducations(values);
      } catch (error) {
        alert('Failed to update!!'); // TO DO
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onDelete = async () => {
    if (!onDeleteEducation || !educationIdDelete) {
      return;
    }
    try {
      formik.setSubmitting(true);
      await onDeleteEducation(educationIdDelete);
    } catch (error) {
      alert(`Failed to delete project ${education.degreeTitle}`); // TO DO alert handle
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <>
      <Form<IUserEducation>
        className="educations-form"
        label="Education"
        formik={formik}
        hasDelete={!isPlaceholder}
        onDelete={() => setEducationIdToDelete(formik.values.id)}
      >
        <FormControl>
          <Input
            label="Level of Education"
            required
            name="educationLevel"
            onChange={formik.handleChange}
            value={formik.values.educationLevel}
            placeholder="i.e. Bachelor/Honors"
          />
          <Input
            label="Exam/Degree Title"
            name="degreeTitle"
            onChange={formik.handleChange}
            value={formik.values.degreeTitle}
            placeholder="i.e. BSc"
          />
        </FormControl>
        <FormControl>
          <Input
            label="Concentration/Major/Group"
            name="group"
            onChange={formik.handleChange}
            value={formik.values.group}
            placeholder="i.e. Computer Science & Engireering"
          />
        </FormControl>
        <FormControl>
          <Input
            label="Institute Name"
            name="instituteName"
            onChange={formik.handleChange}
            value={formik.values.instituteName}
            placeholder="i.e. University of Asia Pacific"
          />
        </FormControl>
        <FormControl>
          <Dropdown
            label="Year of Passing"
            defaultOptions={[
              getDDOption({ label: formik.values.passingYear.toString() }),
            ]}
            required
            options={Array.from(Array(100).keys())
              .map((i) => (i + 1980).toString())
              .map((year) => getDDOption({ label: year }))}
            onSelect={(options) => {
              options.length &&
                formik.setFieldValue('passingYear', parseInt(options[0].value));
            }}
          />
        </FormControl>
      </Form>
      {educationIdDelete && (
        <ConfirmationModal
          isShown
          isConfirming={formik.isSubmitting}
          title="Confirm Delete Education"
          onCancel={() => setEducationIdToDelete(undefined)}
          onConfirm={onDelete}
        >
          Are you sure?
        </ConfirmationModal>
      )}
    </>
  );
}
