import React, { useState } from 'react';
import { Modal } from '@mui/material';

import { Text, Button } from 'design-library-v2';
import { Icon as Iconv1 } from 'design-library';
import { CrossIcon } from 'common/icons';
import ConfirmModal from 'messaging/components/message-box/conversation/confirm-modal/ConfirmModal';

import './BlockModal.scss';

const BlockModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirmClick = () => {
    setIsConfirmed(true);
    handleClose();
  };

  const handleConfirmedPopUp = () => {
    setIsConfirmed(false);
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="block-conversation-modal-title"
        aria-describedby="block-conversation-modal-description"
      >
        <div className='blockModal'>
          <button onClick={handleClose} className='blockModal-btnClose'>
            <Iconv1 className='conversation-header-elipsisBtn-icon' icon={CrossIcon}/>
          </button>
          <Text
            className="blockModal-title"
            text='Are you sure you want to block this user?'
            type='large'
          />
          <Button
            text="Confirm"
            className='blockModal-confirmBtn'
            buttonType="secondary"
            size="large"
            onClick={handleConfirmClick}
          />
        </div>
      </Modal>
      <ConfirmModal
        text='Block Succeed'
        open={isConfirmed}
        handleClose={handleConfirmedPopUp}
      />
    </React.Fragment>
  );
};

export default BlockModal;
