import { useContext, useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import { IExpertProject, IExpertProjectSkill } from 'common/models';
import {
  Dropdown,
  Form,
  FormControl,
  IDropdownOptionBase,
  Input,
  ConfirmationModal,
} from 'design-library-v2';
import { AppContext } from 'common/contexts';

interface IExpertProjectSkillDDOption extends IDropdownOptionBase, IExpertProjectSkill {}

export function ProjectForm({
  isPlaceholder = false,
  project,
  onDeleteProject = () => new Promise(() => ({})),
  onUpdateProjects,
}: {
  isPlaceholder?: boolean;
  project: IExpertProject;
  onDeleteProject?: (projectId: string) => Promise<void>;
  onUpdateProjects: (updatedProject: IExpertProject) => Promise<void>;
}) {
  const { skills } = useContext(AppContext);

  const [projectIdToDelete, setProjectIdToDelete] = useState<string>();

  const formik: FormikProps<IExpertProject> = useFormik<IExpertProject>({
    initialValues: project,
    onSubmit: async (values: IExpertProject, { setSubmitting }) => {
      try {
        await onUpdateProjects(values);
      } catch (error) {
        alert('Failed to update!!'); // FIX ME
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Form<IExpertProject>
        className="projects-form"
        formik={formik}
        hasSecondaryBtn={!isPlaceholder}
        onClickSecondaryBtn={() => setProjectIdToDelete(formik.values.id)}
        secondaryBtnLabel="Delete"
      >
        <FormControl>
          <Input
            label="Project Title"
            name="title"
            onChange={formik.handleChange}
            placeholder="i.e. Groundfloor.io"
            value={formik.values.title}
            required
          />
        </FormControl>
        <FormControl>
          <Input
            label="Description"
            name="description"
            inputType="text-area"
            onChange={formik.handleChange}
            placeholder="i.e. Description"
            value={formik.values.description}
            required
          />
        </FormControl>
        <FormControl>
          <Input
            label="Organization Name"
            name="orgName"
            onChange={formik.handleChange}
            placeholder="i.e. Expert Hire"
            value={formik.values.orgName}
          />
        </FormControl>
        <FormControl>
          <Dropdown<IExpertProjectSkillDDOption>
            allowMultiSelect
            defaultOptions={formik.values.skills.map((skill) => ({
              ...skill,
              id: skill.id,
              label: skill.name,
              value: skill.skillId,
            }))}
            label="We recommend adding your top 5 used in this role."
            onSelect={(options) => formik.setFieldValue('skills', options)}
            options={skills.map((skill) => ({
              id: crypto.randomUUID(),
              label: skill.name,
              name: skill.name,
              projectId: project.id,
              skillId: skill.id,
              value: skill.id,
            }))}
            placeholder="i.e. Search here"
          />
        </FormControl>
        <FormControl>
          <Input
            label="Paste or type a link"
            name="link"
            onChange={formik.handleChange}
            placeholder="i.e. http://github.com/expert-hire"
            value={formik.values.link}
            required
          />
        </FormControl>
      </Form>
      {projectIdToDelete && (
        <ConfirmationModal
          isShown
          isConfirming={formik.isSubmitting}
          title="Confirm Delete Project and Portfolio"
          onCancel={() => setProjectIdToDelete(undefined)}
          onConfirm={() => onDeleteProject(projectIdToDelete)}
        >
          Are you sure?
        </ConfirmationModal>
      )}
    </>
  );
}
