import { useEffect, useState } from 'react';

import { IExpertReviewCount } from 'common/models';
import { ExpertReviewServices } from 'expert/services';

export const useExpertReviewCount = (expertId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [expertReviewCount, setExpertReviewCount] =
    useState<IExpertReviewCount>({
      rate: 0,
      total: 0,
    });

  useEffect(() => {
    setIsLoading(true);
    ExpertReviewServices.getExpertReviewCount(expertId)
      .then(setExpertReviewCount)
      .catch((err) => {
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoading(false));
  }, [expertId]);

  return {
    expertReviewCount,
    isLoadingExpertReviewCount: isLoading,
  };
};
