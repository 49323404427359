import { useNavigate } from 'react-router-dom';

import { Button as MUIButton } from '@mui/material';
import { Box, Divider, Paper, Typography } from 'design-library-v2';

import { IUserEducation } from 'common/models';

const Education = ({
  education,
  readOnly = false,
}: {
  education: IUserEducation;
  readOnly?: boolean;
}) => {
  const navigate = useNavigate();

  const goToEducationEditPage = () =>
    navigate('/expert/profile-setup/education');

  return (
    <Paper flexDirectionColumn>
      <Box styles={{ justifyContent: 'space-between' }}>
        <Box flexDirectionColumn styles={{ gap: '8px' }}>
          <Typography variant="h6">{education.educationLevel}</Typography>
          <Typography>{education.degreeTitle}</Typography>
        </Box>
        <Box flexDirectionColumn styles={{ gap: '8px' }}>
          <Typography variant="h6">{education.instituteName}</Typography>
          <Typography>{education.group}</Typography>
        </Box>
      </Box>
      <Box>
        <Box flexDirectionColumn styles={{ gap: '8px' }}>
          <Typography variant="h6">Year of Passing</Typography>
          <Typography>{education.passingYear.toString()}</Typography>
        </Box>
      </Box>
      <Divider />
      {!readOnly && (
        <Box styles={{ justifyContent: 'flex-end' }}>
          <MUIButton onClick={goToEducationEditPage}>Edit</MUIButton>
        </Box>
      )}
    </Paper>
  );
};

export default Education;
