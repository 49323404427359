import { Avatar } from './Avatar';

export default function AvatarStory() {
  return (
    <>
      <div>
        <Avatar />
        <Avatar size="medium" />
        <Avatar size="small" />
      </div>
      <div>
        <Avatar imageUrl="http://localhost:3000/images/elon-mask.jpeg" />
        <Avatar
          imageUrl="http://localhost:3000/images/elon-mask.jpeg"
          size="medium"
        />
        <Avatar
          imageUrl="http://localhost:3000/images/elon-mask.jpeg"
          size="small"
        />
      </div>
      <div>
        <Avatar imageUrl="http://localhost:3000/images/Jason-Statham.jpeg" />
        <Avatar
          imageUrl="http://localhost:3000/images/Jason-Statham.jpeg"
          size="medium"
        />
        <Avatar
          imageUrl="http://localhost:3000/images/Jason-Statham.jpeg"
          size="small"
        />
      </div>
    </>
  );
}
