import { useEffect, useState } from 'react';

import { JobNatureEnum, JobTypeEnum } from 'common/enums';
import { usePagination } from 'common/hooks';
import { IJobPost, IPageResult } from 'common/models';

import { BulletinBoardServices } from 'expert/services';

export type JobPostSearchParams = {
  name?: string;
  jobType?: JobTypeEnum;
  jobNature?: JobNatureEnum;
  skills?: string;
};

export const useJobPostSearch = (initialSearchParams?: JobPostSearchParams) => {
  const { paginationModel, setPaginationModel } = usePagination({
    page: 0,
    pageSize: 9,
  });
  const [searchParams, setSearchParams] = useState<JobPostSearchParams>({
    ...initialSearchParams,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [jobResults, setJobResult] = useState<IPageResult<IJobPost>>({
    data: [],
    total: 0,
  });

  useEffect(() => {
    const { name, jobNature, jobType, skills } = searchParams;
    const params = new URLSearchParams({
      ...(name ? { name } : {}),
      ...(typeof jobNature === 'number'
        ? { jobNature: jobNature.toString() }
        : {}),
      ...(typeof jobType === 'number' ? { jobType: jobType.toString() } : {}),
      ...(skills ? { skills } : {}),
      page: (paginationModel.page + 1).toString(),
      pageSize: paginationModel.pageSize.toString(),
    });
    setIsLoading(true);
    BulletinBoardServices.getJobPosts(params.toString())
      .then((res) => setJobResult(res))
      .catch((err) => {
        alert('Field to load');
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoading(false));
  }, [paginationModel, searchParams]);

  return {
    isSearchingJobPosts: isLoading,
    jobResults,
    paginationModel,
    searchParams,
    setIsSearchingJobPosts: setIsLoading,
    setPaginationModel,
    setSearchParams,
  };
};
