import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Table, TableRowParams } from 'design-library-v2';
import { JobTypeEnum } from 'common/enums';
import { IJobPost } from 'common/models';

import { JobPostingServices } from 'hirer/services';
import { usePostedJobs } from 'hirer/hooks';
import { getCurrencyFormat } from 'common/utils';

export const PostedJobs = ({ hirerId }: { hirerId: string }) => {
  const navigate = useNavigate();

  const {
    isLoadingPostedJobs,
    paginationModel,
    postedJobsResult,
    setPaginationModel,
  } = usePostedJobs({ hirerId });

  const getJobBudget = (job: IJobPost) =>
    getCurrencyFormat(
      JobPostingServices.calculateJobPostBudget({
        end: job.endTime,
        hourlyRate: job.hourlyRate,
        hoursPerDay: job.hoursPerDay,
        start: job.startTime,
      })
    );

  const getFormattedRows = (jobs: Array<IJobPost>) =>
    jobs.map((job) => ({
      bidder: job.jobType === JobTypeEnum['PartTime'] ? job.bidCount : '-',
      budget: job.jobType === JobTypeEnum['PartTime'] ? getJobBudget(job) : '-',
      id: job.id,
      postedDate: job.createdAt
        ? moment(job.createdAt).format('DD/MM/YYYY')
        : '-',
      projectName: job.projectName,
    }));

  const routeToProjectDetails = (params: TableRowParams) =>
    navigate(
      `/hirer/manage-your-projects?activeTab=yet-to-start-projects&page=${paginationModel.page}&pageSize=${paginationModel.pageSize}&focusedJob=${params.id}`
    );

  return (
    <Table
      columns={[
        {
          field: 'projectName',
          headerName: 'Project Name',
          width: 310,
        },
        {
          field: 'postedDate',
          headerName: 'Posted Date',
          width: 210,
        },
        {
          field: 'budget',
          headerName: 'Budget',
          width: 210,
        },
        {
          field: 'bidder',
          headerName: 'Bidder',
          width: 210,
        },
      ]}
      loading={isLoadingPostedJobs}
      paginationMode="server"
      onRowClick={routeToProjectDetails}
      onPaginationModelChange={setPaginationModel}
      paginationModel={paginationModel}
      pageSizeOptions={[paginationModel.pageSize]}
      rowCount={postedJobsResult.total}
      rows={getFormattedRows(postedJobsResult.data)}
    />
  );
};
