import { useEffect, useState } from 'react';

import { usePagination } from 'common/hooks';
import { IJobPost, IPageResult } from 'common/models';
import { getHirerPostedJobs } from 'services';

export const useHirerPostedJobs = ({
  hirerId,
  page,
  pageSize,
}: {
  hirerId: string;
  page?: number;
  pageSize?: number;
}) => {
  const [isLoadingPostedJobs, setIsLoadingPostedJobs] = useState(true);
  const { paginationModel, setPaginationModel } = usePagination({
    page,
    pageSize,
  });
  const [postedJobsResult, setPostedJobsResult] = useState<
    IPageResult<IJobPost>
  >({
    data: [],
    total: 0,
  });

  useEffect(() => {
    setIsLoadingPostedJobs(true);
    const queryParams = new URLSearchParams({
      page: (paginationModel.page + 1).toString(),
      pageSize: paginationModel.pageSize.toString(),
    });

    getHirerPostedJobs(hirerId, queryParams.toString())
      .then((res) => setPostedJobsResult(res))
      .catch((err) => {
        alert('Failed to fetch posted data');
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoadingPostedJobs(false));
  }, [hirerId, paginationModel]);

  return {
    isLoadingPostedJobs,
    paginationModel,
    postedJobsResult,
    setPaginationModel,
  };
};
