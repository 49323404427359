import { useNavigate } from 'react-router-dom';

import { Box, Button, Spinner } from 'design-library-v2';

import { useExpertEducation } from 'expert/hooks';

import Education from './Education';

const EducationAndTraining = ({
  expertId,
  readOnly,
}: {
  expertId: string;
  readOnly: boolean;
}) => {
  const navigate = useNavigate();
  const { expertEducations, isExpertEducationLoading } =
    useExpertEducation(expertId);

  if (isExpertEducationLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <Box flexDirectionColumn>
      {expertEducations.map((education) => (
        <Education
          key={education.id}
          education={education}
          readOnly={readOnly}
        />
      ))}

      {!readOnly && (
        <Box styles={{ justifyContent: 'flex-end' }}>
          <Button onClick={() => navigate('/expert/profile-setup/education')}>
            + Add More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EducationAndTraining;
