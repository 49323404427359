import { useContext, useState } from 'react';
import { Button, Spinner } from 'design-library-v2';
import TrainingForm from './TrainingForm';
import { ExpertContext } from 'expert/context';
import { IUserTraining } from 'common/models';

import './Training.scss';

export const Training = () => {
  const {
    currentExpert,
    expertTrainings,
    isExpertContextLoading,
    onUpdateExpertTrainings,
    onDeleteExpertTraining,
  } = useContext(ExpertContext);

  const [showAddTrainingForm, setShowAddTrainingForm] =
	useState<boolean>(false);

  const onUpdateTrainings = async (updatedTraining: IUserTraining) => {
    const currentTrainings = [...expertTrainings];
    const trainingIndex = currentTrainings.findIndex(
      (training) => training.id === updatedTraining.id
    );
    if (trainingIndex === -1) {
      currentTrainings.push(updatedTraining);
    } else {
      currentTrainings[trainingIndex] = updatedTraining;
    }
    await onUpdateExpertTrainings(currentTrainings);
    setShowAddTrainingForm(false);
  };

  if (isExpertContextLoading) {
    return <Spinner type="contained" />;
  }

  return (
    <div className='trainings'>
      {expertTrainings.map((training) => (
        <TrainingForm
          key={training.id}
          training={training}
          onDeleteTraining={onDeleteExpertTraining}
          onUpdateTrainings={onUpdateTrainings}
        />
      ))}
      {showAddTrainingForm && currentExpert && (
        <TrainingForm
          isPlaceholder
          onUpdateTrainings={onUpdateTrainings}
          training={{
            certification: '',
            id: crypto.randomUUID(),
            institute: '',
            userId: currentExpert.id,
            validity: '',
          }}
        />
      )}
      <div className="trainings-addTraining">
        <Button
          buttonType="secondary"
          disabled={expertTrainings.length >= 3}
          onClick={() => setShowAddTrainingForm(true)}
        >
          Add Training
        </Button>
      </div>
    </div>
  );
};
