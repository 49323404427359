import { Outlet } from 'react-router-dom';
import { HirerProvider } from 'hirer/context';

export const HirerRoot = () => {
  return (
    <HirerProvider>
      <Outlet />
    </HirerProvider>
  );
};
