import Icon from './Icon';
import { IconName, svgIcons } from 'common/icons/svgs';

export default function IconStory() {
  return (
    <div
      style={{
        background: '#CDDCFC',
        borderRadius: '5px',
        padding: '10px 10px',
      }}
    >
      {Object.keys(svgIcons).map((iconName, index) => (
        <Icon key={index} name={iconName as IconName} />
      ))}
    </div>
  );
}
