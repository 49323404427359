import { Text } from 'design-library';
import { Button } from 'design-library-v2';

import './ErrorsPage.scss';

export const ErrorsPage = ({ statusCode = 404 }: { statusCode?: number }) => {
  return (
    <div
      className="errorsPage"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + '/images/error-page-background.png'
        })`,
      }}
    >
      <Text className="errorsPage-error" text="Error" />
      <Text className="errorsPage-code" text={statusCode.toString()} />
      <Text className="errorsPage-text" text="Oops! Seems like you are lost." />
      <Text className="errorsPage-text" text="Don’t panic, just " />
      <div className="errorsPage-btn">
        <Button text="Go Back" onClick={() => history.back()} />
      </div>
    </div>
  );
};
