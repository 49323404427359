import React from 'react';
import { Dropdown } from './Dropdown';

export default function DropdownStory() {
  return (
    <>
      <div>
        <Dropdown
          defaultOptions={[
            { id: crypto.randomUUID(), label: 'Option A', value: 'Option A' },
          ]}
          onSelect={() => ({})}
          options={[
            { id: crypto.randomUUID(), label: 'Option A', value: 'Option A' },
            { id: crypto.randomUUID(), label: 'Option B', value: 'Option B' },
            { id: crypto.randomUUID(), label: 'Option C', value: 'Option C' },
          ]}
        />
      </div>
      <div>
        <Dropdown
          label="Dropdown label"
          onSelect={() => ({})}
          options={[
            { id: crypto.randomUUID(), label: 'Option A', value: 'Option A' },
            { id: crypto.randomUUID(), label: 'Option B', value: 'Option B' },
            { id: crypto.randomUUID(), label: 'Option C', value: 'Option C' },
          ]}
        />
      </div>
      <div>
        <Dropdown
          disabled={true}
          label="Dropdown disabled"
          onSelect={() => ({})}
          options={[
            { id: crypto.randomUUID(), label: 'Option A', value: 'Option A' },
            { id: crypto.randomUUID(), label: 'Option B', value: 'Option B' },
            { id: crypto.randomUUID(), label: 'Option C', value: 'Option C' },
          ]}
        />
      </div>
      <div>
        <Dropdown
          allowMultiSelect
          defaultOptions={[
            { id: crypto.randomUUID(), label: 'Option A', value: 'Option A' },
          ]}
          label="Dropdown MultiSelect"
          onSelect={() => ({})}
          options={[
            { id: crypto.randomUUID(), label: 'Option A', value: 'Option A' },
            { id: crypto.randomUUID(), label: 'Option B', value: 'Option B' },
            { id: crypto.randomUUID(), label: 'Option C', value: 'Option C' },
          ]}
        />
      </div>
    </>
  );
}
