import { useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  ConfirmationModal,
  Divider,
  Modal,
  Paper,
  Typography,
} from 'design-library-v2';

import { BidStatusEnum, JobTypeEnum } from 'common/enums';
import { IBid, IJobPost } from 'common/models';
import { getCurrencyFormat, getDateFormat } from 'common/utils';

import { calculateJobPostBudget } from 'hirer/services/JobPostingServices';
import { useExpertTokens } from 'expert/hooks';
import { BuyTokenForm } from 'token/components';

import { SubTitleAndCaption } from './JobPostMetaInfo';

export const JobPosterAndBidCard = ({
  bid,
  jobPost,
  onWithdrawBid,
  onClickApplyOrBid,
}: {
  bid?: IBid;
  jobPost: IJobPost;
  onWithdrawBid: (bidId: string) => Promise<void>;
  onClickApplyOrBid?: () => void;
}) => {
  const { expertTokens, hasExpertSubscription } = useExpertTokens();
  const [
    showWithdrawBidConfirmationModal,
    setShowWithdrawBidConfirmationModal,
  ] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [showBuyTokenModal, setShowBuyTokenModal] = useState(false);

  const isBidPending = bid && bid.status === BidStatusEnum.Pending;
  const isFullTimeJobPost = jobPost.jobType === JobTypeEnum.FullTime;
  const tokenRequiredToBid = 2;
  const tokenRequiredToApply = 20;

  const handleWithdrawBid = async () => {
    if (!bid) {
      return;
    }
    setIsWithdrawing(true);
    try {
      await onWithdrawBid(bid.id);
    } catch (error) {
      alert('Failed bid withdraw');
      console.error(error); // FIX ME
    } finally {
      setIsWithdrawing(false);
      setShowWithdrawBidConfirmationModal(false);
    }
  };

  const getPartTimeJobBudget = () =>
    calculateJobPostBudget({
      end: jobPost.endTime,
      hourlyRate: jobPost.hourlyRate,
      hoursPerDay: jobPost.hoursPerDay,
      start: jobPost.startTime,
    });

  const renderPartTimeJobInfo = () => (
    <Box>
      <SubTitleAndCaption
        subTitle="Start Date"
        caption={getDateFormat(bid?.createdAt || jobPost.startTime)}
      />

      {isBidPending ? (
        <SubTitleAndCaption
          subTitle="Budget"
          caption={`${getCurrencyFormat(
            calculateJobPostBudget({
              end: jobPost.endTime,
              hourlyRate: jobPost.hourlyRate,
              hoursPerDay: jobPost.hoursPerDay,
              start: jobPost.startTime,
            })
          )}`}
        />
      ) : bid ? (
        <SubTitleAndCaption
          subTitle="Agreed Price"
          caption={`${getCurrencyFormat(bid.bidAmount)}`}
        />
      ) : jobPost.showBudget ? (
        <SubTitleAndCaption
          subTitle="Budget"
          caption={`${
            jobPost.showBudget
              ? getCurrencyFormat(getPartTimeJobBudget())
              : 'Not Disclosed'
          }`}
        />
      ) : null}
    </Box>
  );

  const renderPartTimeJobPreBidState = () =>
    !bid && expertTokens && !isFullTimeJobPost ? (
      <Box
        styles={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle1">{`${tokenRequiredToBid} token required`}</Typography>
        {expertTokens.totalToken < tokenRequiredToBid ? (
          <Button onClick={() => setShowBuyTokenModal(true)}>Buy Token</Button>
        ) : (
          <Button onClick={onClickApplyOrBid}>Bid</Button>
        )}
      </Box>
    ) : null;

  const renderPartTimeJobPostBidState = () =>
    isBidPending ? (
      <Box styles={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <SubTitleAndCaption
          subTitle="Your Bidding Amount"
          caption={`${getCurrencyFormat(bid.bidAmount)}`}
        />
        <Button
          size="small"
          onClick={() => setShowWithdrawBidConfirmationModal(true)}
        >
          Withdraw
        </Button>
      </Box>
    ) : null;

  const renderFullTimeJobPreApplyState = () =>
    expertTokens && isFullTimeJobPost ? (
      <Box styles={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="subtitle1">{`${tokenRequiredToApply} token required`}</Typography>
        {!hasExpertSubscription() &&
        expertTokens.totalToken < tokenRequiredToApply ? (
            <Button onClick={() => setShowBuyTokenModal(true)}>
              Buy Token / Become Pro
            </Button>
          ) : (
            <Button onClick={onClickApplyOrBid}>Apply</Button>
          )}
      </Box>
    ) : null;

  return (
    <Paper
      flexDirectionColumn
      styles={{ gap: '16px', height: 'fit-content', width: '100%' }}
    >
      <Avatar size="medium" imageUrl={jobPost.imageSrc} />
      <Typography variant="subtitle1">
        {bid?.bidInfo?.hirerName || jobPost.postedBy}
      </Typography>
      {isFullTimeJobPost ? (
        <Box>
          <SubTitleAndCaption
            subTitle="Posted Date"
            caption={getDateFormat(jobPost.createdAt)}
          />
        </Box>
      ) : (
        renderPartTimeJobInfo()
      )}
      <Divider />
      {renderPartTimeJobPreBidState()}
      {renderPartTimeJobPostBidState()}
      {renderFullTimeJobPreApplyState()}

      {showWithdrawBidConfirmationModal && (
        <ConfirmationModal
          isConfirming={isWithdrawing}
          isShown
          onCancel={() => setShowWithdrawBidConfirmationModal(false)}
          onConfirm={handleWithdrawBid}
          title="Confirm Withdraw Bid"
        >
          Are you sure?
        </ConfirmationModal>
      )}

      {showBuyTokenModal && (
        <Modal
          isShown
          onCancel={() => setShowBuyTokenModal(false)}
          size="medium"
        >
          <BuyTokenForm />
        </Modal>
      )}
    </Paper>
  );
};
