import React, { useState } from 'react';
import Label from 'design-library/label/Label';

import './CheckboxSelector.scss';

function CheckboxSelector({
  alignment = 'vertical',
  className = '',
  label,
  options,
  onSelectionChanged,
  singleSelect = false,
  type = 'checkbox',
}: {
  alignment?: 'horizontal' | 'vertical';
  className?: string;
  label?: string;
  options: string[];
  onSelectionChanged: (selectedOptions: string[]) => void;
  singleSelect?: boolean;
  type?: 'checkbox' | 'radio';
}) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value;
    if (event.target.checked) {
      singleSelect
        ? setSelectedOptions([selectedOption])
        : setSelectedOptions([...selectedOptions, selectedOption]);
    } else {
      singleSelect
        ? setSelectedOptions([])
        : setSelectedOptions(
          selectedOptions.filter((option) => option !== selectedOption)
        );
    }

    onSelectionChanged(selectedOptions);
  };

  return (
    <div className={`iqc-dl-checkboxSelector ${className}`}>
      {label && <Label labelText={label} />}
      <div className={`iqc-dl-checkboxSelector-labelContainer __${alignment}`}>
        {options.map((option) => (
          <label className="iqc-dl-checkboxSelector-label" key={option}>
            <input
              type={type}
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={handleOptionChange}
            />
            {option}
          </label>
        ))}
      </div>
    </div>
  );
}

export default CheckboxSelector;
