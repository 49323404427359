import { useContext, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';

import { IExpert, IExpertAddress } from 'common/models';
import { AppContext } from 'common/contexts';
import {
  AvatarUploader,
  Dropdown,
  Form,
  FormControl,
  IDropdownOptionBase,
  Input,
  Spinner,
} from 'design-library-v2';

import { ExpertContext } from 'expert/context';
import { updateExpertPersonalInfo } from 'expert/services';

import { UserLanguages } from './UserLanguages';

import './PersonalInfo.scss';

export type PersonalInfoFormData = Pick<
  IExpert,
  'addresses' | 'bio' | 'name' | 'displayName' | 'userLanguages' | 'imageSrc'
>;

interface AddressDropdownOption extends IDropdownOptionBase, IExpertAddress {}

export function PersonalInfo() {
  const { currentExpert, updateCurrentExpert } = useContext(ExpertContext);
  const { languages, loadCities, locations } = useContext(AppContext);

  useEffect(() => {
    if (
      currentExpert?.addresses.length &&
      currentExpert?.addresses[0].country
    ) {
      loadCities(currentExpert.addresses[0].country);
    }
  }, [currentExpert, loadCities]);

  const formik: FormikProps<PersonalInfoFormData> =
    useFormik<PersonalInfoFormData>({
      initialValues: {
        addresses: currentExpert?.addresses || [],
        bio: currentExpert?.bio || '',
        displayName: currentExpert?.displayName || '',
        imageSrc: currentExpert?.imageSrc,
        name: currentExpert?.name || '',
        userLanguages: currentExpert?.userLanguages || [],
      },
      onSubmit: async (values: PersonalInfoFormData, { setSubmitting }) => {
        if (!currentExpert) {
          return;
        }
        try {
          const updatedExpert = await updateExpertPersonalInfo(
            currentExpert?.id,
            {
              ...currentExpert,
              ...values,
            }
          );
          updateCurrentExpert(updatedExpert);
        } catch (error) {
          alert('Failed to update!!'); // TO DO
        } finally {
          setSubmitting(false);
        }
      },
    });

  const getCities = () => {
    if (!formik.values.addresses.length) {
      return [];
    }
    return (
      locations.find(
        (location) => location.name === formik.values.addresses[0].country
      )?.cities || []
    ).map((city) => ({ id: crypto.randomUUID(), label: city, value: city }));
  };

  if (!currentExpert) {
    return <Spinner type="contained" />;
  }

  return (
    <Form<PersonalInfoFormData> formik={formik}>
      <FormControl className="personal-info-avatarControl">
        <AvatarUploader
          avatarURL={formik.values.imageSrc}
          onUpload={(imageUrl) => formik.setFieldValue('imageSrc', imageUrl)}
          refId={currentExpert.id}
        />
      </FormControl>
      <FormControl>
        <Input
          label="Name"
          name="name"
          onChange={formik.handleChange}
          placeholder="i.e. Faizul Islam"
          required
          value={formik.values.name}
        />
      </FormControl>
      <FormControl>
        <Input
          label="Display name"
          name="displayName"
          onChange={formik.handleChange}
          placeholder="i.e. faz"
          required
          value={formik.values.displayName}
        />
      </FormControl>
      <FormControl label="Where are you based?">
        <Dropdown<AddressDropdownOption>
          defaultOptions={formik.values.addresses.map((address) => ({
            city: address.city,
            country: address.country,
            id: address.id,
            isCurrent: address.isCurrent,
            label: address.country,
            userId: address.userId,
            value: address.country,
          }))}
          label="Country"
          options={locations.map((location) => ({
            city: '',
            country: location.name,
            id: crypto.randomUUID(),
            isCurrent: false,
            label: location.name,
            userId: currentExpert.id,
            value: location.name,
          }))}
          onSelect={(options) => {
            formik.setFieldValue(
              'addresses',
              options.map((option) => ({
                city: option.city,
                country: option.value,
                id: option.id,
                isCurrent: true,
                userId: option.userId,
              }))
            );
            options.length && loadCities(options[0].value);
          }}
          required
        />
        <Dropdown
          label="City"
          defaultOptions={formik.values.addresses.map((address) => ({
            id: crypto.randomUUID(),
            label: address.city,
            value: address.city,
          }))}
          disabled={!formik.values?.addresses[0]?.country}
          options={getCities()}
          onSelect={(options) => {
            formik.setFieldValue('addresses', [
              {
                ...formik.values.addresses[0],
                city: options[0]?.value || '',
              },
            ]);
          }}
          required
        />
      </FormControl>
      <UserLanguages
        formik={formik}
        languageList={languages}
        userLanguages={formik.values.userLanguages}
        userId={currentExpert.id}
      />
      <FormControl>
        <Input
          inputType="text-area"
          label="Bio"
          name="bio"
          onChange={formik.handleChange}
          placeholder="Write your bio"
          required
          value={formik.values.bio}
        />
      </FormControl>
    </Form>
  );
}
