import moment from 'moment';
import { useState } from 'react';

import { Modal, Skeleton, Table } from 'design-library-v2';

import { IBid } from 'common/models';
import { getCurrencyFormat } from 'common/utils';


import { PostedJobDetailsCard } from 'job-post/components';
import { useJobPost } from 'job-post/hooks';
import { useExpertBidJobs } from '../hooks';

const BidDetailsCard = ({
  bid,
  onCloseBidDetails,
}: {
  bid: IBid;
  onCloseBidDetails: () => void;
}) => {
  const { jobPost } = useJobPost(bid.jobId);

  if (!jobPost) {
    return <Skeleton />;
  }

  return (
    <PostedJobDetailsCard
      jobBidInfo={bid}
      jobPost={jobPost}
      onCloseBidDetails={onCloseBidDetails}
      role="expert"
      showMetaInfo
    />
  );
};

export const ExpertBidJobs = ({ expertId }: { expertId: string }) => {
  const { isLoading, bidProjectsResult, paginationModel, setPaginationModel } =
    useExpertBidJobs({ expertId });

  const [selectedBid, setSelectedBid] = useState<IBid>();

  const getFormattedRows = (bidedJobs: Array<IBid>) =>
    bidedJobs.map((bid) => ({
      applied: bid.bidAmount === 0 ? 'Applied' : '-',
      biddingAmount:
        bid.bidAmount === 0 ? '-' : getCurrencyFormat(bid.bidAmount),
      biddingDate: moment(bid.createdAt).format('DD/MM/YYYY'),
      hirerName: bid.bidInfo?.hirerName || '-',
      id: bid.id,
      projectData: bid,
      projectName: bid.projectName,
      projectPostDate: bid.projectPostDate
        ? moment(bid.projectPostDate).format('DD/MM/YYYY')
        : '-',
    }));

  return (
    <>
      <Table
        columns={[
          {
            field: 'projectName',
            headerName: 'Project Name',
            minWidth: 310,
          },
          {
            field: 'hirerName',
            headerName: 'Hirer Name',
            minWidth: 210,
          },
          {
            field: 'projectPostDate',
            headerName: 'Project Post Date',
            minWidth: 210,
          },
          {
            field: 'biddingDate',
            headerName: 'Bidding Date',
            minWidth: 210,
          },
          {
            field: 'biddingAmount',
            headerName: 'Bidding Amount',
            minWidth: 210,
          },
          {
            field: 'applied',
            headerName: 'Applied',
            minWidth: 210,
          },
        ]}
        loading={isLoading}
        paginationMode="server"
        onRowClick={(params) => setSelectedBid(params.row.projectData)}
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        rowCount={bidProjectsResult.total}
        rows={getFormattedRows(bidProjectsResult.data)}
      />
      {selectedBid && (
        <Modal isShown onCancel={() => setSelectedBid(undefined)}>
          <BidDetailsCard
            bid={selectedBid}
            onCloseBidDetails={() => setSelectedBid(undefined)}
          />
        </Modal>
      )}
    </>
  );
};
