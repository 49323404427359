import { AxiosError } from 'axios';
import { useState } from 'react';

import { BidStatusEnum } from 'common/enums';

import { applyFullTimeJob } from 'services';

export const useFullTimeJobApplication = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onApplyFullTimeJob = async (jobId: string, expertId: string) => {
    setIsLoading(true);
    try {
      await applyFullTimeJob({
        agreeTermsAndCondition: true,
        bidAmount: 0,
        expertId,
        id: crypto.randomUUID(),
        jobId,
        projectName: '',
        status: BidStatusEnum.Draft,
      });
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        alert('Already applied');
        return;
      }
      alert('Failed to apply');
      console.error(error); // TO DO use sentry
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isApplying: isLoading,
    onApplyFullTimeJob,
  };
};
