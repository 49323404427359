import { Link } from 'react-router-dom';

import { Caret, Icon } from 'design-library-v2';
import { IconName } from 'common/icons';

import './NavMenu.scss';

export function NavMenu({
  caret = 0,
  icon,
  menu,
  url,
}: {
  caret?: number;
  icon: IconName;
  menu: string;
  url: string;
}) {
  return (
    <Link className="navMenu" to={url}>
      {caret >= 1 && (
        <Caret appearance="danger" className="navMenu-caret" value={caret} />
      )}
      <Icon name={icon} />
      <span>{menu}</span>
    </Link>
  );
}
