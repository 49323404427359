import React from 'react';
import { Avatar as MUIAvatar } from '@mui/material';

export const Avatar = ({
  className = '',
  imageUrl,
  alt = 'Avatar',
  size = 'large',
  ...props
}: {
  className?: string;
  imageUrl?: string;
  alt?: string;
  size?: 'large' | 'medium' | 'small';
} & React.ComponentProps<typeof MUIAvatar>) => {
  const dimension =
    size === 'large'
      ? {
        height: '112px',
        width: '112px',
      }
      : size === 'medium'
        ? {
          height: '72px',
          width: '72px',
        }
        : {
          height: '32px',
          width: '32px',
        };
  return (
    <MUIAvatar
      alt={alt}
      className={className}
      src={imageUrl}
      sx={{
        ...dimension,
      }}
      {...props}
    />
  );
};
