import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, List, ListItemButton, ListItemText } from '@mui/material'; // TO DO replace with DL

import { Avatar, Box } from 'design-library-v2';

import { AppContext, AuthContext } from 'common/contexts';
import { AssetServices } from 'services';

export function NavProfileButton() {
  const { currentUserRole } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { signoutRedirectCallback } = useContext(AuthContext);
  const navigator = useNavigate();

  const handleProfileAvatarClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setAnchorEl(null);

    return currentUserRole === 'expert'
      ? navigator('/expert/profile')
      : navigator('/hirer/profile-setup');
  };

  return (
    <Box styles={{ alignItems: 'center', justifyContent: 'center' }}>
      <button onClick={handleProfileAvatarClick} className="nav-profile-avatar">
        <Avatar imageUrl={AssetServices.getProfilePictureURL()} size="small" />
      </button>
      <Popover
        id="nav-avaterPopover-id"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={{ left: -45, top: 40, width: 200, zIndex: 10 }}
        PaperProps={{ style: { width: 150 } }}
      >
        <List component="nav" aria-label="profile and logout pop over">
          <ListItemButton selected onClick={handleProfileClick}>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              signoutRedirectCallback();
            }}
          >
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Popover>
    </Box>
  );
}
