import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from 'design-library-v2';

export const StripeConnectReturn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/expert/earning');
    }, 3000);
  }, [navigate]);

  return (
    <Box
      flexDirectionColumn
      styles={{
        alignContent: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h4">Stripe Connected!!</Typography>
      <Typography variant="subtitle1">
        Redirecting to earning dashboard...
      </Typography>
    </Box>
  );
};
