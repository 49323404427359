import { useEffect, useState } from 'react';

import { HirerProfileServices } from 'hirer/services';

export const useHirerInsight = (hirerId: string) => {
  const [hirerInsight, setHirerInsight] = useState<number>(0);

  useEffect(() => {
    HirerProfileServices.getHirerInsight(hirerId)
      .then(setHirerInsight)
      .catch(console.error);
  }, [hirerId]);

  return {
    hirerInsight,
  };
};
