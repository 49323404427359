import { ReactNode } from 'react';

import { Box } from '../layout';
import { Typography } from '../text';

export default function FormControl({
  className = '',
  containerClassName = '',
  children,
  label,
}: {
  className?: string;
  containerClassName?: string;
  children: ReactNode;
  label?: string;
}) {
  return (
    <Box className={containerClassName} flexDirectionColumn>
      {label && <Typography variant="subtitle2">{label}</Typography>}
      <Box className={className}>{children}</Box>
    </Box>
  );
}
