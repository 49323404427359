import './Label.scss';

export default function Label({
  className = '',
  disabled = false,
  labelText,
  required = false,
  size = 'regular',
}: {
  className?: string;
  disabled?: boolean;
  labelText: string;
  required?: boolean;
  size?: 'regular' | 'medium' | 'large';
}) {
  return (
    <label
      className={`iqcDL-label ${size} ${
        disabled ? 'disabled' : ''
      } ${className}`}
    >
      {`${labelText}${required ? '*' : ''}`}
    </label>
  );
}
