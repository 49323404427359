import moment, { Moment } from 'moment';

export const formatDateTime = (dateTime: string): string => {
  const dateTimeObj = new Date(dateTime);
  const today: Moment = moment().startOf('day');
  const formattedDateTime = moment(dateTimeObj).format(
    moment(dateTimeObj).isSameOrAfter(today) ? 'h:mm a' : 'MMM D, h:mm a'
  );
  return formattedDateTime;
};
