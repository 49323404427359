import moment from 'moment';
import { useContext, useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import {
  CheckboxSelector,
  DatePicker,
  Dropdown,
  Form,
  FormControl,
  IDropdownOptionBase,
  Input,
  ConfirmationModal,
} from 'design-library-v2';
import { AppContext } from 'common/contexts';
import { IAreaOfExperience, IExperience } from 'common/models';

interface IAreaOfExperienceDDOption
  extends IDropdownOptionBase,
    IAreaOfExperience {}

export function ExperienceForm({
  isPlaceholder = false,
  experience,
  onDeleteExperience,
  onUpdateExperiences,
}: {
  isPlaceholder?: boolean;
  experience: IExperience;
  onDeleteExperience?: (experienceId: string) => Promise<void>;
  onUpdateExperiences: (updatedExperience: IExperience) => Promise<void>;
}) {
  const { jobs } = useContext(AppContext);
  const [experienceIdDelete, setExperienceIdDelete] = useState<string>();

  const formik: FormikProps<IExperience> = useFormik<IExperience>({
    initialValues: experience,
    onSubmit: async (values: IExperience, { setSubmitting }) => {
      try {
        await onUpdateExperiences(values);
      } catch (error) {
        alert('Failed to update!!'); // TO DO
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onDelete = async () => {
    if (!onDeleteExperience || !experienceIdDelete) {
      return;
    }

    try {
      formik.setSubmitting(true);
      await onDeleteExperience(experienceIdDelete);
    } catch (error) {
      alert(`Failed to delete project ${experience.designation}`); // TO DO alert handle
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <>
      <Form<IExperience>
        className="experiences-form"
        formik={formik}
        hasDelete={!isPlaceholder}
        onDelete={() => setExperienceIdDelete(formik.values.id)}
      >
        <FormControl>
          <Input
            label="Company Name"
            name="companyName"
            onChange={formik.handleChange}
            placeholder="i.e. Codeman Solutions Inc"
            required
            value={formik.values.companyName}
          />
          <Input
            label="Company Business"
            name="companyBusiness"
            onChange={formik.handleChange}
            placeholder="i.e. Build Custom Solutions"
            required
            value={formik.values.companyBusiness}
          />
        </FormControl>
        <FormControl>
          <Input
            label="Designation"
            name="designation"
            onChange={formik.handleChange}
            placeholder="i.e. Software QA Engineer"
            required
            value={formik.values.designation}
          />
          <Input
            label="Department"
            name="department"
            onChange={formik.handleChange}
            placeholder="i.e. QA & Support"
            required
            value={formik.values.department}
          />
        </FormControl>
        <FormControl label="Employment Period">
          <DatePicker
            name="startTime"
            onChange={formik.handleChange}
            value={moment(formik.values.startTime).format('YYYY-MM-DD')}
          />
          <DatePicker
            disabled={Boolean(formik.values.isCurrentCompany)}
            name="endTime"
            onChange={formik.handleChange}
            value={moment(
              formik.values.isCurrentCompany ? '' : formik.values.endTime
            ).format('YYYY-MM-DD')}
          />
        </FormControl>
        <FormControl>
          <CheckboxSelector
            defaultOptions={[formik.values.isCurrentCompany ? 0 : -1]}
            onSelectionChanged={(options) =>
              formik.setFieldValue('isCurrentCompany', options.length)
            }
            options={['Currently working']}
            singleSelect
          />
        </FormControl>
        <FormControl>
          <Dropdown<IAreaOfExperienceDDOption>
            allowMultiSelect
            label="Area of Expertise"
            defaultOptions={formik.values.areaOfExperiences.map((exp) => ({
              ...exp,
              label: exp.name,
              value: exp.areaId,
            }))}
            options={jobs.map((job) => ({
              areaId: job.id,
              experienceId: experience.id,
              id: crypto.randomUUID(),
              label: job.name,
              name: job.name,
              value: job.id,
            }))}
            onSelect={(options) =>
              formik.setFieldValue('areaOfExperiences', options)
            }
            placeholder="i.e. Search here"
          />
        </FormControl>
        <FormControl>
          <Input
            label="Responsibilities"
            inputType="text-area"
            name="responsibilites"
            onChange={formik.handleChange}
            required
            value={formik.values.responsibilites}
          />
        </FormControl>
      </Form>
      {experienceIdDelete && (
        <ConfirmationModal
          isShown
          isConfirming={formik.isSubmitting}
          title="Confirm Delete Experience"
          onCancel={() => setExperienceIdDelete(undefined)}
          onConfirm={onDelete}
        >
        Are you sure?
        </ConfirmationModal>
      )}
    </>
  );
}
