import { useContext } from 'react';

import { PageWrapper, Spinner } from 'design-library-v2';

import { ExpertContext } from 'expert/context';
import {
  ExpertEaringTable,
  ExpertStripeConnection,
} from 'expert/earning/components';

export const ExpertEarningPage = () => {
  const { currentExpert } = useContext(ExpertContext);

  if (!currentExpert) {
    return <Spinner type="page" />;
  }

  return (
    <PageWrapper>
      <ExpertStripeConnection />
      <ExpertEaringTable expertId={currentExpert.id} />
    </PageWrapper>
  );
};
