import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import { BASE_BLUE } from 'common/constants/colors';

type StyledRatingProps = {
  fillcolor?: string;
  hovercolor?: string;
};

const StyledRating = styled(Rating)<StyledRatingProps>(
  ({ fillcolor = BASE_BLUE, hovercolor = BASE_BLUE }) => ({
    '& .MuiRating-iconFilled': {
      color: fillcolor,
    },
    '& .MuiRating-iconHover': {
      color: hovercolor
    },
  })
);

export default StyledRating;
