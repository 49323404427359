import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
        paddingX: '8px',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          sx={{
            borderRadius: '5px',
            height: '12px',
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function ProgressBar({ progress }: { progress: number }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
