import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button, Icon, Modal } from 'design-library-v2';
import { IconName } from 'common/icons';

import { ProPlanDetails } from 'expert/components';

export function ProfileSetupNavbar({ isProUser }: { isProUser?: boolean }) {
  const location = useLocation();
  const [showProPlanModal, setShowProPlanModal] = useState(false);

  const isActiveRoute = (routeName: string, routeIndex: number) => {
    const urlSegments = location.pathname.split('/');
    if (urlSegments.length === 3 && routeIndex === 0) {
      return true;
    }
    return urlSegments.includes(routeName);
  };

  const formatRouteName = (route: string) =>
    route
      .split('-')
      .map((r) => r[0].toUpperCase() + r.slice(1))
      .join(' ');

  const getProfileSetupRoutes = (): Array<{
    icon: IconName;
    name: string;
  }> => [
    { icon: 'person', name: 'personal-info' },
    { icon: 'skills', name: 'skills' },
    { icon: 'profiles', name: 'social-profile' },
    { icon: 'preferences', name: 'preferences' },
    { icon: 'portfolio', name: 'project-and-portfolio' },
    { icon: 'experience', name: 'experience' },
    { icon: 'education', name: 'education' },
    { icon: 'education', name: 'training' },
    { icon: 'resume', name: 'resume' },
  ];

  return (
    <div className="profile-setup-navbar">
      <ul>
        {getProfileSetupRoutes().map((route, index) => (
          <li
            key={index}
            className={isActiveRoute(route.name, index) ? 'isActive' : ''}
          >
            <Link
              to={index === 0 ? '' : route.name}
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <Icon name={route.icon as IconName} />
              {formatRouteName(route.name)}
              {route.name === 'resume' && !isProUser && (
                <Button
                  buttonType="secondary"
                  onClick={() => setShowProPlanModal(true)}
                  size="small"
                >
                  Pro
                </Button>
              )}
            </Link>
          </li>
        ))}
      </ul>
      {showProPlanModal && (
        <Modal isShown onCancel={() => setShowProPlanModal(false)} size="large">
          <ProPlanDetails />
        </Modal>
      )}
    </div>
  );
}
