import { useCallback, useEffect, useState } from 'react';

import { IFile } from 'common/models';
import { FileTypeEnum } from 'common/enums';

import { AssetServices } from 'services';

export const useJobPostFiles = (jobId?: string | null) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [isUploadingJobFiles, setIsUploadingJobFiles] = useState(false);

  const updateFileList = useCallback(async (jobId: string) => {
    setFiles(
      await AssetServices.getFilesByReference(jobId, FileTypeEnum.PostJobFile)
    );
  }, []);

  useEffect(() => {
    if (!jobId) {
      return;
    }
    updateFileList(jobId);
  }, [jobId, updateFileList]);

  const onUploadJobFiles = async (jobId: string, files: File[]) => {
    setIsUploadingJobFiles(true);
    try {
      await AssetServices.uploadJobFiles(jobId, files);
      await updateFileList(jobId);
    } catch (err) {
      console.error(err); // TO DO Use sentry
    } finally {
      setIsUploadingJobFiles(false);
    }
  };

  const onRemoveJobFile = async (jobId: string, fileId: string) => {
    try {
      await AssetServices.removeFile(fileId);
      await updateFileList(jobId);
    } catch (err) {
      console.error(err); // TO DO Use sentry
    }
  };

  return {
    isUploadingJobFiles,
    jobFiles: files,
    onRemoveJobFile,
    onUploadJobFiles,
  };
};
