import { useContext, useEffect } from 'react';
import { AuthContext } from 'common/contexts';
import LoadingState from './LoadingState';

const SigninRedirectCallback = () => {
  const { signinRedirectCallback } = useContext(AuthContext);

  useEffect(() => signinRedirectCallback(), [signinRedirectCallback]);

  return <LoadingState />;
};

export default SigninRedirectCallback;
