import { useEffect, useState } from 'react';

import { usePagination } from 'common/hooks';
import { IBid, IPageResult } from 'common/models';

import { getExpertBidProjects } from 'services';

export const useExpertBidJobs = ({ expertId }: { expertId: string }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { paginationModel, setPaginationModel } = usePagination();

  const [bidProjectsResult, setBidProjectsResult] = useState<IPageResult<IBid>>(
    {
      data: [],
      total: 0,
    }
  );

  useEffect(() => {
    setIsLoading(true);
    const params = new URLSearchParams({
      page: (paginationModel.page + 1).toString(),
      pageSize: paginationModel.pageSize.toString(),
    });
    getExpertBidProjects(expertId, params.toString())
      .then((res) => setBidProjectsResult(res))
      .catch((err) => {
        alert('Failed to fetch posted data');
        console.error(err); // TO DO use sentry
      })
      .finally(() => setIsLoading(false));
  }, [expertId, paginationModel.page, paginationModel.pageSize]);

  return {
    bidProjectsResult,
    isLoading,
    paginationModel,
    setPaginationModel,
  };
};
