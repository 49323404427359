import { Button } from '@mui/material';

import { Typography } from 'design-library-v2';
import { usePagination } from 'common/hooks';

import './ResultPagePaginationFooter.scss';

type UsePaginationReturnType = ReturnType<typeof usePagination>;

export const ResultPagePaginationFooter = ({
  count,
  paginationModel,
  setPaginationModel,
  total,
}: {
  count: number;
  paginationModel: UsePaginationReturnType['paginationModel'];
  setPaginationModel: UsePaginationReturnType['setPaginationModel'];
  total: number;
}) => {
  return (
    <div className="resultPagePaginationFooter">
      <Typography noWrap variant="caption">
        {`Showing ${
          paginationModel.page * paginationModel.pageSize + (count ? 1 : 0)
        }-${
          count + paginationModel.page * paginationModel.pageSize
        } of ${total} results`}
      </Typography>
      <Button
        disabled={paginationModel.page === 0}
        onClick={() =>
          setPaginationModel((paginationModel) => ({
            page: paginationModel.page - 1,
            pageSize: paginationModel.pageSize,
          }))
        }
        variant="contained"
      >
        Previous
      </Button>
      <Button
        disabled={
          (paginationModel.page + 1) * paginationModel.pageSize >= total
        }
        onClick={() =>
          setPaginationModel((paginationModel) => ({
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
          }))
        }
        variant="contained"
      >
        Next
      </Button>
    </div>
  );
};
