import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ExpertContext, ExpertProvider } from 'expert/context';
import { Spinner } from 'design-library-v2';

const ExpertOutlet = () => {
  const { currentExpert } = useContext(ExpertContext);
  const location = useLocation();

  if (!currentExpert) {
    return <Spinner type="page" />;
  }

  const expertProfileSetUpPath = '/expert/profile-setup';
  if (
    !currentExpert.isOnBoardingComplete &&
    !location.pathname.includes(expertProfileSetUpPath)
  ) {
    return <Navigate to={expertProfileSetUpPath} replace />;
  }

  return <Outlet />;
};

export const ExpertRoot = () => {
  return (
    <ExpertProvider>
      <ExpertOutlet />
    </ExpertProvider>
  );
};
