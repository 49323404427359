import { Axios } from 'common/services';
import { IUserTraining as IExpertTraining } from 'common/models';

const controllerName = 'expertTraining';

export const getExpertTrainings = async (
  expertId: string
): Promise<Array<IExpertTraining>> =>
  await (
    await Axios.get(`/${controllerName}/${expertId}/trainings`)
  ).data;

export const updateExpertTrainings = async (
  expertId: string,
  payload: Array<IExpertTraining>
): Promise<Array<IExpertTraining>> =>
  await (
    await Axios.put(`/${controllerName}/${expertId}/trainings`, payload)
  ).data;

export const deleteExpertTraining= async (trainingId: string) =>
  await Axios.delete(`/${controllerName}/${trainingId}`);
