import Slider from '@mui/material/Slider';

import { BASE_BLUE } from 'common/constants/colors';

const valuetext = (value: number): string => `value is ${value}`;

const DistanceSlider = ({
  className='',
  onSliderUpdate,
  minDistance=10,
  min=0,
  max=1000,
  value,
}: {
  onSliderUpdate: (data: [number, number]) => void;
  value: [number, number];
  className?: string;
  minDistance?: number;
  min?: number;
  max?: number;
}) => {

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    event.preventDefault();

    if (!Array.isArray(newValue)) {
      return;
    }

    const sliderInterval: [number, number] = [newValue[0], newValue[1]];

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], max - minDistance);
        sliderInterval[0] = clamped;
        sliderInterval[1] = clamped + minDistance;
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        sliderInterval[0] = clamped - minDistance;
        sliderInterval[1] = clamped;
      }
    }

    onSliderUpdate(sliderInterval);
  };

  return (
    <Slider
      className={className}
      getAriaLabel={() => 'Minimum distance shift'}
      value={value}
      style={{ color: BASE_BLUE }}
      onChange={handleChange}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      disableSwap
      min={min}
      max={max}
    />
  );
};

export default DistanceSlider;
