import { Button, Icon, Text } from 'design-library';
import { faq } from '../constants';
import { CircleIFillcon } from 'common/images';

import './FaqNav.scss';

const FaqNav = ({
  currentQuestionId,
  onChangecurrentQuestionId,
} : {
  currentQuestionId: number;
  onChangecurrentQuestionId: (currentQuestionId: number) => void;
}) => {
  return (
    <div className='faqNav'>
      {
        faq.map( item => (
          <Button
            key={item.id}
            className={`faqNav-option ${
              currentQuestionId === item.id ? '__isCurrent' : ''
            }`}
            onClick={() => onChangecurrentQuestionId(item.id)}
          >
            <Icon icon={CircleIFillcon} className={`faqNav-optionIcon ${
              currentQuestionId === item.id ? '__isCurrentIcon' : 'icon'
            }`} />
            <Text className={`faqNav-optionText ${
              currentQuestionId === item.id ? '__isCurrentText' : 'text'
            }`} text={item.question} />
          </Button>
        ))
      }
    </div>
  );
};

export default FaqNav;
