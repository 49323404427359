import { Axios } from './axios';
import { IJob, ILanguage, ILocation, ISkill } from 'common/models';

export const getSkills = async (): Promise<Array<ISkill>> =>
  // TO DO Replace with react query
  await (
    await Axios.get('/common/skills')
  ).data;

export const getLanguages = async (): Promise<Array<ILanguage>> =>
  await (
    await Axios.get('/common/languages')
  ).data;

export const getJobNames = async (): Promise<Array<IJob>> =>
  await (
    await Axios.get('/common/jobs')
  ).data;

export const getLocations = async (): Promise<Array<ILocation>> =>
  await await (
    await Axios.get('/common/location')
  ).data;

export const getCities = async (location: string): Promise<Array<string>> =>
  await (
    await Axios.get('/common/location/cities', {
      params: { country: location },
    })
  ).data;
