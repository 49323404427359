import React, { useState } from 'react';
import { Modal } from '@mui/material';

import { Button, CheckboxSelector, Input } from 'design-library-v2';

import ConfirmModal from 'messaging/components/message-box/conversation/confirm-modal/ConfirmModal';


import './ReportModal.scss';

const blameOptions: string[] = [
  'Unwanted Commercial content or spam',
  'Hate speech or graphic violence',
  'Harassment',
  'Rude or abusive behavior',
  'Fraud or Scam',
  'It’s inappropriate',
  'Something Else'
];

const ReportModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirmClick = () => {
    setIsConfirmed(true);
    handleClose();
  };

  const handleConfirmedPopUp = () => {
    setIsConfirmed(false);
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="block-conversation-modal-title"
        aria-describedby="block-conversation-modal-description"
      >
        <div className='reportModal'>
          <CheckboxSelector
            className='reportModal-blame'
            label="Why are you reporting this user"
            options={blameOptions}
            onSelectionChanged={() => ({})}
            type="radio"
          />
          <div className='reportModal-issueInput'>
            <Input
              inputType="text-area"
              placeholder="Write Here"
              label='Write your issue'
            />
          </div>
          {/* TODO: add drop and drag in report section */}
          <Button
            onClick={handleConfirmClick}
            className='reportModal-btn'
            text='Generate Report' buttonType='secondary' size='medium'/>
        </div>
      </Modal>

      <ConfirmModal
        open={isConfirmed}
        text='We received your report'
        handleClose={handleConfirmedPopUp}
        description='Thank your for helping to keep our community safe. You will receive  a notification when the review is complete.'
      />
    </React.Fragment>
  );
};

export default ReportModal;
