import { ReactNode } from 'react';
import { FormControlLabel } from '@mui/material';

import { Checkbox, MUICheckboxProps } from './Checkbox';

type CheckboxOptionProps = MUICheckboxProps & {
  labelComponent: ReactNode;
};

export const CheckboxOption = (checkboxOptionProps: CheckboxOptionProps) => {
  const { labelComponent } = checkboxOptionProps;
  return (
    <FormControlLabel
      control={<Checkbox {...checkboxOptionProps} />}
      label={labelComponent}
    />
  );
};
