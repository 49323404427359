import { ReactNode } from 'react';

import { Modal } from './Modal';
import Button from '../button/Button';

import './ConfirmationModal.scss';

export function ConfirmationModal({
  children,
  isConfirming = false,
  isShown,
  onCancel,
  onConfirm,
  title,
}: {
  children: ReactNode;
  isConfirming?: boolean;
  isShown: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
}) {
  return (
    <Modal
      isShown={isShown}
      hideCloseBtn={true}
      size="small"
      onCancel={onCancel}
    >
      <div className="iqcDL-confirmationModal">
        <div className="iqcDL-confirmationModal-header">
          <h2>{title}</h2>
        </div>
        {children}
        <div className="iqcDL-confirmationModal-footer">
          <Button
            buttonType="secondary"
            disabled={isConfirming}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button disabled={isConfirming} onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}
