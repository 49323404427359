import { useContext } from 'react';
import { FormikProps } from 'formik';

import {
  Box,
  CheckboxSelector,
  Dropdown,
  FormControl,
  Typography,
  getDDOption,
} from 'design-library-v2';

import { IJobPost } from 'common/models';
import { AppContext } from 'common/contexts';
import { JobNatureEnum, JobTypeEnum, getEnumKeys } from 'common/enums';

export const TypeOfJobSection = ({
  formik,
  updateStepsByJobType,
}: {
  formik: FormikProps<IJobPost>;
  updateStepsByJobType: (jobType: JobTypeEnum) => void;
}) => {
  const { locations, loadCities, getCitiesByCountry } = useContext(AppContext);

  return (
    <Box flexDirectionColumn>
      <Typography variant="subtitle2">
        Type of job offer you want to post
      </Typography>
      <CheckboxSelector
        label="Job Type"
        defaultOptions={[formik.values.jobType]}
        onSelectionChanged={(options) => {
          formik.setFieldValue('jobType', options[0]);
          updateStepsByJobType(options[0]);
        }}
        options={getEnumKeys(JobTypeEnum)}
        singleSelect
        type="radio"
        required
      />
      <CheckboxSelector
        label="Job Nature"
        defaultOptions={[formik.values.jobNature]}
        onSelectionChanged={(options) =>
          formik.setFieldValue('jobNature', options[0])
        }
        options={getEnumKeys(JobNatureEnum)}
        singleSelect
        type="radio"
        required
      />
      {formik.values.jobNature === JobNatureEnum['Onsite'] && (
        <FormControl label="Where are you based ?">
          <Dropdown
            defaultOptions={
              formik.values.country
                ? [getDDOption({ label: formik.values.country })]
                : []
            }
            label="Country"
            options={locations.map((location) =>
              getDDOption({ label: location.name })
            )}
            onSelect={(options) => {
              if (!options.length) {
                return;
              }
              formik.setFieldValue('country', options[0].value);
              loadCities(options[0].value);
            }}
          />
          <Dropdown
            defaultOptions={
              formik.values.city
                ? [getDDOption({ label: formik.values.city })]
                : []
            }
            label="City"
            disabled={!formik.values.country}
            options={(formik.values.country
              ? getCitiesByCountry(formik.values.country)
              : []
            ).map((city) => getDDOption({ label: city }))}
            onSelect={(options) =>
              formik.setFieldValue('city', options[0].value)
            }
          />
        </FormControl>
      )}
    </Box>
  );
};
