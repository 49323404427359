import { useCallback, useEffect, useState } from 'react';

import { IUserEducation as IExpertEducation } from 'common/models';
import {
  deleteExpertEducation,
  getExpertEducations,
  updateExpertEducations,
} from 'expert/services';

export const useExpertEducation = (expertId: string) => {
  const [isExpertEducationLoading, setIsExpertEducationLoading] =
    useState(true);
  const [expertEducations, setExpertEducations] = useState<
    Array<IExpertEducation>
  >([]);

  useEffect(() => {
    if (!expertId) {
      return;
    }
    setIsExpertEducationLoading(true);
    getExpertEducations(expertId)
      .then((res) => setExpertEducations(res))
      .catch((err) => {
        alert('Failed to fetch expert project');
        console.error(err); // TO DO use sentry;
      })
      .finally(() => setIsExpertEducationLoading(false));
  }, [expertId]);

  const onDeleteExpertEducation = async (educationId: string) => {
    try {
      setIsExpertEducationLoading(true);
      await deleteExpertEducation(educationId);
      setExpertEducations(
        expertEducations.filter((education) => education.id !== educationId)
      );
    } catch (error) {
      alert('Failed to delete user educations');
      console.error(error); // TO DO use sentry;
    } finally {
      setIsExpertEducationLoading(false);
    }
  };

  const onUpdateExpertEducations = useCallback(
    async (educations: Array<IExpertEducation>) => {
      try {
        setIsExpertEducationLoading(true);
        const updatedEducations = await updateExpertEducations(
          expertId,
          educations
        );
        setExpertEducations(updatedEducations);
      } catch (error) {
        alert('Failed to update user Educations');
        console.error(error); // TO DO use sentry;
      } finally {
        setIsExpertEducationLoading(false);
      }
    },
    [expertId]
  );

  return {
    expertEducations,
    isExpertEducationLoading,
    onDeleteExpertEducation,
    onUpdateExpertEducations,
  };
};
