import { ComponentProps } from 'react';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';

type DataGridProps = ComponentProps<typeof DataGrid>;

export type TableRowParams = GridRowParams;

export const Table = ({
  columns,
  disableRowSelectionOnClick = true,
  loading = false,
  onCellClick = () => ({}),
  onRowClick = () => ({}),
  onPaginationModelChange,
  paginationMode = 'client',
  paginationModel,
  pageSizeOptions = [],
  rowCount,
  rows,
  slots = {},
  styles = {},
}: {
  columns: DataGridProps['columns'];
  disableRowSelectionOnClick?: boolean;
  loading?: DataGridProps['loading'];
  onCellClick?: DataGridProps['onCellClick'];
  onRowClick?: DataGridProps['onRowClick'];
  onPaginationModelChange?: DataGridProps['onPaginationModelChange'];
  paginationMode?: DataGridProps['paginationMode'];
  paginationModel?: DataGridProps['paginationModel'];
  pageSizeOptions?: DataGridProps['pageSizeOptions'];
  rowCount?: DataGridProps['rowCount'];
  rows: DataGridProps['rows'];
  slots?: DataGridProps['slots'];
  styles?: DataGridProps['sx'];
}) => {
  const serverSidePaginationProps: Partial<DataGridProps> = {
    onPaginationModelChange,
    paginationModel,
    rowCount,
  };

  const clientSidePaginationProps: Partial<DataGridProps> = {
    initialState: {
      pagination: {
        paginationModel,
      },
    },
  };

  return (
    <DataGrid
      columns={columns}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      loading={loading}
      onCellClick={onCellClick}
      onRowClick={onRowClick}
      pagination
      paginationMode={paginationMode}
      pageSizeOptions={pageSizeOptions}
      rows={rows}
      rowHeight={45}
      slots={slots}
      isCellEditable={() => false}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          color: 'primary.main',
          fontSize: '16px',
          fontWeight: 700,
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-root': {
          border: 'none',
        },
        '& .MuiDataGrid-withBorderColor': {
          border: 'none',
        },
        border: 'none',
        borderRadius: '16px',
        boxShadow:
          '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
        minHeight: '340px',
        px: '12px',
        ...styles,
      }}
      {...(paginationMode === 'server'
        ? serverSidePaginationProps
        : clientSidePaginationProps)}
    />
  );
};
