import { useContext } from 'react';
import { FormikProps, useFormik } from 'formik';

import { Dropdown, Form, FormControl } from 'design-library-v2';
import { IExpertSkill } from 'common/models';
import { AppContext } from 'common/contexts';
import { SkillDropdownOption } from 'common/types';

import { ExpertContext } from 'expert/context';
import { updateExpertSkills } from 'expert/services';

type SkillsFormData = {
  skills: Array<IExpertSkill>;
};

export function Skills() {
  const { skills } = useContext(AppContext);
  const { currentExpert, updateCurrentExpert } = useContext(ExpertContext);

  const formik: FormikProps<SkillsFormData> = useFormik<SkillsFormData>({
    initialValues: {
      skills: currentExpert?.skills || [],
    },
    onSubmit: async (values: SkillsFormData, { setSubmitting }) => {
      try {
        const updatedExpert = await updateExpertSkills(
          currentExpert?.id as string,
          values.skills.map((skill) => ({
            id: skill.id,
            isPublic: true,
            name: skill.name,
            skillId: skill.skillId,
            userId: currentExpert?.id as string,
          }))
        );
        updateCurrentExpert(updatedExpert);
      } catch (error) {
        alert('Failed to update!!'); // FIX ME
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Form<SkillsFormData> formik={formik}>
      <FormControl>
        <Dropdown<SkillDropdownOption>
          allowMultiSelect
          disabled={formik.isSubmitting}
          defaultOptions={formik.values.skills.map((skill) => ({
            id: skill.id,
            isPublic: skill.isPublic,
            label: skill.name,
            value: skill.skillId,
          }))}
          label="Tell us your skills"
          onSelect={(options: Array<SkillDropdownOption>) => {
            formik.setValues({
              skills: options.map((option) => ({
                id: option.id as string,
                isPublic: Boolean(option.isPublic),
                name: option.label,
                skillId: option.value,
                userId: currentExpert?.id as string,
              })),
            });
          }}
          options={skills.map((skill) => ({
            id: crypto.randomUUID(),
            isPublic: skill.isPublic,
            label: skill.name,
            value: skill.id,
          }))}
          placeholder="Search here"
        />
      </FormControl>
    </Form>
  );
}
