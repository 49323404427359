import React, { useContext, useState } from 'react';

import {
  TextField,
  SendIcon,
  MUIButton,
  MUIBox,
  Stack,
} from 'design-library-v2';

import { MessagingContext } from 'messaging/context';

import { ConversationBody, ConversationHeader } from './conversation';

const MessageBox = () => {
  const [message, setMessage] = useState<string>('');

  const { selectedMessagingUser, sendMessageToSelectedUser } =
    useContext(MessagingContext);

  const onSendMessage = (): void => {
    if (message) {
      setMessage('');
      sendMessageToSelectedUser(message);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<
      HTMLDivElement | HTMLTextAreaElement | HTMLInputElement
    >
  ) => {
    if (event?.ctrlKey && event?.key === 'Enter') {
      onSendMessage();
    }
  };

  return (
    <Stack py={1} spacing={4} width="72%">
      <Stack>
        <ConversationHeader />
        <ConversationBody />
      </Stack>

      <MUIBox gap={2} px={5} py={2}>
        <MUIBox flexGrow={1} py={2}>
          <TextField
            disabled={!selectedMessagingUser}
            placeholder="Your message"
            multiline
            variant="standard"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            onKeyDown={handleKeyDown}
            fullWidth={true}
          />
        </MUIBox>
        <MUIBox justifyContent="center" alignItems="center">
          <MUIButton
            disabled={!message.trim()}
            variant="contained"
            onClick={onSendMessage}
            endIcon={<SendIcon />}
          >
            Send
          </MUIButton>
        </MUIBox>
      </MUIBox>
    </Stack>
  );
};

export default MessageBox;
