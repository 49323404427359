import React from 'react';
import { Icon, Text, Input } from 'design-library';
import { Button } from 'design-library-v2';

import { SearchIcon, LocationIcon } from 'common/icons';
import {
  TopBannerIcon,
  ExpertHireCardIcon1,
  ExpertHireCardIcon2,
  ExpertHireCardIcon3,
  ExpertHireCardIcon4,
  ExpertHireCardIcon5,
  ExpertHireCardIcon6,
} from 'common/images';

import './TopBanner.scss';

const experts = [
  {
    className: '',
    description: 'One platform for both full time and part time jobs',
    icon: ExpertHireCardIcon1,
    id: 1,
  },
  {
    className: 'secondCard',
    description:
      'Using AI to find the best candidate for your company for full time position(ExpertPlus)',
    icon: ExpertHireCardIcon2,
    id: 2,
  },
  {
    className: '',
    description: 'Find the best expert on the field',
    icon: ExpertHireCardIcon3,
    id: 3,
  },
  {
    className: '',
    description: 'Find expert based on location for onsite jobs  ',
    icon: ExpertHireCardIcon4,
    id: 4,
  },
  {
    className: 'fifthCard',
    description: 'Provide 24/7 support for headhunting expert (ExpertPlus)',
    icon: ExpertHireCardIcon5,
    id: 5,
  },
  {
    className: '',
    description: 'Find expert based on language preference',
    icon: ExpertHireCardIcon6,
    id: 6,
  },
];

const TopBanner = () => {
  return (
    <div className="laningPage">
      <div className="landingPageTopBanner">
        <div className="landingPageTopBanner-leftPart">
          <Text
            className="landingPageTopBanner-leftPart-heading-text"
            text="Connecting experts across horizons"
          />
          <Text
            className="landingPageTopBanner-leftPart-normal-text"
            text="Your audiences are hanging around the internet and in social media.
            They are engaging with their favorite brands on a whole new way and level. Do not let your business
            get left behind."
          />
          <Text
            className="landingPageTopBanner-leftPart-second-heading-text"
            text="Trending Jobs keyword :"
          />

          <div className="landingPageTopBanner-leftPart-keywords">
            <Text
              className="landingPageTopBanner-leftPart-keywords-text"
              text="Web Designer"
            />
            <Text
              className="landingPageTopBanner-leftPart-keywords-text"
              text="UI/UX Designer"
            />
            <Text
              className="landingPageTopBanner-leftPart-keywords-text"
              text="Frontend"
            />
          </div>

          <div className="landingPageTopBanner-leftPart-boxWrapper">
            <div className="landingPageTopBanner-leftPart-boxWrapper-item">
              <Icon
                className="landingPageTopBanner-leftPart-boxWrapper-icon"
                icon={SearchIcon}
              />
              <Input
                className="landingPageTopBanner-leftPart-boxWrapper-input"
                placeholder="Job title or keyword"
              />
              <div className="landingPageTopBanner-leftPart-boxWrapper-vertical-line"></div>
            </div>
            <div className="landingPageTopBanner-leftPart-boxWrapper-item">
              <Icon
                className="landingPageTopBanner-leftPart-boxWrapper-icon"
                icon={LocationIcon}
              />
              <Input
                className="landingPageTopBanner-leftPart-boxWrapper-input"
                placeholder="Bandung, Indonesia"
              />
            </div>
            <div className="landingPageTopBanner-leftPart-boxWrapper-searchBtn">
              <Button
                text="Search"
                onClick={() => window.location.replace('/expert')}
              />
            </div>
          </div>
        </div>
        <div className="landingPageTopBanner-rightPart">
          <Icon
            icon={TopBannerIcon}
            className="landingPageTopBanner-rightPart-icon"
          />
        </div>
      </div>

      <div className="expertHire">
        <Text className="expertHire-heading" text="Why Experthire" />
        <Text
          className="expertHire-description"
          text="With us on this platform you can access all versatility of
          jobs having 
          options to level up the experience on a certain expertise. Furthermore, you as a recruiter have
          opportunities to have any kind of expert based on level
          of skills, languages, location whether local or remote."
        />

        <div className="expertHire-cardWrapper">
          {experts.map((item) => {
            return (
              <div
                key={item.id}
                className={`expertHire-cardContainer ${item.className}`}
              >
                <div className="expertHire-cardContainer-item">
                  <div className="expertHire-cardContainer-item-iconDiv">
                    <Icon
                      className="expertHire-cardContainer-item-iconDiv-icon"
                      icon={item.icon}
                    />
                  </div>
                  <Text
                    className="expertHire-cardContainer-item-description"
                    text={item.description}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
