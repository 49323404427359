import { Outlet } from 'react-router-dom';
import { Navbar } from 'navbar';

function RootPage() {
  return (
    <div className="App">
      <Navbar />
      <div className="main">
        <Outlet />
      </div>
    </div>
  );
}

export default RootPage;
